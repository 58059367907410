import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type transitionEffectDataType = {
    showing: boolean,
    phaseSlug?: string
}

type initialStateType = {
    id: string,
    transitionEffect: transitionEffectDataType,
    currentActivityId: string,
    showSurveyModal: boolean,
}

const initialState: initialStateType = {
    id: '0',
    transitionEffect: {
        showing: false,
    },
    currentActivityId: '0',
    showSurveyModal: true,
}

const CurrentPhaseSlice = createSlice({
    name: 'currentPhase',
    initialState,
    reducers: {
        resetCurrentPhaseData: (state) => {
            return initialState;
        },
        setCurrentPhaseId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                id: action.payload
            }
        },
        setTransitionEffect: (state, action: PayloadAction<transitionEffectDataType>) => {
            return {
                ...state,
                transitionEffect: action.payload
            }
        },
        setCurrentActivityId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                currentActivityId: action.payload
            }
        },
        setShowSurveyModal: (state, action) => {
            return {
                ...state,
                showSurveyModal: action.payload
            }
        }
    }
});

export default CurrentPhaseSlice.reducer;
export const { setCurrentPhaseId, setCurrentActivityId, setTransitionEffect, resetCurrentPhaseData, setShowSurveyModal } = CurrentPhaseSlice.actions;
import { Switch } from "@headlessui/react";
import { FC, ReactNode } from "react";
import { Avatar } from "rizzui";
import { DAYS_DISPLAY_NAME } from "../../redux/configuration/ConfigurationSlice";
import { PatientGoal } from "../../redux/dashboard/DashboardSlice";

type GoalCardProps = {
  goal: PatientGoal;
  toggleRequestFlag?:boolean;
  toggleSwitchHandler: () => void;
  setAsTargetGoal: () => void;
};

const GoalCard: FC<GoalCardProps> = ({
  goal,
  toggleSwitchHandler,
  setAsTargetGoal,
  toggleRequestFlag
}) => {
  const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL;

  const remindersDaysString = (): ReactNode => {
    if (goal.notificationDays?.length === 0 || !goal.notificationDays) {
      return "As no day is selected, Goal is now deactivated";
    }

    return goal.notificationDays
      ?.map((reminderDay) => DAYS_DISPLAY_NAME[reminderDay])
      .join(", ");
  };

  return (
    <div
      className={`flex flex-col w-full p-3 rounded-md mb-4`}
      style={{ background: `${goal.journeyGoal.backgroundColor}` }}
    >
      <div className="grid grid-cols-12 gap-8 mb-3">
        <div className="col-start-1 col-end-10">
          <div className="flex items-center gap-4">
            <Switch
              checked={goal.active}
              onChange={toggleSwitchHandler}
              disabled={toggleRequestFlag}
              className={`group inline-flex h-7 min-w-[48px] items-center rounded-full shadow-inner ${goal.active ? "bg-[#19B394]" : "bg-gray-300"
                }  transition data-[checked]:bg-blue-600`}
            >
              <span
                className={`block h-[80%] w-1/2 rounded-full transition shadow-inner duration-300 ease-in-out bg-white transform ${goal.active ? "translate-x-[90%]" : "translate-x-[10%]"
                  }`}
              ></span>
            </Switch>
            <span>{goal.journeyGoal.title}</span>
          </div>
        </div>
        <div className="col-start-10 col-end-13">
          <div className="flex justify-end gap-4">
            <div className="flex">
              <Avatar
                name={goal.journeyGoal.tooltip ? goal.journeyGoal.tooltip : ""}
                src={`${CLOUDFRONT_URL && CLOUDFRONT_URL.endsWith("/")
                  ? CLOUDFRONT_URL
                  : `${CLOUDFRONT_URL}/`
                  }${goal?.journeyGoal?.image?.s3FilePath}`}
                size="sm"
              />
            </div>
          </div>
        </div>
      </div>

      {!goal?.notificationDays || !goal.notificationTimes || goal?.notificationDays?.length === 0 || goal.notificationTimes?.length === 0 ? (
        <div className="flex w-full">
          <div className="w-full p-4 mb-3" role="alert">
            <p className="text-center">Once you enable the goal, the reminder schedule will appear here.</p>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col">
            {goal.journeyGoal.description && (
              <p className="text-xs mb-3 px-8">
                {goal.journeyGoal.description}
              </p>
            )}

            <div className="text-center flex flex-col justify-center">
              <p className="mb-1 text-sm">
                In your Journey:{" "}
                <span className="font-semibold">{remindersDaysString()}</span>
              </p>
              <p className="mb-3 text-sm">
                Reminders:{" "}
                <span className="font-semibold">
                  {goal.notificationTimes && goal.notificationTimes?.length > 0 ? goal.notificationTimes
                    ?.map(
                      (notificationTime) => notificationTime.notificationTime
                    )
                    .join(", ") : "As no time is selected, Goal is now deactivated"}
                </span>
              </p>
            </div>
          </div>
        </>
      )}

      <div className="flex justify-center">
        <button
          className={`tw-btn text-white bg-[#19B394]  block font-medium rounded-lg py-1 px-4 capitalize`}
          onClick={setAsTargetGoal}
        >
          More Options
        </button>
      </div>
    </div>
  );
};
export default GoalCard;

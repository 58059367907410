import { useEffect, useState } from "react";

import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { fetchSymptomEntries } from "../../../../core/requests/_requests";
import { TrandDownIcon, TrandUPIcon } from "../../../../Images/icons/Icons";
import { SymptomEntry } from "../symptom-tracker/SymptomTracker";
import { PeeDiaryChart } from "./peeDiaryChart";
import { WeeklyMoodChart } from "./weeklyMoodChart";

const DiaryCharts = ({ gotoSymptomsTab, className }: { gotoSymptomsTab: () => void; className?: string }) => {
  const [weeklyMoodTrend, setWeeklyMoodTrend] = useState<string>('');
  const [peeDiaryTrend, setPeeDiaryTrend] = useState<string>('')
  const [symptomcheckins, setSymptomcheckins] = useState<SymptomEntry[]>([]);

  const fetchData = async () => {
    const res: SymptomEntry[] = await fetchSymptomEntries({});
    setSymptomcheckins(res);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getTrendIcon = (trend: string) => {
    switch (trend) {
      case 'up':
        return <TrandUPIcon />;
      case 'neutral':
        return <ArrowRightIcon strokeWidth="2" className="h-5 w-6 mt-[3px] text-yellow-500" />;
      case 'down':
        return <TrandDownIcon />;
      default:
        return null;
    }
  };

  return (
    <div className={`flex flex-row ${className} gap-3`}>
      <div className="w-1/2 rounded-lg p-3 bg-[#D9D9D9]">
        <div className="flex">
          <h5 className="text-sm font-bold mb-2 mr-1">Weekly Mood</h5>
          {symptomcheckins?.length > 0 && (
            <span className="mt-[-3px]">
              {getTrendIcon(weeklyMoodTrend)}
            </span>
          )}
        </div>
        <div className={`${symptomcheckins?.length === 0 ? 'bg-[#D9D9D9] rounded-lg' : 'bg-[#EAE9E3] rounded-lg'}`}>
          <WeeklyMoodChart journalEntries={symptomcheckins} gotoSymptomsTab={gotoSymptomsTab} weeklyMoodTrendData={(data: string) => { setWeeklyMoodTrend(data) }} />
        </div>
      </div>
      <div className="w-1/2 rounded-lg p-3 bg-[#D9D9D9]">
        <div className="flex">
          <h5 className="text-sm font-bold mb-2 mr-1">Pee Dairy</h5>
          {symptomcheckins?.length > 0 && (
            <span className="mt-[-3px]">
              {getTrendIcon(peeDiaryTrend)}
            </span>
          )}
        </div>
        <div className={`${symptomcheckins?.length === 0 ? 'bg-[#D9D9D9] rounded-lg' : 'bg-[#EAE9E3] rounded-lg'}`}>
          <PeeDiaryChart journalEntries={symptomcheckins} gotoSymptomsTab={gotoSymptomsTab} trendData={(data: string) => { setPeeDiaryTrend(data) }} />
        </div>
      </div>
    </div>
  );
};
export default DiaryCharts;

import { EntryParameter } from "../types/journal";
import { USDateformat } from "./DateFormat";
import {
  getJournalCSVContent,
  getJournalPDFContent,
} from "./requests/_requests";

/** This function is used to calculate the days in between two dates */
export const calculateDaysInBetween = (fromDate: Date, toDate: Date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  const diffDays = Math.round(
    Math.abs((toDate.getTime() - fromDate.getTime()) / oneDay)
  );
  return diffDays;
};

/** This function is used to calculate the year from the given date to current date */
export const getAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

/**
 * Executes a callback function after a specified delay.
 *
 * @param {Function} callback - The function to be executed after the delay.
 * @param {number} [duration=1000] - The delay duration in milliseconds before executing the callback. Defaults to 1000 milliseconds (1 second).
 * @returns {number} - The identifier of the timeout, which can be used to cancel the timeout with clearTimeout().
 */
export const callWithDelay = (
  callback: Function,
  duration: number = 1000
): number => {
  return setTimeout(callback, duration);
};

/**
 * Formats a given time in seconds into a string with minutes and seconds.
 * The format is "MM:SS", where MM is the number of minutes and SS is the
 *
 * @param {number} seconds - The total time in seconds to be formatted.
 * @returns {string} The formatted time string in "MM:SS" format.
 */
export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const videoMimeTypes: { [key: string]: string } = {
  mp4: "video/mp4",
  webm: "video/webm",
};

const imageMimeTypes: { [key: string]: string } = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  bmp: "image/bmp",
  webp: "image/webp",
};

export const generateMediaHTML = (mediaPath: string, fileName: string): any => {
  const ext = mediaPath.split(".").pop();
  const extension = ext ? ext.toLowerCase() : "";
  if (extension in videoMimeTypes) {
    return `
      <div class="media-center">
        <video width="300" height="200" controls>
          <source src="${mediaPath}" type="${videoMimeTypes[extension]}">
          Your browser does not support the video tag.
        </video>
      </div>`;
  } else if (extension in imageMimeTypes) {
    return `
      <div class="media-center">
        <img src="${mediaPath}" type="${imageMimeTypes[extension]}" alt="${fileName}" width="300" height="200">
      </div>`;
  } else if (extension === 'mp3') {
    return `
      <div class="media-center">
        <audio controls>
      <source src="${mediaPath}" type="audio/mpeg">
        </audio>
      </div>`;
  } else if (["pdf"].includes(extension)) {
    return `
       <div class="media-center flex flex-col items-center">
    <div download title="${fileName}" onClick="window.open('${mediaPath}', '_self')" class="cursor-pointer">
        <svg fill="#000000" width="50" height="50" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="evenodd">
                <path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z"/>
                <path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z"/>
            </g>
        </svg>
    </div>
   
    <input class="text-ellipsis whitespace-nowrap w-[100px] pointer-events-none"  type="text" value=${fileName} readonly>
   
</div>
      `;
  } else return "";
};

export const generateFileName = (extension: string): string => {
  const date = new Date();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `journal_log_data_${USDateformat(date.toString(), "-", [
    "y",
    "m",
    "d",
  ])} ${hours}_${minutes}_${seconds}.${extension}`;
};

export const handleReportExport = async (
  patientId: number,
  format: "pdf" | "csv"
) => {
  if (patientId) {
    try {
      const response =
        format === "csv"
          ? await getJournalCSVContent()
          : await getJournalPDFContent();
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", generateFileName(format));
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error downloading report:", err);
    }
  }
};

export const debounce = <T extends (...args: any[]) => void>(func: T, timeout = 300): (...args: Parameters<T>) => void => {
  let timer: NodeJS.Timeout | undefined;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(null, args);
    }, timeout);
  };
}

export const extractTextFromHTML = (htmlString: any) => {
  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  return tempDiv.innerText || tempDiv.textContent || "";
}

export const checkForContent = (htmlContent: string): boolean => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;

  const textContent = tempDiv.textContent?.trim() || "";
  if (textContent.length > 0) {
    return true;
  }

  const mediaElements = tempDiv.querySelectorAll("img, video, audio");
  if (mediaElements.length > 0) {
    return true;
  }

  const fileLinks = tempDiv.querySelectorAll("input");
  const hasFileAttachment = Array.from(fileLinks).some((element) => {
    const value = element.getAttribute("value");
    if (value) {
      const fileExtensions = [
        "pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "zip", "rar",
        "jpg", "jpeg", "png", "gif", "svg", "mp3", "mp4", "avi", "mkv"
      ];
      return fileExtensions.some((ext) => value.toLowerCase().endsWith(`.${ext}`));
    }
    return false;
  });

  if (hasFileAttachment) {
    return true;
  }

  const hasInlineFile = Array.from(mediaElements).some((element) => {
    const src = element.getAttribute("src");
    return src?.startsWith("data:") || src?.startsWith("blob:");
  });

  return hasInlineFile;
};

export const areParametersEmpty = (params: EntryParameter[]): boolean => {
  const emptyCriteria = [
    { category: 'intake', data: { soda: 0, coffee: 0, water: 0 } },
    { category: 'leakage', data: { count: 0, urge: true } },
    { category: 'voiding', data: { count: 0, amount: 0 } },
    { category: 'Urination', data: { Volume: undefined, 'No. of times': 0 } },
    { category: 'Accidental leaks', data: { Volume: 0 } },
  ];

  return JSON.stringify(params) === JSON.stringify(emptyCriteria);
};

export const times: string[] = Array.from({ length: 48 }, (_, i) => {
  let hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? '00' : '30';
  const period = hour < 12 ? 'AM' : 'PM';
  hour = hour % 12 || 12;
  return `${String(hour).padStart(2, '0')}:${minute} ${period}`;
});

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const isSameDate = (date1: Date, date2: Date | null) => {
  if (date2 == null) return;
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

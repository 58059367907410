import classNames from "classnames";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { alertNotification } from "../../../../core/alert/ToastAlert";
import { tabBlue, tabGreen, tabNames, tabPink } from "../../../../core/constants/color_constan";
import { createSymptomEntry, updateSymptomEntry } from "../../../../core/requests/_requests";
import { formatDate } from "../../../../core/utilities";
import { CheckMarkSvg } from "../../../../Images/icons/Icons";
import { EntryParameter, ParameterCategory } from "../../../../types/journal";
import Liquids, { LiquidOption } from "./Liquids";
import Symptoms from "./Symptoms";
import { SymptomEntry } from "./SymptomTracker";
import Voiding, { VoidingDataType } from "./Voiding";

const { INTAKE, LEAKAGE, VOIDING, MOOD } = ParameterCategory;

const defaultIntakeData = {
  [MOOD]: { mood: 0 },
  [INTAKE]: {
    soda: 0,
    wine: 0,
    water: 0,
    coffee: 0,
  },
  [LEAKAGE]: { count: 0, urge: true },
  [VOIDING]: { count: 0, amount: 0 },
  voidingData: {
    urge: false,
    leakage: 0,
    voiding: 0,
    amount: 0,
  }
};

const isDefaultLiquidCounts = (counts: {
  soda: number,
  coffee: number,
  wine: number,
  water: number,
}) => JSON.stringify(counts) === JSON.stringify(defaultIntakeData[INTAKE]);

const isDefaultVoidingData = (data: VoidingDataType) => JSON.stringify(data) === JSON.stringify(defaultIntakeData.voidingData);

const tabs: {
  name: string;
  color: string;
}[] = [
    { name: tabNames[0], color: tabPink },
    { name: tabNames[1], color: tabBlue },
    { name: tabNames[2], color: tabGreen },
  ];

const getActiveTabColor = (activeTab: string) => {
  const activeTabData = tabs.find(tab => tab.name === activeTab);
  return activeTabData ? activeTabData?.color : "";
};

type SymptomCheckInTabsProps = {
  selectedDate: Date;
  symptomData: SymptomEntry[];
  completeClick: boolean;
  setCompleteClick: Dispatch<SetStateAction<boolean>>;
  setNavActionDisabled: Dispatch<
    SetStateAction<{
      back: boolean;
      forward: boolean;
    }>
  >;
  fetchData: () => void;
};

const SymptomCheckInTabs: React.FC<
  SymptomCheckInTabsProps
> = ({
  symptomData,
  selectedDate,
  completeClick,
  setCompleteClick,
  setNavActionDisabled,
  fetchData,
}: SymptomCheckInTabsProps) => {
    const [entryParameters, setEntryParameters] = useState<EntryParameter[]>([]);
    const [activeTab, setActiveTab] = useState<string>(tabs[0].name);
    const [mood, setMood] = useState<number>(0);
    const [liquidCounts, setLiquidCounts] = useState<Record<LiquidOption["name"], number>>(defaultIntakeData[INTAKE]);
    const [voidingData, setVoidingData] = useState<VoidingDataType>(defaultIntakeData.voidingData);

    const handleSave = async () => {
      const dataExists = symptomData.find(symptom => symptom.date === formatDate(selectedDate));
      const sendData = {
        date: formatDate(selectedDate),
        parameters: entryParameters,
      };
      if (dataExists) {
        await updateSymptomEntry(sendData)
        alertNotification("success", "Record updated successfully");
      } else {
        await createSymptomEntry(sendData)
        alertNotification("success", "Record created successfully");
      }
      fetchData();
      setCompleteClick(false);
    };

    const resetValuesHandler = () => {
      if (activeTab === tabNames[0]) setMood(0)
      if (activeTab === tabNames[1]) setLiquidCounts(defaultIntakeData[INTAKE])
      if (activeTab === tabNames[2]) setVoidingData(defaultIntakeData.voidingData)
    }

    useEffect(() => {
      setNavActionDisabled({
        forward: false,
        back: true,
      });
      const dataExists = symptomData.find(symptom => symptom.date === formatDate(selectedDate));
      const liquidDataCount = dataExists?.parameters.find((item: EntryParameter) => item.category === ParameterCategory.INTAKE)
      const moodDataCount = dataExists?.parameters.find((item: EntryParameter) => item.category === ParameterCategory.MOOD)
      const voidDataCount = dataExists?.parameters.find((item: EntryParameter) => item.category === ParameterCategory.VOIDING)
      const leakageDataCount = dataExists?.parameters.find((item: EntryParameter) => item.category === ParameterCategory.LEAKAGE)

      if (dataExists) {
        setLiquidCounts(liquidDataCount ? liquidDataCount.data : defaultIntakeData[INTAKE]);
        setMood(moodDataCount ? moodDataCount.data.mood : 0);
        setVoidingData({
          urge: leakageDataCount?.data.urge,
          leakage: leakageDataCount?.data.count,
          voiding: voidDataCount?.data.count,
          amount: voidDataCount?.data.amount
        });
      } else {
        setMood(0)
        setLiquidCounts(defaultIntakeData[INTAKE])
        setVoidingData(defaultIntakeData.voidingData)
      }

    }, [selectedDate, symptomData, setNavActionDisabled]);

    useEffect(() => {
      setEntryParameters((prevParameters) => {
        const newParameters = prevParameters.filter((param) => param.category !== ParameterCategory.MOOD);
        newParameters.push({
          category: ParameterCategory.MOOD,
          data: { mood: mood },
        });
        return newParameters;
      });
    }, [mood]);

    useEffect(() => {
      setEntryParameters((prevParameters) => {
        const newParams = prevParameters.filter((param) => param.category !== ParameterCategory.INTAKE);
        newParams.push({
          category: ParameterCategory.INTAKE,
          data: liquidCounts,
        });
        return newParams;
      });
    }, [liquidCounts]);

    useEffect(() => {
      setEntryParameters((prevParameters) => {
        const filteredParameters = prevParameters.filter((param) => param.category !== ParameterCategory.LEAKAGE && param.category !== ParameterCategory.VOIDING);

        const leakageParam = {
          category: ParameterCategory.LEAKAGE,
          data: { count: voidingData.leakage, urge: voidingData.urge },
        };
        const voidingParam = {
          category: ParameterCategory.VOIDING,
          data: { count: voidingData.voiding, amount: voidingData.amount },
        };

        return [...filteredParameters, leakageParam, voidingParam];
      });
    }, [voidingData]);

    useEffect(() => {
      if (completeClick) {
        handleSave();
      }
    }, [completeClick]);

    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            value={activeTab}
            onChange={(e) => setActiveTab(e.target.value)}
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.name}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden sm:block">
          <nav aria-label="Tabs" className="isolate flex gap-1 rounded-t-lg">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  setActiveTab(tab.name);
                  setNavActionDisabled({ forward: false, back: true });
                }}
                aria-current={activeTab === tab.name ? "page" : undefined}
                className={classNames(
                  activeTab === tab.name ? "text-gray-900" : "",
                  "group rounded-t-lg relative min-w-0 flex-1 overflow-hidden bg-transparent px-4 py-2 text-center text-sm font-medium hover:bg-white focus:z-10"
                )}
                style={{
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  borderLeft: "1px solid black",
                  backgroundColor: activeTab === tab.name ? tab.color : "transparent",
                  ...(activeTab !== tab.name && {
                    "--hover-bg": tab.color,
                  } as React.CSSProperties),
                }}
                onMouseEnter={(e) => { if (activeTab !== tab.name) (e.currentTarget.style as any).backgroundColor = tab.color; }}
                onMouseLeave={(e) => { if (activeTab !== tab.name) (e.currentTarget.style as any).backgroundColor = "transparent"; }}
              >
                <span className="flex gap-1 items-center">
                  {tab.name}
                  {tab.name === tabNames[0] && mood !== 0 && <CheckMarkSvg height={14} width={14} />}
                  {tab.name === tabNames[1] && !isDefaultLiquidCounts(liquidCounts) && <CheckMarkSvg height={14} width={14} />}
                  {tab.name === tabNames[2] && !isDefaultVoidingData(voidingData) && <CheckMarkSvg height={14} width={14} />}
                </span>
              </button>
            ))}
          </nav>
        </div>

        <div className={`p-4 rounded-b-lg min-h-[calc(72vh-187px)] max-h-[calc(72vh-351px)] overflow-y-auto scrollbar`}
          style={{ borderBottom: "1px solid black", borderRight: "1px solid black", borderLeft: "1px solid black", background: getActiveTabColor(activeTab) }}
        >
          {activeTab === tabNames[0] && <Symptoms {...{ mood, setMood, resetValuesHandler }} />}
          {activeTab === tabNames[1] && <Liquids {...{ liquidCounts, setLiquidCounts, resetValuesHandler }} />}
          {activeTab === tabNames[2] && <Voiding {...{ voidingData, setVoidingData, resetValuesHandler }} />}
        </div>
      </div>
    );
  };

export default SymptomCheckInTabs;

import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Tooltip } from "rizzui";
import { USDateformat } from "../../../../core/DateFormat";
import {
  getJournalCSVContent,
  getJournalPDFContent
} from "../../../../core/requests/_requests";
import { ExportIcon, NavAdd, NavSearch } from "../../../../Images/icons/Icons";
import { RootState, useAppSelector } from "../../../../redux/store";
import { Patient } from "../../../../redux/user/UserSlice";
import { JournalEntry } from "../../../../types/journal";
import Sorting from "../../../UI/sort";

type JournalNavProps = {
  tabKey: number;
  tabValue: string;
  entryLength: number;
  journalEntries: JournalEntry[];
  setJournalEntries: Dispatch<SetStateAction<JournalEntry[]>>;
  toggleEditor: (tabKey: number) => void;
  setSearchFilter: Dispatch<SetStateAction<string | number>>;
  setIsSearchVisible: Dispatch<SetStateAction<boolean>>;
  setNoFilterEntriesFound: Dispatch<SetStateAction<boolean>>;
  // handleFilterCallback: (value: { type: string, value: string }) => void;
  setIsSort: Dispatch<SetStateAction<boolean>>;
  selectedDate: Date | null;
  removeFilter: () => void;
};

const JournalNav: FC<JournalNavProps> = ({
  tabKey,
  tabValue,
  entryLength,
  journalEntries,
  setJournalEntries,
  toggleEditor,
  setSearchFilter,
  setIsSearchVisible,
  setNoFilterEntriesFound,
  // handleFilterCallback,
  setIsSort,
  selectedDate,
  removeFilter
}) => {
  const [iconClick, setIconClick] = useState<string>();
  const patient: Patient = useAppSelector(
    (state: RootState) => state.user.authUser
  );

  const generateFileName = (extension: string): string => {
    const date = new Date();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `journal_log_data_${USDateformat(date.toString(), "-", [
      "y",
      "m",
      "d",
    ])} ${hours}_${minutes}_${seconds}.${extension}`;
  };
  useEffect(() => {
    if (selectedDate) {
      setIconClick("")
      setIsSearchVisible(false);
      setSearchFilter("");
      setNoFilterEntriesFound(false);
    }
  }, [selectedDate])

  const handleReportExport = async (format: "pdf" | "csv") => {
    if (patient?.id) {
      try {
        const response =
          format === "csv"
            ? await getJournalCSVContent()
            : await getJournalPDFContent();
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", generateFileName(format));
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.error("Error downloading report:", err);
      }
    }
  };

  const handleSortCallback = (value: { sortBy: string; order: string }) => {
    const sortedEntries = [...journalEntries].sort((entryA, entryB) => {
      if (
        value.sortBy === "time" &&
        entryA.updateTimestamp &&
        entryB.updateTimestamp
      ) {
        const dateA = new Date(entryA.updateTimestamp);
        const dateB = new Date(entryB.updateTimestamp);
        return value.order === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else {
        const titleA = entryA.title.toLowerCase();
        const titleB = entryB.title.toLowerCase();
        if (value.order === "asc") {
          return titleA.localeCompare(titleB);
        } else {
          return titleB.localeCompare(titleA);
        }
      }
    });
    setIsSort(true);
    setJournalEntries(sortedEntries);
  };

  const toggleSearch = () => {
    setIsSearchVisible((prev) => !prev);
    setSearchFilter("");
    setNoFilterEntriesFound(false);
    removeFilter();
  };

  return (
    <nav className="bg-white border-gray-200">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
        <div
          className="w-full items-center flex justify-between"
          id="navbar-default"
        >
          <p className="flex justify-end text-xs mr-3">Journal: {tabValue}</p>
          <div className="flex justify-end rounded-md items-center shadow-sm">
            {entryLength > 0 && (
              <>
                <Tooltip content={"Export"} placement="top">
                  <div
                    className="relative inline-flex items-center rounded-l-md bg-white px-2 py-[0.375rem] text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-1 cursor-pointer"
                    onClick={() => handleReportExport("pdf")}
                  >
                    <ExportIcon />
                  </div>
                </Tooltip>
                <Tooltip content={"Search"} placement="top">
                  <div
                    className="relative -ml-px inline-flex items-center bg-white px-2 py-[0.375rem] text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10 cursor-pointer"
                    onClick={() => {
                      toggleSearch();
                      setIconClick('search')
                    }
                    }
                  >
                    <NavSearch />
                  </div>
                </Tooltip>
              </>
            )}
            <Tooltip content={"Add Journal Entry"} placement="top">
              <div
                className="relative -ml-px inline-flex items-center bg-white px-2 py-[0.375rem] text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10 cursor-pointer"
                onClick={() => toggleEditor(tabKey)}
              >
                <NavAdd />
              </div>
            </Tooltip>
            {entryLength > 0 && (
              <>
                <div
                  className="relative -ml-px inline-flex items-center rounded-r-md bg-white text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-1 cursor-pointer"
                  onClick={() => {
                    if (iconClick === 'search' || iconClick === '') {
                      setIsSearchVisible(false);
                      setSearchFilter("");
                      setNoFilterEntriesFound(false);
                    }
                    setIconClick("sort");
                  }}
                >
                  <Sorting
                    handleSortCallback={handleSortCallback}
                    sortClick={iconClick}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default JournalNav;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addToFavouritesOrRemove,
  getVideoCategoriesByJourney,
  getVideosByJourney,
} from "../../core/requests/_requests";
import { Doctor } from "../doctor/DoctorSlice";

export type Video = {
  id: number;
  title?: string;
  subtitles: Subtitles[];
  description?: string;
  media_url?: string;
  poster_url?: string;
  category?: string;
  doctor?: Doctor;
  is_favorite?: boolean;
  download_url?: string;
};

export type MediaResponse = {
  createTimestamp: string;
  active: boolean;
  id: number;
  title: string;
  fileName: string;
  fileType: string;
  isUploadComplete: true;
  s3Path: string;
};

type Subtitles = {
  lang: string;
  language: string;
  url: string;
};

export type VideosList = {
  data: Video[];
  loading: boolean;
  error?: string | null;
};

export type Category = {
  id: number;
  title?: string;
  videos?: Video[];
};

type initialStateType = {
  videos: VideosList;
  categories: Category[];
  selectedVideo?: Video | null;
};

const initialState: initialStateType = {
  videos: {
    data: [],
    loading: false,
    error: null,
  },
  categories: [],
  selectedVideo: null,
};

export const fetchVideos = createAsyncThunk(
  "videoGallery/FetchVideos",
  async ({
    journeyId,
    params,
  }: {
    journeyId: number;
    params?: Parameters<typeof getVideosByJourney>["1"];
  }) => {
    try {
      const response = await getVideosByJourney(journeyId, params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCategories = createAsyncThunk(
  "videoGallery/FetchCategories",
  async (journeyId?: number) => {
    try {
      const response = await getVideoCategoriesByJourney(journeyId);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addOrRemoveFavourite = createAsyncThunk(
  "videoGallery/addOrRemoveFavourite",
  async (videoId: number) => {
    await addToFavouritesOrRemove(videoId);
    return videoId;
  }
);

const VideoGallerySlice = createSlice({
  name: "videoGallery",
  initialState,
  reducers: {
    setSelectedVideo: (state, action: PayloadAction<Video | null>) => {
      return {
        ...state,
        selectedVideo: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.videos.loading = true;
        state.videos.error = null;
      })
      .addCase(
        fetchVideos.fulfilled,
        (state, action: PayloadAction<Video[]>) => {
          state.videos.data = action.payload;
          state.videos.loading = false;
          state.videos.error = null;
        }
      )
      .addCase(fetchVideos.rejected, (state, action: PayloadAction<any>) => {
        state.videos.loading = false;
        state.videos.error = action.payload;
      })
      .addCase(
        addOrRemoveFavourite.fulfilled,
        (state, action: PayloadAction<number>) => {
          const indexOfObjectToToggle = state.videos.data.findIndex(
            (item: Video) => item.id === action.payload
          );
          state.videos.data[indexOfObjectToToggle].is_favorite =
            !state.videos.data[indexOfObjectToToggle].is_favorite;
        }
      )
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<Category[]>) => {
          state.categories = action.payload;
        }
      );
  },
});

export default VideoGallerySlice.reducer;
export const { setSelectedVideo } = VideoGallerySlice.actions;

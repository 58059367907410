import classNames from "classnames";
import { FC } from "react";
import { LibraryCategory } from "./VideoGallery";

interface VideoCategoryProps {
  allCategories: LibraryCategory[];
  selectedCategory: LibraryCategory;
  handleSelectCategory: (category: LibraryCategory) => void;
}

const VideoCategory: FC<VideoCategoryProps> = ({
  allCategories,
  selectedCategory,
  handleSelectCategory,
}) => {
  return (
    <div className="space-y-2">
      {allCategories.map((category: LibraryCategory) => (
        <button
          className={classNames(
            selectedCategory.title === category.title
              ? "bg-green-700"
              : "bg-black/50",
            "text-white hover:bg-green-700 tw-btn flex items-center justify-center rounded-lg w-full normal-case"
          )}
          onClick={() => handleSelectCategory(category)}
          key={category.id}
        >
          {category?.icon}
          <span className="ml-2">{category.title}</span>
        </button>
      ))}
    </div>
  );
};

export default VideoCategory;

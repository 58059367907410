import { FC } from "react";
import VideoPlayer from "../video-player/CustomVideoPlayer";

type InitialIntructions = {
  handleVideoEnd: () => void;
  src?: string;
  videoSubtitles?: any;
};

const InitialIntructions: FC<InitialIntructions> = ({
  handleVideoEnd,
  src = "",
  videoSubtitles,
}) => {
  return (
    <div className="fullscreen-video fixed top-0 left-0 w-full h-screen">
      <VideoPlayer
        src={src}
        subtitles={videoSubtitles}
        customEventHandlers={{
          ended: handleVideoEnd,
        }}
        customVideoEndButton={{
          show: true,
          //$ buttonText: "NEXT",
          endingEvent: handleVideoEnd,
        }}
      />
    </div>
  );
};

export default InitialIntructions;

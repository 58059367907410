import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";

type ConsentPopupProps = {
  /** Function to handle the action when the user declines the consent */
  onDecline: () => void;
  /** Function to handle the action when the user agrees to the consent */
  onAgree: () => void;
  /** Boolean to show or hide the close icon default {true} */
  showCloseIcon?: boolean;
} & (
  | { showCloseIcon: false; onClose?: never } // onClose is required when showCloseIcon is true
  | { showCloseIcon?: true; onClose?: () => void }
); // onClose is not required when showCloseIcon is false

/**
 * A popup component for consent agreements.
 *
 * @param {ConsentPopupProps} props - The props for the ConsentPopup component.
 * @returns A React component that displays a consent message with agree and decline buttons.
 */
const ConsentPopup: React.FC<ConsentPopupProps> = ({
  onDecline,
  onAgree,
  showCloseIcon = true,
  onClose,
}) => {
  return (
    <div className="fixed shadow-md inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-slate-50 rounded-lg shadow-lg p-3 max-w-3xl w-full">
        {/* Conditional rendering of Close icon */}
        {showCloseIcon && (
          <div className="float-right">
            <button
              className="flex justify-center items-center bg-gray-200 hover:bg-gray-300 p-2 rounded-full cursor-pointer"
              onClick={onClose}
            >
              <XMarkIcon className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        )}
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl text-gray-500 font-semibold">Consent</h2>
          </div>
          <p className="mb-4 leading-6 text-sm text-gray-700">
            You agree that your use of the Bering Health website and any uses of
            any services or materials are subject to your agreement with all of
            these Terms of Use, Copyright and Reprint Policy, and the Website
            Privacy Policy. You agree that you will not violate any local,
            state, federal or international laws in using this website or
            accessing any Material on this website.
          </p>
          <div className="flex justify-end space-x-4 mt-16">
            <div className="flex w-1/2 justify-between gap-4">
              <button
                className="flex-1 text-gray-500 border border-solid border-gray-400 hover:border-gray-800 hover:text-gray-700 px-4 py-2 rounded-md"
                onClick={onDecline}
              >
                I decline
              </button>
              <button
                className="flex-1 bg-green-dark-100 hover:bg-green-dark-200 text-white px-4 py-2 rounded-md"
                onClick={onAgree}
              >
                I agree
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsentPopup;

import {
  ComponentType,
  createElement,
  FC,
  lazy,
  ReactNode,
  Suspense,
} from "react";
import { Route, Routes } from "react-router-dom";
import IndexElement from "../../components/Index/IndexElement";
import LoadingContainer from "../../components/loader/LoadingContainer";
import JourneySelection from "../../components/patient/patient-home/JourneySelection";
import JourneyRoutes from "./JourneyRoutes";

const PersonalHealthRecord = lazy(
  () => import("../../components/personal-health-record/PersonalHealthRecord")
);
const SurveyFormUrl = lazy(
  () => import("../../components/survey-form-url/SurveyFormUrl")
);

const PrivateRoutes: FC = () => {

  return (
    <>
      <Routes>
        <Route index element={<IndexElement />} />
        <Route
          path="/personal-health-record"
          element={<LazyComponentWrapper component={PersonalHealthRecord} />}
        />
        <Route
          path="/journal-survey-form"
          element={<LazyComponentWrapper component={SurveyFormUrl} />}
        />
        <Route path="/journey-selection" element={<JourneySelection />} />
        <Route path="/:journeySlug/*" element={<JourneyRoutes />} />
      </Routes>
    </>
  );
};

const LazyComponentWrapper: FC<{
  component: ComponentType;
  FallBack?: ReactNode;
}> = ({ component, FallBack = <LoadingContainer /> }) => {
  return <Suspense fallback={FallBack}>{createElement(component)}</Suspense>;
};

export default PrivateRoutes;

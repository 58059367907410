import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createJournalEntry,
  createPatientJournalRequest,
  deleteJournalEntryRequest,
  duplicateJournalEntryRequest,
  getPatientJournalEntries,
  getPatientJournals,
  searchJournalEntryRequest,
  updateJournalEntryRequest,
} from "../../core/requests/_requests";
import { JournalEntry, PatientJournal } from "../../types/journal";

type initialStateType = {
  patientJournals: PatientJournal[];
  journalEntries: JournalEntry[];
  loading: boolean;
  error?: string | null;
  showNudge: boolean;
};

const initialState: initialStateType = {
  patientJournals: [],
  journalEntries: [],
  loading: false,
  error: null,
  showNudge: false,
};

export const allPatientJournals = createAsyncThunk(
  "journals/allPatientJournals",
  async () => {
    try {
      const journals = await getPatientJournals();
      return journals;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createPatientJournal = createAsyncThunk(
  "journals/createPatientJournal",
  async (data: Parameters<typeof createPatientJournalRequest>["0"]) => {
    try {
      const journals = await createPatientJournalRequest(data);
      return journals;
    } catch (error) {
      console.log("catch block", error);
      console.log(error);
    }
  }
);

export const allPatientJournalEntries = createAsyncThunk(
  "journals/allPatientJournalEntries",
  async () => {
    try {
      const journalEntries = await getPatientJournalEntries();
      return journalEntries;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addJournalEntry = createAsyncThunk(
  "journals/addJournalEntry",
  async (data: Parameters<typeof createJournalEntry>["0"]) => {
    try {
      const journalEntry = await createJournalEntry(data);
      return journalEntry;
    } catch (error) {
      console.log(error);
    }
  }
);

export const duplicateJournalEntry = createAsyncThunk(
  "journals/duplicateJournalEntry",
  async (data: Parameters<typeof duplicateJournalEntryRequest>["0"]) => {
    try {
      const journalEntry = await duplicateJournalEntryRequest(data);
      return journalEntry;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateJournalEntry = createAsyncThunk(
  "journals/updateJournalEntry",
  async (data: Parameters<typeof updateJournalEntryRequest>["0"]) => {
    try {
      const journalEntrys = await updateJournalEntryRequest(data);
      return journalEntrys;
    } catch (error) {
      console.log(error);
    }
  }
);

export const searchJournalEntry = createAsyncThunk(
  "journals/searchJournalEntry",
  async (data: Parameters<typeof searchJournalEntryRequest>["0"]) => {
    try {
      const journalEntrys = await searchJournalEntryRequest({
        searchString: data.searchString,
        patientJournalUuid: data.patientJournalUuid,
        dateFilter: data.dateFilter,
      });
      return journalEntrys;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteJournalEntry = createAsyncThunk(
  "journals/deleteJournalEntry",
  async (entryUuid: string) => {
    try {
      const journalEntry = await deleteJournalEntryRequest(entryUuid);
      return journalEntry;
    } catch (error) {
      console.log(error);
    }
  }
);

const JournalSlice = createSlice({
  name: "journals",
  initialState,
  reducers: {
    setShowNudge: (state, action: PayloadAction<boolean>) => {
      state.showNudge = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allPatientJournals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        allPatientJournals.fulfilled,
        (state, action: PayloadAction<PatientJournal[] | undefined>) => {
          if (action.payload == null) return;

          state.patientJournals = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        allPatientJournals.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(
        allPatientJournalEntries.fulfilled,
        (state, action: PayloadAction<JournalEntry[] | undefined>) => {
          if (action.payload == null) return;

          state.journalEntries = action.payload.map((val)=>{
            if(!val.date.toString().includes("T")){
              val.date=val.date+"T00:00:00"
            }
            return val;
          });
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        allPatientJournalEntries.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(
        createPatientJournal.fulfilled,
        (state, action: PayloadAction<PatientJournal[] | undefined>) => {
          if (action.payload == null || !Array.isArray(action.payload)) return;

          state.patientJournals = action.payload;
        }
      )
      .addCase(
        createPatientJournal.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(
        addJournalEntry.fulfilled,
        (state, action: PayloadAction<JournalEntry | undefined>) => {
          if (action.payload == null) return;
          if(!action.payload.date.toString().includes("T"))
            action.payload.date  = action.payload.date+"T00:00:00"
          state.journalEntries.push(action.payload);
        }
      )
      .addCase(
        addJournalEntry.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
        }
      )
      .addCase(
        duplicateJournalEntry.fulfilled,
        (state, action: PayloadAction<any>) => {
          if(!action.payload.date.toString().includes("T"))
            action.payload.date  = action.payload.date+"T00:00:00"
          state.journalEntries.push(action.payload);
        }
      )
      .addCase(
        duplicateJournalEntry.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
        }
      )
      .addCase(
        updateJournalEntry.fulfilled,
        (state, action: PayloadAction<JournalEntry[] | undefined>) => {
          if (action.payload == null) return;

          state.journalEntries = action.payload.map((val)=>{
            if(!val.date.toString().includes("T")){
              val.date=val.date+"T00:00:00"
            }
            return val;
          });
        }
      )
      .addCase(
        updateJournalEntry.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
        }
      )
      .addCase(
        deleteJournalEntry.fulfilled,
        (state, action: PayloadAction<JournalEntry[] | undefined>) => {
          if (action.payload == null) return;

          state.journalEntries = action.payload.map((val)=>{
            if(!val.date.toString().includes("T")){
              val.date=val.date+"T00:00:00"
            }
            return val;
          });
        }
      )
      .addCase(
        deleteJournalEntry.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
        }
      );
  },
});
export const { setShowNudge } = JournalSlice.actions;
export default JournalSlice.reducer;

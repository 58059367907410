import classNames from "classnames";
import { useEffect, useState } from "react";
import { RouteObject, matchRoutes, useLocation } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import KommunicateChat from "../../components/kommunicate/KommunicateChat";
import JournalNudge from "../../components/patient/dashboard/journal/JournalNudge";
import Stepper from "../../components/stepper/Stepper";
import { resetCurrentPhaseData } from "../../redux/current-phase/CurrentPhaseSlice";
import { setShowChatBot } from "../../redux/layout/LayoutSlice";
import { fetchPatientNotifications } from "../../redux/notification/NotificationSlice";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import MenuHeaderControls from "./menuHeaderControls";

TopBarProgress.config({
  barThickness: 3.5,
  barColors: {
    "0": "#00843d",
    "1.0": "#00843d",
  },
  shadowColor: "#00843d",
  shadowBlur: 10,
});

const LayoutProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const patient = useAppSelector((state: RootState) => state.user.authUser);
  const showNudge: boolean = useAppSelector((state: RootState) => state.journals.showNudge);
  const [prevLoc, setPrevLoc] = useState<string>(() => {
    return localStorage.getItem("previousLocation") || "";
  });
  const imageUrl: string = useAppSelector(
    (state: RootState) => state.layout.imageSource
  );
  const showChatBot = useAppSelector(
    (state: RootState) => state.layout.showChatBot
  );

  const HavingcurrentPhaseDataRoutes: RouteObject[] = [
    { path: "/:journeySlug/journey-phase/*" },
  ];

  const ChatbotHiddenRoutes: RouteObject[] = [
    { path: "/:journeySlug/dashboard/*" },
    { path: "/:journeySlug/video-gallery" },
    { path: "/:journeySlug/notifications" },
    { path: "/:journeySlug/scheduling" },
    { path: "/:journeySlug/profile" }
  ];

  const storePreviousLocation = (location: string) =>
    location !== "/journey-phase/welcome-video" &&
    localStorage.setItem("previousLocation", location);

  const resetCurrentPhase = () => {
    if (!matchRoutes(HavingcurrentPhaseDataRoutes, location.pathname)) {
      dispatch(resetCurrentPhaseData());
    }
  };

  useEffect(() => {
    if (matchRoutes(ChatbotHiddenRoutes, location.pathname) && showChatBot) {
      dispatch(setShowChatBot(false));
    }
  }, [location]);

  useEffect(() => {
    setPrevLoc(location.pathname);
    if (location.pathname === prevLoc) {
      setPrevLoc("");
    }
    storePreviousLocation(prevLoc);
    resetCurrentPhase();
  }, [location]);

  useEffect(() => {
    if (patient?.id) {
      dispatch(fetchPatientNotifications());
    }
  }, [patient?.id, dispatch, location.pathname]);

  return (
    <>
      <MenuHeaderControls />
      <div className={classNames("fixed top-0 w-full h-full")}>
        <img
          src={imageUrl}
          alt="Dynamic"
          className={"w-full h-full object-cover transition-all duration-800"}
        />
        <div className="fixed overflow-y-auto h-full top-0 w-full ">
          {children}
        </div>
      </div>
      <Stepper />
      <KommunicateChat />
      {showNudge && <JournalNudge />}
    </>
  );
};
export default LayoutProvider;

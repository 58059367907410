const NextFooter = () => {
  return (
    <div className="flex justify-center my-4">
      <p className="text-base font-semibold">
        Select <span className="text-green-700">Next</span> when you are ready
        to submit.
      </p>
    </div>
  );
};
export default NextFooter;

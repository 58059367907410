import React from 'react'

const ActivitySkeleton = () => {
    return (
        <div className='grid grid-cols-1 gap-6 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar px-2 animate-pulse'>
            <div className='bg-white/40 rounded-xl h-[152px] p-8' />
            <div className='bg-white/40 rounded-xl h-[152px] p-8' />
        </div>
    )
}

export default ActivitySkeleton
import { Appointment } from "../../redux/appointment/AppointmentSlice";
import { SurveyResponse } from "../../types/survey";

export enum PhaseActivityStatus {
  "INCOMPLETED" = 0,
  "INPROGRESS" = 1,
  "COMPLETED" = 2,
  "SKIPPED" = 3, // Need confirmation regarding this
}

export enum AppointmentActivityTypes {
  QUESTION = 1,
  IMAGE = 2,
  VIDEO = 3,
  DOCTOR_SELECTION = 4,
  PAGE = 5,
  SUMMARY = 6,
  TASK = 7,
  DIRECTION = 8,
  JOURNAL = 9,
  SURVEY = 10,
  QUESTIONNAIRE = 11,
  TAKEAWAYS = 12,
  GUIDANCE = 13,
  HOMEWORK = 14,
  DOCTOR_VIDEO = 15,
  SYMPTOM_CHECKER = 16,
  PATIENT_INFORMATION = 17,
  MEDICATION_REVIEW = 18,
  SCHEDUAL_APPOINTMENT = 19,
  KEGEL_TIMER = 20,
  PATIENT_GOAL = 21,
}

export type Activity = {
  id: number;
  journey_phase_id: number;
  type: AppointmentActivityTypes | undefined | any;
  title: string;
  name?: string;
  showChatBot: boolean | null;
  description: string;
  link: string;
  frontier_data: any;
  is_skippable: boolean;
  timerDuration?: number;
  media: {
    subtitles: any;
    media_url: string;
    title: string;
  };
  journey_phase: {
    slug: string;
    title?: string;
    description?: string;
  };
};

export type AppointmentActivity = {
  id: string;
  patient_id: string;
  journey_phase_id: number;
  status: PhaseActivityStatus;
  journey_phase: Appointment;
  phase_activity: Activity;
  next_phase_slug: string;
  external_data: SurveyResponse[]; //todo: update type
};

import { FC, useEffect, useState } from "react";
import { getDashboardGoals, PatientGoal } from "../../../redux/dashboard/DashboardSlice";
import { RootState, useAppDispatch, useAppSelector } from "../../../redux/store";

const GoalTabTitle: FC = () => {
  const dispatch = useAppDispatch();
  const goals = useAppSelector(
    (state: RootState) => state.dashboard.goals.data
  );
  const Patient = useAppSelector((state: RootState) => state.user.authUser);
  const symptoms = useAppSelector((state: RootState) => state.symptom.data);
  const [patientGoals, setPatientGoals] = useState<PatientGoal[]>([]);
  const [activeGoal, setactiveGoal] = useState<PatientGoal[]>([]);

  useEffect(() => {
    if (symptoms && goals) {
      const enabledGoalList = goals?.filter((goal) => goal?.active);
      const selectedSymptoms: (string | undefined)[] = symptoms
        ?.filter((itm) => itm?.is_positive === true)
        .map((itm) => itm?.title);

      if (selectedSymptoms.length > 0) {
        const filteredGoal = goals.filter((item) =>
          item.reportedSymptoms?.some((symptom) =>
            selectedSymptoms.includes(symptom)
          )
        );
        const enabledGoalList = goals?.filter((goal) => goal?.active);

        if (filteredGoal.length > 0) {
          setPatientGoals(filteredGoal);
        }
        if (enabledGoalList.length > 0) {
          setactiveGoal(enabledGoalList);
        } else {
          setactiveGoal([]);
        }
      } else {
        setPatientGoals(goals);
        setactiveGoal(enabledGoalList);
      }
    }
  }, [symptoms, goals]);

  const fetchGoals = async () => {
    if (Patient.current_journey_id) {
      await dispatch(getDashboardGoals(Patient.current_journey_id));
    }
  };

  useEffect(() => {
    if (Patient) {
      fetchGoals();
    }
  }, [Patient]);

  return (
    <div className="flex items-center gap-2">
      <span className="font-inter">Set Goals</span>
      <div className="text-xs bg-[#e9ecf1] rounded-full flex justify-center items-center h-5 w-5 font-semibold">
        {activeGoal.length}
      </div>
    </div>
  );
};

export default GoalTabTitle;
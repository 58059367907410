import {
  ComponentProps,
  FC,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
} from "react";
import { setShowModal } from "../../redux/layout/LayoutSlice";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import NavControls from "./NavControls";

type PageWrapperProps = {
  nextCallback?: ComponentProps<typeof NavControls>["nextFn"];
  backCallback?: ComponentProps<typeof NavControls>["backFn"];
  disabled?: ComponentProps<typeof NavControls>["disabled"];
  children?: ReactNode;
  closeable?: boolean;
  ignoreRefs?: RefObject<HTMLElement>[];
};

const PageWrapper: FC<PageWrapperProps> = ({
  nextCallback,
  backCallback,
  disabled,
  children,
  closeable = false,
  ignoreRefs = [],
}) => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector(
    (state: RootState) => state.layout.showModal
  );
  const modalRef = useRef<HTMLDivElement>(null);
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // const handleClickOutside = (event: MouseEvent) => {
    //   if (modalRef.current == null || !closeable) return;
    //   if (isClickIgnoreEvent(event)) return;
    //   if (
    //     containsNode(event, [
    //       modalRef,
    //       nextButtonRef,
    //       backButtonRef,
    //       ...ignoreRefs,
    //     ])
    //   )
    //     return;
    //   dispatch(setShowModal(false));
    // };
    // document.addEventListener("mousedown", handleClickOutside);
    // return () => {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, [closeable, dispatch]);

  useEffect(() => {
    if (!closeable) {
      dispatch(setShowModal(true));
    }
  }, [closeable]);

  if (closeable && !showModal) {
    return <></>;
  }

  return (
    <div className="fixed overflow-y-auto h-full top-0 w-full">
      <div className="min-h-[calc(100vh-6rem)] flex justify-center">
        <div
          ref={modalRef}
          className="inline-block mt-14 relative max-w-[48%] w-full min-h-[73vh] max-h-[73vh] rounded-md shadow-lg container_border"
        >
          <div className="absolute bg-white top-3 bottom-3 left-3 right-3 rounded-md overflow-y-auto scrollbar">
            <div className="h-full relative inline-block w-full">
              {closeable && (
                <div className="absolute top-3 right-6 z-10">
                  <span
                    className="modal-cross-icon cursor-pointer"
                    onClick={() => {
                      dispatch(setShowModal(false));
                    }}
                  />
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
        <NavControls
          nextFn={nextCallback}
          backFn={backCallback}
          disabled={disabled}
          backButtonRef={backButtonRef}
          nextButtonRef={nextButtonRef}
        />
      </div>
    </div>
  );
};

export default PageWrapper;

const isClickIgnoreEvent = (event: MouseEvent) => {
  if (event.button === 2) return true;
  if (event.target == null) return true;

  let element: unknown | null = event.target;

  while (element != null) {
    const hasClassname = IGNORE_ELEMENT_CLICK_CLASSNAMES.some(
      //todo: resolve warning
      // eslint-disable-next-line no-loop-func
      (className) =>
        typeof element === "object" &&
        element != null &&
        "className" in element &&
        typeof element.className === "string" &&
        element?.className.includes(className)
    );

    if (hasClassname) {
      return true;
    }

    if (
      typeof element !== "object" ||
      element == null ||
      !("parentElement" in element)
    ) {
      return false;
    }

    element = element.parentElement;
  }

  return false;
};

const containsNode = (event: MouseEvent, refs: RefObject<HTMLElement>[]) => {
  return refs.some((ref) => ref.current?.contains(event.target as Node)); //todo: remove as
};

const IGNORE_ELEMENT_CLICK_CLASSNAMES = [
  "react-ios-time-picker",
  "Toastify",
  "menu-header-link",
  "left-nav-stepper",
];

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAuthenticatedPatient,
  getHealthDemographicInformation,
  getPatientAllergies,
  getPatientAppointment,
  getPatientInsurance,
  getPatientMedication,
  patientUpdateRequest,
} from "../../core/requests/_requests";
import { Doctor } from "../doctor/DoctorSlice";
import { Journey } from "../journey/JourneySlice";

export type Language = {
  id: number;
  language: string;
};

// todo: move type and validation function
export type Patient = {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  gender?: string;
  phone_number?: string;
  birthdate?: string;
  address?: {
    billing?: {
      address_line1?: string;
      address_line2?: string;
      city?: string;
      state?: string;
      district?: string;
      country?: string;
      zip_code?: string;
    };
    shipping: {
      address_line1?: string;
      address_line2?: string;
      city?: string;
      state?: string;
      district?: string;
      country?: string;
      zip_code?: string;
    };
  };
  lastLogin?: string;
  auth0_id?: string;
  profile?: {
    media_url?: string;
  };
  marital_status?: string;
  communication_languages?: Language[];
  redox_patient_id?: string;
  openemr_patient_id?: string;
  eligible_for_journey?: number;
  openemr_patient_uuid?: string;
  current_phase_activity?: number;
  active_phase_id?: number;
  current_phase_id?: number;
  next_phase_id?: number;
  doctor_id?: number;
  doctor?: Doctor;
  time_zone?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  current_journey_id?: number;
  patient_journeys?: Journey[];
};

// todo: move function with patient type
export const isPatient = (obj: unknown): obj is Patient => {
  return (
    // todo: improve required key checks
    typeof obj === "object" &&
    obj != null &&
    ["id", "auth0_id"].every((key) => key in obj)
  );
};

export type HealthRecord = {
  auth0_id?: string;
  redox_id?: string;
  first_name?: string;
  last_name?: string;
  gender?: string;
  birth_date?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  phone_number?: string;
  email?: string;
  marital_status?: string;
  communication_language?: string[];
};

export type Allergy = {
  allergy_id?: string;
  allergy_name?: string;
  type?: string;
  criticality?: string;
  clinical_status?: string;
};

export type Medication = {
  medication_id?: string;
  medication_name?: string;
  effactive_date?: string;
  status?: string;
};

export type Appointment = {
  appointment_id?: string;
  reason?: string;
  start?: string;
  end?: string;
  minutes_duration?: number;
  status?: string;
};

export type Insurance = {
  insurance_id?: string;
  insurance_start?: string;
  insurance_end?: string;
  policy_id?: string;
  policy_name?: string;
  plan_name?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  phone_number?: string;
  status?: string;
};

export type Questions = {
  question?: string;
  options?: Options[];
  answer?: string;
  answer_id?: number;
  question_id?: number;
};

export type QuestionList = {
  data: Questions[];
  loading: boolean;
  error?: string | null;
  message?: string | null;
};

export type Options = {
  text: string;
  value: string;
};

type UserState = {
  authUser: Patient;
  PatientDetailsModalOpen: boolean;
  PersonalHealthInfo: HealthRecord;
  allergies: Allergy[];
  medications: Medication[];
  appointments: Appointment[];
  insurance: Insurance;
  questionList: QuestionList;
};

const initialState: UserState = {
  authUser: {},
  PatientDetailsModalOpen: false,
  PersonalHealthInfo: {},
  allergies: [],
  medications: [],
  appointments: [],
  insurance: {},
  questionList: {
    data: [],
    loading: false,
    error: null,
    message: null,
  },
};

export const setAuthenticatedPatientDetails = createAsyncThunk(
  "patient/setAuthenticatedPatient",
  async ({
    currentJourneySlug,
    email,
  }: {
    currentJourneySlug?: string;
    email?: string;
  }) => {
    try {
      // const response = await getPatientByAuth0Id({ auth0_id });                                  //commenting due to new migration api changes
      // return response.data.patient;
      const response = await getAuthenticatedPatient({
        journeySlug: currentJourneySlug,
        email,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      return response.patient;
    } catch (error) {
      console.error("GET patient by auth error\n", error);
      throw Error();
    }
  }
);

export const setHealthDemographicInformation = createAsyncThunk(
  "patient/setHealthDemographicInformation",
  async () => {
    try {
      const response = await getHealthDemographicInformation();
      return response.data;
    } catch (error) {}
  }
);

export const setPatientAllergies = createAsyncThunk(
  "patient/setPatientAllergies",
  async () => {
    try {
      const response = await getPatientAllergies();
      return response.data;
    } catch (error) {}
  }
);

export const setPatientMedication = createAsyncThunk(
  "patient/setPatientMedication",
  async () => {
    try {
      const response = await getPatientMedication();
      return response.data;
    } catch (error) {}
  }
);

export const setPatientAppointment = createAsyncThunk(
  "patient/setPatientAppointment",
  async () => {
    try {
      const response = await getPatientAppointment();
      return response.data;
    } catch (error) {}
  }
);

export const setPatientInsurance = createAsyncThunk(
  "patient/setPatientInsurance",
  async () => {
    try {
      const response = await getPatientInsurance();
      return response.data;
    } catch (error) {}
  }
);

export const patientUpdate = createAsyncThunk(
  "patient/patientUpdate",
  async (patientDetails: any) => {
    try {
      const response = await patientUpdateRequest(patientDetails);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<Patient>) => {
      return {
        ...state,
        authUser: action.payload,
      };
    },
    setPatientDetailsModalState: (
      state,
      action: PayloadAction<{ status: boolean }>
    ) => {
      return {
        ...state,
        PatientDetailsModalOpen: action.payload.status,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        setAuthenticatedPatientDetails.fulfilled,
        (state, action: PayloadAction<Patient>) => {
          return {
            ...state,
            authUser: action.payload,
            PatientDetailsModalOpen: action.payload?.redox_patient_id
              ? false
              : true,
          };
        }
      )
      .addCase(
        setHealthDemographicInformation.fulfilled,
        (state, action: PayloadAction<HealthRecord>) => {
          return {
            ...state,
            PersonalHealthInfo: action.payload,
          };
        }
      )
      .addCase(
        setPatientAllergies.fulfilled,
        (state, action: PayloadAction<Allergy[]>) => {
          return {
            ...state,
            allergies: action.payload,
          };
        }
      )
      .addCase(
        setPatientMedication.fulfilled,
        (state, action: PayloadAction<Medication[]>) => {
          return {
            ...state,
            medications: action.payload,
          };
        }
      )
      .addCase(
        setPatientAppointment.fulfilled,
        (state, action: PayloadAction<Appointment[]>) => {
          return {
            ...state,
            appointments: action.payload,
          };
        }
      )
      .addCase(
        setPatientInsurance.fulfilled,
        (state, action: PayloadAction<Insurance>) => {
          return {
            ...state,
            insurance: action.payload,
          };
        }
      )
      .addCase(
        patientUpdate.fulfilled,
        (state, action: PayloadAction<Patient>) => {
          if (action.payload && action.payload.id) {
            return {
              ...state,
              authUser: { ...state.authUser, ...action.payload },
            };
          }
        }
      );
  },
});

export const { setAuthUser, setPatientDetailsModalState } = UserSlice.actions;
export default UserSlice.reducer;

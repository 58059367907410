import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";

const SignIn = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies();

  const setDoctorReferralId = (referralId?: string | null) => {
    setCookie("doctor_reference", referralId);
  };

  const setJourneyReferralId = (referralId?: string | null) => {
    setCookie("journey_reference", referralId);
  };

  useEffect(() => {
    if (
      searchParams.get("doctor_reference") &&
      searchParams.get("doctor_reference") !== null
    ) {
      setDoctorReferralId(searchParams.get("doctor_reference"));
    }

    if (
      searchParams.get("journey_reference") &&
      searchParams.get("journey_reference") !== null
    ) {
      setJourneyReferralId(searchParams.get("journey_reference"));
    }

    if (!isAuthenticated && !isLoading) {
      localStorage.setItem(
        "auth0_callback",
        setReturnUrl(window.location.href)
      );
      loginWithRedirect();
    }
  }, []);

  return <></>;
};

export default SignIn;

const setReturnUrl = (href: string) => {
  const slugMatch = href.match(/\?slug=(\w+(?:-?\w+)*$)/);
  if (slugMatch != null) return `${href.split("?")[0]}${slugMatch[1]}`;
  return href;
};

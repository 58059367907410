import { FC, useCallback, useEffect, useRef, useState } from "react";

const SCROLL_BOX_MIN_HEIGHT = 20;

interface ScrollWrapperProps {
  children: React.ReactNode;
}

const ScrollWrapper: FC<ScrollWrapperProps> = ({ children }) => {
  const [hovering, setHovering] = useState(false);
  const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);
  const [scrollBoxTop, setScrollBoxTop] = useState(0);
  const [shouldShowScroll, setShouldShowScroll] = useState(false);
  const scrollHostRef = useRef<HTMLDivElement | null>(null);

  const updateScrollBar = useCallback(() => {
    const scrollHostElement = scrollHostRef.current;
    if (!scrollHostElement) return;

    const { clientHeight, scrollHeight } = scrollHostElement;
    const contentOverflow = scrollHeight > clientHeight;

    if (contentOverflow) {
      setShouldShowScroll(true);
      const scrollBoxPercentage = clientHeight / scrollHeight;
      const scrollbarHeight = Math.max(scrollBoxPercentage * clientHeight, SCROLL_BOX_MIN_HEIGHT);
      setScrollBoxHeight(scrollbarHeight);
      handleScroll();
    } else {
      setShouldShowScroll(false);
    }
  }, []);

  const handleScroll = useCallback(() => {
    const scrollHostElement = scrollHostRef.current;
    if (!scrollHostElement) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollHostElement;
    const scrollBoxPercentage = clientHeight / scrollHeight;
    const scrollbarHeight = Math.max(scrollBoxPercentage * clientHeight, SCROLL_BOX_MIN_HEIGHT);
    const newTop = (scrollTop / scrollHeight) * clientHeight;

    setScrollBoxHeight(scrollbarHeight);
    setScrollBoxTop(Math.min(newTop, clientHeight - scrollbarHeight));
  }, []);

  const handleMouseOver = useCallback(() => {
    if (shouldShowScroll) setHovering(true);
  }, [shouldShowScroll]);

  const handleMouseOut = useCallback(() => {
    setHovering(false);
  }, []);

  useEffect(() => {
    const scrollHostElement = scrollHostRef.current;
    if (!scrollHostElement) return;

    const onScroll = () => handleScroll();
    scrollHostElement.addEventListener("scroll", onScroll);

    updateScrollBar();

    return () => {
      scrollHostElement.removeEventListener("scroll", onScroll);
    };
  }, [handleScroll, updateScrollBar]);

  useEffect(() => {

    const onResize = () => {
      updateScrollBar();
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [updateScrollBar]);

  useEffect(() => {
    const scrollHostElement = scrollHostRef.current;
    if (!scrollHostElement) return;

    const observer = new MutationObserver(() => {
      updateScrollBar();
    });

    observer.observe(scrollHostElement, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [updateScrollBar]);

  return (
    <div
      className="scrollhost-container relative"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div
        ref={scrollHostRef}
        className="scrollhost overflow-y-auto max-h-[calc(72vh-242px)] min-h-[326px]"
      >
        {children}
      </div>

      {shouldShowScroll && hovering && (
        <div
          className="scroll-bar absolute right-1 top-0 rounded"
          style={{ width: '8px', height: '100%' }}
        >
          <div
            className="scroll-thumb bg-gray-500 opacity-40 rounded"
            style={{
              height: `${scrollBoxHeight}px`,
              top: `${scrollBoxTop}px`,
              width: '100%',
              position: 'absolute',
              left: '-2px',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ScrollWrapper;

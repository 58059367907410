import { FormikProps } from 'formik';
import React from 'react';

interface InputFieldProps {
  label: string;
  name: string;
  placeholder?: string;
  inputClass?: string;
  formik?: FormikProps<any>;
  inputWrapClass?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  name,
  placeholder,
  inputClass,
  formik,
  inputWrapClass
}) => {
  const error = formik?.errors[name];
  const touched = formik?.touched[name];

  return (
    <div className={`inline-block w-full ${inputWrapClass && inputWrapClass}`}>
      <label htmlFor={name} className="block text-base font-semibold leading-6">
        {label}
      </label>
      <input
        className={`bg-gray-200 shadow-inner block w-full rounded-md border-0 px-4 py-2.5 text-ms placeholder:text-text-white/72 focus:outline-1 ${inputClass && inputClass}`}
        type="text"
        placeholder={placeholder}
        {...formik?.getFieldProps(name)}
      />
      {touched && typeof error === 'string' && (
        <div className="flex pt-2 justify-start">
          <p className="text-sm font-semibold text-red-500">{error}</p>
        </div>
      )}
    </div>
  );
};

export default InputField;
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import JourneyAppointment from "../../components/journey/journey-phase/JourneyAppointment";
import Notification from "../../components/Notification/Notification";
import Dashboard from "../../components/patient/dashboard/Dashboard";
import PatientProfile from "../../components/patient/patient-profile/PatientProfile";
import Scheduling from "../../components/schedule/Scheduling";
import VideoGallery from "../../components/video-gallery/VideoGallery";
import {
  AppointmentProgress,
  fetchPatientJourneyProgresses,
} from "../../redux/appointment/AppointmentSlice";
import { getDashboardGoals, PatientGoal } from "../../redux/dashboard/DashboardSlice";
import { setShowNudge } from "../../redux/journals/JournalSlice";
import {
  JOURNEY_SLUGS,
  setCurrentJourneySlug,
} from "../../redux/journey/JourneySlice";
import { setBackGroundLoading } from "../../redux/layout/LayoutSlice";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchSymptomsList } from "../../redux/symptom/SymptomSlice";
import { Patient } from "../../redux/user/UserSlice";

const JourneyRoutes = () => {
  const { journeySlug } = useParams<{ journeySlug: string }>();
  const goals = useAppSelector(
    (state: RootState) => state.dashboard.goals.data
  );
  const dispatch = useAppDispatch();
  const patient: Patient = useAppSelector(
    (state: RootState) => state.user.authUser
  );
  const appointments = useAppSelector((state) => state.appointment);
  const [navigateUrl, setNavigateUrl] = useState<string | null>(null);
  const currentAppointment = appointments?.data?.appontments?.find(
    (appointment) => appointment.status === 1
  );
  const appointmentSlug = currentAppointment?.journey_phase.slug;
  const currentAppointmentId: string = useAppSelector(
    (state: RootState) => state.currentPhase.id
  );
  const currentPhaseData: AppointmentProgress | undefined =
    appointments?.data?.appontments?.find(
      (appointment: AppointmentProgress) =>
        appointment.id === currentAppointmentId
    );

  const fetchAppointmentData = async (currentJourneySlug: string) => {
    dispatch(
      setBackGroundLoading({
        // removing the loader from the Urinalysis Results when navigated from patient information
        status: currentPhaseData?.journey_phase?.slug !== undefined,
        loadingMessage: "Fetching Appointments",
      })
    );
    await dispatch(
      fetchPatientJourneyProgresses({
        journeyId: patient.current_journey_id,
      })
    );
    await dispatch(
      fetchSymptomsList({
        journeyId: patient.current_journey_id,
      })
    );
    dispatch(setBackGroundLoading({ status: false }));
  };

  // todo: replace with an error case rather than a default journey
  useEffect(() => {
    if (journeySlug && patient?.id) {
      let validSlug = null;
      if (JOURNEY_SLUGS.includes(journeySlug)) {
        validSlug = journeySlug;
      } else {
        validSlug = JOURNEY_SLUGS[0];
      }
      dispatch(setCurrentJourneySlug(validSlug));
      if (currentPhaseData?.journey_phase?.slug === "doctor-selection") return; //removing the redundant calling of journey progress api call as it is getting called already in HandleActivityEnd function for doctor selection page in oab
      fetchAppointmentData(validSlug);
    }
  }, [journeySlug, patient?.id]);

  useEffect(() => {
    const isInInitialState =
      appointments.data?.appontments?.length === 0 &&
      !appointments.loading &&
      appointments.error == null;
    if (appointments.loading || isInInitialState) return;

    if (appointments.error) {
      setNavigateUrl("dashboard");
      return;
    }

    if (appointmentSlug == null) {
      setNavigateUrl("dashboard");
      return;
    }

    setNavigateUrl(`journey-phase/${appointmentSlug}`);
  }, [appointments]);

  const [navActionDisabled, setNavActionDisabled] = useState<{
    back: boolean;
    forward: boolean;
  }>({ back: true, forward: true });

  const [completeClick, setCompleteClick] = useState<boolean>(false);

  useEffect(() => {
    if (patient && patient.redox_patient_id && journeySlug === "overactive-bladder" && patient.current_journey_id) {
      dispatch(getDashboardGoals(patient.current_journey_id)).then((response) => {
        const isAnygoalSet = response.payload.some((goal: PatientGoal) => goal.active);
        if (isAnygoalSet) {
          const lastLoginDate = new Date(patient.lastLogin as string);
          const today = new Date().toLocaleDateString("en-US");
          const lastLoginLocalDate = lastLoginDate.toLocaleDateString("en-US");
          if (lastLoginLocalDate !== today) {
            dispatch(setShowNudge(true));
          }
        }
      });
    }
  }, [journeySlug, patient])

  return (
    <Routes>
      <Route path="/dashboard" element={(patient && patient.redox_patient_id) ? <Dashboard />:null} />
      <Route path="/profile" element={<PatientProfile />} />
      <Route path="/video-gallery" element={(patient && patient.redox_patient_id) ? <VideoGallery />:null} />
      <Route path="/scheduling" element={(patient && patient.redox_patient_id) ? <Scheduling />:null} />
      <Route
        path="/notifications"
        element={
          (patient && patient.redox_patient_id) ? <Notification
            {...{ completeClick, setCompleteClick, setNavActionDisabled }}
            backClick={navActionDisabled.back}
          />:null
        }
      />
      <Route
        path="/journey-phase/:appointmentSlug"
        element={<JourneyAppointment />}
      />
      <Route
        path="*"
        element={
          <>{navigateUrl != null ? <Navigate to={navigateUrl} /> : null}</> //todo: improve this
        }
      />
    </Routes>
  );
};

export default JourneyRoutes;

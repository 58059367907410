import { useAuth0 } from "@auth0/auth0-react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { AppointmentActivity } from "../../../../core/phase-activity/AppointmentActivityConfig";
import { searchRedoxPatient } from "../../../../core/requests/_requests";
import NextFooter from "../../../../providers/LayoutProvider/NextFooter";
import PageWrapper from "../../../../providers/LayoutProvider/PageWrapper";

import { AppointmentProgress } from "../../../../redux/appointment/AppointmentSlice";
import { setBackGroundLoading } from "../../../../redux/layout/LayoutSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  isPatient,
  setAuthenticatedPatientDetails,
} from "../../../../redux/user/UserSlice";
import InputField from "../../../UI/Input";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  date_of_birth: Yup.string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Invalid Date of Birth")
    .required("Date of Birth is required"),
});

interface Props {
  activityDetails: AppointmentActivity;
}

const PatientDetails: FC<Props> = ({
  activityDetails
}) => {
  const navigate = useNavigate();
  const { journeySlug } = useParams<{ journeySlug: string }>();

  const patient = useAppSelector((state) => state.user.authUser);
  const appointmentProgresses = useAppSelector(
    (state) => state.appointment.data.appontments
  );

  const handleCallbackNavigation = () => {
    const currentPhase = appointmentProgresses.find(
      (phase: AppointmentProgress) =>
        phase.journey_phase.slug ===
        activityDetails?.phase_activity?.journey_phase?.slug
    );
    const phaseActivitiesList =
      currentPhase?.journey_phase?.patient_phase_activity;
    const currentActivityIndex = phaseActivitiesList?.findIndex(
      (activity: AppointmentActivity) => activity.id === activityDetails.id
    );

    let callback = `${journeySlug}/journey-phase/${activityDetails.next_phase_slug}`;
    if (
      currentActivityIndex !== undefined &&
      phaseActivitiesList &&
      phaseActivitiesList.length > 0 &&
      phaseActivitiesList.length - 1 > currentActivityIndex
    ) {
      callback = `${journeySlug}/journey-phase/${currentPhase.journey_phase.slug
        }?activityId=${phaseActivitiesList[currentActivityIndex + 1].id}`;
    }
    navigate(`/${journeySlug}/profile?callback=${callback}`);
  }

  useEffect(() => {
    if (patient?.redox_patient_id) {
      handleCallbackNavigation();
    }
  }, [patient]);

  const { user } = useAuth0();

  const dispatch: AppDispatch = useAppDispatch();
  const currentJourneySlug = useAppSelector(
    (state: RootState) => state.journey.currentJourneySlug
  );

  const initialValues = {
    first_name: "",
    last_name: "",
    date_of_birth: "",
  };


  const redoxPatientSearch = async (
    values: Parameters<typeof searchRedoxPatient>[0]
  ) => {
    const patientSearchResponse = await searchRedoxPatient(values);
    if (!isPatient(patientSearchResponse)) {
      return;
    }

    await dispatch(
      setAuthenticatedPatientDetails({
        currentJourneySlug,
        email: user?.email,
      })
    );
    handleCallbackNavigation();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values,) => {
      dispatch(setBackGroundLoading({ status: true }));
      await redoxPatientSearch({
        given: values.first_name,
        family: values.last_name,
        birthdate: values.date_of_birth,
      });
      await dispatch(
        setAuthenticatedPatientDetails({
          currentJourneySlug,
          email: user?.email,
        })
      );
      dispatch(setBackGroundLoading({ status: false }));
    }
  });

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [formik]);

  return (
    <>
      <PageWrapper
        nextCallback={() => {
          formik.handleSubmit();
        }}
      >
        <div className="flex flex-col h-full">
          <div className="flex-none">
            <div className="grid grid-cols-12 gap-4 py-8">
              <div className="col-start-2 col-end-12">
                <div className="flex justify-center flex-col ">
                  <h2 className="text-center text-4xl font-semibold mb-6">
                    Patient Confirmation
                  </h2>
                  <p className="text-base font-medium mb-4 leading-6">
                    Please enter your{" "}
                    <span className="font-bold">First Name, Last Name</span> and{" "}
                    <span className="font-bold">Date of Birth</span> so we can
                    connect your OAB Journey to your Cleveland Clinic medical
                    record.
                  </p>
                  <div className="flex items-start gap-4">
                    <InformationCircleIcon
                      className="h-6 w-6 text-green-500 flex-none self-start mt-1"
                      aria-hidden="true"
                    />
                    <p className="text-base font-medium leading-6">
                      This allows us to share your activity with your primary
                      care provider and assist you in ordering medication or
                      scheduling an appointment(s).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto scrollbar mx-2">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-start-2 col-end-12">
                <div className="inline-block w-full rounded-xl">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="inline-block w-full">
                      <div className="flex flex-col">
                        <InputField
                          label="First Name"
                          name="first_name"
                          placeholder="Enter your first name"
                          inputWrapClass="mb-5"
                          formik={formik}
                        />

                        <InputField
                          label="Last Name"
                          name="last_name"
                          placeholder="Enter your last name"
                          inputWrapClass="mb-5"
                          formik={formik}
                        />

                        <div className="inline-block mb-6">
                          <label
                            htmlFor="date_of_birth"
                            className="block text-base font-semibold border-black leading-6"
                          >
                            Date of Birth
                          </label>
                          <div className="mt-2 relative">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateField
                                className="w-full date-input [&>div>input]:bg-gray-200 [&>div>input]:shadow-inner [&>div>input]:!text-black"
                                {...formik.getFieldProps("date_of_birth")}
                                onChange={(newValue: Dayjs | null) => {
                                  const formattedDate = newValue
                                    ? newValue.format("YYYY-MM-DD")
                                    : "";
                                  formik.setFieldValue(
                                    "date_of_birth",
                                    formattedDate
                                  );
                                }}
                                format="MM/DD/YYYY"
                                InputLabelProps={{ focused: false }}
                              />
                            </LocalizationProvider>
                            {formik.touched.date_of_birth &&
                              formik.errors.date_of_birth && (
                                <div className="flex pt-2 justify-start">
                                  <p className="text-sm font-semibold text-red-500">
                                    {formik.errors.date_of_birth}
                                  </p>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <NextFooter />
        </div>
      </PageWrapper>
    </>
  );
};

export default PatientDetails;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSystemConfigurationsRequest } from "../../core/requests/_requests";

interface SystemConfigurationsState {
  data: Record<string, any> | null;  // Define data as a record or null
  loading: boolean;
  error: string | null;
}

const initialState: SystemConfigurationsState = {
  data: null,
  loading: false,
  error: null,
};

export const getSystemConfigurations = createAsyncThunk(
  "systemConfigurations/getSystemConfigurations",
  async () => {
    try {
      const response = await getSystemConfigurationsRequest();
      return response.data.data;
    } catch (error) { }
  }
);

const SystemConfigurationsSlice = createSlice({
  name: "systemConfigurations",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSystemConfigurations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSystemConfigurations.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        getSystemConfigurations.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default SystemConfigurationsSlice.reducer;

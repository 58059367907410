import { FC, useEffect, useState } from "react";
import ConditionallyRender from "react-conditionally-render";
import { useLocation } from "react-router-dom";
import { Modal } from "rizzui";
import { Model, ValueChangedEvent } from "survey-core";
import "survey-core/defaultV2.min.css";
import * as SurveyTheme from "survey-core/themes";
import { Survey } from "survey-react-ui";
import { AppointmentActivity } from "../../core/phase-activity/AppointmentActivityConfig";
import { callWithDelay } from "../../core/utilities";
import PageWrapper from "../../providers/LayoutProvider/PageWrapper";
import { AppointmentProgress } from "../../redux/appointment/AppointmentSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../redux/store";
import { Symptom } from "../../redux/symptom/SymptomSlice";
import {
  Treatment,
  fetchTreatmentsListByPatientJourneyId,
} from "../../redux/treatments/TreatmentSlice";
import { SurveyResponse, isSurveyResponse } from "../../types/survey";
import { useCustomProperties } from "./hooks";

type SurveyType = {
  showSurveyModal: boolean;
  onClose?: () => void | Promise<void>;
  surveyData: any;
  showCompletedPage?: boolean;
  surveyTheme?: any;
  external_data?: SurveyResponse[] | string;
  onComplete: (surveyResponses: SurveyResponse[]) => void | Promise<void>;
  playSound?: boolean;
  showContentInModal?: boolean;
  displayWrapper?: boolean;
  /**
   * Indicated the active page for surveys.
   * This is used when needs navigate back/forth or specific page in survey.
   */
  currentActivePage?: number;

  /**
   * Indicates whether to show the survey when not in a modal.
   * Defaults to false. This is used when `showContentInModal` is false and the survey needs to be hidden.
   */
  hideSurveyWhenNotInModal?: boolean;

  nextBtnClicked?: boolean;
  btnClick?: number;

  /**
   * callback to indicate validation status of the form.
   */
  onValidation?: (isValid: boolean) => void;
};

type optionsDataQuestion = {
  value?: number[];
  name?: string;
  question?: { choices?: Symptom[] };
};
const audio = new Audio(process.env.REACT_APP_SURVEY_FORM_TONE_SOUND);
audio.volume = 0.5;

const GenerateSurveyModal: FC<SurveyType> = ({
  showSurveyModal = true,
  onClose = () => { },
  surveyData,
  surveyTheme = SurveyTheme.DefaultLight,
  showCompletedPage = false,
  external_data,
  onComplete,
  playSound = false,
  showContentInModal = true,
  displayWrapper = true,
  currentActivePage,
  hideSurveyWhenNotInModal = false,
  nextBtnClicked,
  onValidation,
}) => {
  useCustomProperties();
  const dispatch: AppDispatch = useAppDispatch();
  const [surveyModel, setSurveyModel] = useState<Model | null>(null);
  const [completeLastPage, setCompleteLastPage] = useState(true);
  const location = useLocation();
  const appointmentProgresses: AppointmentProgress[] = useAppSelector(
    (state: RootState) => state.appointment.data.appontments
  );
  const currentJourneySlug: string = useAppSelector(
    (state: RootState) => state.journey.currentJourneySlug
  );
  const currentPhaseId: string = useAppSelector(
    (state: RootState) => state.currentPhase.id
  );
  const symptoms = useAppSelector((state: RootState) => state.symptom);
  const customSurveyPopupCss: {} = {
    root: "sd-root-modern sd-survey__rt-container",
    //rootWrapper: "sd-element-survey-root-modern__wrapper",
    container: "sd-element-survey-container__modern",
    bodyNavigationButton: "sd-element-survey__btn",
    panel: {
      withFrame: "sd-element-survey__panel",
    },
    navigation: {
      complete: "sd-navigation__complete-btn",
      prev: "sd-navigation__prev-btn",
      next: "sd-navigation__next-btn",
      start: "sd-navigation__start-btn",
      preview: "sd-navigation__preview-btn",
    },
  };
  const patient = useAppSelector((state: RootState) => state.user.authUser);
  const treatments = useAppSelector(
    (state: RootState) => state.treatments.data
  );

  const getDynamicSymptomList = (survey: Model) => {
    const question = survey.getQuestionByName("dynamic_symptoms");

    if (
      appointmentProgresses.length === 0 ||
      currentJourneySlug !== "menopause" ||
      !question
    ) {
      return;
    }

    // this function is to display the list of symptoms from the health questionnaire symptom ranking using the ranking component
    const patientJourneySymptomsPhase = appointmentProgresses.find(
      (phase) => phase?.journey_phase?.slug === "step-1---your-symptoms"
    );

    if (!patientJourneySymptomsPhase?.journey_phase?.patient_phase_activity)
      return;

    const patientSymptomsList =
      patientJourneySymptomsPhase?.journey_phase?.patient_phase_activity?.find(
        (patient_phase_activity: AppointmentActivity) => {
          return (
            patient_phase_activity?.phase_activity?.name?.toLowerCase() ===
            "hormonal health questionnaire"
          );
        }
      )?.external_data;
    const journeyProgressPhaseActivity =
      patientJourneySymptomsPhase?.journey_phase?.patient_phase_activity?.find(
        (patient_phase_activity: AppointmentActivity) => {
          return (
            patient_phase_activity?.phase_activity?.name?.toLowerCase() ===
            "patient journey recommendation"
          );
        }
      )?.phase_activity;

    if (
      !isSurveyResponses(patientSymptomsList) ||
      journeyProgressPhaseActivity?.frontier_data?.title?.trim() !==
      surveyData?.title?.trim() ||
      surveyData?.title === undefined
    )
      return;

    const top_5_symptoms_rank = patientSymptomsList.find(
      (response) => response.name === "top_5_symptoms_rank"
    )?.answer;
    const select_symptoms = patientSymptomsList.find(
      (response) => response.name === "select_symptoms"
    )?.answer;

    const dynamicMenoSymptomList = top_5_symptoms_rank ?? select_symptoms;
    const result: Symptom[] = [];
    if (Array.isArray(dynamicMenoSymptomList) && symptoms.data.length) {
      dynamicMenoSymptomList?.forEach((id) => {
        const dataObject = symptoms.data.find(
          (item) => item.id === id && !item.is_dissable_qualification
        ); //excluding the red flag symptoms
        if (dataObject) {
          result.push({
            ...dataObject,
            value: dataObject.id,
            text: dataObject.title,
          });
        }
      });
      if (question) {
        question.choices = [...question.choices, ...result];
        question.selectAll();
      }
    }
  };

  const onValueChangeEvent = (
    sender: Model,
    options: any,
    treatmentsList: Treatment[] | null
  ) => {
    const dataQuestion =
      options ?? sender.getQuestionByName("final_preferred_treatment");
    if (
      dataQuestion == null ||
      dataQuestion?.name !== "final_preferred_treatment"
    ) {
      return;
    }

    const selectedTreatments = treatmentsList?.filter((treatment: Treatment) =>
      dataQuestion.value.includes(treatment.treatmentId)
    );

    const element1 = sender.getQuestionByName("selected_treatments_in_summary");
    if (element1) {
      element1.html = `<ul class="info-circle-icon survey-font-md" >
        ${selectedTreatments?.reduce((acc: string, cur: Treatment) => {
        acc += `<li> ${cur.treatmentDescription}</li>`;
        return acc;
      }, "")}
          </ul>`;
    }
    const element2: any = sender.getQuestionByName(
      "selected_treatment_summary_medication"
    );
    if (element2) {
      element2.html = `<ul class="info-circle-icon survey-font-md" >
              ${selectedTreatments?.reduce((acc: string, cur: Treatment) => {
        acc += `<li> ${cur.selectedText}</li>`;
        return acc;
      }, "")}</ul>`;
    }
  };
  useEffect(() => {
    if (nextBtnClicked && surveyModel) {
      onValidation?.(surveyModel.validate());

      if (!surveyModel.validate()) return;

      if (surveyModel.isLastPage) {
        setCompleteLastPage(true);
        surveyModel.completeLastPage();
        return;
      }
      setCompleteLastPage(false);
      surveyModel.nextPage();
    }
  }, [nextBtnClicked, onValidation]);

  useEffect(() => {
    if (currentActivePage !== undefined && surveyModel) {
      if (
        surveyModel.isLastPage &&
        surveyModel.currentPageNo <= currentActivePage
      ) {
        setCompleteLastPage(true);
        surveyModel.completeLastPage();
        return;
      }
      setCompleteLastPage(false);
      surveyModel.currentPageNo = currentActivePage;
    }
  }, [currentActivePage, surveyModel]);

  const scrolltoBottomEvent = () => {
    callWithDelay(() => {
      const formelement = document.querySelector(
        ".sd-element-survey-container__modern"
      );
      formelement?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 0);
  };

  const validateShareEmail = (
    sender: Model,
    options: { name: string; value: string; error: string }
  ) => {
    if (options.name === "SHARE_EMAIL") {
      const emailValues = options.value;
      if (!emailValues) return;

      const emails = emailValues.split(",").map((email) => email.trim());
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const allValid = emails.every((email) => emailRegex.test(email));

      if (!allValid) {
        options.error = "Invalid email address(es)";
      }
      return allValid;
    }
  };

  useEffect(() => {
    if (completeLastPage || !location.pathname.includes("dashboard")) {
      setCompleteLastPage(false);
      const survey = new Model({ ...surveyData, showNavigationButtons: false });
      survey.applyTheme(surveyTheme);
      survey.css = customSurveyPopupCss; //to apply the custom styling
      survey.showCompletedPage = showCompletedPage;

      if (external_data != null) {
        survey.data = getSurveyData(external_data);
        // onValueChangeEvent(survey, null, treatments);
      }

      survey.onValidateQuestion.add(validateShareEmail); // validation function for share email

      survey.onComplete.add(async (sender, options) => {
        const surveyResponses = getSurveyResponses(sender);
        setSurveyModel(null);
        await onComplete(surveyResponses);
      });
      setSurveyModel(survey);
      // Executing below code only for journal form to avoid unnecessary side effects
      if (surveyData?.surveyType === "journal") {
        survey.onVisibleChanged.add(scrolltoBottomEvent); // This is added because wanted to scroll on displaying the blocks conditionally
        survey.onPanelVisibleChanged.add(scrolltoBottomEvent); // This is added because wanted to scroll on displaying the Panels conditionally
        survey.onValueChanged.add((sender, options) => {
          // We can call the scroll event on every value change but thats causing scroll for every value change even in the top values also, So added this condition.
          if (options.name === "date") scrolltoBottomEvent(); // This is added because wanted to scroll on initial date change
        });
      }

      getDynamicSymptomList(survey);
    }
  }, [surveyData]);

  useEffect(() => {
    try {
      // console.log("audio.src:", audio.src, audio.error);
      if (playSound && !audio.error) audio.play();
    } catch (e) {
      console.error("Media load Exception:", e);
    }
  }, [playSound]);

  const onSurveyloadHighlight = (surveyModel: Model) => {
    const currentPage = surveyModel.currentPage; // Get the current page
    if (!currentPage) return;
    let optionsObject = {
      name: currentPage?.name,
      question: currentPage.questions ? currentPage.questions[1] : null,
      value: surveyModel.data?.select_symptoms,
    };
    onCheckValueChangeEvent(surveyModel, optionsObject);
  };

  const symptomCheckerHightlight = (selectedSymptoms: Symptom[]) => {
    const inputs =
      document.querySelectorAll<HTMLInputElement>(".sd-item__control");

    // update highlight based on checkbox state
    const updateHighlight = (input: HTMLInputElement) => {
      // Get the input's value
      const inputValue = parseInt(input.getAttribute("value") ?? "", 10);

      // Find the symptom in the JSON data that matches the input value
      const symptomData = selectedSymptoms.find(
        (symptom) => symptom.value === inputValue
      );

      if (symptomData) {
        const item = input.closest(".sd-item");
        if (item) {
          const controlLabel = item.querySelector(".sd-item__control-label");
          if (controlLabel) {
            if (input.checked && symptomData.is_dissable_qualification) {
              controlLabel.classList.add("health-question-symptom-highlight"); // Add class to apply color
            } else {
              controlLabel.classList.remove(
                "health-question-symptom-highlight"
              ); // Remove class if unchecked
            }
          }
        }
      }
    };

    const handleInputChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      updateHighlight(input);
    };

    inputs.forEach((input) => {
      input.addEventListener("change", handleInputChange);
      updateHighlight(input);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("change", handleInputChange);
      });
    };
  };

  const onCheckValueChangeEvent = (
    sender: Model,
    options: ValueChangedEvent
    // options: optionsDataQuestion
  ) => {
    const dataQuestion = options ?? sender.getQuestionByName("select_symptoms");
    if (!dataQuestion === null && !dataQuestion.name && !dataQuestion.value)
      return;
    if (
      dataQuestion?.name === "select_symptoms" ||
      dataQuestion?.name === "health_questionnaire_symptom_checker"
    ) {
      const selectedSymptoms = symptoms.data?.filter((symptom: Symptom) => {
        return (
          Array.isArray(dataQuestion.value) &&
          typeof symptom.value === "number" &&
          dataQuestion.value.includes(symptom.value)
        );
      });
      symptomCheckerHightlight(selectedSymptoms);
    }
    if (
      //dataQuestion?.name === "top_5_symptoms_rank" ||
      dataQuestion?.name === "health_questionnaire_top_5_concerns"
    ) {
      const nonDisqualifyingSymptoms = symptoms.data?.filter(
        (symptom: Symptom) => {
          //excluding the red flag symptoms
          return (
            Array.isArray(dataQuestion.value) &&
            typeof symptom.value === "number" &&
            dataQuestion.value.includes(symptom.value) &&
            !symptom.is_dissable_qualification
          );
        }
      );
      if (!dataQuestion.question) return;
      dataQuestion.question.choices = [...nonDisqualifyingSymptoms];
    }
  };

  // Below hook is to show the disqualified symptoms for menopause hormonal health questionairre
  useEffect(() => {
    if (
      appointmentProgresses.length === 0 ||
      currentJourneySlug !== "menopause"
    ) {
      return;
    }
    const patientJourneySymptomsPhase = appointmentProgresses.find(
      (phase) =>
        phase?.journey_phase?.slug === "step-1---your-symptoms" &&
        phase.id === currentPhaseId
    );

    if (!patientJourneySymptomsPhase?.journey_phase?.patient_phase_activity)
      return;
    const journeyProgressPhaseActivity =
      patientJourneySymptomsPhase?.journey_phase?.patient_phase_activity[0]
        ?.phase_activity;

    if (
      journeyProgressPhaseActivity?.frontier_data?.title?.trim() !==
      surveyData?.title?.trim() ||
      surveyData?.title === undefined
    )
      return;

    surveyModel?.onAfterRenderPage.add(() => {
      onSurveyloadHighlight(surveyModel);
    });
    surveyModel?.onValueChanged.add((sender, options) => {
      onCheckValueChangeEvent(sender, options);
    });
  }, [appointmentProgresses, dispatch, patient, currentPhaseId, surveyModel]);

  // Below hook is to Get the treatments summary list from API
  useEffect(() => {
    const patientJourneySymptomsPhase = appointmentProgresses.find(
      (phase) =>
        phase?.journey_phase?.slug === "step-3---treatment" &&
        phase.id === currentPhaseId
    );
    if (!patientJourneySymptomsPhase) return;
    const appendTreatmentSummeryList = (
      survey: Model,
      treatmentsData: Treatment[]
    ) => {
      const question = survey.getQuestionByName("final_preferred_treatment");
      if (!question) {
        return;
      }
      const choices: { text: string; value: number }[] = [];
      treatmentsData?.forEach((sym: Treatment) => {
        choices.push({ text: sym.summaryText, value: sym.treatmentId });
      });

      question.choices = choices;
      // This onValueChanged method should be kept here otherwise it will take the default treatment values
      survey.onValueChanged.add((sender: Model, options: unknown) => {
        onValueChangeEvent(sender, options, treatmentsData);
      });

      // setSurveyModel(survey);
      // Manually trigger the value changed handler after survey model is set
      question.clickItemHandler(question.value);
    };

    // if (!treatments || !surveyModel) {
    //   return;
    // }

    const getTreatmentSummeryList = async () => {
      const fetchTreatment = await fetchTreatmentsListByPatientJourneyId({
        journeyId: patient.current_journey_id,
      });
      await dispatch(fetchTreatment).then((treat_data) => {
        if (
          surveyModel &&
          treat_data?.payload &&
          Array.isArray(treat_data.payload)
        ) {
          appendTreatmentSummeryList(surveyModel, treat_data?.payload);
        }
      });
    };
    getTreatmentSummeryList();
  }, [appointmentProgresses, dispatch, patient, currentPhaseId, surveyModel]);

  if (surveyModel == null) return null;

  if (hideSurveyWhenNotInModal) return null;

  if (!displayWrapper)
    return (
      <SurveyModal
        onClose={onClose}
        showContentInModal={showContentInModal}
        showSurveyModal={showSurveyModal}
        surveyModel={surveyModel}
      />
    );

  return (
    <PageWrapper
      nextCallback={() => {
        if (surveyModel.isLastPage) {
          setCompleteLastPage(true);
          surveyModel.completeLastPage();
          return;
        }

        setCompleteLastPage(false);
        surveyModel.nextPage();
      }}
      backCallback={() => {
        if (surveyModel.isFirstPage) return false;
        return surveyModel.prevPage();
      }}
    >
      <SurveyModal
        onClose={onClose}
        showContentInModal={showContentInModal}
        showSurveyModal={showSurveyModal}
        surveyModel={surveyModel}
      />
    </PageWrapper>
  );
};

export default GenerateSurveyModal;

const getSurveyResponses = (survey: Model) => {
  //$ const hasNotes =
  //$   survey.getQuestionByName("notes") && survey.getValue("notes") === undefined;

  const { pageNo, ...answers } = survey.data as Record<
    string,
    SurveyResponse["answer"]
  >;

  const surveyResponses = Object.entries(answers).map<SurveyResponse>(
    ([name, answer]) => {
      const qData = survey.getQuestionByName(name);

      // todo: remove special case for symptom ranking limit
      if (name === "top_5_symptoms_rank" && Array.isArray(answer))
        return { answer: answer.slice(0, 5), name, dbId: qData.dbId };

      return { answer, name, dbId: qData.dbId };
    }
  );

  //$ const surveyData = hasNotes ? { ...survey.data, notes: null } : survey.data;

  return surveyResponses;
};

const getSurveyData = (surveyResponses: SurveyResponse[] | string) => {
  const isString = typeof surveyResponses === "string";
  const parsedRes = isString ? JSON.parse(surveyResponses) : surveyResponses;

  if (!Array.isArray(parsedRes)) return {};

  const existingAnswers = parsedRes.reduce<
    Record<string, SurveyResponse["answer"]>
  >((prev, answer) => {
    return { ...prev, [answer.name]: answer.answer };
  }, {});

  return existingAnswers;
};

const isSurveyResponses = (data: unknown): data is SurveyResponse[] => {
  return Array.isArray(data) && data.every((obj) => isSurveyResponse(obj));
};

const SurveyModal: FC<{
  showContentInModal: boolean;
  surveyModel: Model;
  showSurveyModal: boolean;
  onClose: () => void;
}> = ({ onClose, showContentInModal, showSurveyModal, surveyModel }) => {
  return (
    <ConditionallyRender
      condition={!showContentInModal}
      show={
        <div className="sd-element-survey-hs__wrapper">
          <Survey model={surveyModel} />
        </div>
      }
      elseShow={
        <Modal
          isOpen={showSurveyModal}
          onClose={onClose}
          rounded="none"
          containerClassName="stepper_modal"
          overlayClassName="stepper_modal_overlay"
        >
          <div className="m-auto px-0 py-0">
            <div className="w-full overflow-hidden scrollbar max-h-[calc(100vh-50px)] h-auto [&>div]:bg-white/10">
              <Survey model={surveyModel} />
            </div>
          </div>
        </Modal>
      }
    />
  );
};

import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import {
  NavigateFunction,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import { alertNotification } from "../../../core/alert/ToastAlert";
import { PhaseActivityStatus } from "../../../core/phase-activity/AppointmentActivityConfig";
import NextFooter from "../../../providers/LayoutProvider/NextFooter";
import PageWrapper from "../../../providers/LayoutProvider/PageWrapper";
import { fetchPatientJourneyProgresses, markActivityAsCompleted } from "../../../redux/appointment/AppointmentSlice";
import { JOURNEY_SLUGS } from "../../../redux/journey/JourneySlice";
import { setBackGroundLoading } from "../../../redux/layout/LayoutSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { Patient, patientUpdate } from "../../../redux/user/UserSlice";
import InputField from "../../UI/Input";
import SelectField from "../../UI/Select";
import { stateOptions } from "./Data";

const tabs = [
  { name: "Billing" },
  { name: "Shipping" },
];

const PatientProfile: FC<{
  scheduling?: boolean;
  handleVerified?: () => void | Promise<void>;
  description?: string;
}> = ({ handleVerified, scheduling = false, description }) => {
  const patient: Patient = useAppSelector(
    (state: RootState) => state.user.authUser
  );
  const dispatch: AppDispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState("Billing");
  const [searchParams] = useSearchParams();
  const callback = searchParams.get("callback");
  const appointmentProgresses = useAppSelector(
    (state) => state.appointment.data.appontments
  );
  const currentJourneySlug = useAppSelector(
    (state) => state.journey.currentJourneySlug
  );
  const currentPhaseData = appointmentProgresses.find(
    (appointment) =>
      appointment.status === PhaseActivityStatus.INPROGRESS &&
      ["patient-registration", "patient-details"].includes(
        appointment?.journey_phase?.slug?.toLowerCase()
      )
  );
  const currentPhaseActivity =
    currentPhaseData?.journey_phase?.patient_phase_activity ?? [];

  const initialValues = {
    phone_number: patient?.phone_number,
    billing_address_line1: patient?.address?.billing?.address_line1,
    billing_address_line2: patient?.address?.billing?.address_line2,
    billing_city: patient?.address?.billing?.city,
    billing_state: patient?.address?.billing?.state?.toUpperCase(),
    billing_country: "US",
    billing_zip_code: patient?.address?.billing?.zip_code,
    shipping_address_line1: patient?.address?.shipping?.address_line1,
    shipping_address_line2: patient?.address?.shipping?.address_line2,
    shipping_city: patient?.address?.shipping?.city,
    shipping_state: patient?.address?.shipping?.state?.toUpperCase(),
    shipping_country: "US",
    shipping_zip_code: patient?.address?.shipping?.zip_code,
  };

  const completeActivityAndUpdateJourney = async () => {
    const { id, phase_activity } = currentPhaseActivity[0];
    const phaseSlug = phase_activity?.journey_phase?.slug;

    dispatch(setBackGroundLoading({ status: true, loadingMessage: "Fetching Appointments" }));

    await dispatch(
      markActivityAsCompleted({
        activityId: id,
        phaseSlug,
      })
    );

    await dispatch(
      fetchPatientJourneyProgresses({
        journeyId: patient.current_journey_id,
      })
    );

  };

  const updatePatientProfile = async (values: any): Promise<void> => {

    try {

      dispatch(setBackGroundLoading({ status: true }));

      await dispatch(patientUpdate(values));

      let callbackUrl: string | null = callback;

      if (scheduling === true) {
        if (handleVerified) handleVerified();
        dispatch(setBackGroundLoading({ status: false }));
        return;
      }

      const nextActivity = currentPhaseActivity.find(
        (activity) => activity.status === PhaseActivityStatus.INPROGRESS
      );

      if (nextActivity != null) {
        await completeActivityAndUpdateJourney();
        callbackUrl = `/${callback}`;
      } else if (!callback) {
        const previousLocation =
          localStorage.getItem("previousLocation") ||
          `/${JOURNEY_SLUGS[0]}/journey-phase/doctor-selection`;

        callbackUrl = patient?.eligible_for_journey && location.search
          ? `/${JOURNEY_SLUGS[0]}/journey-phase/review-symptoms`
          : previousLocation;
      }

      navigate(callbackUrl as string);
      alertNotification("success", "Profile updated successfully!", "top-right");
    } catch (error) {
      console.error("Error updating patient profile:", error);
      alertNotification("error", "Failed to update profile!", "top-right");
    } finally {
      dispatch(setBackGroundLoading({ status: false }));
    }

  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await updatePatientProfile({
        address: {
          billing: {
            address_line1: values.billing_address_line1,
            address_line2: values.billing_address_line2,
            city: values.billing_city,
            state: values.billing_state,
            country: values.billing_country,
            zip_code: values.billing_zip_code,
          },
          shipping: {
            address_line1: values.shipping_address_line1,
            address_line2: values.shipping_address_line2,
            city: values.shipping_city,
            state: values.shipping_state,
            country: values.shipping_country,
            zip_code: values.shipping_zip_code,
          },
        },
        phone_number: values?.phone_number,
        patientId: patient?.id,
      });
      setSubmitting(false);
    },
  });

  const billingAddressFields = [
    formik.values.billing_address_line1,
    formik.values.billing_address_line2,
    formik.values.billing_city,
    formik.values.billing_state,
    formik.values.billing_zip_code
  ];

  const shippingAddressFields = [
    formik.values.shipping_address_line1,
    formik.values.shipping_address_line2,
    formik.values.shipping_city,
    formik.values.shipping_state,
    formik.values.shipping_zip_code
  ];

  const isShippingAddressSame = (): boolean => {

    const areShippingFieldsEmpty = shippingAddressFields.every(field => (!field));

    if (areShippingFieldsEmpty) {
      return true;
    }

    for (let index = 0; index < billingAddressFields.length; index++) {
      if (billingAddressFields[index] !== shippingAddressFields[index]) {
        return false
      }
    }
    return true
  }

  const [isShippingSameAsBilling, setIsShippingSameAsBilling] = useState<boolean>(isShippingAddressSame());
  useEffect(() => {
    const fetchData = async () => {
      if (appointmentProgresses.length === 0 && patient.id) {
        await dispatch(
          fetchPatientJourneyProgresses({
            journeyId: patient.current_journey_id,
          })
        )
      }
    }
    fetchData()
  }, [patient])
  useEffect(() => {
    if (isShippingSameAsBilling) {
      formik.setFieldValue("shipping_address_line1", formik.values.billing_address_line1);
      formik.setFieldValue("shipping_address_line2", formik.values.billing_address_line2);
      formik.setFieldValue("shipping_city", formik.values.billing_city);
      formik.setFieldValue("shipping_state", formik.values.billing_state);
      formik.setFieldValue("shipping_zip_code", formik.values.billing_zip_code);
    }
  }, [...billingAddressFields, isShippingSameAsBilling])

  useEffect(() => {
    setIsShippingSameAsBilling(isShippingAddressSame());
  }, [...shippingAddressFields, currentTab]);

  const findTabWithError = (errors: any): string | null => {
    const billingErrors = [
      "billing_address_line1",
      "billing_city",
      "billing_state",
      "billing_country",
      "billing_zip_code",
    ];
    const shippingErrors = [
      "shipping_address_line1",
      "shipping_city",
      "shipping_state",
      "shipping_country",
      "shipping_zip_code",
    ];

    for (const error of billingErrors) {
      if (errors[error]) {
        return "Billing";
      }
    }

    for (const error of shippingErrors) {
      if (errors[error]) {
        return "Shipping";
      }
    }

    return null;
  };

  useEffect(() => {
    if (formik.isSubmitting && formik.errors) {
      const errorTab = findTabWithError(formik.errors);
      if (errorTab && errorTab !== currentTab) {
        setCurrentTab(errorTab);
      }
    }
  }, [formik.isSubmitting, formik.errors]);

  return (
    <PageWrapper
      nextCallback={() => {
        console.log("NEXT CLICK", { values: formik.values });
        formik.handleSubmit();
      }}
      disabled={{
        backBtn: scheduling === false && callback === null,
      }}
    >
      <form className="flex flex-col h-full" onSubmit={formik.handleSubmit}>
        <div className="flex-none">
          <div className="grid grid-cols-12 gap-4 py-4">
            <div className="col-start-2 col-end-12">
              <div className="flex flex-col">
                <div className="flex justify-center mb-3">
                  <h2 className="text-center text-3xl font-semibold">
                    Patient Information
                  </h2>
                </div>

                <p className="text-[17px] font-medium mb-2">
                  {description ? description : "To ensure a smooth experience when ordering medication or scheduling appointments, please confirm that your information is accurate."}
                </p>

                <div className="grid gap-6 xl:grid-cols-2 mt-2 mb-4">
                  <div className="inline-block w-full">
                    <label className="block text-base font-semibold leading-6">
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      className={`bg-gray-200 shadow-inner block w-full rounded-md border-0 px-4 py-2.5 text-ms placeholder:text-text-white/72 focus:outline-1`}
                      type="text"
                      id="text"
                      defaultValue={patient?.email}
                      readOnly
                    />
                  </div>

                  <div className="inline-block w-full">
                    <label className="block text-base font-semibold leading-6">
                      Phone Number <span className="text-red-500">*</span>
                    </label>

                    <PhoneInput
                      className={`bg-gray-200 [&>input]:bg-gray-200 [&>input]:h-[24px] [&>input]:border-0 shadow-inner block w-full rounded-md border-0 [&>input]:!ring-transparent px-4 py-2.5 text-ms placeholder:text-text-white/72 focus:outline-1`}
                      placeholder="Enter phone number"
                      value={formik.values.phone_number}
                      onChange={(value) =>
                        formik.setFieldValue("phone_number", value)
                      }
                      defaultCountry="US"
                    />

                    {formik.errors.phone_number && (
                      <div className="flex pt-2 justify-start">
                        <p className="text-sm font-semibold text-red-500">
                          {formik.errors.phone_number}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-4">
          <div className="col-start-2 col-end-12">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                value={currentTab}
                onChange={(e) => setCurrentTab(e.target.value)}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.name}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="hidden sm:block">
              <div className="overflow-hidden">
                <nav className="-mb-px flex gap-1" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.name}
                      onClick={() => setCurrentTab(tab.name)}
                      className={`${tab.name === currentTab && "bg-green-700 text-white"} w-auto border-b px-4 py-2 text-center text-base font-medium border-t border-l border-r cursor-pointer`}
                    >
                      {tab.name}
                    </div>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto scrollbar">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-end-12">
              <div className="inline-block w-full border px-6 overflow-y-auto">
                {currentTab === "Billing" && (
                  <div className="flex flex-col w-full py-2">
                    <div className="flex flex-wrap items-center justify-between gap-4 mb-6">
                      <div className="flex">
                        <h2 className="text-2xl font-semibold">
                          Billing Information
                        </h2>
                      </div>

                      <div className="flex">
                        <span className="space-y-2">
                          <label className="tw-checkbox-label cursor-pointer">
                            <input
                              type="checkbox"
                              checked={isShippingSameAsBilling}
                              onChange={() => setIsShippingSameAsBilling(!isShippingSameAsBilling)}
                              className="h-4 w-4 checkbox_class"
                            />
                            <span className="ml-2 text-sm font-bold">
                              Shipping address is the same
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div className="grid gap-6 grid-cols-1 xl:grid-cols-2">
                        <div className="inline-block">
                          <label className="block text-base font-semibold leading-6">
                            Address Line 1{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <InputField
                            label=""
                            name="billing_address_line1"
                            inputWrapClass="mb-4"
                            formik={formik}
                          />
                        </div>

                        <InputField
                          label="Address Line 2"
                          name="billing_address_line2"
                          inputWrapClass="mb-4"
                          formik={formik}
                        />
                      </div>

                      <div className="grid gap-6 grid-cols-1 xl:grid-cols-12">
                        <div className="col-start-1 col-end-4">
                          <div className="inline-block">
                            <label className="block text-base font-semibold leading-6">
                              City <span className="text-red-500">*</span>
                            </label>
                            <InputField
                              label=""
                              name="billing_city"
                              inputWrapClass="mb-4"
                              formik={formik}
                            />
                          </div>
                        </div>

                        <div className="col-start-4 col-end-10">
                          <div className="inline-block w-full">
                            <label className="block text-base font-semibold leading-6">
                              State <span className="text-red-500">*</span>
                            </label>
                            <SelectField
                              label=""
                              placeholder="Select State"
                              formik={formik}
                              name="billing_state"
                              options={stateOptions}
                              selectWrapClass="mb-4"
                            />
                          </div>
                        </div>

                        <div className="col-start-10 col-end-13">
                          <div className="inline-block">
                            <label className="block text-base font-semibold leading-6">
                              Zip Code <span className="text-red-500">*</span>
                            </label>

                            <InputField
                              label=""
                              name="billing_zip_code"
                              inputWrapClass="mb-4"
                              formik={formik}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {currentTab === "Shipping" && (
                  <div className="flex flex-col w-full py-2">
                    <div className="flex mb-6">
                      <h2 className="text-2xl font-semibold">
                        Shipping Information
                      </h2>
                    </div>

                    <div className="flex flex-col">
                      <div className="grid gap-6 grid-cols-1 xl:grid-cols-2">
                        <div className="inline-block">
                          <label className="block text-base font-semibold leading-6">
                            Address Line 1{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <InputField
                            label=""
                            name="shipping_address_line1"
                            inputWrapClass="mb-4"
                            formik={formik}
                          />
                        </div>

                        <InputField
                          label="Address Line 2"
                          name="shipping_address_line2"
                          inputWrapClass="mb-4"
                          formik={formik}
                        />
                      </div>

                      <div className="grid gap-6 grid-cols-1 xl:grid-cols-12">
                        <div className="col-start-1 col-end-4">
                          <div className="inline-block">
                            <label className="block text-base font-semibold leading-6">
                              City <span className="text-red-500">*</span>
                            </label>
                            <InputField
                              label=""
                              name="shipping_city"
                              inputWrapClass="mb-4"
                              formik={formik}
                            />
                          </div>
                        </div>

                        <div className="col-start-4 col-end-10">
                          <div className="inline-block w-full">
                            <label className="block text-base font-semibold leading-6">
                              State <span className="text-red-500">*</span>
                            </label>
                            <SelectField
                              label=""
                              placeholder="Select State"
                              formik={formik}
                              name="shipping_state"
                              options={stateOptions}
                              selectWrapClass="mb-4"
                            />
                          </div>
                        </div>

                        <div className="col-start-10 col-end-13">
                          <div className="inline-block">
                            <label className="block text-base font-semibold leading-6">
                              Zip Code <span className="text-red-500">*</span>
                            </label>
                            <InputField
                              label=""
                              name="shipping_zip_code"
                              inputWrapClass="mb-4"
                              formik={formik}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <NextFooter />
      </form>
    </PageWrapper>
  );
};
export default PatientProfile;

const validationSchema = Yup.object().shape({
  phone_number: Yup.string().required("Phone Number is required"),
  billing_address_line1: Yup.string().required(
    "Billing Address Line1 is required"
  ),
  billing_city: Yup.string().required("Billing City is required"),
  billing_state: Yup.string().required("Billing State is required"),
  billing_zip_code: Yup.string()
    .matches(/^\d{5}(?:-\d{4})?$/, "Invalid Zip Code")
    .required("Billing Zip Code is required")
    .min(5),
  shipping_address_line1: Yup.string().required(
    "Shipping Address Line1 is required"
  ),
  shipping_city: Yup.string().required("Shipping City is required"),
  shipping_state: Yup.string().required("Shipping State is required"),
  shipping_zip_code: Yup.string()
    .matches(/^\d{5}(?:-\d{4})?$/, "Invalid Zip Code")
    .required("Shipping Zip Code is required")
    .min(5),
});
import { Disclosure } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import {
  CheckIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import {
  ComponentProps,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import ConditionallyRender from "react-conditionally-render";
import PhoneInput from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import useTogglePatientGoal from "../../core/_hooks/useTogglePatientGoal";
import { alertNotification } from "../../core/alert/ToastAlert";
import { USDateformat } from "../../core/DateFormat";
import NavControls from "../../providers/LayoutProvider/NavControls";
import PageWrapper from "../../providers/LayoutProvider/PageWrapper";
import {
  getDashboardGoals,
  PatientGoal,
  togglePatientGoal,
  updatePatientGoal,
} from "../../redux/dashboard/DashboardSlice";
import { JOURNEY_SLUGS } from "../../redux/journey/JourneySlice";
import {
  fetchPatientNotifications,
  NotificationType,
  readAllPatientNotifications,
  removeNotification,
} from "../../redux/notification/NotificationSlice";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { patientUpdate } from "../../redux/user/UserSlice";
import GoalCard from "../goals/GoalCard";
import GoalReminders from "../goals/GoalReminders";

interface NotificationProps {
  completeClick: boolean;
  setCompleteClick: Dispatch<SetStateAction<boolean>>;
  setNavActionDisabled: Dispatch<
    SetStateAction<{
      back: boolean;
      forward: boolean;
    }>
  >;
  backClick: boolean;
}

const Notification: FC<NotificationProps> = ({
  completeClick,
  setNavActionDisabled,
  setCompleteClick,
  backClick,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { journeySlug } = useParams<{
    journeySlug: string;
  }>();

  const Patient = useAppSelector((state: RootState) => state.user.authUser);
  const goals = useAppSelector(
    (state: RootState) => state.dashboard.goals.data
  );
  const [patientGoals, setPatientGoals] = useState<PatientGoal[]>([]);
  const symptomsList = useAppSelector(
    (state: RootState) => state.symptom.symptomsList
  );

  const notifications: any = useAppSelector(
    (state: RootState) => state.notification
  );
  const {
    toggleSwitch,
    selectedGoal,
    targetGoal,
    setSelectedGoal,
    setTargetGoal,
    toggleGoalCard,
    toggleRequestFlag
  } = useTogglePatientGoal();
  const notificationsList: NotificationType[] = notifications.data;
  const [showEditCellInput, setShowEditCellInput] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState(Patient?.phone_number);

  // notification related functions
  useEffect(() => {
    if (Patient?.id) {
      dispatch(fetchPatientNotifications());
    }
  }, []);

  const handleNotificationClick = async (notification?: NotificationType) => {
    await dispatch(removeNotification(notification?.uuid as string));
    if (Patient?.id) {
      await dispatch(fetchPatientNotifications());
    }
  };

  const handleNavigateNotificationLink = async (
    notification?: NotificationType
  ) => {
    if (notification && notification?.link) {
      navigate(notification?.link);
    }
  };

  const handleNotificationMarkAll = async () => {
    await dispatch(readAllPatientNotifications());
    if (Patient?.id) {
      await dispatch(fetchPatientNotifications());
    }
  };

  const sumbitCellData = async (sendData: any) => {
    const res = await dispatch(patientUpdate(sendData));
    if (res.payload.id && phoneNumber === res.payload.phone_number) {
      alertNotification(
        "success",
        "Phone number saved successfully!",
        "top-right"
      );
    }
    setShowEditCellInput(false);
  };

  // goals related functions
  useEffect(() => {
    if (symptomsList && goals) {
      const selectedSymptoms: (string | undefined)[] = symptomsList
        ?.filter((itm) => itm?.is_positive === true)
        .map((itm) => itm?.title);

      if (selectedSymptoms.length > 0) {
        const filteredGoal = goals.filter((item) =>
          item.reportedSymptoms?.some((symptom) =>
            selectedSymptoms.includes(symptom)
          )
        );
        if (filteredGoal.length > 0) {
          setPatientGoals(
            filteredGoal.filter((goal: PatientGoal) => goal.active === true)
          );
        }
      } else {
        setPatientGoals(
          goals.filter((goal: PatientGoal) => goal.active === true)
        );
      }
    }
  }, [symptomsList, goals, Patient]);

  const BackButtonCallBack: ComponentProps<
    typeof NavControls
  >["backFn"] = async () => {
    if (
      !selectedGoal?.notificationDays ||
      !selectedGoal?.notificationTimes ||
      selectedGoal?.notificationDays?.length === 0 ||
      selectedGoal?.notificationTimes?.length === 0
    ) {
      if (selectedGoal && targetGoal?.active) {
        await dispatch(
          togglePatientGoal({
            journeyId: selectedGoal?.journeyId,
            journeyGoalId: selectedGoal?.journeyGoalId,
            id: selectedGoal?.id,
            uuid: selectedGoal?.uuid,
          })
        );
      }
    }
    await fetchGoals();
    setTargetGoal(null);
    setSelectedGoal(null);
  };

  const NextButtonCallBack = async () => {
    if (targetGoal !== null) {
      if (
        targetGoal?.notificationDays?.length === 0 ||
        targetGoal?.notificationTimes?.length === 0
      ) {
        const updatedTargetGoal = {
          ...targetGoal,
          uuid: targetGoal.uuid,
          notificationDays: [],
          notificationTimes: [],
          active: false,
        };
        await dispatch(updatePatientGoal(updatedTargetGoal));

        alertNotification(
          "warning",
          targetGoal?.notificationDays?.length === 0
            ? "As no day is selected, Goal will now be deactivated"
            : "As no time is selected, Goal will now be deactivated",
          "top-right"
        );
      } else {
        await dispatch(updatePatientGoal(targetGoal));
      }
      await fetchGoals();
      setTargetGoal(null);
      setSelectedGoal(null);
      return;
    }
  };

  const disableNextButton = (): boolean => {
    if (
      targetGoal &&
      targetGoal.notificationDays &&
      targetGoal.notificationTimes
    ) {
      return (
        targetGoal.notificationDays.length === 0 &&
        targetGoal.notificationTimes.length === 0
      );
    }
    return false;
  };

  useEffect(() => {
    if (backClick) {
      setTargetGoal(null);
      setSelectedGoal(null);
    }
  }, [backClick]);

  useEffect(() => {
    if (completeClick && Patient) {
      NextButtonCallBack().then(async () => {
        setCompleteClick(false);
      });
    }
  }, [completeClick, Patient]);

  useEffect(() => {
    if (targetGoal) {
      setNavActionDisabled({ forward: disableNextButton(), back: false });
    } else {
      setNavActionDisabled({ forward: true, back: true });
    }
  }, [targetGoal]);

  const fetchGoals = async () => {
    if (Patient.current_journey_id) {
      if (journeySlug === JOURNEY_SLUGS[0])
        await dispatch(getDashboardGoals(Patient.current_journey_id));
    }
  };

  useEffect(() => {
    if (Patient) {
      fetchGoals();
    }
  }, [Patient]);

  return (
    <PageWrapper
      backCallback={targetGoal ? BackButtonCallBack : undefined}
      nextCallback={NextButtonCallBack}
      disabled={{
        backBtn: targetGoal === null,
        nextBtn: targetGoal === null || disableNextButton(),
      }}
      closeable
    >
      {targetGoal ? (
        <GoalReminders goal={targetGoal} setTargetGoal={setTargetGoal} />
      ) : (
        <div className="flex flex-col h-full relative bg-[#f5f5f5]">
          <div className="flex-none">
            <div className="grid grid-cols-12 gap-4 pt-4 pb-2">
              <div className="col-start-2 col-end-12">
                <div className="flex justify-center">
                  <h2 className="text-center text-3xl font-semibold flex-1">
                    Notifications
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto scrollbar px-6 mx-2">
            <div className="inline-block w-full px-6 pt-4 pb-6 bg-white shadow rounded-lg mb-8">
              <div className="grid grid-cols-12 gap-8">
                <div className="col-start-1 col-end-8">
                  <div className="flex flex-col">
                    <div className="flex gap-2 items-baseline justify-between">
                      <h6 className="text-base font-semibold mb-2">
                        New Notifications
                      </h6>

                      {notificationsList &&
                        notificationsList.filter(
                          (notification: NotificationType) =>
                            !notification.readTimestamp
                        ).length > 0 && (
                          <div
                            className="flex items-center cursor-pointer gap-1"
                            onClick={() => handleNotificationMarkAll()}
                          >
                            <div className="inline-block">
                              <CheckCircleIcon
                                className="h-4 w-4 text-green-700 flex-none self-start"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="text-base underline">
                              Mark all as read
                            </p>
                          </div>
                        )}
                    </div>

                    <div className="flex flex-col">
                      <ConditionallyRender
                        condition={
                          notificationsList &&
                          notificationsList.filter(
                            (notification: NotificationType) =>
                              !notification.readTimestamp
                          ).length !== 0
                        }
                        show={
                          <>
                            {notificationsList
                              ?.filter(
                                (notification: NotificationType) =>
                                  !notification.readTimestamp
                              )
                              ?.map((notification: NotificationType) => (
                                <div
                                  className="flex items-start gap-6 mb-3"
                                  key={notification?.id}
                                >
                                  <div className="flex">
                                    <span className="text-base font-bold">
                                      {USDateformat(
                                        notification?.createTimestamp,
                                        "/"
                                      )}
                                    </span>
                                  </div>

                                  <div className="flex w-full max-w-[60%]">
                                    <p
                                      className={`text-base font-bold underline ${
                                        notification?.link && "cursor-pointer"
                                      }`}
                                      onClick={() =>
                                        handleNavigateNotificationLink(
                                          notification
                                        )
                                      }
                                    >
                                      {notification?.message}
                                    </p>
                                  </div>

                                  <div
                                    className="flex w-full justify-end items-center gap-1"
                                    onClick={() =>
                                      handleNotificationClick(notification)
                                    }
                                  >
                                    <p className="text-base underline cursor-pointer">
                                      Mark as read
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </>
                        }
                        elseShow={
                          <div className="flex flex-col items-center gap-2 mb-6">
                            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                              <ExclamationCircleIcon
                                className="h-6 w-6 text-yellow-500"
                                aria-hidden="true"
                              />
                            </div>

                            <p className="text-base font-semibold">
                              No notification found
                            </p>
                          </div>
                        }
                      />

                      <Disclosure>
                        {({ open }) => (
                          <>
                            <div className="relative">
                              <Disclosure.Button
                                className={classNames(
                                  "flex w-full justify-between text-left font-semibold text-black bg-[#f5f5f5]",
                                  open ? "rounded-t-lg" : "rounded-lg"
                                )}
                              >
                                <div
                                  className={`flex flex-col w-full border ${
                                    open ? "rounded-t-lg" : "rounded-lg"
                                  } px-4 py-2`}
                                >
                                  <div className="flex justify-between">
                                    <span>Read</span>
                                    <svg
                                      className={classNames(
                                        open && "rotate-180",
                                        "transition-all duration-500"
                                      )}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 1024 1024"
                                      height="1.5em"
                                      width="1.5em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                                    </svg>
                                  </div>
                                </div>
                              </Disclosure.Button>
                            </div>
                            <Disclosure.Panel className="text-sm text-black rounded-b-lg border-l border-r border-b">
                              <div className="space-y-5 max-h-[20vh] overflow-y-auto pt-2 pb-1 pr-2 scrollbar px-2">
                                <div className="flex space-x-5 items-start py-2">
                                  <div className="flex flex-col flex-1">
                                    <ConditionallyRender
                                      condition={
                                        notificationsList &&
                                        notificationsList.filter(
                                          (notification) =>
                                            notification.readTimestamp
                                        ).length !== 0
                                      }
                                      show={
                                        <>
                                          {notificationsList
                                            ?.filter(
                                              (notification) =>
                                                notification.readTimestamp
                                            )
                                            .map(
                                              (
                                                notification: NotificationType
                                              ) => (
                                                <div
                                                  className="flex items-start gap-6 mb-3"
                                                  key={notification?.id}
                                                >
                                                  <div className="flex">
                                                    <span className="text-base font-bold">
                                                      {USDateformat(
                                                        notification?.createTimestamp,
                                                        "/"
                                                      )}
                                                    </span>
                                                  </div>

                                                  <div className="flex">
                                                    <p className="text-base">
                                                      {notification?.message}
                                                    </p>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </>
                                      }
                                      elseShow={
                                        <div className="flex flex-col items-center gap-2 py-3">
                                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                            <ExclamationCircleIcon
                                              className="h-6 w-6 text-yellow-500"
                                              aria-hidden="true"
                                            />
                                          </div>

                                          <p className="text-base font-semibold">
                                            No notification found
                                          </p>
                                        </div>
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                </div>

                <div className="col-start-8 col-end-13">
                  <div className="flex flex-col w-full">
                    <h6 className="text-base font-semibold mb-2">
                      Contact info:
                    </h6>
                    {showEditCellInput ? (
                      <div className="border w-full items-center px-4 py-1 rounded-lg flex mb-2">
                        <PhoneInput
                          defaultCountry="US"
                          placeholder="Enter phone number"
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          className="journey_status_phone_input"
                        />
                        <div className="flex gap-2">
                          <CheckIcon
                            className="w-5 h-5 ms-2 text-green-700 cursor-pointer"
                            onClick={() =>
                              sumbitCellData({
                                ...Patient,
                                phone_number: phoneNumber,
                              })
                            }
                          />
                          <XMarkIcon
                            className="w-5 h-5 text-red-700 cursor-pointer"
                            onClick={() => setShowEditCellInput(false)}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <p className="text-sm mb-2">{`Cell: ${
                          Patient?.phone_number ? Patient?.phone_number : "-"
                        }`}</p>
                      </>
                    )}
                    <p className="text-sm mb-2 break-all">{`Email: ${
                      Patient?.email ? Patient?.email : "-"
                    }`}</p>
                    <p
                      className="text-sm text-[#0078BF] cursor-pointer"
                      onClick={() => setShowEditCellInput(true)}
                    >
                      Edit contact info
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="inline-block mb-7 w-full px-6 bg-white shadow rounded-lg">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-start-1 col-end-13">
                  <div className="flex flex-col">
                    {patientGoals.length > 0 && (
                      <>
                        <h5 className="text-[22px] font-semibold py-3">
                          Customize your Reminders and Communication Preferences
                        </h5>
                        <div className="flex-1 py-1">
                          <span>You are currently opted-in to:</span>
                        </div>
                        {patientGoals.map((goal, index) => (
                          <GoalCard
                            key={index}
                            goal={goal}
                            setAsTargetGoal={() => {
                              toggleGoalCard(goal);
                            }}
                            toggleSwitchHandler={() => {
                              toggleSwitch(goal);
                            }}
                            toggleRequestFlag={toggleRequestFlag}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};
export default Notification;

import { HeartIcon, PlayIcon, ShareIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import React, { FC, ReactNode, useEffect, useState } from "react";
import ConditionallyRender from "react-conditionally-render";
import { useParams } from "react-router-dom";
import { Tooltip } from "rizzui";
import { alertNotification } from "../../core/alert/ToastAlert";
import {
  addToFavouritesOrRemove,
  addVideoToHistory,
  getSearchResults,
  removeVideoFromHistory,
  sendEmailViaCustomerIo,
  ShareEmailContentType,
} from "../../core/requests/_requests";
import PageWrapper from "../../providers/LayoutProvider/PageWrapper";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import {
  Category,
  fetchCategories,
  fetchVideos,
  setSelectedVideo,
  Video,
} from "../../redux/video-gallery/VideoGallerySlice";
import { CustomEventHandlers } from "../video-player/CustomVideoPlayer";
import GalleryPlayer from "./GalleryPlayer";
import IntroGalleryVideo from "./IntroGalleryVideo";
import LoadingIcon from "./LoadingIcon";
import NoVideoFound from "./NoVideoFound";
import Pagination from "./Pagination";
import ShareComponent from "./ShareComponent";
import VideoCategory from "./VideoCategory";
export type LibraryCategory = {
  id: number | string;
  title?: string;
  videos?: Video[];
  apiEndPoint: string;
  icon?: any;
};

const libraryStaticCategories: LibraryCategory[] = [
  {
    id: "a",
    title: "All Videos",
    apiEndPoint: "all",
  },
  {
    id: "b",
    title: "Favorites",
    apiEndPoint: "favorite",
    icon: <HeartIcon className="text-red-500 h-7 w-7" />,
  },
  {
    id: "c",
    title: "History",
    apiEndPoint: "history",
  },
];

const videosPerPage = 6;

const VideoGallery: FC = () => {
  const { journeySlug } = useParams<{ journeySlug: string }>();

  const [currentPage, setCurrentPage] = useState(0);

  let offset = currentPage * videosPerPage;

  let videos: Video[] = useAppSelector(
    (state: RootState) => state.videoGallery.videos.data
  );
  const [pageCount, setPageCount] = useState(
    Math.ceil(videos.length / videosPerPage)
  );
  const [paginatedVideos, setPaginatedVideos] = useState<Video[]>(
    videos.slice(offset, offset + videosPerPage)
  );

  const selectedVideo: Video | null =
    useAppSelector((state: RootState) => state.videoGallery.selectedVideo) ??
    null;

  const [selectedCategory, setSelectedCategory] = useState<LibraryCategory>(
    libraryStaticCategories[0]
  );

  const categories: Category[] = useAppSelector(
    (state: RootState) => state.videoGallery.categories
  );

  const { first_name, last_name, current_journey_id, patient_journeys } =
    useAppSelector((state: RootState) => state.user.authUser);

  const [loading, setLoading] = useState<{ [key: number]: boolean }>({});
  const [searchQuery, setSearchQuery] = useState("");

  const currentJourneySlug: string = useAppSelector(
    (state: RootState) => state.journey.currentJourneySlug
  );

  const [videoObject, setVideoObject] = useState<Video>();
  const [isShareEnabled, setIsShareEnabled] = useState<boolean>(false);
  const [receipientEmails, setReceipientEmails] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const addToHistory = async (videoId: number) => {
    await addVideoToHistory(videoId);
  };

  const handleRemoveFromHistory = async (videoId: number) => {
    setLoading({ [videoId]: true });
    await removeVideoFromHistory(videoId);
    if (current_journey_id === undefined) return;
    const removeData = await dispatch(
      fetchVideos({
        //journeyId: journeyIds[currentJourneySlug],                            //$ removing hardcoding journeyIds
        journeyId: current_journey_id,
        params: {
          categoryId:
            typeof selectedCategory?.id === "number"
              ? selectedCategory?.id
              : undefined,
          filterType:
            typeof selectedCategory?.id !== "number"
              ? (selectedCategory.apiEndPoint as
                  | "all"
                  | "favorite"
                  | "history"
                  | undefined)
              : undefined,
        },
      })
    );
    offset = currentPage * videosPerPage;

    if (Array.isArray(removeData.payload)) {
      let current =
        currentPage === 0 && removeData.payload.length > 0
          ? 0
          : removeData.payload.length > offset
          ? currentPage
          : currentPage - 1;
      setCurrentPage(current);
      setPageCount(Math.ceil(removeData.payload.length / videosPerPage));
      setPaginatedVideos(
        removeData.payload.slice(offset, offset + videosPerPage)
      );
      alertNotification("success", "Video Removed Successfully!");
    }
    setLoading({});
  };
  const handleFavouriteClick = async (videoId: number) => {
    setLoading({ [videoId]: true });
    let addOrRemove = await addToFavouritesOrRemove(videoId);
    if (searchQuery) {
      searchVideos(currentPage);
    } else {
      if (current_journey_id === undefined) return;
      const favorite = await dispatch(
        fetchVideos({
          //journeyId: journeyIds[currentJourneySlug],                              //$ removing hardcoding journeyIds
          journeyId: current_journey_id,
          params: {
            categoryId:
              typeof selectedCategory?.id === "number"
                ? selectedCategory?.id
                : undefined,
            filterType:
              typeof selectedCategory?.id !== "number"
                ? (selectedCategory.apiEndPoint as
                    | "all"
                    | "favorite"
                    | "history"
                    | undefined)
                : undefined,
          },
        })

        //$ fetchVideos({
        //$   journeyId: journeyIds[currentJourneySlug],
        //$   filterType:
        //$     typeof selectedCategory.id === "string"
        //$       ? selectedCategory.apiEndPoint
        //$       : selectedCategory?.id,
        //$ })
      );
      offset = currentPage * videosPerPage;

      if (Array.isArray(favorite.payload)) {
        let current =
          currentPage === 0 && favorite.payload.length > 0
            ? 0
            : favorite.payload.length > offset
            ? currentPage
            : currentPage - 1;
        setCurrentPage(current);
        setPageCount(Math.ceil(favorite.payload.length / videosPerPage));
        setPaginatedVideos(
          favorite.payload.slice(offset, offset + videosPerPage)
        );
      }
    }
    alertNotification("success", "Video favorite status changed"); //todo: restore message
    // alertNotification("success", addOrRemove.message); //todo: restore message
    setLoading({});
  };

  const handleVideoEnd = async () => {
    dispatch(setSelectedVideo(null));
    handlePageClick({ selected: currentPage });
    if (searchQuery) {
      handleSearchClick();
    }

    if (selectedCategory.title === "History") {
      if (current_journey_id === undefined) return;
      const recentHistory = await dispatch(
        fetchVideos({
          //journeyId: journeyIds[currentJourneySlug],                                     //$ removing hardcoding journeyIds
          journeyId: current_journey_id,
          params: {
            categoryId:
              typeof selectedCategory?.id === "number"
                ? selectedCategory?.id
                : undefined,
            filterType:
              typeof selectedCategory?.id !== "number"
                ? (selectedCategory.apiEndPoint as
                    | "all"
                    | "favorite"
                    | "history"
                    | undefined)
                : undefined,
          },
        })

        //$ fetchVideos({
        //$   journeyId: journeyIds[currentJourneySlug],
        //$   filterType:
        //$     typeof selectedCategory.id === "string"
        //$       ? selectedCategory.apiEndPoint
        //$       : selectedCategory?.id,
        //$ })
      );
      offset = currentPage * videosPerPage;
      if (Array.isArray(recentHistory.payload)) {
        let current =
          currentPage === 0 && recentHistory.payload.length > 0
            ? 0
            : recentHistory.payload.length > offset
            ? currentPage
            : currentPage - 1;
        setCurrentPage(current);
        setPageCount(Math.ceil(recentHistory.payload.length / videosPerPage));
        setPaginatedVideos(
          recentHistory.payload.slice(offset, offset + videosPerPage)
        );
      }
    }
  };

  const generateCustomEventHandlers = (): CustomEventHandlers => {
    return {
      ended: handleVideoEnd,
    };
  };

  const handleSelectCategory = async (category: any) => {
    setSearchQuery("");
    setSelectedCategory(category);
    if (category.title === "All Videos") {
      await getLibraryVideos();
    }
    setCurrentPage(0);
    offset = currentPage * videosPerPage;
    setPaginatedVideos(videos.slice(offset, offset + videosPerPage));
    setPageCount(Math.ceil(videos.length / videosPerPage));
  };

  const VideoClick = (video: Video) => {
    dispatch(setSelectedVideo(video));
    addToHistory(video.id);
  };

  const getLibraryVideos = async () => {
    if (current_journey_id === undefined) return;
    let data = await dispatch(
      fetchVideos({
        // journeyId: journeyIds[currentJourneySlug],                                             //$ removing hardcoding journeyIds
        journeyId: current_journey_id,
        params: {
          categoryId:
            typeof selectedCategory?.id === "number"
              ? selectedCategory?.id
              : undefined,
          filterType:
            typeof selectedCategory?.id !== "number"
              ? (selectedCategory.apiEndPoint as
                  | "all"
                  | "favorite"
                  | "history"
                  | undefined)
              : undefined,
        },
      })
    );
  };

  useEffect(() => {
    if (Array.isArray(videos)) {
      setPaginatedVideos(videos.slice(offset, offset + videosPerPage));
      setPageCount(Math.ceil(videos.length / videosPerPage));
    }
  }, [videos, currentPage]);

  const getLibraryCategories = async () => {
    // if (journeySlug == null) return;
    // await dispatch(fetchCategories(journeyIds[journeySlug]));                                        //$ removing hardcoding journeyIds
    if (current_journey_id === undefined) return;
    await dispatch(fetchCategories(current_journey_id));
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
    setPageCount(Math.ceil(videos.length / videosPerPage));
    setPaginatedVideos(videos.slice(offset, offset + videosPerPage));
  };

  const searchVideos = async (selectedPage = 0) => {
    try {
      const searchVideosRequest = await getSearchResults(
        searchQuery,
        journeySlug
      );
      const results = searchVideosRequest ?? [];
      if (results?.length > videosPerPage) {
        const currentPageTemp = selectedPage;
        offset = currentPageTemp * videosPerPage;
        setPageCount(Math.ceil(results.length / videosPerPage));
        setPaginatedVideos(results.slice(offset, offset + videosPerPage));
      } else {
        setPageCount(Math.ceil(results.length / videosPerPage));
        setPaginatedVideos(results);
      }
    } catch (error) {
      console.error("Error searching videos:", error);
    }
  };

  const handleSearchClick = async () => {
    if (searchQuery.trim().length > 0) {
      if (selectedCategory.title !== "All Videos") {
        setSelectedCategory(libraryStaticCategories[0]);
      }
      setCurrentPage(0);
      searchVideos(0);
    } else {
      if (selectedCategory.title === "All Videos") {
        await getLibraryVideos();
      }
    }
  };
  const handleSearchEnter = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key !== "Enter") return;

    if (searchQuery.trim().length > 0) {
      if (selectedCategory.title !== "All Videos") {
        setSelectedCategory(libraryStaticCategories[0]);
      }
      setCurrentPage(0);
      searchVideos(0);
    } else {
      if (selectedCategory.title === "All Videos" && searchQuery === "") {
        await getLibraryVideos();
      }
    }
  };

  useEffect(() => {
    getLibraryVideos();
  }, [selectedCategory, current_journey_id]);

  useEffect(() => {
    getLibraryCategories();
  }, [current_journey_id]);

  const allCategories: LibraryCategory[] = [
    ...libraryStaticCategories,
    ...(categories
      ? categories.map((category: Category) => ({
          id: category.id,
          title: category.title,
          apiEndPoint: `/${category.id}`,
        }))
      : []),
  ];

  const renderAbsoluteIcon = (video: Video): ReactNode => {
    if (loading[video.id]) return <LoadingIcon />;

    switch (selectedCategory.title) {
      case "Favorite":
        return (
          <button
            className="text-red-500"
            onClick={(e) => {
              e.stopPropagation();
              handleFavouriteClick(video.id);
            }}
          >
            <HeartIcon className="h-8 w-8" />
          </button>
        );

      case "History":
        return (
          <button
            className="text-white hover:text-red-500 transition-all"
            onClick={() => handleRemoveFromHistory(video.id)}
          >
            <XCircleIcon className="h-8 w-8" />
          </button>
        );
      default:
        return (
          <>
            <Tooltip placement="top" content={"Click to favorite"}>
              <HeartIcon
                className={`h-8 w-8 transition-all cursor-pointer ${
                  video.is_favorite
                    ? "text-red-500"
                    : "text-white hover:text-red-500"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavouriteClick(video.id);
                }}
              />
            </Tooltip>
            <Tooltip placement="top" content={"Click to share"}>
              <ShareIcon
                className="h-8 w-8 text-white hover:text-red-500 transition-all cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShareClick(video);
                }}
              />
            </Tooltip>
          </>
        );
    }
  };

  const handleShareClick = (video: Video) => {
    if (!video.id) return;

    setVideoObject(video);
  };

  // // (optional) attach a file to your message.
  // request.attach("receipt.pdf", fs.readFileSync("receipt.pdf"));

  const handleShareForm = (isValid: boolean, shareOnEmails?: string[]) => {
    setIsShareEnabled(isValid);
    if (!shareOnEmails) return;
    setReceipientEmails(shareOnEmails);
  };

  const handleNextButton = async () => {
    /**
     * In case of multiple action needs to be performed with this button wrap the below control statement into a new function and execute here.
     * Action: Video Share
     */
    if (videoObject !== undefined && isShareEnabled) {
      const baseURL = `${window.location.origin}`;

      // Template id to send for specific content obtained from customer.io default to 33 (Shared Video Template)
      const emailTemplateId =
        process.env.REACT_APP_CUSTOMER_SHARE_VIDEO_TEMPLATE ?? "33";

      // Encode video id
      const encodedId = btoa(videoObject?.id?.toString());

      const fullName = `${first_name}${last_name ? " " + last_name : ""}`;
      const currentJourney = patient_journeys?.length
        ? patient_journeys?.find((j) => j.journey_id === current_journey_id)
        : { title: "" };

      const link = `${baseURL}/playVideo?mediaId=${encodedId}`;

      const content: ShareEmailContentType = {
        journey: currentJourney?.title ?? "",
        name: fullName ?? "",
        videolink: `<a href="${link}">${link}</a>`,
      };
      setIsShareEnabled(false);
      await sendEmailViaCustomerIo(receipientEmails, content, emailTemplateId);
      setVideoObject(undefined);
    }
  };

  const handleBackButton = () => {
    setVideoObject(undefined);
    return true;
  };

  return (
    <>
      <ConditionallyRender
        condition={!selectedVideo}
        show={
          <PageWrapper
            nextCallback={handleNextButton}
            backCallback={handleBackButton}
            disabled={{
              backBtn: videoObject === undefined,
              nextBtn: !isShareEnabled,
            }}
            closeable
          >
            <div className="flex flex-col h-full">
              <div className="grid grid-cols-12 gap-4 py-4">
                <div className="col-start-3 col-end-11 flex justify-between items-center">
                  <h2 className="text-center text-3xl font-semibold flex-1">
                    Video Gallery
                  </h2>
                </div>
              </div>

              {videoObject !== undefined ? (
                <div className="px-8">
                  <ShareComponent
                    video={videoObject}
                    onValidForm={handleShareForm}
                  />
                </div>
              ) : (
                <>
                  <div className="flex-1 overflow-y-auto scrollbar px-2 mx-2">
                    <div className="grid grid-cols-12 gap-4 h-full">
                      <div className="col-span-4 px-4 pb-4 h-full overflow-overflow-y-auto">
                        <div className="space-y-3">
                          <div className="relative">
                            <input
                              type="text"
                              placeholder="Search Videos"
                              className="tw-input block w-full px-4 py-2 border  text-white pr-12 placeholder:text-white"
                              style={{ fontSize: "14px" }}
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              onKeyDown={handleSearchEnter}
                            />
                            <MagnifyingGlassIcon
                              className="absolute mt-2 right-4 top-1/3 transform -translate-y-1/2 text-white h-5 w-5 cursor-pointer"
                              onClick={handleSearchClick}
                            />
                          </div>
                          <VideoCategory
                            allCategories={allCategories}
                            selectedCategory={selectedCategory}
                            handleSelectCategory={handleSelectCategory}
                          />
                        </div>
                      </div>

                      <div className="col-span-8">
                        <ConditionallyRender
                          condition={paginatedVideos.length > 0}
                          show={
                            <div className="inline-block mb-4">
                              <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-2 gap-4 xl:gap-8">
                                {paginatedVideos.map((video: Video, index) => (
                                  <div className="relative" key={index}>
                                    <div className="relative group aspect-video mb-4 cursor-pointer">
                                      <IntroGalleryVideo
                                        video={video}
                                        renderAbsoluteIcon={(video) =>
                                          renderAbsoluteIcon(video)
                                        }
                                        onClick={() => VideoClick(video)}
                                      />

                                      <PlayIcon className="h-10 w-10 text-white group-hover:text-white/70 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all" />
                                    </div>

                                    <div className="text-black text-sm">
                                      <p>{video.description}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }
                          elseShow={<NoVideoFound />}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex-none">
                    <div className="grid grid-cols-12 gap-4 py-2">
                      <div className="col-start-5 col-end-13">
                        {paginatedVideos.length > 0 && pageCount > 1 && (
                          <Pagination
                            currentPage={currentPage}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </PageWrapper>
        }
        elseShow={
          <PageWrapper
            backCallback={handleVideoEnd}
            disabled={{ nextBtn: true }}
          >
            <GalleryPlayer
              customEventHandlers={generateCustomEventHandlers()}
              src={selectedVideo?.media_url}
              videoSubtitles={selectedVideo?.subtitles}
            />
          </PageWrapper>
        }
      />
    </>
  );
};
export default VideoGallery;

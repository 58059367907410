import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllNotifications, markAllNotificationAsRead, markNotificationAsRead } from "../../core/requests/_requests";

export type NotificationType = {
    uuid: string,
    id?: string,
    createTimestamp?: string,
    readTimestamp?: string,
    active?: boolean,
    link?: string,
    message?: string,
    patient_id: string,
}

type initialStateType = {
    data: NotificationType[],
    loading: boolean;
    error?: string | null;
}

const initialState: initialStateType = {
    data: [],
    loading: false,
    error: null
}

export const fetchPatientNotifications = createAsyncThunk(
    "notification/PatientNotification",
    async () => {
        try {
            const response = await getAllNotifications( { showRead: true });
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const removeNotification = createAsyncThunk("notification/RemoveNotification",
    async (notificationUuid: string) => {
        try {
            await markNotificationAsRead(notificationUuid);
            return notificationUuid;
        } catch (error) {
            throw error;
        }
    }
)

export const readAllPatientNotifications = createAsyncThunk("notification/ReadAllPatientNotifications",
    async () => {
        try {
            await markAllNotificationAsRead();
            return;
        } catch (error) {
            throw error;
        }
    }
)

const NotificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder.addCase(fetchPatientNotifications.pending, (state) => {
            state.loading = true;
            state.error = null;
        }).addCase(fetchPatientNotifications.fulfilled, (state, action: PayloadAction<NotificationType[]>) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        }).addCase(fetchPatientNotifications.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
})

export default NotificationSlice.reducer;
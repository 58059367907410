import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { AppointmentActivity } from "../../core/phase-activity/AppointmentActivityConfig";
import ScheduleVirtualCareVisit from "../schedule/ScheduleVirtualCareVisit";

type MedicationProps = {
    activityDetails: AppointmentActivity,
    handleActivityCompletion: (activityDetails: AppointmentActivity) => void | Promise<void>
    setHideDefaultNextButton: Dispatch<SetStateAction<boolean>>;
}

const MedicationReview: FC<MedicationProps> = ({ activityDetails, handleActivityCompletion, setHideDefaultNextButton }) => {

    const handdleNextButtonClick = async () => {
        await handleActivityCompletion(activityDetails);
    };

    useEffect(() => {
        setHideDefaultNextButton(true);
    }, [])

    return (
        <ScheduleVirtualCareVisit
            activityDetails={activityDetails}
            handleActivityCompletion={handleActivityCompletion}
            setHideDefaultNextButton={setHideDefaultNextButton}
        />
    )
}

export default MedicationReview;

import { memo, useCallback, useEffect } from "react";
import useKommunicateChat from "../../core/_hooks/useKommunicateChat";
import eventEmitter from "../../core/Event";
import { RootState, useAppSelector } from "../../redux/store";

const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL;
const CHAT_STATE_KEY = "kommunicateChatState";

const getStorage = () => {
  try {
    if (localStorage) return localStorage;
  } catch (e) {
    try {
      if (sessionStorage) return sessionStorage;
    } catch (e) {
      return null;
    }
  }
  return null;
};
const storage = getStorage();

const KommunicateChat = () => {
  const showChatBot = useAppSelector(
    (state: RootState) => state.layout.showChatBot
  );

  useKommunicateChat();

  const openChat = useCallback(() => {
    eventEmitter.dispatch("openKommunicateChatBot", {});
    storage?.setItem(CHAT_STATE_KEY, "open");
  }, []);

  const closeChat = useCallback(() => {
    eventEmitter.dispatch("closeKommunicateChatBot", {});
  }, []);

  useEffect(() => {
    const chatState = storage?.getItem(CHAT_STATE_KEY);
    if (chatState === "open") openChat();
    if (chatState === "closed" || !showChatBot) closeChat();
  }, [showChatBot]);

  if (!showChatBot) {
    return null;
  }

  return (
    <div
      className="bg-black/50 w-auto overflow-hidden fixed bottom-4 xl:bottom-16 right-4 xl:right-20 rounded-full cursor-pointer flex items-center justify-center hover:bg-black/80"
      onClick={openChat}
    >
      <div
        className={
          "scale-100 opacity-100 relative overflow-hidden w-full text-white px-4"
        }
      >
        CHAT
      </div>
      <div className="w-16 h-14 overflow-hidden flex items-center justify-center flex-none relative rounded-full p-1">
        <img
          className="scale-100 opacity-100 rounded-full w-full h-full"
          src={`${CLOUDFRONT_URL}/assets/chat-user-picture.png`}
          alt=""
        />
      </div>
    </div>
  );
};

export default memo(KommunicateChat);

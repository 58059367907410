import { FC, useEffect } from "react";
import { AppointmentProgress } from "../../../redux/appointment/AppointmentSlice";
import { setShowChatBot } from "../../../redux/layout/LayoutSlice";
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from "../../../redux/store";
import CustomVideoPlayer, {
  CustomEventHandlers
} from "../../video-player/CustomVideoPlayer";

type PhaseTransitionProps = {
  currentPhaseProgress?: AppointmentProgress;
  handlePhaseTransitionEnd: () => void;
  openChatBot: () => void;
};

const PhaseTransition: FC<PhaseTransitionProps> = ({
  currentPhaseProgress,
  handlePhaseTransitionEnd,
  openChatBot
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const videoClip =
    currentPhaseProgress?.journey_phase?.phase_instructions_clip?.src;
  const showChatBot = useAppSelector(
    (state: RootState) => state.layout.showChatBot
  );
  const generateCustomEventHandlers = (): CustomEventHandlers => {
    return {
      ended: handlePhaseInstructionEnd,
    };
  };

  const handlePhaseInstructionEnd = () => {
    handlePhaseTransitionEnd();
    if (currentPhaseProgress?.journey_phase.showChatBot) {
      dispatch(setShowChatBot(false))
    }
  };

  useEffect(() => {
    if (currentPhaseProgress?.journey_phase.showChatBot && showChatBot) {
      openChatBot()
    } else if (!currentPhaseProgress?.journey_phase.showChatBot) {
      dispatch(setShowChatBot(false))
    }
  }, [currentPhaseProgress?.journey_phase.showChatBot, showChatBot])

  useEffect(() => {
    if (!currentPhaseProgress?.journey_phase?.phase_instructions_clip?.src) {
      handlePhaseTransitionEnd();
    }
  }, [currentPhaseProgress]);

  return (
    <div className="fullscreen-video fixed top-0 left-0 w-full h-screen">
      <CustomVideoPlayer
        key={currentPhaseProgress?.id}
        src={videoClip || ""}
        customEventHandlers={generateCustomEventHandlers()}
        customVideoEndButton={{
          show: true,
          endingEvent: () => handlePhaseInstructionEnd(),
        }}
        subtitles={
          currentPhaseProgress?.journey_phase?.phase_instructions_clip
            ?.subtitles
        }
      />
    </div>
  );
};

export default PhaseTransition;

import { FC, useEffect, useRef, useState } from "react";
import { initialNumbersValue, returnSelectedValue } from "./helper";

interface MinuteWheelProps {
  height: number;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

interface Hour {
  number: string;
  translatedValue: string;
  selected: boolean;
  hidden?: boolean;
}

const MinuteWheel: FC<MinuteWheelProps> = ({ height, value, setValue }) => {
  const [hours, setHours] = useState<Hour[]>(
    initialNumbersValue(height, 60, parseInt(value.slice(3, 5)), 5)
  );

  const mainListRef = useRef<HTMLDivElement | null>(null);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const [firstCursorPosition, setFirstCursorPosition] = useState<number | null>(null);
  const [currentTranslatedValue, setCurrentTranslatedValue] = useState<number>(
    parseInt(
      initialNumbersValue(height, 60, parseInt(value.slice(3, 5)), 5).filter(
        (item) => item.number === value.slice(3, 5) && item.selected === true
      )[0].translatedValue
    )
  );
  const [startCapture, setStartCapture] = useState<boolean>(false);
  const [showFinalTranslate, setShowFinalTranslate] = useState<boolean>(false);
  // start and end times
  const [dragStartTime, setDragStartTime] = useState<number | null>(null);
  const [dragEndTime, setDragEndTime] = useState<number | null>(null);
  // drag duration
  const [dragDuration, setDragDuration] = useState<number | null>(null);
  // drag type fast or slow
  const [dragType, setDragType] = useState<"fast" | "slow" | null>(null);
  // drag direction
  const [dragDirection, setDragDirection] = useState<"up" | "down" | null>(null);
  // selected number
  const [selectedNumber, setSelectedNumber] = useState<number | null>(null);

  useEffect(() => {
    setCurrentTranslatedValue(
      parseInt(
        initialNumbersValue(height, 60, parseInt(value.slice(3, 5)), 5).filter(
          (item) => item.number === value.slice(3, 5) && item.selected === true
        )[0].translatedValue
      )
    );
    setHours(initialNumbersValue(height, 60, parseInt(value.slice(3, 5)), 5));
  }, [value, height]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowFinalTranslate(false);
    setFirstCursorPosition(e.clientY);
    setStartCapture(true);
    setDragStartTime(performance.now());
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setShowFinalTranslate(false);
    setFirstCursorPosition(e.targetTouches[0].clientY);
    setStartCapture(true);
    setDragStartTime(performance.now());
  };

  const handleMouseUp = () => {
    setStartCapture(false);
    if (cursorPosition) {
      setCurrentTranslatedValue((prev) => prev + cursorPosition);
    }
    setShowFinalTranslate(true);
    setDragEndTime(performance.now());
    if (dragStartTime && performance.now() - dragStartTime <= 100) {
      setDragType("fast");
    } else {
      setDragType("slow");
    }
    if (cursorPosition && cursorPosition < 0) {
      setDragDirection("down");
    } else {
      setDragDirection("up");
    }
  };

  const handleMouseLeave = () => {
    setStartCapture(false);
    if (cursorPosition) {
      setCurrentTranslatedValue((prev) => prev + cursorPosition);
    }
    setShowFinalTranslate(true);
    setDragEndTime(performance.now());
    if (dragStartTime && performance.now() - dragStartTime <= 100) {
      setDragType("fast");
    } else {
      setDragType("slow");
    }
    if (cursorPosition && cursorPosition < 0) {
      setDragDirection("down");
    } else {
      setDragDirection("up");
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (startCapture && firstCursorPosition) {
      setCursorPosition(e.clientY - firstCursorPosition);
    } else {
      setCursorPosition(0);
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startCapture && firstCursorPosition) {
      setCursorPosition(e.targetTouches[0].clientY - firstCursorPosition);
    } else {
      setCursorPosition(0);
    }
  };

  // preview translation
  useEffect(() => {
    if (startCapture && mainListRef.current && cursorPosition) {
      mainListRef.current.style.transform = `translateY(${currentTranslatedValue + cursorPosition
        }px)`;
    }
  }, [cursorPosition]);

  // final translation here `
  useEffect(() => {
    if (showFinalTranslate && dragEndTime && dragStartTime) {
      setDragDuration(dragEndTime - dragStartTime);
      if (dragEndTime - dragStartTime <= 100 && cursorPosition !== 0) {
        let currentValue;
        if (dragDirection === "down") {
          currentValue =
            currentTranslatedValue -
            (120 / (dragEndTime - dragStartTime)) * 100;
        } else if (dragDirection === "up") {
          currentValue =
            currentTranslatedValue +
            (120 / (dragEndTime - dragStartTime)) * 100;
        }

        if (currentValue && mainListRef.current) {
          let finalValue = Math.round(currentValue / height) * height;
          if (finalValue < height * -177) finalValue = height * -177;
          if (finalValue > height * 2) finalValue = height * 2;

          mainListRef.current.style.transform = `translateY(${finalValue}px)`;
          setCurrentTranslatedValue(finalValue);
        }
      }
      if (dragEndTime - dragStartTime > 100 && cursorPosition !== 0) {
        let finalValue = Math.round(currentTranslatedValue / height) * height;
        if (finalValue < height * -177) finalValue = height * -177;
        if (finalValue > height * 2) finalValue = height * 2;
        mainListRef.current!.style.transform = `translateY(${finalValue}px)`;
        setCurrentTranslatedValue(finalValue);
      }
      setCursorPosition(0);
    }
  }, [showFinalTranslate]);

  // return to default position after drag end (handleTransitionEnd)
  const handleTransitionEnd = () => {
    returnSelectedValue(height, 60, 5).map((item) => {
      if (parseInt(item.translatedValue) === currentTranslatedValue) {
        if (item.arrayNumber) {
          setSelectedNumber(item.arrayNumber);
        }
        setValue((prev) => `${prev.slice(0, 2)}:${item.number}`);
        setHours(() => {
          const newValue = initialNumbersValue(height, 60, null, 5).map(
            (hour) => {
              if (
                hour.number == item.number &&
                hour.translatedValue == currentTranslatedValue.toString()
              ) {
                return {
                  ...hour,
                  selected: true,
                };
              }
              return hour;
            }
          );
          return newValue;
        });
      }
    });
  };

  // handle click to select number
  const handleClickToSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    if (cursorPosition === 0) {
      setCurrentTranslatedValue(parseInt(e.currentTarget.dataset.translatedValue!));
    }
  };

  const isFastCondition = showFinalTranslate && dragType === "fast";
  const isSlowCondition = showFinalTranslate && dragType === "slow";

  /* ***************************   handle wheel scroll ************************* */

  const handleWheelScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaY > 0) {
      if (currentTranslatedValue < height * 2) {
        setCurrentTranslatedValue((prev) => prev + height);
      }
    } else if (currentTranslatedValue > height * -177) {
      setCurrentTranslatedValue((prev) => prev - height);
    }
  };

  return (
    <div
      className="react-ios-time-picker-minute"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{ height: height * 5 }}
      onWheel={handleWheelScroll}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleMouseUp}
    >
      {/* <PickerEffects height={height} /> */}
      <div
        ref={mainListRef}
        className={`${isFastCondition === true && "react-ios-time-picker-fast"
          } ${isSlowCondition === true && "react-ios-time-picker-slow"}`}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateY(${currentTranslatedValue}px)` }}
      >
        {hours.map((hourObj, index) => (
          <div
            key={index}
            className="react-ios-time-picker-cell-minute"
            style={{ height: `${height}px` }}
          >
            <div
              className={`react-ios-time-picker-cell-inner-minute${hourObj.selected
                ? " react-ios-time-picker-cell-inner-selected"
                : ""
                }`}
              onClick={handleClickToSelect}
              data-translated-value={hourObj.translatedValue}
            >
              {hourObj.number}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MinuteWheel;

import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { submitPausedJourneyAction } from "../../../core/requests/_requests";
import { fetchPatientJourneyProgresses } from "../../../redux/appointment/AppointmentSlice";
import { setBackGroundLoading } from "../../../redux/layout/LayoutSlice";
import { RootState, useAppDispatch, useAppSelector } from "../../../redux/store";
import GenerateSurveyModal from "../../survey/GenerateSurveyModal";
import VerifiedJourneyAppointment from "./VerifiedJourneyAppointment";

type PausedJourneyAlert = {
  pausedJourneyModelData: any,
  setPausedJourneyModelData: any
}

const PausedJourneyAlert: FC<PausedJourneyAlert> = ({ pausedJourneyModelData, setPausedJourneyModelData }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentJourneySlug: string = useAppSelector((state: RootState) => state.journey.currentJourneySlug);
  const patient = useAppSelector(
    (state: RootState) => state.user.authUser
  );

  const handleSubmitPausedJourneyAction = async (data: any) => {

    dispatch(setBackGroundLoading({ status: true, loadingMessage: "Submitting Your Request" }));
    await submitPausedJourneyAction({ surveyAnswers: data, journey_slug: currentJourneySlug }).then(async (response) => {
      if (response.continueJourney) {
        await dispatch(
          fetchPatientJourneyProgresses({
             journeyId: patient.current_journey_id,
          })
        )
        setPausedJourneyModelData(null);
        dispatch(setBackGroundLoading({ status: false }));
        return <VerifiedJourneyAppointment />;
      } else {
        setPausedJourneyModelData(null);
        navigate(`/${currentJourneySlug}/dashboard`);
      }
      dispatch(setBackGroundLoading({ status: false }));
    })
  };

  return <GenerateSurveyModal
    showContentInModal={false}
    surveyData={pausedJourneyModelData}
    showSurveyModal={true}
    onClose={() => { }}
    onComplete={handleSubmitPausedJourneyAction}
    playSound={true}
  />
}

export default PausedJourneyAlert
import { useEffect, useState } from "react";

const InlineSvgDisplay = ({ url, className }: { url: string, className?: string }) => {
  const [svgContent, setSvgContent] = useState('');

  useEffect(() => {
    const fetchSvg = async () => {
      const response = await fetch(url);
      const text = await response.text();
      setSvgContent(text);
    };

    fetchSvg();
  }, [url]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgContent }}
      className={`${className} flex justify-center items-center object-cover overflow-hidden`}
    />

  );
};
export default InlineSvgDisplay;
import { Switch } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  ComponentProps,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { alertNotification } from "../../core/alert/ToastAlert";
import { days, months, time } from "../../core/constants/date_time";
import { ScheduleVisitPatientProfileDescription } from "../../core/constants/menopause_constants";
import { AppointmentActivity } from "../../core/phase-activity/AppointmentActivityConfig";
import {
  getSelectedDoctor,
  getVirtualVisit,
  makeVirtualVisit,
} from "../../core/requests/_requests";
import NextFooter from "../../providers/LayoutProvider/NextFooter";
import PageWrapper from "../../providers/LayoutProvider/PageWrapper";
import { JourneyStatus } from "../../redux/appointment/AppointmentSlice";
import { setBackGroundLoading } from "../../redux/layout/LayoutSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { SurveyResponse } from "../../types/survey";
import PatientProfile from "../patient/patient-profile/PatientProfile";
import { DoctorType } from "./Scheduling";

type ScheduleProps = {
  activityDetails: AppointmentActivity;
  handleActivityCompletion?: (
    activityDetails: AppointmentActivity,
    data: SurveyResponse[]
  ) => void | Promise<void>;
  setHideDefaultNextButton: Dispatch<SetStateAction<boolean>>;
};

const ScheduleVirtualCareVisit: FC<ScheduleProps> = ({
  activityDetails,
  handleActivityCompletion,
  setHideDefaultNextButton,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const callback = searchParams.get("callback");
  const { journeySlug } = useParams<{
    journeySlug: string;
  }>();

  const appointments = useAppSelector((state) => state.appointment);
  const [confirmed, setConfirm] = useState<boolean>(false);
  const [verifyPatient, setVerifyPatient] = useState<boolean>(true);
  const [enabled, setEnabled] = useState<boolean>(
    !(appointments.data.journey_status?.status === JourneyStatus.PAUSED)
  );

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<number>(-1);
  const [doctor, setDoctor] = useState<DoctorType | null>(null);

  const pausedJourneyData: SurveyResponse[] = [
    {
      answer: enabled,
      name: "continue_journey",
      dbId: 1256,
    },
  ];

  const handleDateChange: ComponentProps<typeof Calendar>["onChange"] = (
    date,
    _event
  ) => {
    if (date == null || Array.isArray(date)) return;
    setSelectedDate(new Date(date));
  };

  const handleSubmit = async () => {
    if (selectedTime === -1) {
      setConfirm(false);
      return;
    }
    await makeVirtualVisit({
      date: selectedDate,
      time: selectedTime,
    });
    if (callback) {
      return navigate(`/${journeySlug}/${callback}`);
    }
    setConfirm(true);
  };

  const handleNext = async () => {
    dispatch(setBackGroundLoading({ status: true }));
    try {
      handleActivityCompletion &&
        (await handleActivityCompletion(activityDetails, pausedJourneyData));
    } catch (error) {
      alertNotification("error", "Something went wrong!");
    } finally {
      dispatch(setBackGroundLoading({ status: false }));
    }
  };
  const handleBack = () => {
    setConfirm(false);
    return true;
  };
  const backVerify = () => {
    setVerifyPatient(true);
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      const virtualVisit = await getVirtualVisit();
      if (virtualVisit) {
        setSelectedDate(new Date(virtualVisit.date));
        setSelectedTime(virtualVisit.time);
      }
      const doctor = await getSelectedDoctor();
      if (doctor) setDoctor(doctor);
    };
    fetchData();
    if (callback) {
      setVerifyPatient(false);
    }
  }, []);

  useEffect(() => {
    setHideDefaultNextButton(true);
  }, [setHideDefaultNextButton]);


  return (
    <>
      {verifyPatient ? (
        <PatientProfile
          handleVerified={() => setVerifyPatient(false)}
          scheduling={true}
          description={ScheduleVisitPatientProfileDescription}
        />
      ) : (
        <>
          {confirmed ? (
            <PageWrapper nextCallback={handleNext} backCallback={handleBack}>
              <div className="flex flex-col h-full">
                <div className="flex-none">
                  <div className="grid grid-cols-12 gap-4 py-4">
                    <div className="col-start-2 col-end-12 flex justify-center">
                      <h2 className="text-center text-3xl font-semibold">
                        Virtual Appointment Confirmation
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="flex  overflow-y-auto scrollbar mx-2">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-start-2 col-end-12">
                      <p className="mt-12 mb-4 leading-6">
                        Your virtual appointment is set for{" "}
                        <b>
                          {`${months[selectedDate.getMonth()]
                            } ${selectedDate.getDate()}, ${selectedDate.getFullYear()}`}
                        </b>{" "}
                        at <b>{time[selectedTime]} ET</b>, with{" "}
                        <b>
                          Dr. {doctor?.firstName} {doctor?.lastName}
                        </b>
                      </p>
                      <ul className="space-y-2">
                        <li>
                          <div className="flex align-top">
                            <InformationCircleIcon className=" text-[#00843d] h-8 me-2 " />
                            <p className="mt-1">
                              You can change or cancel appointments by selecting
                              Scheduling from the menu above at any time, up to
                              24 hours before your scheduled visit.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="flex align-top">
                            <InformationCircleIcon className="text-[#00843d] h-10 me-2 " />
                            <p className="mt-2">
                              You will receive an appointment reminder 24 hours
                              before your scheduled virtual visit appointment.
                              We will also followup with next-steps once you
                              have completed your visit.
                            </p>
                          </div>
                        </li>
                      </ul>
                      <br />
                      <div>
                        <p className="mb-6 leading-6">
                          At this time, you have the option to{" "}
                          <span className="text-green-700 font-bold">
                            Pause
                          </span>{" "}
                          until you complete your virtual care visit, or you can
                          select{" "}
                          <span className="text-green-700 font-bold">
                            Continue
                          </span>{" "}
                          to learn about
                          <b> Medical Procedures.</b>
                        </p>
                        <Switch
                          checked={enabled}
                          onChange={setEnabled}
                          className={`bg-[#f9f9f9] relative inline-flex h-[48px] w-60 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#16b399] focus:ring-offset-2 shadow-inner`}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            className={`${enabled
                              ? "translate-x-[calc(100%-10px)]"
                              : "translate-x-0"
                              } pointer-events-none relative h-full w-fit px-8 transform rounded-full text-white font-semibold bg-[#16b399] shadow ring-0 transition duration-200 ease-in-out text-sm flex items-center justify-center`}
                          >
                            {enabled ? "Continue" : "Pause"}
                          </span>

                          <span
                            className={`absolute inset-0 flex items-center ${enabled ? "justify-start" : "justify-end"
                              } px-2`}
                            aria-hidden="true"
                          >
                            {!enabled && (
                              <span className={`text-gray-500 px-4`}>
                                Continue
                              </span>
                            )}

                            {enabled && (
                              <span className={`text-gray-500 px-4`}>
                                Pause
                              </span>
                            )}
                          </span>
                        </Switch>
                        {enabled ? (
                          <p className="mt-5"></p>
                        ) : (
                          <p className="mt-5">
                            Okay, we will pause your journey! When you are ready
                            to return select <b>Resume</b> from your Dashboard.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-1 overflow-y-auto scrollbar mx-8 px-2"></div>

                <NextFooter />
              </div>
            </PageWrapper>
          ) : (
            <PageWrapper
              nextCallback={handleSubmit}
              backCallback={backVerify}
              disabled={{ nextBtn: selectedTime === -1 }}
            >
              <div className="flex flex-col h-full">
                <div className="flex-none">
                  <div className="grid grid-cols-12 gap-4 py-4">
                    <div className="col-start-2 col-end-12">
                      <div className="flex justify-center flex-col ">
                        <h2 className="text-center text-3xl font-semibold mb-6">
                          Schedule a Virtual Consultation
                        </h2>
                        <p className="pl-16 text-base font-medium mb-2 leading-6">
                          Select a date on the calender to view the available
                          times for that day. Then click <br /> on the{" "}
                          <span className="tw-btn-green px-1">time</span> that
                          works best for you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-1 overflow-y-auto scrollbar ">
                  <div className="flex flex-col">
                    <div className="grid grid-cols-12 gap-4 mt-2">
                      <div className="col-start-2 col-end-7 mt-12 ml-10">
                        <Calendar
                          className="w-fit h-fit scale-125"
                          onChange={handleDateChange}
                          value={new Date(selectedDate)}
                          minDate={new Date()}
                        />
                      </div>

                      <div className="col-start-8 col-end-12">
                        <div>
                          <div className="font-semibold text-base text-black py-3 ">
                            {`${days[selectedDate.getDay()]}, ${months[selectedDate.getMonth()]
                              } ${selectedDate.getDate()}, ${selectedDate.getFullYear()}`}
                          </div>

                          <div className="grid grid-cols-1 gap-x-1 gap-y-3">
                            {time.map((time, index) => (
                              <div
                                key={index}
                                className={`flex items-center p-2 rounded-md ${selectedTime === index
                                  ? "bg-gray-200"
                                  : "bg-transparent"
                                  }`}
                              >
                                <button
                                  key={index}
                                  className={`tw-btn py-1.5 w-26 rounded-xl ${selectedTime === index
                                    ? "bg-sky-600 hover:bg-sky-500 text-white"
                                    : "tw-btn-green"
                                    }`}
                                  onClick={() => setSelectedTime(index)}
                                >
                                  {time}
                                </button>
                                <span className="ml-5 font-semibold text-xs">
                                  PHONE: (216) 444-2200 <br />
                                  CLINICIAN: DR.{" "}
                                  {doctor?.firstName?.toUpperCase()}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-14 gap-4 justify-center">
                      <div className="flex items-center gap-2">
                        <div className="w-3 h-3 bg-sky-600" />
                        <span className="text-sm">Selected</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="w-3 h-3 bg-green-700" />
                        <span className="text-sm">Available</span>
                      </div>
                    </div>
                  </div>
                </div>
                <NextFooter />
              </div>
            </PageWrapper>
          )}
        </>
      )}
    </>
  );
};

export default ScheduleVirtualCareVisit;
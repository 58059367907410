import { Switch } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { alertNotification } from "../../core/alert/ToastAlert";
import {
  removeInPersonVisit,
  removeVirtualVisit,
} from "../../core/requests/_requests";
import NextFooter from "../../providers/LayoutProvider/NextFooter";
import PageWrapper from "../../providers/LayoutProvider/PageWrapper";
import Scheduling from "./Scheduling";

export interface DateTime {
  type?: string;
  date?: string;
  time?: string;
}

const CancelSchedule: FC<DateTime> = ({ type, date, time }) => {
  const [showCancel, setShowCancel] = useState<boolean>(true);
  const [enabled, setEnabled] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (enabled) {
      if (type === "virtual") await removeVirtualVisit();
      if (type === "inperson") await removeInPersonVisit();
      alertNotification("success", "Your appointment has been cancelled");
      setShowCancel(false);
    }
    if (!enabled) {
      alertNotification("success", "Your appointment has NOT been cancelled");
      setShowCancel(false);
    }
  };

  const handleBack = () => {
    setShowCancel(false);
    return true;
  };

  if (!showCancel) {
    return <Scheduling />;
  }

  return (
    <PageWrapper nextCallback={handleSubmit} backCallback={handleBack} closeable>
      <div className="flex flex-col h-full">
        <div className="flex-none">
          <div className="grid grid-cols-12 gap-4 py-4">
            <div className="col-start-2 col-end-12">
              <div className="flex justify-center flex-col ">
                <h2 className="text-center text-3xl font-semibold mb-6">
                  {type === "virtual"
                    ? "Cancel My Virtual Consultation"
                    : "Cancel My In-Person Appointment"}
                </h2>
              </div>
              <p className="mt-12 mb-4 leading-6">
                {type === "virtual"
                  ? "You are requesting to cancel your consultation with a clinician to discuss Medication options for Overactive Bladder."
                  : " You are requesting to cancel your in-person appointment with a clinician to discuss medical procedure options for Overactive Bladder."}
              </p>
              <ul className="space-y-2">
                <li>
                  <div className="flex align-top">
                    <InformationCircleIcon className=" text-[#00843d] h-4 me-2 mt-1" />
                    <p>
                      {`Your appointment is set for ${date}
                          at ${time} ET`}
                    </p>
                  </div>
                </li>
              </ul>
              <br />
              <div className="ml-8">
                <p className="my-4 leading-6">
                  Do you wish to continue with this cancellation? <br />
                </p>
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={`bg-[#ebe8e8] relative inline-flex h-[48px] w-40 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#16b399] focus:ring-offset-2 shadow-inner`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    className={`${enabled
                        ? "translate-x-[calc(100%-0px)]"
                        : "translate-x-[2px]"
                      } pointer-events-none relative h-full w-1/2 transform rounded-full text-white font-semibold bg-[#16b399] shadow ring-0 transition duration-200 ease-in-out text-sm flex items-center justify-center`}
                  >
                    {enabled ? "Yes" : "No"}
                  </span>
                  <span
                    className={`absolute inset-0 flex items-center ${enabled ? "justify-start" : "justify-end"
                      } px-2`}
                    aria-hidden="true"
                  >
                    <span
                      className={`text-gray-500 ${enabled ? "opacity-0" : "opacity-100"
                        }`}
                    >
                      Yes
                    </span>
                    <span
                      className={`text-gray-500 ${enabled ? "opacity-100" : "opacity-0"
                        }`}
                    >
                      No
                    </span>
                  </span>
                </Switch>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto scrollbar mx-8 px-2"></div>
        <NextFooter />
      </div>
    </PageWrapper>
  );
};
export default CancelSchedule;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createGoal,
  createJournalSurvey,
  destroyGoalActivity,
  fetchRecommendedGoals,
  getAllJournalSurvey,
  getNotesData,
  toggleGoal,
  updatedNotesData,
  updateGoal,
  updateJournalSurvey,
  updatePatientGoalProgressRequest
} from "../../core/requests/_requests";
import { Day } from "../configuration/ConfigurationSlice";

type commonResponseType<T> = {
  loading: boolean;
  data: T;
  error: any;
};

export type Note = {
  appointmentTitle: string;
  appointmentName: string;
  activityTitle: string;
  patient_activity_id: number;
  patient_note_content: string;
  last_update: string;
};

export type Journal = {
  id: number;
  journal_external_data: {
    title: string;
  };
  journal_time?: string | null;
  journal_date?: string | null;
};

export enum GoalStatusType {
  DONE = "Done",
  IN_PROGRESS = "In Progress",
  TO_DO = "To Do",
}

export enum GoalActivityStatusType {
  COMPLETED = 1,
  IN_COMPLETED = 0,
}

export type GoalActivity = {
  id: number;
  title: string;
  description: string;
  goal_id: number;
  status: GoalActivityStatusType;
};

export type GoalProgresses = {
  date: string;
  active: boolean;
};

export enum ReminderType {
  EMAIL = "email",
  SMS = "sms",
}

export interface GoalNotificationTime {
  notificationTime: string;
  hasSmsNotification: boolean;
  hasEmailNotification: boolean;
}

export interface journeyGoalImage {
  description: string;
  fileName: string;
  fileType: string;
  id: number;
  s3FilePath: string;
  title: string;
}

export interface JourneyGoal {
  title: string;
  description: string;
  tooltip: string;
  journeyId: number;
  imageId: number;
  symptomIds: number[];
  backgroundColor: string;
  image: journeyGoalImage;
  active: boolean;
  createUser: number | undefined;
  createTimestamp: Date | undefined;
  id: number;
}

export interface PatientGoal {
  id?: number;
  uuid?: string;
  active: boolean;
  // patientId: number;
  journeyId: number;
  journeyGoalId: number;
  reportedSymptoms: string[];
  notificationDays?: Day[];
  notificationTimes?: GoalNotificationTime[];
  journeyGoal: JourneyGoal;
}

export type Goal = {
  id: number;
  patient_id: number;
  title: string;
  description: string;
  status: GoalStatusType;
  success_metric?: string;
  achievementDate: string;
  createdAt: string;
  activities: GoalActivity[];
  goalProgressInPercentage: string;
  goalProgress: GoalProgresses[];
  goalAchievedDates: string[];
  frontierFormData: any;
};

type InitialState = {
  overview: commonResponseType<any>;
  journal: commonResponseType<any>;
  notes: commonResponseType<Note[]>;
  goals: commonResponseType<PatientGoal[]>;
  summaries: commonResponseType<any>;
  homework: commonResponseType<any>;
  journeyStatus: commonResponseType<any>;
  activitySubmitLoader: boolean;
};

export type LiquidIntake = {
  journal_date?: string | null;
  journal_time?: string | null;
  quantity?: number;
  fluid_type?: string;
};

export type PeeDiary = {
  journal_date?: string | null;
  journal_time?: string | null;
  quantity?: number;
  leak_urine_volume?: number;
  urination_volume?: string | null;
};

export type JournalSurvey = {
  liquid_intake?: LiquidIntake[];
  pee_diary?: PeeDiary[];
};

export type NotesMetaInfo = {
  id: number;
  data: string;
};

const initialState: InitialState = {
  overview: {
    loading: false,
    data: [],
    error: null,
  },
  journal: {
    loading: false,
    data: [],
    error: null,
  },
  notes: {
    loading: false,
    data: [],
    error: null,
  },
  goals: {
    loading: false,
    data: [],
    error: null,
  },
  summaries: {
    loading: false,
    data: [],
    error: null,
  },
  homework: {
    loading: false,
    data: [],
    error: null,
  },
  journeyStatus: {
    loading: false,
    data: [],
    error: null,
  },
  activitySubmitLoader: false,
};

export const getDashboardNotes = createAsyncThunk(
  "patient/getDashboardNotes",
  async () => {
    try {
      const response = await getNotesData();
      return response;
    } catch (error) { }
  }
);

export const updateDashboardNote = createAsyncThunk(
  "patient/updateDashboardNote",
  async (data: { id: number | undefined; data: string | undefined }) => {
    try {
      const response = await updatedNotesData(data);
      return response.data;
    } catch (error) { }
  }
);

export const updateDashboardJournal = createAsyncThunk(
  "patient/updateDashboardJournal",
  async ({
    id,
    data,
  }: {
    id?: number;
    data: { journey_slug?: string; title?: string };
  }) => {
    try {
      const response = await updateJournalSurvey(id, data);
      return response.data;
    } catch (error) { }
  }
);

export const getDashboardGoals = createAsyncThunk(
  "patient/getDashboardGoals",
  async (journeyId: number) => {
    try {
      const response = await fetchRecommendedGoals(journeyId);
      return response.filter(
        (goal: PatientGoal) => goal.journeyGoal.active === true
      );
    } catch (error) { }
  }
);

export const createDashboardGoal = createAsyncThunk(
  "patient/createDashboardGoal",
  async (data: any, thunkAPI) => {
    try {
      const response = await createGoal(data);
      return response;
    } catch (error: any) {
      const errorMessage = error.message || "An error occurred";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const updatePatientGoal = createAsyncThunk(
  "patient/updatePatientGoal",
  async (data: any, thunkAPI) => {
    try {
      const response = await updateGoal(data);
      return response;
    } catch (error: any) {
      const errorMessage = error.message || "An error occurred";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const togglePatientGoal = createAsyncThunk(
  "patient/togglePatientGoal",
  async (data: Parameters<typeof toggleGoal>["0"], thunkAPI) => {
    try {
      const response = await toggleGoal(data);
      return response;
    } catch (error: any) {
      const errorMessage = error.message || "An error occurred";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const updatePatientGoalProgress = createAsyncThunk(
  "goal/updatePatientGoalProgress",
  async (data: { activity_date: string; goal_id: number }) => {
    const response = await updatePatientGoalProgressRequest(data);
    return response;
  }
);

export const deleteGoalActivity = createAsyncThunk(
  "goal/deleteGoalActivity",
  async (activity: GoalActivity) => {
    try {
      await destroyGoalActivity(activity.id);
      return activity;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllDashboardJournalSurvey = createAsyncThunk(
  "patient/getAllDashboardJournalSurvey",
  async (journeySlug?: string) => {
    try {
      const response = await getAllJournalSurvey(journeySlug);
      return response;
    } catch (error) { }
  }
);

export const createDashboardJournalSurvey = createAsyncThunk(
  "patient/createDashboardJournalSurvey",
  async (data: any) => {
    try {
      const response = await createJournalSurvey(data);
      return response.data;
    } catch (error) { }
  }
);

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setActivitySubmitLoader: (state, action) => {
      state.activitySubmitLoader = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDashboardNotes.pending, (state) => {
        state.notes.loading = true;
        state.notes.error = null;
      })
      .addCase(
        getDashboardNotes.fulfilled,
        (state, action: PayloadAction<Note[]>) => {
          state.notes.data = action.payload;
          state.notes.loading = false;
          state.notes.error = null;
        }
      )
      .addCase(
        getDashboardNotes.rejected,
        (state, action: PayloadAction<any>) => {
          state.notes.loading = false;
          state.notes.error = action.payload;
        }
      )
      .addCase(
        updateDashboardNote.fulfilled,
        (state, action: PayloadAction<any>) => {
          const fromMeta: NotesMetaInfo = (action as unknown as any)?.meta?.arg;
          state.notes.data = state.notes.data.map((note: Note) => {
            if (
              [action?.payload?.patient_activity_id, fromMeta.id].includes(
                note?.patient_activity_id
              )
            ) {
              return {
                ...note,
                patient_note_content:
                  action?.payload?.patient_note_content ?? fromMeta.data,
                last_update: action?.payload?.last_update ?? "Just now",
              };
            }
            return note;
          });
          state.notes.loading = false;
          state.notes.error = null;
        }
      )
      .addCase(
        updateDashboardNote.rejected,
        (state, action: PayloadAction<any>) => {
          state.notes.loading = false;
          state.notes.error = action.payload;
        }
      )
      .addCase(
        updateDashboardJournal.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.journal.data = state.journal.data.map((journal: Journal) => {
            if (journal?.id === action?.payload?.id) {
              return {
                ...journal,
                journal_external_data: {
                  title: action?.payload?.journal_external_data.title,
                },
              };
            }
            return journal;
          });
          state.journal.loading = false;
          state.journal.error = null;
        }
      )
      .addCase(
        updateDashboardJournal.rejected,
        (state, action: PayloadAction<any>) => {
          state.journal.loading = false;
          state.journal.error = action.payload;
        }
      )
      .addCase(updateDashboardJournal.pending, (state) => {
        state.journal.loading = true;
        state.journal.error = null;
      })
      .addCase(getDashboardGoals.pending, (state) => {
        state.goals.loading = true;
        state.goals.error = null;
      })
      .addCase(
        getDashboardGoals.fulfilled,
        (state, action: PayloadAction<PatientGoal[]>) => {
          state.goals.data = action.payload;
          state.goals.loading = false;
          state.goals.error = null;
        }
      )
      .addCase(
        getDashboardGoals.rejected,
        (state, action: PayloadAction<any>) => {
          state.goals.loading = false;
          state.goals.error = action.payload;
        }
      )
      .addCase(
        updatePatientGoal.fulfilled,
        (state, action: PayloadAction<PatientGoal>) => {
          state.goals.data = state.goals.data.map((goal) => {
            if (goal?.journeyGoalId === action?.payload?.journeyGoalId) {
              return {
                ...goal, ...action.payload, reportedSymptoms: goal?.reportedSymptoms, journeyGoal: {
                  ...action.payload.journeyGoal,
                  image: goal?.journeyGoal?.image
                }
              };
            }
            return goal;
          });
          state.goals.loading = false;
          state.goals.error = null;
        }
      )
      .addCase(
        updatePatientGoal.rejected,
        (state, action: PayloadAction<any>) => {
          state.goals.loading = false;
          state.goals.error = action.payload;
        }
      )
      .addCase(
        togglePatientGoal.fulfilled,
        (state, action: PayloadAction<PatientGoal>) => {
          state.goals.data = state.goals.data.map((goal) => {
            if (goal?.journeyGoalId === action?.payload?.journeyGoalId) {
              return { ...goal, active: action?.payload?.active };
            }
            return goal;
          });
          state.goals.loading = false;
          state.goals.error = null;
        }
      )
      .addCase(
        togglePatientGoal.rejected,
        (state, action: PayloadAction<any>) => {
          state.goals.loading = false;
          state.goals.error = action.payload;
        }
      )
      .addCase(getAllDashboardJournalSurvey.pending, (state) => {
        state.journal.loading = true;
        state.journal.error = null;
      })
      .addCase(
        getAllDashboardJournalSurvey.fulfilled,
        (state, action: PayloadAction<JournalSurvey[]>) => {
          state.journal.data = action.payload;
          state.journal.loading = false;
          state.journal.error = null;
        }
      )
      .addCase(
        getAllDashboardJournalSurvey.rejected,
        (state, action: PayloadAction<any>) => {
          state.journal.loading = false;
          state.journal.error = action.payload;
        }
      )
      .addCase(createDashboardJournalSurvey.pending, (state) => {
        state.journal.loading = true;
        state.journal.error = null;
      })
      .addCase(
        createDashboardJournalSurvey.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.journal.loading = false;
          state.journal.error = null;
        }
      )
      .addCase(
        createDashboardJournalSurvey.rejected,
        (state, action: PayloadAction<any>) => {
          state.journal.loading = false;
          state.journal.error = action.payload;
        }
      );
  },
});

export default DashboardSlice.reducer;
export const { setActivitySubmitLoader } = DashboardSlice.actions;

export type PatientJournal = {
  // id: string;
  name: string;
  color: string;
  description: string;
  createTimestamp?: string | Date;
  uuid: string;
};

export type JournalEntry = {
  // id?: string;
  uuid?: string;
  title: string;
  content: string;
  patientJournalUuid: PatientJournal["uuid"];
  date: Date | string;
  parameters: EntryParameter[];
  createTimestamp?: string | Date;
  updateTimestamp?: string | Date;
  coverImage?: string;
};

export type EntryParameter = {
  category: ParameterCategory;
  data: Record<string, any>;
};

export enum ParameterCategory {
  MOOD = "mood",
  INTAKE = "intake",
  LEAKAGE = "leakage",
  VOIDING = "voiding",
  URINATION = "Urination",
  ACCIDENTAL_LEAKS = "Accidental leaks",
}

export type FilteredJournalResponse = {
  patientJournalList: PatientJournal[];
  journalEntryList: JournalEntry[];
};

export enum filterCategory {
  MOOD = "mood",
  SODA = "soda",
}

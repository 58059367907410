import { FC, PropsWithChildren } from "react";
import { Provider } from "react-redux";

import { CookiesProvider } from "react-cookie";
import AppUnderMaintenance from "../components/under-maintenance/AppUnderMaintenance";
import store from "../redux/store";
import AuthProvider from "./AuthProvider";
import LogProvider from "./LogProvider";

const isSiteUnderMaintenance = process.env.REACT_APP_SITE_UNDER_MAINTENANCE === 'true';

const RootProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ReduxProvider children={
      isSiteUnderMaintenance ? <AppUnderMaintenance /> :
        <AuthProvider>
          <LogProvider>
            <CookiesProvider>{children}</CookiesProvider>
          </LogProvider>
        </AuthProvider>
    } />
  );
};

export default RootProvider;

const ReduxProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}
import { Navigate, Route, Routes } from 'react-router-dom';
import SignIn from '../SignIn';
import { FC } from 'react';

const PublicRoutes: FC = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="*" element={<SignIn />} />
            </Routes>
        </>
    )
}

export default PublicRoutes;
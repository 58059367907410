import axios, { InternalAxiosRequestConfig } from "axios";

axios.defaults.maxRedirects = 0;
const Api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const V1_API = axios.create({
  baseURL: process.env.REACT_APP_V1_API_BASE_URL,
});

const addAuth = async (config: InternalAxiosRequestConfig<unknown>) => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

Api.interceptors.request.use(addAuth);
V1_API.interceptors.request.use(addAuth);

export default Api;

import { FC, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import ConditionallyRender from "react-conditionally-render";
import { useNavigate, useParams } from "react-router-dom";
import { days, months, time } from "../../core/constants/date_time";
import {
  getInPersonVisit,
  getSelectedDoctor,
  getVirtualVisit,
} from "../../core/requests/_requests";
import PageWrapper from "../../providers/LayoutProvider/PageWrapper";
import { AppointmentProgress } from "../../redux/appointment/AppointmentSlice";
import { RootState, useAppSelector } from "../../redux/store";
import { Patient } from "../../redux/user/UserSlice";
import CancelSchedule, { DateTime } from "./CancelSchedule";

export interface Schedule {
  date: Date;
  time: number;
}

export interface DoctorType {
  id?: number;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  description?: string;
}

const Scheduling: FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(true);
  const [cancelled, setCancel] = useState<boolean>(false);
  const [cancelDateTime, setCancelDateTime] = useState<DateTime | null>(null);
  const [appointments, setAppointments] = useState<boolean>(true);
  const [virtual, setVirtual] = useState<boolean>(false);
  const [inperson, setInPerson] = useState<boolean>(false);
  const [doctor, setDoctor] = useState<DoctorType | null>(null);

  const patient: Patient = useAppSelector(
    (state: RootState) => state.user.authUser
  );

  const [inpersonVisit, setInpersonVisit] = useState<Schedule>({
    date: new Date(),
    time: 0,
  });
  const [virtualVisit, setVirtualVisit] = useState<Schedule>({
    date: new Date(),
    time: 0,
  });

  const { journeySlug } = useParams<{
    journeySlug: string;
  }>();
  const appointmentProgresses: AppointmentProgress[] = useAppSelector(
    (state: RootState) => state.appointment.data.appontments
  );

  const handleCancel = ({ type, date, time }: DateTime) => {
    setCancelDateTime({ type, date, time });
    setCancel(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const inpersonVisit = await getInPersonVisit();
      const virtualVisit = await getVirtualVisit();
      if (inpersonVisit) {
        setInpersonVisit({
          date: new Date(inpersonVisit.date),
          time: inpersonVisit.time,
        });
        setInPerson(true);
      }
      if (virtualVisit) {
        setVirtualVisit({
          date: new Date(virtualVisit.date),
          time: virtualVisit.time,
        });
        setVirtual(true);
      }
      setShowModal(true);
      if (!virtualVisit && !inpersonVisit) setAppointments(false);
      const doctor = await getSelectedDoctor();
      if (doctor) setDoctor(doctor);
    };
    fetchData();
  }, []);

  const virtualModify = () => {
    const virtualPhase = appointmentProgresses.find(
      (appointment) => appointment.journey_phase.slug === "medications-for-oab"
    );
    const virtualActivity =
      virtualPhase &&
      virtualPhase.journey_phase.patient_phase_activity.find(
        (activity) =>
          activity.phase_activity.description ===
          "Schedule Your Virtual Consultation"
      );
    navigate(
      `/${journeySlug}/journey-phase/${virtualPhase?.journey_phase.slug}?activityId=${virtualActivity?.id}&callback=scheduling`
    );
  };

  const inpersonModify = () => {
    const inpersonPhase = appointmentProgresses.find(
      (appointment) => appointment.journey_phase.slug === "oab-procedures"
    );
    const inpersonActivity =
      inpersonPhase &&
      inpersonPhase.journey_phase.patient_phase_activity.find(
        (activity) => activity.phase_activity.description === "Schedule a Visit"
      );
    navigate(
      `/${journeySlug}/journey-phase/${inpersonPhase?.journey_phase.slug}?activityId=${inpersonActivity?.id}&callback=scheduling`
    );
  };

  return (
    <>
      {showModal ? (
        <>
          {cancelled ? (
            <CancelSchedule
              type={cancelDateTime?.type}
              date={cancelDateTime?.date}
              time={cancelDateTime?.time}
            />
          ) : (
            <>
              <PageWrapper
                disabled={{ backBtn: true, nextBtn: true }}
                closeable
              >
                <div className="flex flex-col h-full">
                  <div className="grid grid-cols-12 gap-4 py-4">
                    <div className="col-start-3 col-end-11 flex justify-between items-center">
                      <h2 className="text-center text-3xl font-semibold flex-1">
                        Your Scheduled Appointments
                      </h2>
                    </div>
                  </div>

                  <ConditionallyRender
                    condition={appointments}
                    show={
                      <div className="container mx-auto ">
                        <div className="grid grid-cols-12 gap-3 pl-16">
                          <div className="col-start-1 col-end-6  p-1">
                            <div className="text-center text-base text-black py-3">
                              <span className="border-b-2 border-blue-600 p-2">
                                Virtual Appointment
                              </span>
                            </div>

                            <div className="container">
                              {virtual && (
                                <div className="max-w-sm py-2 px-8 my-3 border border-gray-200 rounded-2xl shadow bg-gray-200">
                                  <p className=" text-base leading-5 font-bold tracking-tight text-black">
                                    {`${days[virtualVisit.date.getDay()]}, ${
                                      months[virtualVisit.date.getMonth()]
                                    } ${virtualVisit.date.getDate()}, ${virtualVisit.date.getFullYear()}`}{" "}
                                    <br />
                                    {time[virtualVisit.time]}
                                  </p>

                                  <p className="mb-2 text-base text-black">
                                    PHONE: (216) 000-0000 <br />
                                    CLINICIAN: Dr. {doctor?.firstName}{" "}
                                    {doctor?.lastName} <br />
                                    <br />
                                    MEETING LINK:{" "}
                                    <a href="#">
                                      <u>
                                        <b>Join Meeting</b>{" "}
                                      </u>
                                    </a>
                                  </p>
                                  <div className="flex justify-between ">
                                    <button
                                      onClick={() => virtualModify()}
                                      className="tw-btn py-1.5 w-26 rounded-xl tw-btn-green"
                                    >
                                      Modify
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleCancel({
                                          type: "virtual",
                                          date: `${
                                            days[virtualVisit.date.getDay()]
                                          }, ${
                                            months[virtualVisit.date.getMonth()]
                                          } ${virtualVisit.date.getDate()}, ${virtualVisit.date.getFullYear()}`,
                                          time: time[inpersonVisit.time],
                                        })
                                      }
                                      className="tw-btn py-1.5 w-26 rounded-xl bg-red-600 px-3 text-white"
                                    >
                                      CANCEL
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-start-7 col-end-12 p-1">
                            <div className="text-center text-base text-black py-3">
                              <span className="border-b-2 border-blue-600 p-2">
                                In-Person Appointment
                              </span>
                            </div>

                            <div className="flex flex-col gap-1">
                              {inperson && (
                                <div className="max-w-sm py-2  px-8 my-3 border border-gray-200 rounded-2xl shadow bg-gray-200">
                                  <p className=" text-base leading-5 font-bold tracking-tight text-black">
                                    {`${days[inpersonVisit.date.getDay()]}, ${
                                      months[inpersonVisit.date.getMonth()]
                                    } ${inpersonVisit.date.getDate()}, ${inpersonVisit.date.getFullYear()}`}{" "}
                                    <br />
                                    {time[inpersonVisit.time]}
                                  </p>

                                  <p className="mb-2 text-base leading-6 text-black">
                                    ADDRESS: 9500 EUCLID AVE, CLEVELAND, OH
                                    44195 <br />
                                    PHONE: (216) 444-2200 <br />
                                    CLINICIAN: Dr. {doctor?.firstName}{" "}
                                    {doctor?.lastName} <br />
                                  </p>
                                  <div className="flex justify-between ">
                                    <button
                                      onClick={() => inpersonModify()}
                                      className="tw-btn py-1.5 w-26 rounded-xl tw-btn-green"
                                    >
                                      Modify
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleCancel({
                                          type: "inperson",
                                          date: `${
                                            days[inpersonVisit.date.getDay()]
                                          }, ${
                                            months[
                                              inpersonVisit.date.getMonth()
                                            ]
                                          } ${inpersonVisit.date.getDate()}, ${inpersonVisit.date.getFullYear()}`,
                                          time: time[inpersonVisit.time],
                                        })
                                      }
                                      className="tw-btn py-1.5 w-26 rounded-xl bg-red-600 px-3 text-white"
                                    >
                                      CANCEL
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    elseShow={
                      <>
                        <div className="container mx-auto ">
                          <div className="grid grid-cols-12 gap-3 pl-16">
                            <div className="col-start-1 col-end-6  p-1">
                              <div className="text-center text-base text-black py-3">
                                <span className="border-b-2 border-blue-600 p-2">
                                  Virtual Appointment
                                </span>
                              </div>
                            </div>
                            <div className="col-start-7 col-end-12 p-1">
                              <div className="text-center text-base text-black py-3">
                                <span className="border-b-2 border-blue-600 p-2">
                                  In-Person Appointment
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-center items-center mt-40">
                          <p className="text-gray-500">
                            You do not have any scheduled appointments at this
                            time.
                          </p>
                        </div>
                      </>
                    }
                  />
                </div>
              </PageWrapper>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default Scheduling;

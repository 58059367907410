import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

const NoVideoFound: FC = () => {
  return (
    <div className="flex justify-center items-center w-full h-[calc(50vh)]">
      <ExclamationTriangleIcon
        className="h-12 w-12 text-yellow-400"
        aria-hidden="true"
      />
      <div className="text-black ps-2 text-xl">No Videos Found</div>
    </div>
  );
};

export default NoVideoFound;

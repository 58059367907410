import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  Activity,
  AppointmentActivity,
} from "../../core/phase-activity/AppointmentActivityConfig";
import {
  getPatientJourneyPhaseProgressComplete,
  getPatientJourneyProgressesByPatient,
  markPhaseActivityAsCompleted,
} from "../../core/requests/_requests";
import { Symptom } from "../symptom/SymptomSlice";

type Page = {
  name: string;
  elements: {
    choices: Symptom[];
    [key: string]: unknown;
  }[];
};

export type Appointment = {
  id: number;
  partner_id: number;
  journey_id: number;
  title: string;
  name: string;
  role: string;
  showChatBot: boolean | null;
  description: string;
  icon_url: string;
  sort_order: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  journey_phase_image: string;
  transition_video: string;
  show_in_dashboard: number;
  phase_instructions_clip: {
    src: string;
    subtitles: any;
    title: string;
  };
  doctor_instructions_clip: {
    src: string;
    subtitles: any;
    title: string;
    excludeMainHeader: boolean;
  };
  patient_phase_activity: AppointmentActivity[];
  background_image: string;
  slug: string;
  autoload_primary_activity: boolean;
  phase_activity_count: number;
  is_skippable: boolean;
  check_phase_eligibility: boolean;
};

export type AppointmentProgress = {
  id: string;
  patient_id: string;
  journey_phase_id: number;
  status: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  phase_activity: Activity;
  journey_phase: Appointment;
  patient_journey_phase_id: string;
};

export enum JourneyStatus {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  COMPLETED = "COMPLETED",
}

export type PatientJourneyStatus = {
  status: JourneyStatus;
  pausedJourneyDTO?: {
    id: number;
    slug: string;
    title: string;
    activityId?: number;
  };
  journeyCompletedDate?: Date;
};

type InitialStateType = {
  data: {
    journey_status?: PatientJourneyStatus;
    appontments: AppointmentProgress[] | [];
  };
  loading: boolean;
  error: string | null;
};

export enum AppointmentStatus {
  LOCKED = 0,
  CURRENT = 1,
  COMPLETED = 2,
  SKIPPED = 3,
}
const initialState: InitialStateType = {
  data: {
    appontments: [],
  },
  loading: false,
  error: null,
};
/** Legacy API Routes */
// export const fetchPatientAppointmentProgresses = createAsyncThunk(
//   "appointment/fetchPatientAppointmentProgresses",
//   async (journeySlug: string) => {
//     try {
//       const response = await getPatientJourneyProgresses(journeySlug);
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const markJourneyAppointmentAsCompleted = createAsyncThunk(
  "appointment/markJourneyAppointmentAsCompleted",
  async (journeyPhaseId: number) => {
    const response = await getPatientJourneyPhaseProgressComplete(
      journeyPhaseId.toString()
    );
    return response.data;
  }
);

export const markActivityAsCompleted = createAsyncThunk(
  "currentPhase/setCurrentPhaseActivities",
  async (activityData: {
    phaseSlug: string;
    activityId: string;
    data?: Object;
    isExternalData?: boolean;
  }) => {
    try {
      const response = await markPhaseActivityAsCompleted(
        activityData.activityId.toString(),
        {
          JourneyPhaseSlug: activityData.phaseSlug,
          data: activityData?.data,
        },
        activityData.isExternalData === false ? false : true
      );
      return response;
    } catch (error) {}
  }
);

/** New API Routes */
// export const fetchPatientAppointmentProgresses = createAsyncThunk(
//   "v1/journeyProgress/fetchPatientJourneyProgressesByPatientId0",
//   async (patientId: any) => {
//     try {
//       const response = await getPatientJourneyProgressesByPatient(patientId);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   }
// );
export const fetchPatientJourneyProgresses = createAsyncThunk(
  "v1/journeyProgress/fetchPatientJourneyProgressesByPatientId",
  async (journeyProgressParams: {
    journeyId?: number;
  }) => {
    try {
      const response = await getPatientJourneyProgressesByPatient(
        journeyProgressParams.journeyId
      );
      return response;
    } catch (error) {
      console.error("ACTIVITY COMPLETE ERROR\n", error);
    }
  }
);

const AppointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    setAppointments: (state, action) => {
      state.data.appontments = action.payload;
      return state;
    },
    setSymptomsList: (
      state,
      action: PayloadAction<{
        symptomsList?: Symptom[];
        phase_id:string;
        activity_id: string;
      }>
    ) => {
      if (
        !action.payload.symptomsList ||
        action.payload.symptomsList.length === 0
      )
        return;
      state.data.appontments = state.data.appontments.map((phase) => {
        if (!phase.journey_phase?.patient_phase_activity) return phase;
        const updatedPatientPhaseActivity =
          phase.journey_phase.patient_phase_activity.map(
            (activity: AppointmentActivity) => {
              if (activity.id === action.payload.activity_id.toString()) {
                activity.phase_activity?.frontier_data?.pages.map(
                  (page: Page) => {
                    if (
                      page.name === "health_questionnaire_symptom_checker" &&
                      action.payload.symptomsList
                    ) {
                      page.elements[0] = {
                        ...page.elements[0],
                        choices: action.payload.symptomsList,
                      };
                    }
                    return page;
                  }
                );
              }
              return activity;
            }
          );
        return {
          ...phase,
          journey_phase: {
            ...phase.journey_phase,
            patient_phase_activity: updatedPatientPhaseActivity,
          },
        };
      });
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(fetchPatientAppointmentProgresses.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(
      //   fetchPatientAppointmentProgresses.fulfilled,
      //   (state, action: PayloadAction<any>) => {
      //     state.data = action.payload;
      //     state.loading = false;
      //     state.error = null;
      //   }
      // )
      // .addCase(
      //   fetchPatientAppointmentProgresses.rejected,
      //   (state, action: PayloadAction<any>) => {
      //     state.loading = false;
      //     state.error = action.payload;
      //   }
      // )
      /**Journey actions */
      .addCase(fetchPatientJourneyProgresses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchPatientJourneyProgresses.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        fetchPatientJourneyProgresses.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(
        markJourneyAppointmentAsCompleted.fulfilled,
        (state, action: PayloadAction<AppointmentProgress>) => {
          const completedPhaseIndex = state.data.appontments.findIndex(
            (phase) => phase.id === action.payload.id
          );

          if (completedPhaseIndex !== -1) {
            state.data.appontments[completedPhaseIndex] = {
              ...state.data.appontments[completedPhaseIndex],
              ...action.payload,
            };

            const nextPhaseIndex = completedPhaseIndex + 1;
            if (nextPhaseIndex < state.data.appontments.length) {
              state.data.appontments[nextPhaseIndex] = {
                ...state.data.appontments[nextPhaseIndex],
                status: AppointmentStatus.COMPLETED,
              };
            }
          }
        }
      )
      .addCase(
        markActivityAsCompleted.fulfilled,
        (state, action: PayloadAction<AppointmentProgress>) => {
          state.data.appontments = state.data.appontments.map((phase) => {
            if (
              phase.journey_phase &&
              phase.journey_phase.patient_phase_activity &&
              phase?.id.toString() === action?.payload?.patient_journey_phase_id
            ) {
              const updatedPatientPhaseActivity =
                phase.journey_phase.patient_phase_activity.map(
                  (activity: any) => {
                    if (activity.id === action.payload.id) {
                      if (
                        activity?.phase_activity?.journey_phase?.slug ===
                          "exercises" &&
                        activity?.phase_activity?.name?.toLowerCase() ===
                          "homework"
                      ) {
                        //to avoid the frontier data getting null before the phase completion for Homework step in OAB
                        return {
                          ...activity,
                          ...action?.payload,
                          phase_activity: {
                            ...action?.payload.phase_activity,
                            frontier_data:
                              activity?.phase_activity?.frontier_data,
                          },
                        };
                      } else {
                        return { ...activity, ...action?.payload };
                      }
                    }
                    return activity;
                  }
                );

              return {
                ...phase,
                journey_phase: {
                  ...phase.journey_phase,
                  patient_phase_activity: updatedPatientPhaseActivity,
                },
              };
            }
            return phase;
          });
        }
      );
  },
});

export default AppointmentSlice.reducer;
export const { setSymptomsList, setAppointments } = AppointmentSlice.actions;

import classNames from "classnames";
import Hls from "hls.js";
import { FC, useEffect } from "react";
import { Doctor } from "../../../redux/doctor/DoctorSlice";
import { Subtitle } from "../../../types/video";
import { hlsSupportedExtensions } from "../../video-player/CustomVideoPlayer";

const IntroVideoPlayer: FC<{
  doctor: Doctor
}> = ({ doctor }) => {

  const addSubtitlesToVideo = (
    videoElement: HTMLVideoElement,
    subtitles: Subtitle[]
  ) => {
    subtitles.forEach((caption, i) => {
      const track = document.createElement("track");
      track.kind = "subtitles";
      track.label = caption.language;
      track.srclang = caption.lang;
      track.src = caption.url;
      track.default = i === 0;
      videoElement.appendChild(track);
    });
  };

  useEffect(() => {

    const videoElement = document.getElementById(`video-${doctor.id}`) as HTMLVideoElement;

    if (doctor?.video?.media_url) {

      const fileExtension = doctor?.video?.media_url
        .split(".")
        .pop()
        ?.toLowerCase();

      if (videoElement) {
        if (fileExtension &&
          hlsSupportedExtensions.includes(`.${fileExtension}`)) {
          const hls = new Hls();
          hls.loadSource(doctor?.video?.media_url);
          hls.attachMedia(videoElement);
        }
      }
    }

    addSubtitlesToVideo(videoElement, doctor.video?.subtitles)

    videoElement?.addEventListener("mouseover", async () => {
      await videoElement.play().catch(_ => {
        // Do nothing
      });
    })

    videoElement?.addEventListener("mouseout", () => {
      videoElement.pause();
      videoElement.currentTime = 0;
    })

  }, [])

  return (
    <>
      <img
        src={doctor?.profile?.media_url}
        alt={doctor.first_name}
        className={classNames(
          "card-image top-0 left-0 w-full rounded-t-2xl group-hover:scale-110 transition-all duration-500 h-[17vh]"
        )}
      />
      <video
        poster={doctor?.profile?.media_url}
        id={`video-${doctor.id}`}
        src={doctor?.video?.media_url}
        className={classNames(
          'hover-video absolute w-full top-0 rounded-t-2xl left-0 opacity-0 group-hover:opacity-100 transition-all object-contain duration-700 h-[17vh]'
        )}
        loop
        controls
        crossOrigin='anonymous'
      />
    </>
  )
}
export default IntroVideoPlayer
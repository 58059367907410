import { Squares2X2Icon } from "@heroicons/react/24/outline";
import { default as classnames } from "classnames";
import {
  ComponentProps,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ConditionallyRender from "react-conditionally-render";
import { Button, Input, Tab } from "rizzui";
import { alertNotification } from "../../../../core/alert/ToastAlert";
import { months } from "../../../../core/constants/date_time";
import { getFilteredJournalEntry } from "../../../../core/requests/_requests";
import { formatDate, isSameDate } from "../../../../core/utilities";
import { searchJournalEntry } from "../../../../redux/journals/JournalSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  filterCategory,
  JournalEntry,
  PatientJournal,
} from "../../../../types/journal";
import CreateEntryForm, { moodOptions } from "./CreateEntryForm";
import CreateJournalForm from "./CreateJournalForm";
import JournalCreateButton from "./JournalCreateButton";
import JournalEntries from "./JournalEntries";
import JournalNav from "./JournalNav";
import ScrollWrapper from "./ScrollWrapper";

interface JournalProps {
  completeClick: boolean;
  setCompleteClick: Dispatch<SetStateAction<boolean>>;
  setNavActionDisabled: Dispatch<
    SetStateAction<{
      back: boolean;
      forward: boolean;
    }>
  >;
  backClick: boolean;
  journalEntryToEdit?: JournalEntry;
  setJournalEntryToEdit: Dispatch<SetStateAction<JournalEntry | undefined>>;
}

export const initialJournal = {
  name: "All Entries",
  color: "#000000",
  description: "All Entries tab",
} as PatientJournal;

const Journal: FC<JournalProps> = ({
  completeClick,
  setCompleteClick,
  setNavActionDisabled,
  backClick,
  journalEntryToEdit,
  setJournalEntryToEdit
}) => {
  const { patientJournals, journalEntries: AllEntries } = useAppSelector(
    (state: RootState) => state.journals
  );
  const dispatch = useAppDispatch();
  const todayDate = useMemo(() => new Date(), []);
  const [showCreateJournalForm, setShowCreateJournalForm] = useState<boolean>(false);
  const [showEditor, setShowEditor] = useState<{
    status: boolean;
    defaultData?: ComponentProps<typeof CreateEntryForm>["defaultData"];
  }>({ status: false });
  const [journalEntries, setJournalEntries] = useState<JournalEntry[]>([]);
  const [searchFilter, setSearchFilter] = useState<string | number>("");
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
  const [noSearchEntriesFound, setNoSearchEntriesFound] = useState<boolean>(false);
  const [isSearchingFilter, setIsSearchingFilter] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<boolean>(false);
  const [noFilterEntriesFound, setNoFilterEntriesFound] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentJournalIndex, setCurrentJournalIndex] = useState<number>(0);
  const [filteredCount, setFilteredCount] = useState({
    journalId: "-1",
    length: 0,
  });
  const [selectedFilters, setSelectedFilters] = useState<{
    moodFilter?: Number;
    drinkFilter?: string;
  }>({});

  const systemConfigurations = useAppSelector(
    (state) => state.systemConfigurations.data
  );

  // Memoize tabList to avoid recalculating it on every render
  const tabList: PatientJournal[] = useMemo(() => {
    const journalData = [...patientJournals];
    const allEntriesIndex = journalData.findIndex(
      (journal) => journal.name === initialJournal.name
    );

    if (allEntriesIndex > -1) {
      const [allEntriesJournal] = journalData.splice(allEntriesIndex, 1);
      journalData.unshift(allEntriesJournal);
    }
    const sortedJournalData = journalData.sort((a, b) => {
      const dateA = a.createTimestamp
        ? new Date(a.createTimestamp)
        : new Date(todayDate);
      const dateB = b.createTimestamp
        ? new Date(b.createTimestamp)
        : new Date(todayDate);
      return dateA.getTime() - dateB.getTime();
    });
    return sortedJournalData;
  }, [patientJournals]);

  const handleDateChange: ComponentProps<typeof Calendar>["onChange"] = (
    date,
    _event
  ) => {
    if (date == null || Array.isArray(date)) return;
    setJournalEntries(AllEntries);
    setIsSort(false);
    setSelectedFilters({});
    setNoSearchEntriesFound(false);
    const localDate = new Date(date);
    if (
      selectedDate &&
      selectedDate.toDateString() === localDate.toDateString()
    ) {
      setSelectedDate(null);
    } else {
      setSelectedDate(localDate);
    }
  };

  // Filter journal entries based on the selected date
  const entriesByDate = useMemo(() => {
    setFilteredCount({
      journalId: tabList[currentJournalIndex]?.uuid,
      length: 0,
    });
    if (
      isSearchingFilter ||
      (Object.entries(selectedFilters).length > 0 && noSearchEntriesFound)
    )
      return journalEntries;
    const currentJournalId = tabList[currentJournalIndex]?.uuid;
    const currentJournalName = tabList[currentJournalIndex]?.name;
    const isAllEntriesTab = currentJournalName === initialJournal.name;

    let filteredEntries = journalEntries.filter(
      (entry) =>
        isSameDate(new Date(entry.date), selectedDate) &&
        (isAllEntriesTab || entry.patientJournalUuid === currentJournalId)
    );

    if (selectedDate === null) {
      setFilteredCount({
        journalId: currentJournalId,
        length: journalEntries.length,
      });
      return isAllEntriesTab
        ? journalEntries
        : journalEntries.filter(
          (entry) => entry.patientJournalUuid === currentJournalId
        );
    }

    if (!isSort) {
      filteredEntries = filteredEntries.sort((a, b) => {
        const aTime = new Date(a.updateTimestamp ?? a.date).getTime();
        const bTime = new Date(b.updateTimestamp ?? a.date).getTime();
        return bTime - aTime;
      });
    }

    setFilteredCount({
      journalId: currentJournalId,
      length: filteredEntries.length,
    });

    return filteredEntries;
  }, [journalEntries, selectedDate, currentJournalIndex, tabList]);

  const handleFilterCallback = async (value: {
    type: string;
    value: string;
  }) => {
    try {
      setIsSort(false);
      let filterType: filterCategory;
      let filterValue: string;

      if (value?.type === "mood") {
        filterType = filterCategory.MOOD;
        filterValue = value?.value;
      } else if (value?.type === "liquid") {
        filterType = filterCategory.SODA;
        filterValue = value?.value;
      } else {
        console.error("Invalid filter type");
        return;
      }
      const currentJournalData = tabList[currentJournalIndex];
      const currentPatientJournalId =
        currentJournalData.name === initialJournal.name
          ? undefined
          : currentJournalData.uuid;
      let filters = {
        ...selectedFilters,
      };
      if (filterType === filterCategory.MOOD) {
        filters.moodFilter = Number(filterValue);
      } else if (filterType === filterCategory.SODA) {
        filters.drinkFilter = filterValue;
      }

      setSelectedFilters(filters);
      let dateFilter = selectedDate?.toLocaleString();
      if (selectedDate) {
        dateFilter = formatDate(selectedDate);
      }
      const filteredEntries = await getFilteredJournalEntry({
        ...filters,
        dateFilter: dateFilter,
        patientJournalFilter: currentPatientJournalId
      });

      if (filteredEntries.journalEntryList.length > 0) {
        setNoSearchEntriesFound(false);
        setJournalEntries(filteredEntries.journalEntryList);
      } else {
        setFilteredCount({
          journalId: tabList[currentJournalIndex]?.uuid,
          length: 0,
        });
        setNoSearchEntriesFound(true);
      }
    } catch (error) {
      console.error("Error fetching filtered entries:", error);
    }
  };

  // Helper function to get the entry count for a journal
  const getEntryCount = useCallback(
    (journalId: PatientJournal["uuid"], idx: number) => {

      const isAllEntriesTab = tabList.find(tab => tab.name === initialJournal.name)?.uuid === journalId;

      // Check if tab is not the selected tab and if it matches "All entries"
      if (currentJournalIndex !== idx) {
        return isAllEntriesTab ? AllEntries.length : AllEntries.filter((entry) => entry.patientJournalUuid === journalId).length;
      }

      // Return filtered count if filters are applied and match the journalId
      if (filteredCount?.journalId === journalId && Object.keys(selectedFilters).length > 0) {
        return filteredCount.length;
      }

      // Handle cases when there's no date filter applied

      if (!selectedDate) {
        return isAllEntriesTab ? AllEntries.length : AllEntries.filter((entry) => entry.patientJournalUuid === journalId).length;
      }

      // Filter entries based on selected date
      const entriesForSelectedDate = AllEntries.filter((entry) =>
        isSameDate(new Date(entry.date), selectedDate)
      );

      // Return count based on whether it's "All entries" or specific journal
      return isAllEntriesTab ? entriesForSelectedDate.length : entriesForSelectedDate.filter((entry) => entry.patientJournalUuid === journalId).length;
    },
    [
      AllEntries,
      selectedDate,
      currentJournalIndex,
      todayDate,
      tabList,
      selectedFilters,
      filteredCount,
    ]
  );

  useEffect(() => {
    if (backClick) {
      if (showCreateJournalForm) {
        setShowCreateJournalForm(false);
      }
      if (showEditor) {
        setShowEditor({ status: false });
      }
    }
  }, [backClick]);

  // Update journal entries based on the selected tab
  const fetchJournalEntries = () => {
    const currentJournalData = tabList[currentJournalIndex];
    if (currentJournalData) {
      setIsSort(false);
      setSelectedFilters({});
      setNoSearchEntriesFound(false);
      const filteredEntries =
        currentJournalData.name === initialJournal.name
          ? AllEntries
          : AllEntries.filter(
            (entry) => entry.patientJournalUuid === currentJournalData.uuid
          );

      setJournalEntries(filteredEntries);
    }
  };

  useEffect(() => {
    fetchJournalEntries();
  }, [tabList, currentJournalIndex, AllEntries, setJournalEntries]);

  // Open the editor when an entry is selected for editing
  const editJournalEntry = useCallback(
    (journalEntry: JournalProps["journalEntryToEdit"]) => {
      setShowEditor({ status: true, defaultData: journalEntry });
    },
    [setShowEditor]
  );

  // Auto-edit if a journal entry is provided from props
  useEffect(() => {
    if (journalEntryToEdit) {
      editJournalEntry(journalEntryToEdit);
      setJournalEntryToEdit(undefined);
    }
  }, [journalEntryToEdit, editJournalEntry, setJournalEntryToEdit]);

  const handleJournalCreate = () => {
    const journalLimit = systemConfigurations?.MAXIMUM_ALLOWED_JOURNALS + 1;
    if (tabList.length >= journalLimit) {
      alertNotification(
        "error",
        `You have reached the maximum journal limit. You cannot create more than ${journalLimit - 1
        } journals.`
      );
      return;
    }
    setShowCreateJournalForm(true);
  };

  const handleSearch = async () => {
    try {
      let updatedSeachFilter =
        typeof searchFilter === "string" ? searchFilter.trim() : searchFilter;
      if (updatedSeachFilter === "") return;
      const encodedString = encodeURIComponent(updatedSeachFilter);
      const currentJournalData = tabList[currentJournalIndex];
      const currentPatientJournalId =
        currentJournalData.name === initialJournal.name
          ? null
          : currentJournalData.uuid;
      let dateFilter = selectedDate?.toLocaleString();
      if (selectedDate) {
        dateFilter = formatDate(selectedDate);
      }

      const payloadObj = {
        searchString: encodedString,
        patientJournalUuid: currentPatientJournalId || undefined,
        dateFilter: dateFilter,
      };
      const res = await dispatch(searchJournalEntry(payloadObj));
      if (Array.isArray(res?.payload) && res.payload.length > 0) {
        setNoFilterEntriesFound(false);
        setIsSearchingFilter(true);
        setJournalEntries(res.payload);
      } else {
        setNoFilterEntriesFound(true);
        setIsSearchingFilter(false);
      }
    } catch (error) {
      setNoFilterEntriesFound(false);
      setIsSearchingFilter(false);
      console.error("Error occured while fetching the search results:", error);
    }
  };

  useEffect(() => {
    if (searchFilter === "") {
      handleSearchClear();
    }
  }, [searchFilter]);

  const removeFilter = async (
    filterToRemove: "moodFilter" | "drinkFilter" | "all"
  ) => {
    let filters: {
      drinkFilter?: string;
      moodFilter?: Number;
      dateFilter?: string;
    } = { ...selectedFilters };
    if (filterToRemove !== "all") {
      delete filters[filterToRemove];
    } else {
      filters = {};
    }
    setSelectedFilters(filters);
    if (Object.entries(filters).length === 0) {
      setNoSearchEntriesFound(false);
    }
    let dateFilter = selectedDate?.toLocaleString();
    if (selectedDate) {
      dateFilter = formatDate(selectedDate);
    }
    const currentJournalData = tabList[currentJournalIndex];
    const currentPatientJournalId =
      currentJournalData.name === initialJournal.name
        ? undefined
        : currentJournalData.uuid;
    const filteredEntries = await getFilteredJournalEntry({
      ...filters,
      dateFilter: dateFilter,
      patientJournalFilter: currentPatientJournalId
    });
    if (filteredEntries.journalEntryList.length > 0) {
      setNoSearchEntriesFound(false);
      setJournalEntries(filteredEntries.journalEntryList);
    } else {
      setNoSearchEntriesFound(true);
    }
  };

  const handleSearchClear = () => {
    setSearchFilter("");
    setNoFilterEntriesFound(false);
    setIsSearchingFilter(false);
    fetchJournalEntries();
  };

  return (
    <>
      {!showCreateJournalForm && <JournalCreateButton handleJournalCreate={handleJournalCreate} />}

      <Tab
        selectedIndex={currentJournalIndex}
        onChange={(index) => {
          setCurrentJournalIndex(index);
          setShowEditor({ status: false });
          setSearchFilter("");
          setIsSearchVisible(false);
          setNoFilterEntriesFound(false);
          setSelectedDate(null);
        }}
      >
        <Tab.List className="border-none gap-4 pb-2 max-w-[530px] m-auto mb-5">
          {tabList.map((tab, idx) => (
            <Tab.ListItem
              key={tab.uuid}
              className={classnames(
                "text-xs font-inter px-0 hover:text-black journal-tab-container",
                currentJournalIndex === idx
                  ? "text-black tab-active-border before:h-[2.5px]"
                  : "text-[#808A98] before:h-[2.5px] before:absolute before:transition-all before:duration-200  before:w-full before:start-0 before:-bottom-[1px] before:opacity-100 before:bg-[#CFD4DA]"
              )}
              aria-selected={currentJournalIndex === idx}
            >
              <span>
                <Squares2X2Icon
                  className="h-4 w-4"
                  style={{ color: tab?.color ? tab?.color : "black" }}
                />
              </span>
              {tab.name} ({getEntryCount(tab.uuid, idx)})
            </Tab.ListItem>
          ))}
        </Tab.List>

        {!showCreateJournalForm && (
          <Tab.Panels className="px-0 py-0 mt-0 max-h-[calc(72vh-165px)] min-h-[calc(72vh-165px)]">
            {tabList.map((tab, idx) => (
              <Tab.Panel key={idx}>
                <ConditionallyRender
                  condition={showEditor.status}
                  show={
                    <CreateEntryForm
                      {...{
                        completeClick,
                        setCompleteClick,
                        setNavActionDisabled,
                        setSelectedDate,
                      }}
                      hideEditor={() => setShowEditor({ status: false })}
                      journal={tab}
                      defaultData={showEditor.defaultData}
                    />
                  }
                  elseShow={
                    <div className="bg-white p-3 rounded-lg border border-[#C9C8C8] w-full max-h-[calc(72vh-176px)] min-h-[calc(72vh-176px)] overflow-y-auto scrollbar journal-tab-container">
                      <div className={`grid grid-cols-2 gap-3`}>
                        <div className="flex items-center">
                          <p className="flex justify-end text-xs mr-3">Use the calendar below to select the date for a new entry</p>
                        </div>
                        <div>
                          <JournalNav
                            tabKey={+tab.uuid}
                            tabValue={tab.name}
                            entryLength={journalEntries.length}
                            toggleEditor={(tabId: number) => {
                              setShowEditor({
                                status: true,
                                defaultData: { date: selectedDate as Date },
                              });
                            }}
                            removeFilter={() => removeFilter("all")}
                            {...{
                              journalEntries,
                              setJournalEntries,
                              setSearchFilter,
                              setIsSearchVisible,
                              setNoFilterEntriesFound,
                              handleFilterCallback,
                              setIsSort,
                              selectedDate,
                            }}
                          />
                        </div>
                      </div>

                      <div className={`grid grid-cols-2 gap-3 mt-1`}>
                        <div className={`flex flex-col`}>
                          <Calendar
                            className="!w-full overview-calendar overflow-hidden mb-2"
                            onChange={handleDateChange}
                            value={selectedDate}
                          />

                          <p className="text-lg text-center">
                            {
                              months[
                              selectedDate
                                ? selectedDate.getMonth()
                                : todayDate.getMonth()
                              ]
                            }{" "}
                            {selectedDate
                              ? selectedDate.getDate()
                              : todayDate.getDate()}
                            ,{" "}
                            {selectedDate
                              ? selectedDate.getFullYear()
                              : todayDate.getFullYear()}
                          </p>
                        </div>
                        <div className="flex flex-col">
                          {isSearchVisible && (
                            <div className="flex my-1">
                              <Input
                                value={searchFilter}
                                suffix={
                                  <Button variant="text" onClick={handleSearch}>
                                    Search
                                  </Button>
                                }
                                placeholder="Search here..."
                                className="w-full"
                                inputClassName="hover:!border-[grey] border-[grey] !ring-0 py-2 ps-3.5 mb-2 pe-0"
                                onChange={(e) =>
                                  setSearchFilter(() => e.target.value)
                                }
                                onClear={() => handleSearchClear()}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSearch();
                                  }
                                }}
                                clearable
                              />
                            </div>
                          )}

                          <div className="flex flex-wrap gap-2">
                            {Object.entries(selectedFilters).map(
                              ([type, value], index) => (
                                <div
                                  key={index}
                                  className="w-fit bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full flex items-center capitalize"
                                >
                                  {type === "moodFilter"
                                    ? moodOptions[Number(value) - 1].value ===
                                      Number(value)
                                      ? moodOptions[Number(value) - 1].title
                                      : String(value)
                                    : String(value)}
                                  <button
                                    onClick={() =>
                                      removeFilter(
                                        type as "moodFilter" | "drinkFilter"
                                      )
                                    }
                                    className="ml-1 text-blue-800 hover:text-blue-900"
                                  >
                                    &times;
                                  </button>
                                </div>
                              )
                            )}
                          </div>

                          <div className="flex flex-col">
                            {noFilterEntriesFound || noSearchEntriesFound ? (
                              <div className="text-[#444444] text-center">
                                {noFilterEntriesFound ? (
                                  "No journal entry found!"
                                ) : (
                                  <>
                                    No journal entry found with selected filters.<br />
                                    <button
                                      className="text-blue-500 underline"
                                      onClick={async () => removeFilter("all")}
                                    >
                                      Click Here
                                    </button>
                                    &nbsp;to remove all applied filters.
                                  </>
                                )}
                              </div>
                            ) : (
                              <ScrollWrapper>
                                <JournalEntries
                                  tabId={+tab.uuid}
                                  tabDefaultColor={tab.color}
                                  entries={entriesByDate}
                                  toggleEditor={() => {
                                    setShowEditor({
                                      status: true,
                                      defaultData: { date: selectedDate as Date },
                                    });
                                  }}
                                  editJournalEntry={editJournalEntry}
                                />
                              </ScrollWrapper>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        )}
      </Tab>

      {/* Create Journal Component */}
      {showCreateJournalForm && (
        <CreateJournalForm
          hideCreateJournalForm={() => setShowCreateJournalForm(false)}
          {...{
            completeClick,
            setCompleteClick,
            setNavActionDisabled,
            setCurrentJournalIndex,
          }}
        />
      )}
    </>
  );
};

export default Journal;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL;

type layoutState = {
  imageSource: string;
  backGroundBlur: boolean;
  backGroundLoading: boolean;
  imageUploadLoding: boolean;
  publicizeStepper: boolean;
  loadingMessage?: string;
  showChatBot: boolean;
  showModal: boolean;
};

const initialState: layoutState = {
  imageSource: `${CLOUDFRONT_URL}/assets/home-bg-picture.png`,
  backGroundBlur: false,
  backGroundLoading: false,
  imageUploadLoding: false,
  publicizeStepper: false,
  showChatBot: false,
  showModal: true,
};

const BackGroundSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setBackGroundImage: (
      state,
      action: PayloadAction<{ imageSource?: string }>
    ) => {
      return {
        ...state,
        imageSource: action.payload.imageSource ?? "",
      };
    },
    setBackGroundBlur: (state, action: PayloadAction<{ status: boolean }>) => {
      return {
        ...state,
        backGroundBlur: action.payload.status,
      };
    },
    setPublicizeStepper: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      return {
        ...state,
        publicizeStepper: action.payload ?? !state.publicizeStepper,
      };
    },
    setShowChatBot: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showChatBot: action.payload,
      };
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showModal: action.payload,
      };
    },
    setBackGroundLoading: (
      state,
      action: PayloadAction<{ status: boolean; loadingMessage?: string }>
    ) => {
      return {
        ...state,
        backGroundLoading: action.payload.status,
        loadingMessage: action.payload.loadingMessage,
      };
    },
    setImageUploadLoding: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        imageUploadLoding: action.payload,
      };
    },
  },
});

export default BackGroundSlice.reducer;
export const {
  setBackGroundImage,
  setBackGroundBlur,
  setPublicizeStepper,
  setBackGroundLoading,
  setImageUploadLoding,
  setShowChatBot,
  setShowModal,
} = BackGroundSlice.actions;

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import { FC } from "react";
import ReactPaginate from "react-paginate";

type PaginationProps = {
  pageCount: number;
  currentPage: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
};

const Pagination: FC<PaginationProps> = ({
  pageCount,
  currentPage,
  handlePageClick,
}) => {
  return (
    <>
      <div className="flex justify-center">
        <ReactPaginate
          previousLabel={
            <span
              className={
                currentPage === 0 ? "pointer-events-none opacity-50" : ""
              }
            >
              <ChevronDoubleLeftIcon className="w-6 h-6" />
            </span>
          }
          nextLabel={
            <span
              className={
                currentPage === pageCount - 1
                  ? "pointer-events-none opacity-50"
                  : ""
              }
            >
              <ChevronDoubleRightIcon className="w-6 h-6" />
            </span>
          }
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          initialPage={currentPage}
          forcePage={currentPage}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

export default Pagination;

import { ComponentProps, FC, useEffect } from "react";
import { AppointmentActivity } from "../../../core/phase-activity/AppointmentActivityConfig";
import { setSymptomsList } from "../../../redux/appointment/AppointmentSlice";
import { setShowSurveyModal } from "../../../redux/current-phase/CurrentPhaseSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { SurveyResponse } from "../../../types/survey";
import GenerateSurveyModal from "../../survey/GenerateSurveyModal";

type SurveyActivityType = {
  activityDetails: AppointmentActivity;
  handleActivityCompletion: (
    activityDetails: AppointmentActivity,
    data: SurveyResponse[]
  ) => Promise<void>;
};

const SurveyActivity: FC<SurveyActivityType> = ({
  activityDetails,
  handleActivityCompletion,
}) => {
  const dispatch = useAppDispatch();

  const showSurveyModal: boolean = useAppSelector(
    (state: RootState) => state.currentPhase.showSurveyModal
  );
  const symptoms = useAppSelector((state: RootState) => state.symptom);
  const currentPhaseId: string = useAppSelector(
    (state: RootState) => state.currentPhase.id
  );
  const currentActivityId: string = useAppSelector(
    (state: RootState) => state.currentPhase.currentActivityId
  );

  const handleSurveyEnd: ComponentProps<
    typeof GenerateSurveyModal
  >["onComplete"] = async (data) => {
    dispatch(setShowSurveyModal(false));
    await handleActivityCompletion(activityDetails, data);
    // dispatch(setShowSurveyModal(true));
  };

  useEffect(() => {
    dispatch(setShowSurveyModal(true));
  }, [dispatch, currentActivityId]);

  useEffect(() => {
    // If the Journey phase is other than "step-1---your-symptoms" then we don't require to continue.
    if (
      activityDetails?.phase_activity?.journey_phase?.slug !==
      "step-1---your-symptoms"
    )
      return;

    dispatch(
      setSymptomsList({
        symptomsList: symptoms.data,
        phase_id: currentPhaseId,
        activity_id: currentActivityId,
      })
    );
  }, [
    activityDetails?.phase_activity?.journey_phase?.slug,
    symptoms,
    currentActivityId,
    dispatch,
    currentPhaseId,
  ]);

  const surveyData = modifySurveyData(
    activityDetails?.phase_activity?.frontier_data
  );

  return (
    <GenerateSurveyModal
      hideSurveyWhenNotInModal={!showSurveyModal}
      showContentInModal={false}
      surveyData={surveyData}
      // surveyData={activityDetails?.phase_activity?.frontier_data} //todo: restore when goals are updated
      showSurveyModal={showSurveyModal}
      external_data={activityDetails?.external_data?.filter(item => item.name !== "SHARE_EMAIL")
        .map(item =>
          item.name === "SHARE_FLAG"
            ? { ...item, answer: false }
            : item
        )}
      onClose={() => {
        dispatch(setShowSurveyModal(false));
      }}
      onComplete={handleSurveyEnd}
      playSound={showSurveyModal}
    />
  );
};

export default SurveyActivity;

//! TEMPORARY SOLUTION FOR `achieve_date` minimum in journey goal creation
const modifySurveyData = (surveyData: unknown) => {
  if (typeof surveyData !== "object" || surveyData == null) return surveyData;
  if (!("pages" in surveyData) || !Array.isArray(surveyData.pages))
    return surveyData;

  const pages = surveyData.pages;

  const goalPageIdx = pages.findIndex(
    (page: unknown) =>
      typeof page === "object" &&
      page != null &&
      "description" in page &&
      typeof page.description === "string" &&
      page.description.match(/goal/i) != null
  );
  if (
    goalPageIdx < 0 ||
    typeof pages[goalPageIdx] !== "object" ||
    pages[goalPageIdx] == null ||
    !("elements" in pages[goalPageIdx]) ||
    !Array.isArray(pages[goalPageIdx].elements)
  )
    return surveyData;

  const elements = pages[goalPageIdx].elements;

  const achieveDateIdx = elements.findIndex(
    (element: unknown) =>
      typeof element === "object" &&
      element != null &&
      "name" in element &&
      element.name === "achieve_date"
  );

  if (achieveDateIdx < 0) return surveyData;

  let newSurveyData = JSON.parse(JSON.stringify(surveyData));

  const today = new Date();
  const minDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );

  newSurveyData.pages[goalPageIdx].elements[achieveDateIdx].min = minDate
    .toISOString()
    .substring(0, 10);
  return newSurveyData;
};

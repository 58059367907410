import { useEffect, useState } from "react";

type TimerSize = "small" | "medium" | "large";

type responseInfo = {
  totalIterations: number;
  totalRepCounts: number;
  timeSpent: number;
};

type toggleResponse = responseInfo & {
  isPaused: boolean;
};

interface TimerProps {
  size?: TimerSize;
    holdTime?:number;
  releaseTime?:number;
  totalRepitions?:number;
  startFlag:boolean;
  onComplete?: (obj: responseInfo) => void;
  onRestart?: (obj: responseInfo) => void;
  onToggle: (obj: toggleResponse) => void;
  onClose: () => void
}

// Define size variants for styling
const sizeVariants = {
  small: {
    diameter: "w-72 h-72",
    borderDiameter: "w-96 h-96",
    innerDiameter: "w-72 h-72",
  },
  medium: {
    diameter: "w-96 h-96",
    borderDiameter: "w-[480px] h-[480px]",
    innerDiameter: "w-96 h-96",
  },
  large: {
    diameter: "w-[480px] h-[480px]", // 30rem or 480px
    borderDiameter: "w-[600px] h-[600px]",
    innerDiameter: "w-[480px] h-[480px]",
  },
};

const audio = new Audio(process.env.REACT_APP_SURVEY_FORM_TONE_SOUND);
audio.volume = 0.5;

let beatTimer: NodeJS.Timeout | undefined;

const AnimatedBreath = ({
  size = "small", // Default size
  holdTime, // Default cycle duration
  releaseTime, // Default cycle duration
  totalRepitions, // Default cycle duration
  startFlag,
  onComplete,
  onRestart,
  onToggle,
  onClose
}: TimerProps) => {
  const [isCounterStarted, setIsCounterStarted] = useState<boolean>(false); // Initialize state to track if the counter has started
 const [isActive, setIsActive] = useState<boolean>(false);
  const { diameter, borderDiameter, innerDiameter } = sizeVariants[size];
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [repLeft, setRepLeft] = useState<number>(0);
  const [isFilling, setIsFilling] = useState<boolean>(true);
  const [isStartedFlag, setIsStartedFlag] = useState<boolean>(false);
  const [showStart,setShowStart] = useState(true);
  useEffect(()=>{
    if(holdTime && releaseTime && totalRepitions && holdTime>=1 && releaseTime>=1 && totalRepitions>=1){
    setRepLeft(totalRepitions);
    setShowStart(true);
      setIsActive(false);
      setProgressPercentage(0)
      setIsCounterStarted(false);
    }
  },[totalRepitions])

  
  const playAudioSound = async () => {
    try {
      await audio.play();
    } catch (error) {
      console.error("Unable to play the audio", error);
    }
  };

  useEffect(()=>{
    if(startFlag){
      setShowStart(startFlag);
      setRepLeft(0);
    }
  },[startFlag])

  useEffect(() => {
    if (isActive && holdTime && releaseTime && totalRepitions && holdTime>=1 && releaseTime>=1 && totalRepitions>=1) {
    beatTimer = setInterval(() => {
        // Update fill percentage based on timeLeft and cycleDuration

        setProgressPercentage((prev) => {
          if (Math.round(prev) === 100 || Math.round(prev) === 0) {
            if (isStartedFlag) {
              playAudioSound();
            }
          }
          if (isFilling) {
              const progress = prev+50 / (holdTime * 10);
              if(progress >= 100){
                setIsFilling(false);
                if (beatTimer) clearInterval(beatTimer);
              setIsStartedFlag(true);
              }
              return progress;
          } else {
              const progress = prev-50 / (releaseTime * 10)
              if(progress<=0){
                
              setIsFilling(true);
              setRepLeft((repLeft*1)-1);
                if (beatTimer) clearInterval(beatTimer);
              }
              return progress;
          }
        });
      }, 50); 
    } else {
      if (beatTimer) clearInterval(beatTimer);
    }

    return () => {
      if (beatTimer) clearInterval(beatTimer);
    };
  }, [isActive, isFilling]);

  useEffect(()=>{
    if(repLeft===0 && totalRepitions){
      setIsActive(false);
      onComplete?.({
        totalIterations: totalRepitions,
        totalRepCounts: totalRepitions,
        timeSpent: 0,
      });


    }
  },[repLeft])

  const toggleTimer = () => {
    if(holdTime && releaseTime && totalRepitions && holdTime>=1 && releaseTime>=1 && totalRepitions>=1){
      setIsCounterStarted(true);
      onClose()
      setIsActive(!isActive)
      setShowStart(false);
      if(repLeft===0){
        setRepLeft(totalRepitions);
        onRestart?.({
          totalIterations: 0,
          totalRepCounts: totalRepitions,
          timeSpent: 0,
        });
        playAudioSound();
      }
      onToggle?.({
        isPaused: isActive,
        totalIterations: totalRepitions-repLeft,
        totalRepCounts: totalRepitions,
        timeSpent: 0,
      });
    }

  }

  return (
    <div className="flex items-center justify-center h-screen">
      <div className={`relative flex items-center justify-center ${diameter}`}>
        <div
          className={`absolute ${diameter} rounded-full`}
          style={{
            backgroundImage:
              isActive || !isCounterStarted || repLeft===0 
                ? `linear-gradient(269.83deg, #19B394 0.15%, #15987D 38.59%, #0B4D40 99.85%)`
                : `linear-gradient(269.83deg, #A6ABBD 0.15%, #C9C8C8 38.59%, #0F0F0F 99.85%)`,
          }}
        />
        <div
          className={`absolute ${borderDiameter} rounded-full ${isActive ? "" : "hidden"
            }`}
          style={{
            backgroundImage: `linear-gradient(269.83deg, #19B394 0.15%, #15987D 38.59%, #0B4D40 99.85%)`,
            maskImage: `conic-gradient(transparent 0% 0.2%, #19B39490 0.2% ${progressPercentage}%, #19B3941A ${progressPercentage}% 100%)`,
            WebkitMask: `conic-gradient(transparent 0% 0.2%, #19B39490 0.2% ${progressPercentage}%, #19B3941A ${progressPercentage}% 100%)`,
            transition: "mask-image 0.1s linear",
          }}
        >
          <div
            className={`absolute ${innerDiameter} rounded-full top-12 left-12`}
            style={{
              backgroundImage: `linear-gradient(269.83deg, #19B394 0.15%, #15987D 38.59%, #0B4D40 99.85%)`,
            }}
          />
        </div>
        <div className="absolute flex flex-col h-full w-full items-center justify-center text-center text-white">
          <div
            className={`flex-col flex h-full py-8 justify-around items-center`}
          >
            {showStart ? (
              <h4>
                <p className="font-semibold font-inter text-5xl">
                  Press to Start
                </p>
              </h4>
            ) : (
              <>
                <div className="text-black font-inter font-bold text-lg pt-2">
                  {repLeft===0
                    ? `Congratulations!`
                    : null}
                  {/* : `Timer: ${formatTime(timeLeft)} sec`} */}
                </div>
                <div>
                  <p className="font-bold font-inter text-4xl">
                    {repLeft===0
                      ? `DONE`
                      : isActive
                        ? isFilling
                        ? "Squeeze"
                        : "Release"
                      : "Paused"}
                  </p>
                  {repLeft!==0  ?<p
                      className={`font-inter text-2xl ${!isActive && "text-transparent"
                        }`}
                    >
                      Slow
                    </p>:null}
                  {repLeft===0 ? (
                    <p className={`font-inter text-md pt-2`}>
                      Press here to Restart
                    </p>
                  ) : null}
                </div>
                <div className="font-inter text-5xl">{totalRepitions && totalRepitions-repLeft}</div>
              </>
            )}
            
          </div>
        </div>
        <button
          className="absolute inset-0 w-full h-full text-transparent"
          onClick={toggleTimer}
        >
          Toggle
        </button>
      </div>
    </div>
  );
};

export default AnimatedBreath;

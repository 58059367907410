import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllPatientjourneys } from "../../core/requests/_requests";

export type Journey = {
  id: number;
  title: string;
  description: string;
  slug: string; //? ENUM VALUE
  media: {
    id: number;
    title: string;
    description: string | null;
    category: string | null;
    subtitle: string | null;
    media_url: string;
    is_favorite: boolean | null;
    subtitles: unknown[]; //todo: expand later
  } | null;
  journey_id?: number;
};

export const JOURNEY_SLUGS = ["overactive-bladder", "menopause"];

export const JourneyEntryAppointmentSlugs: any = {
  "overactive-bladder": "welcome-video",
  menopause: "welcome-to-the-cleveland-clinic",
};

// removing id hard coding
// export const journeyIds: { [key: string]: number } = {
//   menopause: 2,
//   "overactive-bladder": 76,
// };

type initialStateType = {
  data: Journey[];
  loading: boolean;
  error?: string | null;
  currentJourneySlug: string;
};

const locallyStoredJourney = localStorage.getItem("current-journey");

const defaultJourney = locallyStoredJourney
  ? JOURNEY_SLUGS.includes(locallyStoredJourney)
    ? locallyStoredJourney
    : JOURNEY_SLUGS[0]
  : // : ""
    JOURNEY_SLUGS[0];
// : "";

const initialState: initialStateType = {
  data: [],
  loading: false,
  error: null,
  currentJourneySlug: defaultJourney,
};

export const fetchPatientJourneys = createAsyncThunk(
  "Journeys/fetchPatientJourneys",
  async () => {
    try {
      //commenting due to new migration api changes
      //$ const response = await getPatientjourneys();
      //$ return response.data;
      const response = await getAllPatientjourneys();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const JourneySlice = createSlice({
  name: "journey",
  initialState,
  reducers: {
    setCurrentJourneySlug: (state, action: PayloadAction<string>) => {
      localStorage.setItem("current-journey", action.payload);
      state.currentJourneySlug = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPatientJourneys.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchPatientJourneys.fulfilled,
        (state, action: PayloadAction<Journey[]>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        fetchPatientJourneys.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default JourneySlice.reducer;
export const { setCurrentJourneySlug } = JourneySlice.actions;

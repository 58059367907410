import { ComponentProps, FC, useState } from "react";
import TimePickerSelection from "./TimePickerSelection";
import "./time-picker.css";

interface TimePickerProps {
  value?: string | null;
  pickerDefaultValue?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  seperator?: boolean;
  use12Hours?: boolean;
  onAmPmChange?: () => void;
}

const TimePicker: FC<TimePickerProps> = ({
  value: initialValue = null,
  pickerDefaultValue = "09:00 PM",
  onChange,
  seperator = true,
  use12Hours = true,
  onAmPmChange = () => { },
}) => {

  const [inputValue, setInputValue] = useState(initialValue);

  let finalValue: string | null = inputValue;

  if (initialValue === null && use12Hours) {
    finalValue = `${pickerDefaultValue}`;
  } else if (initialValue === null && !use12Hours) {
    finalValue = pickerDefaultValue;
  }

  const params: ComponentProps<typeof TimePickerSelection> = {
    onChange,
    setInputValue,
    seperator,
    use12Hours: true,
    onAmPmChange,
    initialValue: finalValue ?? "",
    pickerDefaultValue,
  };

  return <TimePickerSelection {...params} />;
}

export default TimePicker;

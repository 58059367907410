import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllDoctors } from "../../core/requests/_requests";
import { Language } from "../user/UserSlice";

export type Doctor = {
  id?: number;
  partner_id?: number;
  salutation?: string;
  first_name?: string;
  last_name?: string;
  description?: string;
  qualification?: string;
  avatar?: any;
  profile?: {
    title?: string;
    media_url?: string;
  };
  languages?: Language[];
  selected_language?: Language;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  video?: {
    subtitles: any;
    media_url?: string;
  };
};

type doctorList = {
  data: Doctor[];
  loading: boolean;
  error?: string | null;
};

type initialStateType = {
  doctorList: doctorList;
};

const initialState: initialStateType = {
  doctorList: {
    data: [],
    loading: false,
    error: null,
  },
};

export const fetchDoctorsList = createAsyncThunk(
  "doctos/setDoctorsList",
  async (journeyId: number) => {
    try {
      const response = await getAllDoctors(journeyId);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const DoctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDoctorsList.pending, (state) => {
        state.doctorList.loading = true;
        state.doctorList.error = null;
      })
      .addCase(
        fetchDoctorsList.fulfilled,
        (state, action: PayloadAction<Doctor[]>) => {
          state.doctorList.data = action.payload;
          state.doctorList.loading = false;
          state.doctorList.error = null;
        }
      )
      .addCase(
        fetchDoctorsList.rejected,
        (state, action: PayloadAction<any>) => {
          state.doctorList.loading = false;
          state.doctorList.error = action.payload;
        }
      );
  },
});

export default DoctorSlice.reducer;

import classNames from "classnames";
import { ComponentProps, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Tooltip } from "rizzui";
import { months } from "../../../../core/constants/date_time";
import { fetchSymptomEntries } from "../../../../core/requests/_requests";
import { formatDate } from "../../../../core/utilities";
import { HappySvg, IntakeSodaSvgNew } from "../../../../Images/icons/Icons";
import voids_img from "../../../../Images/voids.png";
import { EntryParameter, ParameterCategory } from "../../../../types/journal";
import SymptomCheckInTabs from "./SymptomCheckInTabs";

export type SymptomEntry = {
  active?: boolean;
  patientId?: number;
  updateTimestamp?: Date,
  date: string,
  parameters: EntryParameter[],
}

export type SymptomTrackerProps = {
  completeClick: boolean;
  setCompleteClick: Dispatch<SetStateAction<boolean>>;
  setNavActionDisabled: Dispatch<
    SetStateAction<{
      back: boolean;
      forward: boolean;
    }>
  >;
};

const SymptomTracker: FC<SymptomTrackerProps> = ({
  completeClick,
  setCompleteClick,
  setNavActionDisabled,
}) => {
  const todayDate = new Date();
  const [selectedDate, setSelectedDate] = useState<Date | null>(todayDate);
  const [symptomcheckins, setSymptomcheckins] = useState<SymptomEntry[]>([]);

  const isSymptomEmpty = (parameters: SymptomEntry["parameters"]): boolean => {
    return parameters.every(param => {
      if (param.category === ParameterCategory.INTAKE) return Object.values(param.data).every(value => value === 0);
      if (param.category === ParameterCategory.LEAKAGE) return param.data.count === 0 && param.data.urge === false;
      if (param.category === ParameterCategory.VOIDING) return param.data.count === 0 && param.data.amount === 0;
      if (param.category === ParameterCategory.MOOD) return param.data.mood === 0;
      return true;
    });
  };

  const fetchData = async () => {
    const res: SymptomEntry[] = await fetchSymptomEntries({});
    const filteredData = res.filter(entry => !isSymptomEmpty(entry.parameters));
    setSymptomcheckins(filteredData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDateChange: ComponentProps<typeof Calendar>["onChange"] = (
    date,
    _event
  ) => {
    if (date == null || Array.isArray(date)) return;
    if (selectedDate && selectedDate.toDateString() === new Date(date).toDateString()) {
      setSelectedDate(new Date());
    } else {
      setSelectedDate(new Date(date));
    }
  };

  const tileClassName = ({ date }: { date: Date }) => {
    return classNames({
      "highlighted-date": symptomcheckins.some((item) => item.date === formatDate(date)),
      "disabled-date": date > todayDate,
    });
  };

  const tileContent = ({ date }: { date: Date }) => {
    const tooltipData = getTooltipData(date);

    if (tooltipData) {
      return (
        <Tooltip
          content={
            <div className="w-fit px-0 -ml-1 inline-block">
              <div className="flex gap-1">
                {tooltipData.mood === 0 ? null : (
                  <HappySvg width={25} height={25} />
                )}
                {Object.values(tooltipData.liquidCounts).some((val) => val > 0) ? (
                  <IntakeSodaSvgNew width={30} height={30} />
                ) : null}
                {tooltipData.voidingData.voiding > 0 || tooltipData.voidingData.leakage > 0 || tooltipData.voidingData.amount || tooltipData.voidingData.urge ? (
                  <div className="flex -mr-2">
                    <img
                      src={voids_img}
                      alt="voids_img"
                      className={classNames(
                        "card-image w-full transition-all duration-500 h-7 object-cover"
                      )}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          }
          placement="top"
        >
          <div className="highlight-date-content">
            <span>{date.getDate()}</span>
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  const getTooltipData = (date: Date) => {
    const dateString = formatDate(date);
    const entry = symptomcheckins.find((item) => item.date === dateString);

    if (entry) {
      let mood = 0;
      let liquidCounts = { soda: 0, coffee: 0, wine: 0, water: 0 };
      let voidingData = { urge: false, leakage: 0, voiding: 0, amount: 0 };

      entry.parameters.forEach((param) => {
        if (param.category === ParameterCategory.MOOD) {
          mood = param.data.mood;
        } else if (param.category === ParameterCategory.INTAKE) {
          liquidCounts = { ...liquidCounts, ...param.data };
        } else if (param.category === ParameterCategory.LEAKAGE) {
          voidingData.leakage = param.data.count;
          voidingData.urge = param.data.urge;
        } else if (param.category === ParameterCategory.VOIDING) {
          voidingData.voiding = param.data.count;
          voidingData.amount = param.data.amount;
        }
      });
      return { mood, liquidCounts, voidingData };
    }
    return null;
  };

  return (
    <div className="container px-0 symptom-tracker-tab-container">
      <div className="mt-6 rounded-lg overflow-y-auto scrollbar max-h-[calc(72vh-150px)] min-h-[calc(72vh-150px)]">
        <div className="grid grid-cols-2 min-h-[calc(72vh-150px)]">
          <div className="flex flex-col bg-[#77bcd1b3] p-2 rounded-lg">
            <div className="flex mb-3">
              <h6 className="text-base pb-1">
                Check-In for:&nbsp;
                <span>
                  {
                    months[
                    selectedDate
                      ? selectedDate.getMonth()
                      : todayDate.getMonth()
                    ]
                  } &nbsp;
                  {selectedDate ? selectedDate.getDate() : todayDate.getDate()},&nbsp;
                  {selectedDate
                    ? selectedDate.getFullYear()
                    : todayDate.getFullYear()}
                </span>
              </h6>
            </div>

            <div className="flex flex-col mb-8">
              <div className="flex symptom-calendar symptom-tracker-calender">
                <Calendar
                  className="!w-full overview-calendar overflow-hidden mb-2"
                  onChange={handleDateChange}
                  value={selectedDate}
                  tileContent={tileContent}
                  tileClassName={tileClassName}
                  maxDate={todayDate}
                />
              </div>

              <div className="flex justify-center items-center gap-6">
                <div className="flex justify-center items-center gap-1">
                  <div className="w-3 h-3 rounded-full bg-[#0178BF]" />
                  <span className="text-[12px]">Denotes a previous entry</span>
                </div>

                <div className="flex justify-center items-center gap-1">
                  <div className="w-4 h-4 opacity-50 bg-[#58CC02]" />
                  <span className="text-[12px]">Selected day</span>
                </div>
              </div>
            </div>

            <div className="flex">
              <p className="text-base text-center">
                Select the date you wish to enter or update information for,
                then use the tabs on the right to add or modify details in each
                section. Click “<span className="text-[#358738]">Next</span>” to
                save your entries for each tab.
              </p>
            </div>
          </div>

          <div className="flex flex-col bg-[white] rounded-lg">
            <SymptomCheckInTabs
              selectedDate={selectedDate ?? new Date()}
              {...{ completeClick, setCompleteClick, setNavActionDisabled, fetchData }}
              symptomData={symptomcheckins}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SymptomTracker;

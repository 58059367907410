import { ArrowDownIcon, ArrowUpIcon, CalendarDaysIcon, QueueListIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { Tooltip } from "rizzui";
import { SortIcon } from "../../Images/icons/Icons";

interface SortingProps {
  handleSortCallback: (value: {
    sortBy: string;
    order: string
  }) => void;
  sortClick?: String;
}

const Sorting: React.FC<SortingProps> = ({
  handleSortCallback, sortClick
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState<string | null>(null);

  const sortJSON = {
    sortBy: [
      { id: 'title', title: "Title", svg: <QueueListIcon className="w-5 h-5" /> },
      { id: 'time', title: "Entry Time", svg: <CalendarDaysIcon className="w-5 h-5" /> }
    ],
    order: [
      { id: 'asc', title: "Ascending", svg: <ArrowUpIcon className="w-5 h-5" /> },
      { id: 'desc', title: "Descending", svg: <ArrowDownIcon className="w-5 h-5" /> }
    ]
  };
  const handleSortClick = (sortWith: string) => {
    setSelectedSorting(sortWith);
  };
  const handleOpenPopup = () => {
    setIsPopupVisible(true);
  };
  useEffect(() => {
    if (sortClick !== 'sort') {
      setIsPopupVisible(false)
      setSelectedSorting(null);
    }
  }, [sortClick])
  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setSelectedSorting(null);
  };
  return (
    <div className="relative inline-block">
      <Tooltip content={"Sorting"} placement="top">
        <div className="px-[0.375rem] py-[0.375rem]" onClick={handleOpenPopup}>
          <SortIcon />
        </div>
      </Tooltip>

      {isPopupVisible && (
        <div className="absolute right-[1rem] top-[18px] mt-2 bg-slate-50 border border-gray-300 rounded-lg shadow-lg w-48 z-50">
          <div className="p-2">
            <div className="flex justify-between items-center border-b-2 !border-black pb-3">
              <span className="flex-grow text-center pt-1 font-bold">Sort</span>
              <div className="z-10">
                <span
                  className="modal-cross-icon cursor-pointer text-sm"
                  onClick={handleClosePopup}
                />
              </div>
            </div>
            <div className="flex flex-col">
              {sortJSON.sortBy.map((item, index) => (
                <div
                  key={item.id}
                  className={`flex items-center py-3 pl-8 pr-2 cursor-pointer ${index !== sortJSON.sortBy.length - 1 ? 'border-b !border-black' : ''
                    }`}
                  onClick={() => handleSortClick(item.id)}
                >
                  {item.svg}<span className="ml-3 font-medium">{item.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {selectedSorting && (
        <div className="absolute right-52 top-[18px] mt-2 bg-slate-50 border border-gray-300 rounded-lg shadow-lg w-48 z-50">
          <div className="p-2">
            <div className="flex justify-between items-center border-b-2 !border-black pb-3">
            <span className="flex-grow text-center pt-1 font-bold">Sort <span className="capitalize">{selectedSorting}</span></span>
              <div className="z-10">
                <span
                  className="modal-cross-icon cursor-pointer text-sm"
                  onClick={() => setSelectedSorting(null)}
                />
              </div>
            </div>
            <div className="flex flex-col">
              {sortJSON.order.map((item, index) => (
                <div
                  key={item.id}
                  className={`flex items-center py-3 px-5 cursor-pointer ${index !== sortJSON.order.length - 1 ? 'border-b !border-black' : ''
                    }`}
                  onClick={() => {
                    handleSortCallback({ sortBy: selectedSorting, order: item.id as 'asc' | 'desc' });
                    handleClosePopup();
                  }}
                >
                  {item.svg}<span className="ml-3 font-medium">{item.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sorting;

import { Auth0Provider } from "@auth0/auth0-react";
import { FC, PropsWithChildren } from "react";

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const auth0Domain: string = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const auth0ClientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const auth0Audience: string = process.env.REACT_APP_AUTH0_AUDIENCE || "";

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        audience: auth0Audience,
        scope: 'offline_access profile email',
        redirect_uri: window.location.origin,
      }}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;

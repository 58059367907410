import { months } from "./constants/date_time";
type allowedDateLiterals = "m" | "d" | "y";
type USDateformatMethod = (
  dateString?: string,
  separator?: string,
  dateOrder?: Array<allowedDateLiterals>
) => string;
export const USDateformat: USDateformatMethod = (
  dateString, // This Param is for getting the date string
  separator = "/", // This is optional Separator to be used in result date
  dateOrder = ["m", "d", "y"] // This param is used to get the returned date in desired order
) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const dateInNewFormat: string = dateOrder
    .map((type: string) => {
      switch (type) {
        case "m":
          return month;
        case "d":
          return day;
        case "y":
          return year;
        default:
          return "";
      }
    })
    .join(separator);
  return dateInNewFormat;
};

export function formatDateTime(inputDateTime: any) {
  const dateObj = new Date(inputDateTime);

  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObj.getDate().toString();
  const year = dateObj.getFullYear().toString().slice(-2);

  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export  const showFormatedDateTime = (dateString: string | Date) => {
  const date = new Date(dateString);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Format the time to AM/PM
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return `${month} ${day}, ${year} ${formattedTime}`;
};

import { Switch } from "@headlessui/react";
import {
  ChatBubbleLeftRightIcon,
  EnvelopeIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { ClockIcon } from "@mui/x-date-pickers";
import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import classNames from "classnames";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Avatar, Tooltip } from "rizzui";
import { Day, DAYS } from "../../redux/configuration/ConfigurationSlice";
import {
  GoalNotificationTime,
  PatientGoal,
  togglePatientGoal
} from "../../redux/dashboard/DashboardSlice";
import { useAppDispatch } from "../../redux/store";
import TimePicker from "./time-picker/TimePicker";

type GoalDetailsProps = {
  goal: Omit<PatientGoal, "notificationDays" | "notificationTimes"> & {
    notificationDays?: Day[];
    notificationTimes?: GoalNotificationTime[];
  };
  setTargetGoal: Dispatch<SetStateAction<GoalDetailsProps["goal"] | null>>;
};

const GoalReminders: FC<GoalDetailsProps> = ({ goal, setTargetGoal }) => {
  const dispatch = useAppDispatch();
  const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL;
  const goalReminderTimes: GoalNotificationTime[] =
    goal?.notificationTimes || [];
  const getdefaultValues = (): {
    index: number;
    details: GoalNotificationTime;
  } => ({
    index: goalReminderTimes.length + 1,
    details: {
      notificationTime: "09:00 PM",
      hasEmailNotification: true,
      hasSmsNotification: true,
    },
  });

  useEffect(() => {
    setLocalReminderTime(getdefaultValues());
  }, [goalReminderTimes.length]);

  const [localReminderTime, setLocalReminderTime] = useState<{
    index: number;
    details: GoalNotificationTime;
  }>(getdefaultValues());

  const handleReminderDaysChange = (day: Day) => {
    if (goal.notificationDays) {
      const updatedDays = goal.notificationDays?.includes(day)
        ? goal.notificationDays.filter((d) => d !== day)
        : [...goal.notificationDays, day];
      setTargetGoal({ ...goal, notificationDays: updatedDays });
    }
  };

  const handleSaveEntry = () => {
    if (localReminderTime) {
      let updatedReminderTimes;
      const existingIndex = goalReminderTimes.findIndex(
        (reminder) => reminder.notificationTime === localReminderTime.details.notificationTime
      );

      if (goalReminderTimes.length === 0) {
        updatedReminderTimes = [localReminderTime.details];
      } else if (existingIndex !== -1) {
        updatedReminderTimes = goalReminderTimes.map((reminderTime, index) =>
          index === existingIndex ? localReminderTime.details : reminderTime
        );
      } else if (localReminderTime.index >= goalReminderTimes.length) {
        updatedReminderTimes = [...goalReminderTimes, localReminderTime.details];
      } else {
        updatedReminderTimes = goalReminderTimes.map((reminderTime, index) =>
          localReminderTime.index === index ? localReminderTime.details : reminderTime
        );
      }
      setTargetGoal({ ...goal, notificationTimes: updatedReminderTimes });
      setLocalReminderTime(getdefaultValues());
    }
  };

  const handleRemoveReminderTime = (index: number) => {
    const updatedReminderTimes = goalReminderTimes
      .slice(0, index)
      .concat(goalReminderTimes.slice(index + 1));
    setTargetGoal({ ...goal, notificationTimes: updatedReminderTimes });
  };

  const handleModifyReminderTime = (index: number) => {
    setLocalReminderTime({ index, details: goalReminderTimes[index] });
  };

  const disableNewReminderTimeEntry =
    (goalReminderTimes.length >= 3 && localReminderTime.index > 3) ||
    (!localReminderTime.details.hasEmailNotification &&
      !localReminderTime.details.hasSmsNotification);

  return (
    <div className="flex flex-col h-full relative bg-[#f5f5f5]">
      <div className="flex-none">
        <div className="grid grid-cols-12 gap-4 py-2">
          <div className="col-start-2 col-end-12">
            <div className="flex goals-center flex-col px-2.5">
              <h2 className="text-center text-2xl font-semibold mb-1">
                Set Your Daily Goals
              </h2>
              <p className="px-4 text-center font-medium text-sm mb-2">
                Use the toggles to add activities and exercises to your Journey,
                and get <br /> reminders to stay on track.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto scrollbar mx-2">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-start-2 col-end-12">
            <div
              className={
                "flex flex-col w-full px-4 pb-1 rounded-md min-h-[490px]"
              }
              style={{ background: `${goal.journeyGoal.backgroundColor}` }}
            >
              <div className="grid grid-cols-12 gap-8 mb-1 pe-8 ps-10">
                <div className="col-start-1 col-end-10">
                  <div className="flex gap-4 items-center mt-2">
                    <Switch
                      defaultChecked={goal.active}
                      onChange={async () => {
                        const { journeyId, journeyGoalId } = goal || {};
                        if (goal?.id) {                          
                          await dispatch(
                            togglePatientGoal({
                              journeyId,
                              journeyGoalId,
                              id: goal?.id,
                              uuid: goal.uuid
                            })
                          );
                        } else {
                          const toggleGoal = { journeyId, journeyGoalId, uuid: goal.uuid}
                          await dispatch(
                            togglePatientGoal(toggleGoal)
                          );
                        }
                        setTargetGoal({ ...goal, active: !goal.active });
                      }}
                      className={`group inline-flex h-7 min-w-[48px] items-center rounded-full shadow-inner ${goal.active ? "bg-[#19B394]" : "bg-gray-300"
                        }  transition data-[checked]:bg-blue-600`}
                    >
                      <span
                        className={`block h-[80%] w-1/2 rounded-full transition shadow-inner duration-300 ease-in-out bg-white transform ${goal.active
                          ? "translate-x-[90%]"
                          : "translate-x-[10%]"
                          }`}
                      ></span>
                    </Switch>
                    <span>{goal.journeyGoal.title}</span>
                  </div>
                </div>
                <div className="col-start-10 col-end-13">
                  <div className="flex justify-end gap-2 pt-2 cursor-pointer">
                    <div className="flex">
                      <Avatar
                        name={goal.journeyGoal.tooltip ? goal.journeyGoal.tooltip : ""}
                        src={`${CLOUDFRONT_URL && CLOUDFRONT_URL.endsWith("/")
                          ? CLOUDFRONT_URL
                          : `${CLOUDFRONT_URL}/`
                          }${goal?.journeyGoal?.image?.s3FilePath}`}
                        size="sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="inline-block w-full px-6">
                <div className="tw-btn m-0 w-full cursor-default text-white bg-[#19B394] block font-medium rounded-md py-2 px-4 capitalize">
                  Customize Your reminders and Communication Preferences
                </div>
              </div>
              <p className="flex justify-center text-xs text-center my-3">
                Select Days of the Week You Would Like to Receive Notifications
                On
              </p>
              <div className="inline-block w-full mb-1">
                <div className="flex justify-center">
                  <div className="container bg-white px-3 py-1 flex justify-between drop-shadow">
                    <span>Monday</span>
                    <span>Tuesday</span>
                    <span>Wednesday</span>
                    <span>Thursday</span>
                    <span>Friday</span>
                    <span>Saturday</span>
                    <span>Sunday</span>
                  </div>
                </div>
                <div className="flex justify-center px-3">
                  <div className="container px-3 py-1 flex justify-between">
                    {Object.keys(DAYS).map((key) => {
                      const day = DAYS[key as keyof typeof DAYS];
                      return (
                        <div key={key}>
                          <label>
                            <input
                              type="checkbox"
                              value={day}
                              defaultChecked={goal.notificationDays?.includes(
                                day
                              )}
                              className="notification_checkbox bg-white"
                              onChange={() => handleReminderDaysChange(day)}
                            />
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="flex flex-col py-2 rounded-lg mb-2">
                <p className="flex items-center gap-2 justify-center text-xs text-center mb-2">
                  Pick up to 3 Times a Day to Receive a Notification
                </p>
                <div className="flex justify-center gap-4 bg-white p-2">
                  <div className="flex items-center justify-end goal-time-picker-wrap h-24 my-auto overflow-hidden">
                    <TimePicker
                      pickerDefaultValue={
                        localReminderTime.details.notificationTime
                      }
                      onChange={(value: string) => {
                        setLocalReminderTime({
                          ...localReminderTime,
                          details: {
                            ...localReminderTime.details,
                            notificationTime: value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="flex text-sm mb-2">
                      How would you like to receive check ins for this goal?
                    </p>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="inline-block w-full">
                        <div className="grid grid-cols-3 gap-2">
                          <div className="col-span-2">
                            <label className="font-medium">
                              <p className="text-sm">via Email</p>
                            </label>
                          </div>
                          <div className="flex">
                            <input
                              type="checkbox"
                              className="notification_checkbox bg-gray-300"
                              checked={
                                localReminderTime.details.hasEmailNotification
                              }
                              onChange={() => {
                                setLocalReminderTime((prevState) => ({
                                  ...prevState,
                                  details: {
                                    ...prevState.details,
                                    hasEmailNotification:
                                      !localReminderTime.details
                                        .hasEmailNotification,
                                  },
                                }));
                              }}
                            />
                          </div>
                          <div className="col-span-2">
                            <label className="font-medium">
                              <p className="text-sm">via SMS/Text</p>
                            </label>
                          </div>
                          <div className="flex">
                            <input
                              type="checkbox"
                              className="notification_checkbox bg-gray-300"
                              checked={
                                localReminderTime.details.hasSmsNotification
                              }
                              onChange={() => {
                                setLocalReminderTime((prevState) => ({
                                  ...prevState,
                                  details: {
                                    ...prevState.details,
                                    hasSmsNotification:
                                      !localReminderTime.details
                                        .hasSmsNotification,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <button
                          className={`tw-btn h-fit text-white block text-xs font-medium bg-[#19B394] rounded-lg py-[6px] px-4 capitalize ${disableNewReminderTimeEntry && "cursor-not-allowed"
                            }`}
                          onClick={handleSaveEntry}
                          disabled={disableNewReminderTimeEntry}
                          type="button"
                        >
                          Save Entry
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {goalReminderTimes.length > 0 && (
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-start-3 col-end-11">
                    <div className="inline-block w-full px-4 py-2 rounded-md">
                      <div className="grid grid-cols-12 gap-4">
                        <div className="col-start-1 col-end-3">
                          <p className="font-semibold flex">Notification</p>
                        </div>
                        <div className="col-start-3 col-end-10">
                          <p className="font-semibold justify-center flex">
                            Time
                          </p>
                        </div>
                        <div className="col-start-10 col-end-13">
                          <p className="flex justify-end font-semibold">
                            Action
                          </p>
                        </div>
                      </div>
                      {goalReminderTimes.map((time, index) => {
                        const highLightEntry = index == localReminderTime.index;
                        return (
                          <div
                            key={index}
                            className={classNames(
                              highLightEntry && "rounded-md",
                              "grid grid-cols-12 gap-4 px-2 py-1"
                            )}
                            style={{
                              background: `${highLightEntry ? "white" : "transparent"
                                }`,
                            }}
                          >
                            <div className="col-start-1 col-end-3">
                              <div className="flex h-full items-center gap-2">
                                {time.hasEmailNotification && (
                                  <EnvelopeIcon className="h-[1.10rem] w-[1.10rem] text-blue-700 cursor-pointer" />
                                )}
                                {time.hasSmsNotification && (
                                  <ChatBubbleLeftRightIcon className="h-[1.10rem] w-[1.10rem] text-yellow-600 cursor-pointer" />
                                )}
                              </div>
                            </div>
                            <div className="col-start-3 col-end-10">
                              <div className="flex justify-center">
                                <p>{time.notificationTime}</p>
                              </div>
                            </div>
                            <div className="col-start-10 col-end-13">
                              <div className="flex h-full items-center justify-end gap-2">
                                <Tooltip
                                  content="Click to Modify"
                                  placement="left"
                                >
                                  <ClockIcon
                                    className="h-[1.10rem] w-[1.10rem] max-h-[1.10rem] max-w-[1.10rem] text-green-700 cursor-pointer"
                                    onClick={() =>
                                      handleModifyReminderTime(index)
                                    }
                                  />
                                </Tooltip>
                                <Tooltip
                                  content="Click to Delete"
                                  placement="right"
                                >
                                  <TrashIcon
                                    className="h-[1.10rem] w-[1.10rem] text-red-700 cursor-pointer"
                                    onClick={() =>
                                      handleRemoveReminderTime(index)
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mb-3">
        <p className="text-base font-semibold">
          Select <span className="text-green-700">Next</span> to save or{" "}
          <span className="text-blue-400">Back</span> to cancel
        </p>
      </div>
    </div>
  );
};
export default GoalReminders;

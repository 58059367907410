import Hls from "hls.js";
import { FC, createRef, memo, useEffect, useState } from "react";
import { Player, PlayerProps } from "react-tuby";
import "react-tuby/css/main.css";
import useAuthOLogout from "../../core/_hooks/useAuthOLogout";
import NavControls from "../../providers/LayoutProvider/NavControls";
import { setShowChatBot } from "../../redux/layout/LayoutSlice";
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { Subtitle } from "../../types/video";
import ConsentPopup from "../consentPopup/ConsentPopup";

let checkUserInteraction = true;

// slugs where you want to display ConsentPopup
export const introSlugs = ["overactive-bladder-intro", "welcome-video", "welcome-to-the-cleveland-clinic"];

export const hlsSupportedExtensions = [".m3u8"];

export type VideoSource = {
  quality: number | string;
  url: string;
};

export type CustomEventHandlers = {
  [eventName in keyof HTMLVideoElementEventMap]?: (
    event: Event
  ) => void | Promise<void>;
};

export type VideoPlayerProps = {
  src: VideoSource[] | string;
  subtitles: Subtitle[];
  poster?: string;
  customVideoEndButton?: {
    show: boolean;
    // buttonText: string;
    endingEvent: (videoElem: HTMLVideoElement, userClick?: string) => void;
  };
  customEventHandlers?: {
    [eventName: string]: (event: Event) => void;
  };
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  forceStart?: boolean;
  title?: string;
};

const VideoPlayer: FC<VideoPlayerProps & PlayerProps> = ({
  src,
  subtitles,
  poster,
  customEventHandlers,
  customVideoEndButton,
  muted = false,
  autoPlay = true,
  forceStart,
  loop = false,
  title,
}) => {
  const { auth0Logout } = useAuthOLogout();
  const dispatch: AppDispatch = useAppDispatch();
  const videoRef = createRef<HTMLVideoElement>();
  const videoProperties = { muted, autoPlay };
  const [isNextButtonActive, setIsNextButtonActive] = useState<boolean>(false);
  const [pausePlayer, setPausePlayer] = useState<boolean>(false);
  const [askForConsent, setAskForConsent] =
    useState<boolean>(checkUserInteraction);

  const {
    data: { appontments },
  } = useAppSelector((rootstate) => rootstate.appointment);
  const { showNudge } = useAppSelector(
    (state: RootState) => state.journals
  );
  const handleCanPlay = () => {
    const video = videoRef.current;

    if ((videoProperties.autoPlay || forceStart) && video && !pausePlayer) {
      video.play().catch(_ => {
        // Do nothing
      });
    }

    if (video && video.textTracks && video.textTracks.length > 0) {
      const videoTextTracks: any = video.textTracks[0];
      videoTextTracks.addEventListener("cuechange", handleCueChange);
      const ccButton = document.querySelector<HTMLElement>(
        '[data-tuby-tooltips="Subtitles (c)"]'
      );
      ccButton?.addEventListener("click", () => {
        if (
          video.textTracks[0] &&
          localStorage.getItem("subtitleMode") !== "hidden"
        ) {
          localStorage.setItem("subtitleMode", "showing");
        } else {
          localStorage.setItem("subtitleMode", "hidden");
        }
      });
      const subtitleMode = localStorage.getItem(
        "subtitleMode"
      ) as TextTrackMode | null;

      if (subtitleMode !== null && video.textTracks[0]) {
        const track = video.textTracks[0];
        if (track.mode !== subtitleMode) {
          track.mode = subtitleMode;
          const button = document.querySelectorAll<HTMLElement>(
            '[data-tuby-tooltips="Subtitles (c)"]'
          )[0];
          button?.click();
        }
      }

      const trackList = video.textTracks;

      // Change event listener
      trackList.addEventListener("change", () => {
        if (trackList.length > 0 && trackList[0]) {
          trackList[0].addEventListener("cuechange", handleCueChange);
          localStorage.setItem("subtitleMode", trackList[0].mode);
        }
      });

      trackList.addEventListener("addtrack", () => {
        localStorage.setItem("subtitleMode", "showing");
      });
    }
  };

  const handleCueChange = (event: any) => {
    const cues = event.currentTarget.cues;

    if (cues) {
      for (let i = 0; i < cues.length; i++) {
        cues[i].line = 11;
        cues[i].size = 45;
      }
    }
  };

  const handlePlayback = () => {
    if (videoRef.current && !loop) {
      videoRef.current.pause();
      setPausePlayer(true);
    }
  };


  useEffect(() => {
    const video = document.querySelector("video");
    const eventListner = () => {
      if (video && activePhaseObject &&
        introSlugs.includes(activePhaseObject?.slug) &&
        checkUserInteraction &&
        askForConsent) video.pause();
    };

    window.addEventListener("keyup", eventListner);

    return () => {
      window.removeEventListener("keyup", eventListner);
    };
  }, []);

  useEffect(()=>{
   if(videoRef.current && showNudge){
    videoRef.current.pause();
      setPausePlayer(true);
   }
  },[showNudge])

  useEffect(() => {
    if (videoRef.current) {
      if (customEventHandlers) {
        for (const eventName in customEventHandlers) {
          videoRef.current.addEventListener(
            eventName,
            customEventHandlers[eventName]
          );
        }
      }
      videoRef.current.muted = videoProperties.muted;
      videoRef.current.loop = loop;
      if (customVideoEndButton?.show) {
        videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
      }

      videoRef.current.addEventListener("ended", handlePlayback);

      videoRef.current.addEventListener("canplay", handleCanPlay);

      if (videoRef.current.textTracks[0]) {
        videoRef.current.textTracks[0].addEventListener(
          "cuechange",
          handleCueChange
        );
      }
    }
    return () => {
      if (videoRef.current) {
        if (customVideoEndButton?.show) {
          videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        }

        videoRef.current.removeEventListener("canplay", handleCanPlay);
        videoRef.current.removeEventListener("ended", handlePlayback);

        if (videoRef.current.textTracks[0]) {
          videoRef.current.textTracks[0].removeEventListener(
            "cuechange",
            handleCueChange
          );
        }

        if (customEventHandlers) {
          for (const eventName in customEventHandlers) {
            videoRef.current.removeEventListener(
              eventName,
              customEventHandlers[eventName]
            );
          }
        }
      }
    };
  }, [customEventHandlers, videoProperties]);

  const enableHlsSupport = (url: string) => {
    const fileExtension = url.split(".").pop()?.toLowerCase();
    if (fileExtension && hlsSupportedExtensions.includes(`.${fileExtension}`)) {
      const video = document.getElementsByTagName("video")[0];
      if (!Hls.isSupported()) return;
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video);
    }
  };

  useEffect(() => {
    enableHlsSupport(src as string);
  }, [src]);

  const handleForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10;
    }
  };

  const handleBackward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10;
    }
  };

  const handleTimeUpdate = (e: Event) => {
    const event = e as CustomEvent & { target: HTMLMediaElement };
    if (event && event.target) {
      const currentTime = event.target.currentTime;
      setIsNextButtonActive(currentTime > 5);
    }
  };

  const activePhaseObject = appontments.find(
    (a) => a.status === 1
  )?.journey_phase;

  const onUserInteraction = () => {
    checkUserInteraction = false;
    dispatch(setShowChatBot(true));
    if (videoRef.current) videoRef.current.play().catch(_ => {
      // Do nothing
    });

    setAskForConsent(false);
  };

  const onDeclineOrClose = () => {
    try {
      auth0Logout();
    } catch (error) {
      console.log("error while logging out");
    }
  };

  return (
    <>
      {activePhaseObject &&
        introSlugs.includes(activePhaseObject?.slug) &&
        checkUserInteraction &&
        askForConsent ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <ConsentPopup
            onClose={onDeclineOrClose}
            onAgree={onUserInteraction}
            onDecline={onDeclineOrClose}
          />
        </div>
      ) : null}

      <div className="relative group share-video-group">
        {customVideoEndButton?.show && (
          <NavControls
            nextFn={() => {
              if (isNextButtonActive && videoRef.current != null) {
                customVideoEndButton.endingEvent(videoRef.current, "userClick");
              }
            }}
            disabled={{ nextBtn: !isNextButtonActive }}
          />
        )}
        <Player
          playerRef={videoRef}
          subtitles={subtitles}
          poster={poster}
          src={src}
        />
        <div className="absolute top-0 flex justify-between w-full h-[calc(100%-10px)] items-center aspect-video pointer-events-none">
          <div className="flex justify-center flex-1 opacity-0 group-hover:opacity-100 transition-all">
            <div className="flex flex-col items-center">
              <button
                className="focus:outline-none w-12 h-12 rounded-full bg-black/60 flex justify-center items-center shadow-xl pointer-events-auto"
                onClick={handleBackward}
              >
                <svg
                  className="h-8 w-8"
                  viewBox="0 0 64 64"
                  strokeWidth="3"
                  stroke="#ffffff"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline
                    points="9.57 15.41 12.17 24.05 20.81 21.44"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12.14,23.94a21.91,21.91,0,1,1-.91,13.25"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <div className="inline-block text-[10px] text-white/70 bg-black/60 p-[3px] rounded-full mt-1">
                10 seconds
              </div>
            </div>
          </div>
          <div className="flex justify-center flex-1 opacity-0 group-hover:opacity-100 transition-all">
            <div className="flex flex-col items-center">
              <button
                className="focus:outline-none w-12 h-12 rounded-full bg-black/60 flex justify-center items-center shadow-xl pointer-events-auto"
                onClick={handleForward}
              >
                <svg
                  className="h-8 w-8"
                  viewBox="0 0 64 64"
                  strokeWidth="3"
                  stroke="#ffffff"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline
                    points="54.43 15.41 51.83 24.05 43.19 21.44"
                    strokeLinecap="round"
                  />
                  <path
                    d="M51.86,23.94a21.91,21.91,0,1,0,.91,13.25"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <div className="inline-block text-[10px] text-white/70 bg-black/60 p-[3px] rounded-full mt-1">
                10 seconds
              </div>
            </div>
          </div>
        </div>
        {title && (
          <div className="flex w-full justify-center pointer-events-none fixed bottom-2.5 z-10">
            <span className="text-white text-lg pointer-events-auto">
              You are watching "{title}"
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(VideoPlayer);

import { useFormik } from "formik";
import * as Yup from "yup";
import NextFooter from "../../providers/LayoutProvider/NextFooter";
import { Video } from "../../redux/video-gallery/VideoGallerySlice";

const initialValues = {
  email: "",
};

interface IShareComponent {
  video: Video;
  onValidForm: (isValid: boolean, emails?: string[]) => void;
}

const ShareComponent: React.FC<IShareComponent> = ({ video, onValidForm }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .test("is-valid-emails", "Invalid email address(es)", (value) => {
        if (!value) return true;
        const emails = value.split(",").map((email) => email.trim());
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emails.every((email) => emailRegex.test(email));
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
    validate: (values) => {
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        onValidForm(true, values.email?.split(","));
      } catch (errors) {
        onValidForm(false);
      }
    },
  });

  return (
    <div>
      <div className="pt-8">
        <div className="flex justify-center items-center self-center mx-auto mb-4">
          <div className="">
            <div className="" key={video?.id}>
              <div className="aspect-video mb-4 h-[25vh]">
                <video
                  className="opacity-75 h-full w-full object-contain bg-black"
                  src={video?.media_url || ""}
                ></video>
              </div>
              <div className="text-black text-sm">
                <p className="font-semibold flex-wrap text-center">
                  {video.description}
                </p>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label
                htmlFor="email"
                className="block text-md font-semibold leading-6 text-black"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  placeholder="Enter email addresses (comma-separated)"
                  className={`bg-gray-200 shadow-inner block w-full rounded-md border-0 px-4 py-2.5 text-ms placeholder:text-text-white/72 focus:outline-1`}
                  {...formik.getFieldProps("email")}
                  name="email"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="flex pt-2 justify-start">
                    <p className="text-sm font-semibold text-orange-400">
                      {formik.errors.email}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>

        <div className="flex justify-center">
          <div className="absolute font-semibold bottom-6 flex justify-center overflow-hidden">
            <NextFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareComponent;

import { Menu, Transition } from "@headlessui/react";
import {
  Dispatch,
  FC,
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Tooltip } from "rizzui";
import { alertNotification } from "../../../../core/alert/ToastAlert";
import { month_short_names } from "../../../../core/constants/date_time";
import { uploadMedia } from "../../../../core/requests/_requests";
import {
  areParametersEmpty,
  checkForContent,
  formatDate,
  generateMediaHTML
} from "../../../../core/utilities";
import {
  AttachOption,
  AudioAttachedSvg,
  HappySvg,
  ImgAttachedSvg,
  PdfSvg,
  SadSvg,
  UnHappySvg,
  UploadIcon,
  VideoAttachedSvg
} from "../../../../Images/icons/Icons";
import {
  addJournalEntry,
  updateJournalEntry,
} from "../../../../redux/journals/JournalSlice";
import { useAppDispatch } from "../../../../redux/store";
import {
  EntryParameter,
  JournalEntry,
  PatientJournal
} from "../../../../types/journal";
import LoadingContainer from "../../../loader/LoadingContainer";
import InlineSvgDisplay from "../../../UI/InlineSvg";
import Editor from "./Editor";

export const moodOptions: { value: number; title: String; icon: ReactNode }[] =
  [
    { value: 1, title: "Sad", icon: <SadSvg /> },
    { value: 2, title: "Neutral", icon: <UnHappySvg /> },
    { value: 3, title: "Happy", icon: <HappySvg /> },
  ];

type CreateEntryFormProps = {
  completeClick: boolean;
  setCompleteClick: Dispatch<SetStateAction<boolean>>;
  setNavActionDisabled: Dispatch<
    SetStateAction<{
      back: boolean;
      forward: boolean;
    }>
  >;
  hideEditor: () => void;
  journal: Pick<PatientJournal, "uuid" | "name">;
  defaultData?: Partial<JournalEntry>;
};

const CreateEntryForm: FC<CreateEntryFormProps> = ({
  completeClick,
  setCompleteClick,
  setNavActionDisabled,
  journal,
  hideEditor,
  defaultData,
}) => {
  const dispatch = useAppDispatch();
  const todayDate = new Date();
  const attachedRef = useRef<HTMLDivElement>(null);

  const [journalTitle, setJournalTitle] = useState<string>("");
  const [journalText, setJournalText] = useState<string>("");
  const [entryParameters, setEntryParameters] = useState<EntryParameter[]>([]);
  const [isAttachedOptionOpen, setIsAttachedOptionOpen] = useState(false);
  const [entryDate, setEntryDate] = useState<Date>(todayDate);
  const [coverImage, setCoverImage] = useState<string | undefined>(defaultData?.coverImage);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [mediaUploadKey, setMediaUploadKey] = useState<number | null>(null);

  useEffect(() => {
    if (defaultData) {
      setJournalTitle(defaultData.title ?? "");
      setJournalText(defaultData.content ?? "");
      setEntryParameters(defaultData.parameters ?? []);
      setEntryDate(defaultData.date ? new Date(defaultData.date) : todayDate);
    }
  }, [defaultData]);

  const handleSubmit = async () => {
    if (defaultData && defaultData.uuid) {
      const updatedEntry = {
        uuid: defaultData.uuid,
        title: journalTitle,
        content: journalText,
        patientJournalUuid: defaultData.patientJournalUuid,
        date: formatDate(entryDate),
        parameters: entryParameters,
        coverImage: coverImage,
      };
      await dispatch(updateJournalEntry(updatedEntry)).then(() => {
        alertNotification("success", "Journal entry has been updated.");
      });
    } else {
      const selectedDate =
        entryDate.getDate() !== todayDate.getDate() ? entryDate : todayDate;
      const newEntry = {
        title: journalTitle,
        content: journalText,
        patientJournalUuid: journal.uuid,
        date: formatDate(selectedDate),
        parameters: entryParameters,
        coverImage: coverImage,
      };
      await dispatch(addJournalEntry(newEntry)).then(() => {
        alertNotification("success", "Journal entry has been added.");
      });
    }
    setJournalText("");
    hideEditor();
    setCompleteClick(false);
    setNavActionDisabled({ forward: true, back: true });
  };

  const handleSpecificFileUpload = (fileType: string, inCover?: string) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = fileType;
    input.onchange = async (event: any) => {
      const file = event.target.files[0];
      const docData = {
        title: `${journalTitle}-${file.name}`,
        fileType: "JOURNAL",
        videoType: "JOURNAL",
        language: "ENGLISH_US",
      };
      const formattedSendData = {
        document: new Blob([JSON.stringify(docData)], {
          type: "application/json",
        }),
        file: file,
      };
      try {
        setIsUploading(true);
        await uploadMedia(formattedSendData)
          .then(async (response) => {
            if (inCover === "showIncoverImage") {
              setCoverImage(
                `${process.env.REACT_APP_CLOUDFRONT_URL}/${response?.data?.s3Path}`
              );
            } else {
              const imagePath = `${process.env.REACT_APP_CLOUDFRONT_URL}/${response?.data?.s3Path}`;
              let mediaHtml = "";
              if (response?.data?.s3Path?.endsWith(".svg")) {
                const svgResponse = await fetch(imagePath);
                const svgData = await svgResponse.text();
                mediaHtml = `
                  <div style="text-align: center;">
                    <div class="media-center" style="display: inline-block; width: 300px; height: 300px;">
                      ${svgData}
                    </div>
                  </div>
                `;
              } else {
                mediaHtml = generateMediaHTML(
                  `${imagePath}`,
                  response?.data?.fileName
                );
              }
              setJournalText((prev) => `${prev}${mediaHtml}<br/>`);
              setMediaUploadKey(Date.now());
              setIsAttachedOptionOpen(false);
            }
            setIsUploading(false);
          })
          .catch((err) => {
            if (err) {
              alertNotification("error", "Error in uploading file");
              setIsUploading(false);
              throw new Error("Upload failed:", err);
            }
          });
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsUploading(false);
        setIsAttachedOptionOpen(false);
      }
    };
    input.click();
  };

  const attachedOptionHandler = () => {
    setIsAttachedOptionOpen(!isAttachedOptionOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;
    if (
      attachedRef.current &&
      !attachedRef.current.contains(target)
    ) {
      setIsAttachedOptionOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const isEntryParametersEmpty = areParametersEmpty(entryParameters);

    const hasContent = checkForContent(journalText);

    const forwardEnabled = hasContent || journalTitle.trim() !== "" || (entryParameters.length !== 0 && !isEntryParametersEmpty);

    setNavActionDisabled({
      forward: !forwardEnabled,
      back: false,
    });
  }, [journalTitle, journalText, entryParameters]);

  useEffect(() => {
    if (completeClick) {
      handleSubmit();
    }
  }, [completeClick]);

  return (
    <>
      <div className="bg-white p-3 rounded-lg border border-[#C9C8C8] w-full max-h-[calc(72vh-176px)] overflow-y-auto scrollbar min-h-[calc(72vh-145px)]">
        <div className="flex flex-col">
          {isUploading && (
            <LoadingContainer customLoadingMessage="Uploading File" />
          )}

          <form onSubmit={handleSubmit}>
            <div className="bg-[#D9D9D9] p-2 rounded-md w-full min-h-[calc(72vh-230px)]">
              <div className="w-full flex justify-between items-start gap-4">
                {/* First column: Title */}
                <div className="flex-1">
                  <strong className="text-sm font-semibold">
                    {defaultData && defaultData.uuid ? "Edit" : "Add new"} entry
                    for: {month_short_names[entryDate.getMonth()]}{" "}
                    {entryDate.getDate()}, {entryDate.getFullYear()}
                  </strong>
                </div>
                {/* Second column: Attachments, Intake, and Mood Input */}
                <div className="flex-1 flex justify-center mb-2">
                  <div className="inline-flex rounded-md">
                    {/* Attachments */}
                    <div
                      className="inline-flex items-center rounded-md text-sm focus:z-10 transition-all"
                      ref={attachedRef}
                    >
                      <Menu
                        as="div"
                        className="relative inline-flex items-center leading-1 align-top text-left"
                      >
                        <Menu.Button
                          onClick={() => attachedOptionHandler()}
                          className={`${isAttachedOptionOpen ? "" : ""}`}
                        >
                          <Tooltip content="Attach Files and Audio" placement="top">
                            <div className="px-2 py-1">
                              <AttachOption />
                            </div>
                          </Tooltip>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          show={isAttachedOptionOpen}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className="absolute top-full right-0 z-10 mt-1 p-3 pt-0 origin-top-right rounded-md bg-[#D9D9D9] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            static
                          >
                            <div className="pt-1 pb-0">
                              <h6 className="text-base font-semibold text-center mb-2">
                                Attach
                              </h6>
                              <div className="flex flex-col">
                                <div
                                  className="flex items-center justify-between text-sm gap-3 mb-2 cursor-pointer"
                                  onClick={() => handleSpecificFileUpload("image/*")}
                                >
                                  <span>image</span>
                                  <ImgAttachedSvg />
                                </div>
                                <div
                                  className="flex items-center justify-between text-sm gap-3 mb-2 cursor-pointer"
                                  onClick={() => handleSpecificFileUpload("audio/mp3")}
                                >
                                  <span>audio</span>
                                  <AudioAttachedSvg />
                                </div>
                                <div
                                  className="flex items-center justify-between text-sm gap-3 mb-2 cursor-pointer"
                                  onClick={() => handleSpecificFileUpload(".mp4, .webm")}
                                >
                                  <span>video</span>
                                  <VideoAttachedSvg />
                                </div>
                                <div
                                  className="flex items-center justify-between text-sm gap-3 cursor-pointer"
                                  onClick={() => handleSpecificFileUpload("application/pdf")}
                                >
                                  <span>pdf</span>
                                  <PdfSvg />
                                </div>
                              </div>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
                {/* Third column: Journal name */}
                <div className="flex-1 flex justify-end mt-1">
                  <p className="text-xs font-semibold">Journal: {journal.name}</p>
                </div>
              </div>

              <div className="min-h-[calc(72vh-275px)] overflow-y-auto scrollbar">
                <div className="flex flex-row gap-3">
                  <div
                    className="flex items-center h-16 w-20 rounded-lg border-2 border-gray-400 justify-between text-sm gap-3 mb-2 cursor-pointer border-black-2"
                    onClick={() =>
                      handleSpecificFileUpload("image/*", "showIncoverImage")
                    }
                  >
                    {coverImage ? (
                      coverImage.endsWith(".svg") ? (
                        <InlineSvgDisplay
                          className="w-full h-full"
                          url={coverImage}
                        />
                      ) : (
                        <img
                          className="h-full w-full object-cover"
                          src={coverImage}
                          alt=""
                        />
                      )
                    ) : (
                      <div className="flex flex-col items-center p-3 text-center">
                        <UploadIcon />
                        <span className="leading-4">Cover Image</span>
                      </div>
                    )}
                  </div>{" "}
                  <input
                    type="text"
                    placeholder="Enter Title"
                    className="w-full mb-1 h-16 focus:ring-0"
                    value={journalTitle}
                    onChange={(e) => setJournalTitle(e.target.value)}
                  />
                </div>
                <Editor key={mediaUploadKey || "editor"} content={journalText} setJournalText={setJournalText} />
              </div>
            </div>
          </form>
        </div>
        <div className="flex justify-center mt-0">
          <p className="text-sm font-semibold">
            Select <span className="text-green-700">NEXT</span> to
            save or <span className="text-blue-400">BACK</span> to
            cancel
          </p>
        </div>
      </div>
    </>
  );
};

export default CreateEntryForm;

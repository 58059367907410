import { Menu, Transition } from "@headlessui/react";
import {
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FC, ReactNode, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { alertNotification } from "../../../../core/alert/ToastAlert";
import { days } from "../../../../core/constants/date_time";
import default_cover_img from "../../../../Images/default_journal_image.png";
import {
  HappySvg,
  IntakeCoffeeSvg,
  IntakeSodaSvg,
  IntakeWaterSvg,
  SadSvg,
  UnHappySvg,
} from "../../../../Images/icons/Icons";
import {
  deleteJournalEntry,
  duplicateJournalEntry,
} from "../../../../redux/journals/JournalSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { JournalEntry, PatientJournal } from "../../../../types/journal";
import InlineSvgDisplay from "../../../UI/InlineSvg";
export const moodOptions: { value: number; apiVal: number; icon: ReactNode }[] =
  [
    { value: 1, apiVal: 3, icon: <SadSvg /> },
    { value: 2, apiVal: 2, icon: <UnHappySvg /> },
    { value: 3, apiVal: 1, icon: <HappySvg /> },
  ];

const liquidOptions = [
  { name: "coffee", svg: <IntakeCoffeeSvg /> },
  { name: "water", svg: <IntakeWaterSvg width={20} height={16} /> },
  { name: "soda", svg: <IntakeSodaSvg width={20} height={18} /> },
];

type JournalEntriesProps = {
  tabId?: number;
  tabDefaultColor: string;
  entries: JournalEntry[];
  toggleEditor?: (tabId: number) => void;
  editJournalEntry: (entry: JournalEntry) => void;
};

const JournalEntries: FC<JournalEntriesProps> = ({
  tabId,
  tabDefaultColor,
  entries,
  toggleEditor,
  editJournalEntry,
}) => {
  const [sortedEntries, setSortedEntries] = useState<JournalEntry[]>([]); // New state for sorted entries
  const { patientJournals } = useAppSelector((state) => state.journals);
  const dispatch = useAppDispatch();
  const getColor = (journalId: PatientJournal["uuid"]): string => {
    const journal = patientJournals.find(
      (journal) => journal.uuid === journalId
    );
    if (journal) {
      return journal.color;
    }
    return "#000000";
  };
  useEffect(() => {
    const sortEntries = [...entries].sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();

      // First, compare by date
      if (dateB !== dateA) {
        return dateB - dateA; // Sort by date descending
      }

      // If dates are the same, compare by updateTimestamp
      return (
        new Date(b.updateTimestamp ?? 0).getTime() - // Use nullish coalescing to provide a fallback
        new Date(a.updateTimestamp ?? 0).getTime()
      ); // Sort by updateTimestamp descending
    });
    setSortedEntries(sortEntries);
  }, [entries]);

  const getMoodIcon = (parameters: any[]): ReactNode => {
    const moodParam = parameters.find((param) => param.category === "mood");
    if (moodParam) {
      const moodValue = moodParam.data.mood;
      const moodOption = moodOptions.find(
        (option) => option.value === moodValue
      );
      return moodOption ? moodOption.icon : null;
    }
    return null;
  };

  const handleduplicateEntry = async (journalEntry: JournalEntry) => {
    try {
      let tempJournalEntryData = JSON.parse(JSON.stringify(journalEntry));
      if (tempJournalEntryData.date?.toString().includes("T")) {
        tempJournalEntryData.date = tempJournalEntryData.date
          .toString()
          .split("T")[0];
      }
      await dispatch(duplicateJournalEntry(tempJournalEntryData));
    } catch (error) {
      alertNotification("error", "Failed to duplicate the journal entry.");
      console.log("Error creating duplicate journal entry", error);
    }
  };

  const getLiquidIcons = (parameters: any[]): ReactNode[] => {
    const intakeParam = parameters.find((param) => param.category === "intake");
    if (intakeParam && intakeParam.data) {
      return liquidOptions
        .filter((liquid) => intakeParam.data[liquid.name] > 0)
        .map((liquid) => (
          <div key={liquid.name} className="flex items-center">
            {liquid.svg}
            <span className="ml-[2px] text-xs font-base">
              {intakeParam.data[liquid.name]}
            </span>
          </div>
        ));
    }
    return [];
  };

  const deleteHandler = async (entry: JournalEntry) => {
    Swal.fire({
      text: "Are you sure you want to delete this journal entry?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteJournalEntry(entry?.uuid as string))
          .then(() => {
            alertNotification("success", "Journal entry has been deleted.");
          })
          .catch(() => {
            alertNotification("error", "Failed to delete journal entry.");
          });
      }
    });
  };

  return (
    <div className="block w-full mt-0">
      {sortedEntries && sortedEntries.length > 0 ? (
        sortedEntries.map((journalEntry, index) => (
          <div
            key={index}
            style={{
              backgroundColor: getColor(journalEntry.patientJournalUuid),
            }}
            className={`container relative rounded-xl cursor-pointer`}
            onClick={() => editJournalEntry(journalEntry)}
          >
            <div className="grid grid-cols-4 gap-1 p-[4px]">
              <div className="col-span-1 border-1 rounded-xl bg-white flex items-center justify-center">
                {journalEntry?.coverImage ? (
                  journalEntry.coverImage.endsWith(".svg") ? (
                    <InlineSvgDisplay
                      className="rounded-xl w-full h-[105px]"
                      url={journalEntry.coverImage}
                    />
                  ) : (
                    <img
                      src={journalEntry.coverImage}
                      alt={"placeholder img"}
                      className={classNames(
                        "card-image rounded-xl w-full transition-all duration-500 h-[105px] object-cover"
                      )}
                    />
                  )
                ) : (
                  <img
                    src={default_cover_img}
                    alt={"placeholder img"}
                    className={classNames(
                      "card-image rounded-xl w-full transition-all duration-500 h-[105px] object-cover"
                    )}
                  />
                )}
              </div>

              <div className="col-span-1 border-1 rounded-xl bg-stone-200 flex flex-col items-center justify-center">
                <h2 className="text-md font-semibold text-center">
                  {days[new Date(journalEntry.date).getDay()]
                    ?.slice(0, 3)
                    ?.toUpperCase()}
                  <br />
                  {`${(new Date(journalEntry.date).getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}/${new Date(journalEntry.date)
                    .getDate()
                    .toString()
                    .padStart(2, "0")}`}
                </h2>
              </div>

              <div className="col-span-2 border-1 rounded-xl bg-white flex">
                <div className="flex flex-col w-full">
                  <div className="grid grid-cols-5 gap-2 h-[27px]">
                    <div className="col-start-1 col-span-4 p-1">
                      <h6 className="text-sm truncate">{journalEntry.title}</h6>
                    </div>

                    <Menu>
                      {({ open }) => (
                        <>
                          <Menu.Button
                            onClick={(event) => event.stopPropagation()}
                            className="flex justify-center"
                          >
                            <EllipsisHorizontalIcon className="h-6 w-6 cursor-pointer" />
                          </Menu.Button>

                          <Transition show={open}>
                            <Menu.Items
                              static
                              className="absolute z-10 right-[30px] top-[17px] origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                            >
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className={`${
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                    } flex w-full px-4 py-1 text-sm text-left`}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      editJournalEntry(journalEntry);
                                    }}
                                  >
                                    <PencilIcon className="mr-2 h-4 w-4" />
                                    Edit
                                  </div>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className={`${
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                    } flex w-full px-4 py-1 text-sm text-left`}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleduplicateEntry(journalEntry);
                                    }}
                                  >
                                    <DocumentDuplicateIcon className="mr-2 h-4 w-4" />
                                    Duplicate
                                  </div>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className={`${
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                    } flex w-full px-4 py-1 text-sm text-left`}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      deleteHandler(journalEntry);
                                    }}
                                  >
                                    <TrashIcon className="mr-2 h-4 w-4" />
                                    Delete
                                  </div>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>

                  <div className="flex flex-col px-1">
                    <p
                      className="text-[10px] break-words line-clamp-3 h-[73px]"
                      dangerouslySetInnerHTML={{ __html: journalEntry.content }}
                    />

                    {/* <div className="flex justify-between items-center mb-1">
                      <div className="inline-block">
                        {getMoodIcon(journalEntry.parameters)}
                      </div>

                      <div className="inline-block">
                        <div className="flex space-x-2">
                          {getLiquidIcons(journalEntry.parameters)}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div
          className="h-24 font-thin border-2 rounded-xl flex items-center justify-center p-3 bg-[#D9D9D9] cursor-pointer me-[6px]"
          style={{ borderColor: tabDefaultColor }}
          onClick={() => {
            if (toggleEditor) toggleEditor(Number(tabId));
          }}
        >
          Click Here or '+' button above to get started
        </div>
      )}
    </div>
  );
};
export default JournalEntries;

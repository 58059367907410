import { FormikProps } from 'formik';
import React from 'react';

interface SelectFieldProps {
  label: string;
  name: string;
  formik: FormikProps<any>;
  options: readonly { value: string; label: string }[];
  placeholder?: string;
  selectClass?: string;
  selectWrapClass?: string;
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  name,
  formik,
  options,
  placeholder,
  selectClass,
  selectWrapClass,
}) => {
  const error = formik.errors[name];
  const touched = formik.touched[name];

  return (
    <div className={`inline-block w-full ${selectWrapClass && selectWrapClass}`}>
      <label htmlFor={name} className="block text-base font-semibold leading-6">
        {label}
      </label>
      <select
        className={`bg-gray-200 shadow-inner block w-full rounded-md border-0 px-4 py-2.5 text-ms placeholder:text-text-white/72 focus:outline-1 ${selectClass && selectClass}`}
        {...formik.getFieldProps(name)}
        name={name}
      >
        <option value="" label={placeholder || 'Select an option'} />
        {options.map((option) => (
          <option key={option.value} value={option.value} label={option.label} />
        ))}
      </select>
      {touched && typeof error === 'string' && (
        <div className="flex pt-2 justify-start">
          <p className="text-sm font-semibold text-red-500">{error}</p>
        </div>
      )}
    </div>
  );
};

export default SelectField;
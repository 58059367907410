import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getPatientJourneySymptoms
} from "../../core/requests/_requests";
import { Journey } from "../journey/JourneySlice";

export type Symptom = {
  id: number;
  title?: string;
  description?: string;
  is_dissable_qualification?: boolean;
  journey?: Journey;
  media_url?: string;
  is_positive?: boolean;
  value?: number;
  text?: string;
};

export type verifyOABSymptoms = {
  eligible_for_journey?: boolean;
};

type initialStateType = {
  data: Symptom[];
  symptomsList: Symptom[];
  loading: boolean;
  error?: string | null;
};

const initialState: initialStateType = {
  data: [],
  symptomsList: [],
  loading: false,
  error: null,
};

export const fetchSymptomsList = createAsyncThunk(
  "symptom/fetchSymptomsList",
  async (symptomParams: { journeyId?: number }) => {
    try {
      //$ const response = await getJourneySymptoms(journeySlug);                            //commented due to new migration api
      //$ return response.data;
      const response = await getPatientJourneySymptoms({
        journeyId: symptomParams.journeyId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// export const fetchSymptomsListByJourneyId = createAsyncThunk(
//   "symptom/fetchSymptomsListByJourneyId",
//   async (journeyId: unknown) => {
//     try {
//       const response = await getSymptomsByJourneyId(journeyId);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

const SymptomSlice = createSlice({
  name: "symptom",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSymptomsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSymptomsList.fulfilled,
        (state, action: PayloadAction<Symptom[]>) => {
          state.data = action.payload.map((sym) => {
            sym.value = sym.id;
            sym.text = sym.title;
            return sym;
          });
          state.symptomsList = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        fetchSymptomsList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
  }
});

export default SymptomSlice.reducer;

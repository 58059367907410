import { ComponentProps, FC, useEffect, useState } from "react";
import useTogglePatientGoal from "../../../core/_hooks/useTogglePatientGoal";
import { alertNotification } from "../../../core/alert/ToastAlert";
import { AppointmentActivity } from "../../../core/phase-activity/AppointmentActivityConfig";
import NavControls from "../../../providers/LayoutProvider/NavControls";
import NextFooter from "../../../providers/LayoutProvider/NextFooter";
import PageWrapper from "../../../providers/LayoutProvider/PageWrapper";
import {
  getDashboardGoals,
  PatientGoal,
  togglePatientGoal,
  updatePatientGoal
} from "../../../redux/dashboard/DashboardSlice";
import { setShowNudge } from "../../../redux/journals/JournalSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import GoalCard from "../../goals/GoalCard";
import GoalReminders from "../../goals/GoalReminders";

type GoalActivityProps = {
  activityDetails: AppointmentActivity;
  handleActivityCompletion?: (
    activityDetails: AppointmentActivity
  ) => void | Promise<void>;
};

const GoalActivity: FC<GoalActivityProps> = ({
  activityDetails,
  handleActivityCompletion,
}) => {
  const dispatch = useAppDispatch();
  const goals = useAppSelector(
    (state: RootState) => state.dashboard.goals.data
  );

  const { toggleSwitch, selectedGoal, targetGoal, setSelectedGoal, setTargetGoal, toggleGoalCard,toggleRequestFlag } = useTogglePatientGoal();
  const symptomsList = useAppSelector(
    (state: RootState) => state.symptom.symptomsList
  );
  const Patient = useAppSelector((state: RootState) => state.user.authUser);
  const [patientGoals, setPatientGoals] = useState<PatientGoal[]>([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState<string>("");

  useEffect(() => {
    if (!goals) return;
    if (!symptomsList) {
      setPatientGoals(goals);
      return;
    }
    const selectedSymptoms = symptomsList
      .filter((itm) => itm?.is_positive)
      .map((itm) => itm?.title);

    if (!selectedSymptoms.length) {
      setPatientGoals(goals);
      return;
    }
    const filteredGoal = goals.filter((item) =>
      item.reportedSymptoms?.some((symptom) =>
        selectedSymptoms.includes(symptom)
      )
    );
    setPatientGoals(filteredGoal.length > 0 ? filteredGoal : goals);
    setSelectedSymptoms(selectedSymptoms.join(", "));
  }, [goals, symptomsList]);

  const BackButtonCallBack: ComponentProps<
    typeof NavControls
  >["backFn"] = async () => {
    if (!selectedGoal?.notificationDays || !selectedGoal?.notificationTimes || selectedGoal?.notificationDays?.length === 0 || selectedGoal?.notificationTimes?.length === 0) {
      if (selectedGoal && targetGoal?.active) {
        await dispatch(togglePatientGoal({ journeyId: selectedGoal?.journeyId, journeyGoalId: selectedGoal?.journeyGoalId, id: selectedGoal?.id, uuid: selectedGoal?.uuid}));
      }
    }
    dispatch(setShowNudge(true));
    setTargetGoal(null);
    setSelectedGoal(null);
  };

  const disableNextButton = (): boolean => {
    if (targetGoal && targetGoal.notificationDays && targetGoal.notificationTimes) {
      return (
        targetGoal.notificationDays.length === 0 &&
        targetGoal.notificationTimes.length === 0
      );
    }
    return false;
  };

  const NextButtonCallBack: ComponentProps<
    typeof NavControls
  >["nextFn"] = async () => {
    if (targetGoal !== null) {
      if (targetGoal?.notificationDays?.length === 0 || targetGoal?.notificationTimes?.length === 0) {
        const updatedTargetGoal = {
          ...targetGoal,
          uuid: targetGoal.uuid,
          notificationDays: [],
          notificationTimes: [],
          active: false
        }        
        await dispatch(updatePatientGoal(updatedTargetGoal));

        alertNotification("warning", targetGoal?.notificationDays?.length === 0 ? "As no day is selected, Goal will now be deactivated" : "As no time is selected, Goal will now be deactivated", "top-right");
      } else {        
        await dispatch(updatePatientGoal(targetGoal));
      }
      dispatch(setShowNudge(true));
      setTargetGoal(null);
      setSelectedGoal(null);
      return;
    }
    handleActivityCompletion &&
      (await handleActivityCompletion(activityDetails));
  };


  const fetchGoals = async () => {
    if (Patient.current_journey_id) {
      await dispatch(getDashboardGoals(Patient.current_journey_id));
    }
  };

  useEffect(() => {
    fetchGoals();
  }, []);

  return (
    <PageWrapper
      backCallback={targetGoal ? BackButtonCallBack : undefined}
      nextCallback={NextButtonCallBack}
      disabled={{ nextBtn: disableNextButton() }}
    >
      {targetGoal ? (
        <GoalReminders goal={targetGoal} setTargetGoal={setTargetGoal} />
      ) : (
        <div className="flex flex-col h-full relative bg-[#f5f5f5]">
          <div className="flex-none">
            <div className="grid grid-cols-12 gap-4 pt-3">
              <div className="col-start-2 col-end-12">
                <div className="flex goals-center flex-col px-2.5">
                  <h2 className="text-center text-3xl font-semibold mb-1">
                    Set Your Daily Goals
                  </h2>
                  <p className="px-4 text-center font-medium mb-2">
                    Use the toggles to add activities and exercises to your
                    Journey, and <br /> get reminders to stay on track.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mx-20 pl-1/2 pr-2">
            <p className="flex justify-center text-center my-2">
              {`Other users who have reported ${selectedSymptoms} are using:`}
            </p>
          </div>
          <div className="flex-1 overflow-y-auto scrollbar mx-2">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-start-2 col-end-12">
                <div className="flex flex-col">
                  {patientGoals.length > 0 &&
                    patientGoals.map((goal, index) => (
                      <GoalCard
                        key={index}
                        goal={goal}
                        setAsTargetGoal={() => {
                          toggleGoalCard(goal);
                        }}
                        toggleSwitchHandler={() => {
                          toggleSwitch(goal);
                        }}
                        toggleRequestFlag={toggleRequestFlag}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          <NextFooter />
        </div>
      )}
    </PageWrapper>
  );
};

export default GoalActivity;

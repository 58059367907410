type NumberValue = {
  number: string;
  translatedValue: string;
  selected: boolean;
  hidden?: boolean;
};

type SelectedValue = {
  number: string;
  translatedValue: string;
  arrayNumber?: number;
  selected?: boolean;
};

export const initialNumbersValue = (
  heightValue: number = 54,
  numbersLength: number = 24,
  value: number | null = null,
  gap: number = 1
): NumberValue[] => {
  const initialValue24hourFormat: NumberValue[] = [
    {
      number: "00",
      translatedValue: (heightValue * 2).toString(),
      selected: false,
    },
    {
      number: "01",
      translatedValue: heightValue.toString(),
      selected: false,
    },
  ];

  const initialValue12hourFormat: NumberValue[] = [
    {
      number: "00",
      translatedValue: heightValue.toString(),
      selected: false,
      hidden: true,
    },
    {
      number: "01",
      translatedValue: heightValue.toString(),
      selected: false,
    },
  ];

  const arrayOfSelectedValue: NumberValue[] =
    numbersLength === 13 ? initialValue12hourFormat : initialValue24hourFormat;

  let count = 0;
  for (let index = 0; index < 3; index++) {
    for (let j = 0; j < numbersLength; j += gap) {
      if ((index === 0 && j < 2) || (numbersLength === 13 && j === 0)) {
        continue;
      }
      if (index === 1 && j === value) {
        arrayOfSelectedValue.push({
          number: j.toString().padStart(2, '0'),
          translatedValue: `-${count}`,
          selected: true,
        });
        count += heightValue;
        continue;
      }
      arrayOfSelectedValue.push({
        number: j.toString().padStart(2, '0'),
        translatedValue: `-${count}`,
        selected: false,
      });

      count += heightValue;
    }
  }

  return arrayOfSelectedValue;
};

export const returnSelectedValue = (
  heightValue: number = 54,
  numbersLength: number = 24,
  gap: number = 1
): SelectedValue[] => {
  const arrayOfSelectedValue: SelectedValue[] = [
    {
      number: "00",
      translatedValue: (heightValue * 2).toString(),
      arrayNumber: 0,
    },
    {
      number: "01",
      translatedValue: heightValue.toString(),
      arrayNumber: 1,
    },
  ];
  let count = 0;
  for (let index = 0; index < 3; index++) {
    for (let j = 0; j < numbersLength; j += gap) {
      if ((index === 0 && j < 2) || (numbersLength === 13 && j === 0)) {
        continue;
      }
      arrayOfSelectedValue.push({
        number: j.toString().padStart(2, '0'),
        translatedValue: `-${count}`,
        selected: false,
      });

      count += heightValue;
    }
  }
  return arrayOfSelectedValue;
};
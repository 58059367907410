import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTreatmentSummeryByPatientJourneyId } from "../../core/requests/_requests";

export type Treatment = {
  treatmentId: number;
  treatmentName: string;
  treatmentDescription: string;
  symptoms: [
    {
      active: boolean;
      id: number;
      title: string;
      description: string;
    }
  ];
  summaryText: string;
  text?: string;
  selectedText: string;
  value?: number;
  id?: number;
};
export type TreatmentOption = {
  text: string;
  value: Treatment;
};
type initialStateType = {
  data: Treatment[] | null;
  loading: boolean;
  error?: string | null;
};

const initialState: initialStateType = {
  data: null,
  loading: false,
  error: null,
};
export const fetchTreatmentsListByPatientJourneyId = createAsyncThunk(
  "treatment/fetchTreatmentsListByPatientJourneyId",
  async (payload: {
    journeyId: number | undefined;
  }) => {
    try {
      const response = await getTreatmentSummeryByPatientJourneyId(
        { journeyId: payload.journeyId }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const TreatmentSlice = createSlice({
  name: "treatment",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTreatmentsListByPatientJourneyId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTreatmentsListByPatientJourneyId.fulfilled,
        (state, action: PayloadAction<Treatment[]>) => {
          state.data = action.payload.length > 0 ? action.payload : null;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        fetchTreatmentsListByPatientJourneyId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default TreatmentSlice.reducer;

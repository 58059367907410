import { useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCurrentJourneySlug } from "../../redux/journey/JourneySlice";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { setAuthenticatedPatientDetails } from "../../redux/user/UserSlice";
import LoadingContainer from "../loader/LoadingContainer";

const IndexElement: FC = () => {
  const { user } = useAuth0();
  const patient = useAppSelector((state) => state.user.authUser);
  const appointments = useAppSelector((state) => state.appointment);
  const [navigateUrl, setNavigateUrl] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [callbackURL] = useState<string | null>(
    localStorage.getItem("auth0_callback")
  );

  const callbackJourney = callbackURL?.match(/^https?:\/\/.*\/(.*)\/.*$/)?.[1];
  const currentJourneySlug: string =
    useAppSelector((state: RootState) => state.journey.currentJourneySlug) ??
    callbackJourney;

  useEffect(() => {
    dispatch(setCurrentJourneySlug(currentJourneySlug));
    const setAuthUserData = async () => {
      await dispatch(
        setAuthenticatedPatientDetails({
          currentJourneySlug,
          email: user?.email,
        })
      );
    };
    if (!patient) setAuthUserData();
  }, [dispatch, patient, currentJourneySlug]);

  useEffect(() => {
    const isInInitialState =
      appointments.data.appontments.length === 0 &&
      !appointments.loading &&
      appointments.error == null;
    if (appointments.loading || isInInitialState) return;

    if (appointments.error) {
      setNavigateUrl("dashboard");
      return;
    }

    const currentAppointment = appointments.data.appontments.find(
      (appointment) => appointment.status === 1
    );
    const appointmentSlug = currentAppointment?.journey_phase.slug;
    if (appointmentSlug == null) {
      setNavigateUrl("dashboard");
      return;
    }

    setNavigateUrl(`journey-phase/${appointmentSlug}`);
  }, [appointments]);

  const handlePatientNavigation = () => {
    navigate(`/${currentJourneySlug}/${navigateUrl}`);
  };

  if (patient && patient.id) {
    if (callbackURL) {
      const callbackURLObj = new URL(callbackURL);
      if (callbackURLObj.pathname !== "/") {
        navigate(callbackURLObj.pathname + callbackURLObj.search);
        localStorage.removeItem("auth0_callback");
      } else {
        handlePatientNavigation();
      }
    } else {
      handlePatientNavigation();
    }
  } else {
    return <LoadingContainer customLoadingMessage="Verifying User Details" />;
  }

  return <></>;
};

export default IndexElement;

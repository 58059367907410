import { FC, useMemo } from "react";
import VideoPlayer, {
  CustomEventHandlers,
} from "../video-player/CustomVideoPlayer";

type GalleryPlayerProps = {
  src?: string;
  customEventHandlers: CustomEventHandlers;
  videoSubtitles?: any;
};

const GalleryPlayer: FC<GalleryPlayerProps> = ({
  src,
  customEventHandlers,
  videoSubtitles,
}) => {
  const videoKey = useMemo(() => Date.now(), [src]);
  return (
    <>
      <div className="fullscreen-video fixed top-0 left-0 w-full h-screen">
        <VideoPlayer
          key={videoKey}
          src={src || ""}
          customEventHandlers={customEventHandlers}
          subtitles={videoSubtitles}
        />
      </div>
    </>
  );
};

export default GalleryPlayer;

import { Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { showFormatedDateTime } from "../../core/DateFormat";
import { AppointmentActivity } from "../../core/phase-activity/AppointmentActivityConfig";
import { createKegelCounterHistory, getKegelCounterHistory, getkegelsetting, updatekegelsetting } from "../../core/requests/_requests";

import PageWrapper from "../../providers/LayoutProvider/PageWrapper";
import { setShowNudge } from "../../redux/journals/JournalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import AnimatedBreath from "../animatedBreath/AnimatedBreath";

interface KegelHistory {
  active: boolean;
  id: number;
  patientId: number;
  date: string;
  reps: number;
}

const KegelTool = ({
  activityDetails,
  handleActivityCompletion,
}: {
  activityDetails: AppointmentActivity;
  handleActivityCompletion: (
    activity: AppointmentActivity,
    completionData?: unknown
  ) => void;
}) => {
  const [btnDisable, setBtnDisable] = useState({
    backBtn: false,
    nextBtn: false,
    // nextBtn: !activityDetails.phase_activity.is_skippable,
  });
  const patient = useAppSelector((state) => state.user.authUser);
  const [kegelObj, setKegelObj] = useState({
    totalIterations: 0,
    totalRepCounts: 0,
    timeSpent: 0,
  });
  const historyRef = useRef<HTMLDivElement>(null);
  const [settingBtnDisable, setSettingBtnDisable] = useState<boolean>(true);
  const [showHistoryPopup, setShowHistoryPopup] = useState<boolean>(false);
  const [kegelHistoryData, setKegelHistoryData] = useState<KegelHistory[]>([]);
  const [isKegelCounterComplete, setIsKegelCounterComplete] = useState<boolean>(false);
  const [startFlag, setStartFlag] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  // let totalminutes = activityDetails?.phase_activity?.timerDuration
  //   ? activityDetails.phase_activity.timerDuration / 60
  //   : 0;
  const [showDiv, setShowDiv] = useState(false);
  const handleSave = async() => {
    try {
    setShowDiv(false);
    setSettingsSaved(true);
    setStartFlag(true);
    const data = await updatekegelsetting({
      "id": (patient && patient.id)? patient.id:0,
      "kegelSetting": settingsData
  })
  
  if(data.kegelSetting){
    setSavedSettings(data.kegelSetting)
  }
  } catch (error){
    setStartFlag(false);
    console.log('Error saving the settings', error);
  }
  }
  const onClose = () => {
    setStartFlag(false);
    setShowDiv(false);
    setShowHistoryPopup(false);
    setSettingsData(savedSettings);

  }
  const [savedSettings, setSavedSettings] = useState<Record<string, any>>(
    {
      holdCount: 0,
      releaseSeconds: 0,
      repCount: 0,
}
  );
  const settingsOptions = [
    { type:"holdCount",name: "Hold Seconds"},
    { type:"releaseSeconds",name: "Release Seconds" },
    { type:"repCount",name: "Number of Reps" },
  ];
  const [settingsData, setSettingsData] = useState<Record<string, any>>(
    {
      holdCount: 0,
      releaseSeconds: 0,
      repCount: 0,
}
  );
  const [settingsSaved,setSettingsSaved]=useState(false);
  let totalRepitions = activityDetails?.phase_activity?.timerDuration ?
    activityDetails?.phase_activity?.timerDuration / 20 :
    10;
  
  useEffect(() => {
    const fetchData = async () => {
      const data = await getkegelsetting();
      if (data.kegelSetting) {
        setSavedSettings(data.kegelSetting)
        if(Number(data.kegelSetting.holdCount) <= 0 || Number(data.kegelSetting.releaseSeconds)<=0 || Number(data.kegelSetting.repCount)<=0){
          setSettingBtnDisable(true)
        } else {
          setSettingBtnDisable(false)
        }
      }
    }
    fetchData()
  }, [])
  useEffect(()=>{
    if(Number(settingsData.holdCount) <= 0 || Number(settingsData.releaseSeconds)<=0 || Number(settingsData.repCount)<=0){
      setSettingBtnDisable(true)
    } else {
      setSettingBtnDisable(false)
    }
  },[settingsData])
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;
    if (historyRef.current && !historyRef.current.contains(target)) {
      setShowHistoryPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleHistoryPopupClick = () => {
    setShowHistoryPopup(!showHistoryPopup);
    setShowDiv(false);
    fetchKegelHistory();
  }

  const fetchKegelHistory = async () => {
    try {
      const kegelHistory = await getKegelCounterHistory();
      if (kegelHistory && Array.isArray(kegelHistory)) {
        setKegelHistoryData(kegelHistory);
      }

    } catch (error) {
      console.log('Error fetching the kegel history', error);
    }
  }

  const handleCreateKegelHistory = async () => {
    try {
      const payload ={
         reps: kegelObj.totalIterations
      }
      if (payload.reps <= 0){
        handleActivityCompletion(activityDetails, kegelObj);
      }
      else{
        await createKegelCounterHistory(payload);
        handleActivityCompletion(activityDetails, kegelObj);
      }

      if (isKegelCounterComplete) {
        dispatch(setShowNudge(true));
      }

    } catch (error) {
      console.log('Error creating kegel history record', error);
    }
  }

  return (
    <PageWrapper
      disabled={btnDisable}
      nextCallback={() => {
        handleCreateKegelHistory();
        //handleActivityCompletion(activityDetails, kegelObj);
      }}
    >
      <div className="flex flex-col h-full relative bg-[#f5f5f5]">
      <div className="flex justify-end">
      <Menu>
      <Menu.Button
                      onClick={() => {
                        setShowDiv(!showDiv)
                        setSettingsSaved(false)
                        setShowHistoryPopup(false);
                        setSettingsData(savedSettings);
                      }}
                      className="px-2 py-1 tw-btn-tealgreen font-inter font-normal rounded-xl mt-4 mr-4"
                      >
                        Settings
                        <div className="float-right">
            
          </div>    
                    </Menu.Button>
      <Transition
      as={Fragment}
      show={showDiv}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
          className="absolute px-2 py-1 font-inter font-normal rounded-xl mt-12 mr-24 rounded-md bg-[#D9D9D9] shadow-lg" // Changed mt-10 to mt-12 for better alignment
          static
      >
        <div className="py-2 block">
          
    <div className="block text-xs">
    <div className="flex items-center justify-between gap-2 px-2 border-b-2 border-gray-800 pb-2"> {/* Changed justify-center to justify-between */}
        <p className="font-bold text-xs text-black whitespace-nowrap mx-auto"> {/* Added mx-auto to center the text */}
            Settings
        </p>
        <button
            className="flex justify-center items-center bg-gray-10 hover:bg-gray-10 px-2 py-1 rounded-full cursor-pointer"
            onClick={onClose}
        >
            <XMarkIcon className="w-5 h-5 text-gray-600" />
        </button>
    </div>
        <div className="container mt-3">
          <div className="grid grid-cols-2 gap-2 px-3">
            {settingsOptions.map((option, index) => (
              <React.Fragment key={index}>
                <div className="flex justify-start ml-1 items-center">
                  {option.name}
                </div>

                <div className="flex justify-end items-center">
                  <input
                    type="number"
                    className="w-9 h-7 p-1 text-center rounded-md"
                    value={
                      settingsData[option.type] === 0
                        ? null
                        : settingsData[option.type]
                    }
                    max={10}
                    min={1}
                    onChange={(e) => {
                      let value;
                      if (Number(e.target.value) < 0) {
                        value = 0;
                        setSettingBtnDisable(true);
                      } else {
                        value = Number(e.target.value)
                      }
                      setSettingsData({
                        ...settingsData,
                        [option.type]: value,
                      });
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>

          <div className="flex justify-center items-center mt-4"> 
            <button
              type="button"
              className={`${settingBtnDisable ? 'bg-gray-600': 'bg-[#0178BF]'} w-fit px-2 py-[1px] rounded-xl text-white text-sm`}
              style={{
                border: "1px solid black",
              }}
              onClick={handleSave}
              disabled = {settingBtnDisable}
            >
              Save
            </button>
          </div>
        </div>
    </div>
          </div>
      </Menu.Items>
    </Transition>
            <Menu.Button
              onClick={() => handleHistoryPopupClick()}
              className="px-2 py-1 tw-btn-tealgreen font-inter font-normal rounded-xl mt-4 mr-8"
            >
              History
              
            </Menu.Button>
            <Transition
              as={Fragment}
              show={showHistoryPopup}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute px-2 py-1 font-inter font-normal rounded-xl mt-12 mr-4 rounded-md bg-[#D9D9D9] shadow-lg" // Changed mt-10 to mt-12 for better alignment
                static
              >
                <div ref={historyRef} className="py-2 block">

                  <div className="block text-xs">
                    <div className="flex items-center justify-between gap-2 px-2 border-b-2 border-gray-800 pb-2">
                      <p className="font-bold text-xs text-black whitespace-nowrap mx-auto">
                        Counter History
                      </p>
        <button
            className="flex justify-center items-center bg-gray-10 hover:bg-gray-10 px-2 py-1 rounded-full cursor-pointer"
            onClick={onClose}
        >
            <XMarkIcon className="w-5 h-5 text-gray-600" />
        </button>
                    </div>
                    <div className="container py-2  h-[152px] w-[219px] overflow-y-auto scrollbar">
                      {kegelHistoryData && kegelHistoryData?.length > 0 ?
                        (<div className="flex px-1 justify-center">
                          <div className=" flex flex-col">
                            <div className="font-bold font-inter pb-2 text-center">
                              Date
                            </div>
                            {kegelHistoryData.map((historyItem, index) => (
                              <div key={index} className="pb-2 text-left">{showFormatedDateTime(historyItem?.date)}</div>
                            ))}
                          </div>
                          <div className=" flex flex-col justify-center items-center ml-4">
                            <div className="font-bold font-inter pb-2">
                              Reps
                            </div>
                            {kegelHistoryData.map((historyItem, index) => (
                              <div key={index} className="pb-2">{historyItem?.reps}</div>
                            ))}
                          </div>
                        </div>) : <div className="flex px-3 justify-center">No History</div>
                      }
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          </div>
      <div className="flex-none">
          <div className="">
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-xl md:text-2xl font-semibold font-inter">
                Pelvic Floor Exercise
              </h1>
              <h6 className=" font-inter mt-2">
                {/* {totalminutes} minute(s) strength workout */}
                Try to do {savedSettings.repCount!==0 && savedSettings.repCount} repetitions
              </h6>
              <p className="m-3 pl-1 mt-4">
                Strengthen your pelvic floor muscles with our Kegel Counter
                tool. It guides you through each squeeze and release, timing
                your movements and tracking your progress to help improve
                bladder control and overall pelvic health. Start counting your
                reps today!
              </p>
            </div>
          </div>
        </div>

        <AnimatedBreath
          holdTime={savedSettings.holdCount!==0?savedSettings.holdCount:undefined}
          releaseTime={savedSettings.holdCount!==0?savedSettings.releaseSeconds:undefined}
          totalRepitions={savedSettings.holdCount!==0?savedSettings.repCount:undefined}
          onToggle={(obj) => {
            if (obj.isPaused) {
              setBtnDisable({ backBtn: false, nextBtn: false });
              setKegelObj(obj);
            } else {
              setBtnDisable({ backBtn: true, nextBtn: true });
            }
          }}
          onComplete={(obj) => {
            setIsKegelCounterComplete(true);
            setKegelObj(obj);
            setBtnDisable({ backBtn: false, nextBtn: false });
          }}
          onClose={onClose}
          startFlag={startFlag}
        />

        <div className="flex-none">
          <div className="grid grid-cols-12 gap-4 pb-6 pt-4" />
        </div>
      </div>
    </PageWrapper>
  );
};
export default KegelTool;

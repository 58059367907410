import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getConfigurationsRequest, setConfigurationsRequest } from "../../core/requests/_requests";
// import { setConfigurationsRequest } from "../../core/requests/_requests";

export const DAYS = {
  mon: "mon",
  tue: "tue",
  wed: "wed",
  thu: "thu",
  fri: "fri",
  sat: "sat",
  sun: "sun"
} as const;

// Define the type for days
export type Day = (typeof DAYS)[keyof typeof DAYS];

// Define the display names for days
export const DAYS_DISPLAY_NAME: Record<Day, string> = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

export type Config = {
  id: number;
  lable: string;
  name: string;
  value: boolean;
  days: {
    day: Day;
    value: boolean;
  }[];
  time: string;
  goal_title: string;
  goalTitle: string;
  goal_id?: number | null;
  notification_channel: string;
  notificationChannel: string;
  subscription_status: boolean;
  subscriptionStatus: boolean;
  weekdays: [];
  weekDays: [];
  notification_time: string;
  notificationTime: string;
};

export const setConfigurations = createAsyncThunk(
  "patient/setConfigurations",
  async ({ id, data }: { id: number; data: any }) => {
    try {
      const response = await setConfigurationsRequest(id, data);
      return response;
    } catch (error) { }
  }
);

// export const getConfigurations = createAsyncThunk(
//   "patient/getConfigurations",
//   async (journeySlug?: string) => {
//     try {
//       const response = await getConfigurationsRequest(journeySlug);
//       return response.data;
//       return []
//     } catch (error) { }
//   }
// );

export const getConfigurations = createAsyncThunk(
  "patient/getConfigurations",
  async (journeySlug?: string) => {
    try {
      const response = await getConfigurationsRequest(journeySlug);
      return response;
    } catch (error) { }
  }
);

const initialState: { data: Config[] } = {
  data: [],
};

const ConfigurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getConfigurations.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.data = action.payload;
        }
      )
      .addCase(
        setConfigurations.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.data = state.data.map((item: Config) => {
            if (item?.id === action?.payload?.id) {
              return {
                ...action.payload,
              };
            }
            return item;
          });
        }
      );
  },
});

export default ConfigurationSlice.reducer;

import { Disclosure } from "@headlessui/react";
import {
  ChatBubbleLeftEllipsisIcon,
  CheckIcon,
  ClockIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import ConditionallyRender from "react-conditionally-render";
import { TimePicker } from "react-ios-time-picker";
import PhoneInput from "react-phone-number-input";
import { useParams } from "react-router-dom";
import { Checkbox, CheckboxGroup, Loader, Tooltip } from "rizzui";
import { alertNotification } from "../../../../core/alert/ToastAlert";
import {
  Config,
  getConfigurations,
  setConfigurations,
} from "../../../../redux/configuration/ConfigurationSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { patientUpdate } from "../../../../redux/user/UserSlice";
import StatusTab from "../overview/StatusTab";

const JourneyStatus = () => {
  const inputRef = useRef<any>(null);
  const patient = useAppSelector((state: RootState) => state.user.authUser);

  const [updatingConfiguration, setupdatingConfiguration] =
    useState<boolean>(false);
  const [showEditCellInput, setShowEditCellInput] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(patient?.phone_number);
  const [selectId, setSelectId] = useState<number | null>(null);
  const { journeySlug } = useParams<{ journeySlug: string }>();

  const dispatch = useAppDispatch();
  const configurations: Config[] = useAppSelector(
    (state: RootState) => state.configuration.data
  );

  const updateConfigurations = async (
    id: number,
    data?: {
      goal_details: {
        subscription_status?: boolean;
        notification_time?: string;
        weekdays?: any;
      };
    }
  ) => {
    setSelectId(id);
    setupdatingConfiguration(true);
    await dispatch(setConfigurations({ id, data }));
    alertNotification("success", "Changes saved successfully!", "top-right");
    setupdatingConfiguration(false);
    setSelectId(null);
  };

  const sumbitCellData = async (e: any) => {
    e.preventDefault();
    await dispatch(patientUpdate({ phone_number: phoneNumber }));
    setShowEditCellInput(false);
    alertNotification(
      "success",
      "Phone number saved successfully!",
      "top-right"
    );
  };

  useEffect(() => {
    dispatch(getConfigurations(journeySlug));
  }, []);

  return (
    <div className="grid grid-cols-1 gap-6 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar px-2">
      {journeySlug === "overactive-bladder" && <StatusTab />}
      <div className="bg-white  p-8 space-y-6 rounded-xl">
        <div className="flex justify-between">
          <h2 className="text-2xl font-medium">Communications</h2>
          <div className="flex gap-1 items-center">
            <GlobeAltIcon className="h-6 w-6 text-green-600 flex" />
            <p className="text-sm">{patient?.time_zone}</p>
          </div>
        </div>

        {configurations?.length > 0 ? (
          <div className="space-y-5">
            <div className="flex space-x-5 items-start bg-slate-100 p-2 border border-gray-200 rounded-lg">
              <div className="flex-1">
                <span>Currently Opted-In Notifications</span>
              </div>
              <div>
                <div className="grid grid-cols-7 gap-y-5 gap-x-1">
                  <div className="text-base text-start w-4">M</div>
                  <div className="text-base text-start w-4">T</div>
                  <div className="text-base text-start w-6">W</div>
                  <div className="text-base text-start w-6">Th</div>
                  <div className="text-base text-start w-6">F</div>
                  <div className="text-base text-start w-6">Sa</div>
                  <div className="text-base text-start w-6">Su</div>
                </div>
              </div>
              <div className="w-20 float-right">Time</div>
            </div>
            {configurations &&
              configurations.some((config) => config.subscription_status) ? (
              <div className="space-y-5 max-h-[35vh] overflow-y-auto pt-1 pr-2 scrollbar px-2">
                {configurations &&
                  configurations
                    .filter((config) => config.subscription_status)
                    .map((config: Config, i) => {
                      return (
                        <>
                          {config?.id === selectId && updatingConfiguration ? (
                            <div
                              className="flex items-center justify-center"
                              key={config.id}
                            >
                              <Loader
                                variant="threeDot"
                                size="xl"
                                className="text-green-600"
                              />
                            </div>
                          ) : (
                            <div className="flex space-x-[26px] items-start">
                              <div
                                className="flex flex-col flex-1"
                                key={config.id}
                              >
                                <div className="relative flex items-start">
                                  <div className="flex h-6 items-center gap-2">
                                    <input
                                      type="checkbox"
                                      id={`option${config.id}`}
                                      name={`${config.name}`}
                                      className="h-6 w-6 border-gray-300 bg-gray-300 text-indigo-600 focus:ring-indigo-600`"
                                      checked={config?.subscription_status}
                                      onChange={(e) => {
                                        updateConfigurations(config.id, {
                                          goal_details: {
                                            subscription_status:
                                              e.target.checked,
                                            notification_time:
                                              config?.notification_time,
                                            weekdays:
                                              e.target.checked === false
                                                ? JSON.stringify([])
                                                : JSON.stringify(
                                                  config?.weekdays
                                                ),
                                          },
                                        });
                                      }}
                                    />
                                    {config?.notification_channel == "sms" && (
                                      <Tooltip
                                        placement="top"
                                        content={"This is a SMS Notification"}
                                      >
                                        <ChatBubbleLeftEllipsisIcon className="h-4 w-4 text-blue-600" />
                                      </Tooltip>
                                    )}
                                    {config?.notification_channel ==
                                      "email" && (
                                        <Tooltip
                                          placement="top"
                                          content={
                                            "This is an Email Notification"
                                          }
                                        >
                                          <EnvelopeIcon className="h-4 w-4 text-blue-600" />
                                        </Tooltip>
                                      )}
                                  </div>
                                  <div className="ml-1 text-sm leading-6 ">
                                    <label
                                      htmlFor={`option${config.id}`}
                                      className="font-medium text-base cursor-pointer"
                                    >
                                      <div className="flex">
                                        <h6>{`${config?.goal_title
                                            ? config?.goal_title
                                            : "Liquid Intake and Pee Diary Journal"
                                          } (${config?.notification_channel
                                          })`}</h6>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-span-7">
                                <CheckboxGroup
                                  values={config?.weekdays || []}
                                  setValues={(values) => {
                                    updateConfigurations(config.id, {
                                      goal_details: {
                                        subscription_status:
                                          values?.length > 0 || values !== null
                                            ? false
                                            : true,
                                        notification_time:
                                          config?.notification_time,
                                        weekdays: JSON.stringify(values),
                                      },
                                    });
                                  }}
                                  className="grid grid-cols-7 gap-x-1"
                                >
                                  <Checkbox
                                    inputClassName="group_check_input"
                                    iconClassName="group_check_svg"
                                    value="0"
                                  />
                                  <Checkbox
                                    inputClassName="group_check_input"
                                    iconClassName="group_check_svg"
                                    value="1"
                                  />
                                  <Checkbox
                                    inputClassName="group_check_input"
                                    iconClassName="group_check_svg"
                                    value="2"
                                  />
                                  <Checkbox
                                    inputClassName="group_check_input"
                                    iconClassName="group_check_svg"
                                    value="3"
                                  />
                                  <Checkbox
                                    inputClassName="group_check_input"
                                    iconClassName="group_check_svg"
                                    value="4"
                                  />
                                  <Checkbox
                                    inputClassName="group_check_input"
                                    iconClassName="group_check_svg"
                                    value="5"
                                  />
                                  <Checkbox
                                    inputClassName="group_check_input"
                                    iconClassName="group_check_svg"
                                    value="6"
                                  />
                                </CheckboxGroup>
                                {Boolean(config.subscription_status) &&
                                  (config?.weekdays?.length === 0 ||
                                    config?.weekdays === null) && (
                                    <p className="text-xs text-red-500 mt-1">
                                      Select weekdays for notifications
                                    </p>
                                  )}
                              </div>
                              <div
                                className={`relative bottom-1 ${!config.subscription_status &&
                                  "pointer-events-none opacity-50"
                                  }`}
                              >
                                <TimePicker
                                  onChange={(time: any) => {
                                    updateConfigurations(config.id, {
                                      goal_details: {
                                        subscription_status:
                                          config?.subscription_status,
                                        notification_time: time,
                                        weekdays: JSON.stringify(
                                          config?.weekdays
                                        ),
                                      },
                                    });
                                  }}
                                  value={config.notification_time}
                                />
                                <ClockIcon className="h-5 w-5 pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 text-green-700" />
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
              </div>
            ) : (
              <div className="flex flex-col mt-10">
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    ></path>
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    You are currently not opted in for any notifications.
                  </h3>
                </div>
              </div>
            )}
            {configurations &&
              configurations.some((config) => !config.subscription_status) && (
                <Disclosure>
                  {({ open }) => (
                    <>
                      <div className="relative bg-slate-100">
                        <Disclosure.Button
                          className={classNames(
                            open ? "rounded-t-lg" : "rounded-lg",
                            "flex w-full justify-between text-left font-medium text-black"
                          )}
                        >
                          <div className="flex flex-col w-full border border-gray-200 rounded-lg p-2">
                            <div className="flex justify-between">
                              <span>Pending Notifications Opt-In</span>
                              <svg
                                className={classNames(
                                  open && "rotate-180",
                                  "transition-all duration-500"
                                )}
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 1024 1024"
                                height="1.5em"
                                width="1.5em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                              </svg>
                            </div>
                          </div>
                        </Disclosure.Button>
                      </div>
                      <Disclosure.Panel className="text-sm text-black rounded-b-lg">
                        <div className="space-y-5 max-h-[20vh] overflow-y-auto pt-1 pr-2 scrollbar px-2">
                          {configurations &&
                            configurations
                              .filter((config) => !config.subscription_status)
                              .map((config: Config, i) => (
                                <div key={config.id}>
                                  {config?.id === selectId &&
                                    updatingConfiguration ? (
                                    <div className="flex items-center justify-center">
                                      <Loader
                                        variant="threeDot"
                                        size="xl"
                                        className="text-green-600"
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex space-x-5 items-start">
                                      <div className="flex flex-col flex-1">
                                        <div className="relative flex items-start">
                                          <div className="flex h-6 items-center gap-2">
                                            <input
                                              type="checkbox"
                                              id={`option${config.id}`}
                                              name={`${config.name}`}
                                              className="h-6 w-6 border-gray-300 bg-gray-300 text-indigo-600 focus:ring-indigo-600"
                                              checked={
                                                config?.subscription_status
                                              }
                                              onChange={(e) => {
                                                updateConfigurations(
                                                  config.id,
                                                  {
                                                    goal_details: {
                                                      subscription_status:
                                                        e.target.checked,
                                                      notification_time:
                                                        config?.notification_time,
                                                      weekdays:
                                                        e.target.checked ===
                                                          false
                                                          ? JSON.stringify([])
                                                          : JSON.stringify(
                                                            config?.weekdays
                                                          ),
                                                    },
                                                  }
                                                );
                                              }}
                                            />
                                            {config?.notification_channel ===
                                              "sms" && (
                                                <Tooltip
                                                  placement="top"
                                                  content={
                                                    "This is a SMS Notification"
                                                  }
                                                >
                                                  <ChatBubbleLeftEllipsisIcon className="h-4 w-4 text-blue-600" />
                                                </Tooltip>
                                              )}
                                            {config?.notification_channel ===
                                              "email" && (
                                                <Tooltip
                                                  placement="top"
                                                  content={
                                                    "This is an Email Notification"
                                                  }
                                                >
                                                  <EnvelopeIcon className="h-4 w-4 text-blue-600" />
                                                </Tooltip>
                                              )}
                                          </div>
                                          <div className="ml-1 text-sm leading-6 ">
                                            <label
                                              htmlFor={`option${config.id}`}
                                              className="font-medium text-base cursor-pointer"
                                            >
                                              <div className="flex">
                                                <h6>{`${config?.goal_title
                                                    ? config?.goal_title
                                                    : "Liquid Intake and Pee Diary Journal"
                                                  } (${config?.notification_channel
                                                  })`}</h6>
                                              </div>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}
          </div>
        ) : (
          <div className="flex flex-col mt-10">
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
              <svg
                className="h-6 w-6 text-red-300"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                ></path>
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                No Data Found!
              </h3>
            </div>
          </div>
        )}

        <div className="flex flex-col">
          <hr />
          <h2 className="text-2xl font-medium my-2">Current Contact Info</h2>
          <div className="gap-2 text-sm">
            <div className="flex items-center gap-2 mb-3">
              <h6 className="w-24">Cell:</h6>
              <div className="border items-center px-4 py-1 rounded-lg flex">
                <PhoneInput
                  defaultCountry="US"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  readOnly={!showEditCellInput}
                  className="journey_status_phone_input"
                  ref={inputRef}
                />
                <ConditionallyRender
                  condition={showEditCellInput}
                  show={
                    <div className="flex gap-2">
                      <CheckIcon
                        className="w-5 h-5 ms-2 text-green-700 cursor-pointer"
                        onClick={(e) => sumbitCellData(e)}
                      />
                      <XMarkIcon
                        className="w-5 h-5 text-red-700 cursor-pointer"
                        onClick={() => setShowEditCellInput(false)}
                      />
                    </div>
                  }
                  elseShow={
                    <div>
                      <PencilIcon
                        onClick={() => {
                          setShowEditCellInput(true);
                          if (inputRef.current) {
                            inputRef.current.focus();
                          }
                        }}
                        className="w-4 h-4 text-green-700 cursor-pointer"
                      />
                    </div>
                  }
                />
              </div>
            </div>

            <div className="flex items-center gap-2">
              <h6 className="w-24">Email Address:</h6>
              <div className="border rounded-md p-2 cursor-not-allowed bg-gray-100 px-4">
                {patient?.email}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyStatus;

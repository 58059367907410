import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PatientGoal, togglePatientGoal, updatePatientGoal } from '../../redux/dashboard/DashboardSlice';
import { AppDispatch } from '../../redux/store';

type UseTogglePatientGoalResult = {
  toggleSwitch: (goalDetails: PatientGoal) => Promise<void>;
  toggleGoalCard: (goalDetails: PatientGoal) => Promise<void>;
  selectedGoal: PatientGoal | null;
  targetGoal: PatientGoal | null;
  toggleRequestFlag : boolean;
  setSelectedGoal: React.Dispatch<React.SetStateAction<PatientGoal | null>>;
  setTargetGoal: React.Dispatch<React.SetStateAction<PatientGoal | null>>;
};

const useTogglePatientGoal = (): UseTogglePatientGoalResult => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedGoal, setSelectedGoal] = useState<PatientGoal | null>(null);
  const [targetGoal, setTargetGoal] = useState<PatientGoal | null>(null);
  const [toggleRequestFlag, setToggleRequestFlag] = useState<boolean>(false);

  const updateGoalStates = useCallback(
    async (goalDetails: PatientGoal, res: any) => {
      const defaultNotificationDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
      const defaultNotificationTimes = [
        {
          notificationTime: '09:00 PM',
          hasEmailNotification: true,
          hasSmsNotification: true,
        },
      ];

      const updatedGoalDetails = {
        ...goalDetails,
        uuid: res.payload.uuid,
        active: true,
        notificationDays: goalDetails.notificationDays && goalDetails.notificationDays?.length > 0 ? goalDetails.notificationDays : defaultNotificationDays,
        notificationTimes: goalDetails.notificationTimes && goalDetails.notificationTimes?.length > 0 ? goalDetails.notificationTimes : defaultNotificationTimes,
      };
      await dispatch(updatePatientGoal(updatedGoalDetails));
      setTargetGoal(updatedGoalDetails as PatientGoal);
      setSelectedGoal(updatedGoalDetails as PatientGoal);
    },
    [dispatch]
  );

  const setAsTargetGoal = useCallback(
    async (goal: PatientGoal) => {
      const { journeyId, journeyGoalId } = goal;
      if (goal) {
        setSelectedGoal({
          ...goal,
          active: true,
        });
      }

      if (goal && !goal.active) {
        if (goal.uuid) {
          await dispatch(togglePatientGoal({ journeyId, journeyGoalId, uuid: goal.uuid }));
        } else {
          let res = await dispatch(togglePatientGoal({ journeyId, journeyGoalId }));

          if (res?.payload) {
            setTargetGoal({
              ...goal,
              id: res.payload.id,
              active: true,
              notificationDays: goal.notificationDays ?? ['mon'],
              notificationTimes: goal.notificationTimes ?? [
                {
                  notificationTime: '09:00 PM',
                  hasEmailNotification: true,
                  hasSmsNotification: true,
                },
              ],
            });
            return;
          }
        }
      }

      setTargetGoal({
        ...goal,
        active: true,
        notificationDays: goal.notificationDays ?? ['mon'],
        notificationTimes: goal.notificationTimes ?? [
          {
            notificationTime: '09:00 PM',
            hasEmailNotification: true,
            hasSmsNotification: true,
          },
        ],
      });
    },
    [dispatch]
  );

  const toggleSwitch = useCallback(
    async (goalDetails: PatientGoal) => {
      if (toggleRequestFlag) {
        return;
      }
      setToggleRequestFlag(true);
      const { journeyId, journeyGoalId } = goalDetails || {};
      let res;
      let togglePayload;

      if (goalDetails?.uuid) {
        togglePayload = { journeyId, journeyGoalId, uuid: goalDetails.uuid }
      } else {
        togglePayload = { journeyId, journeyGoalId}
      }
      try {
      res = await dispatch(togglePatientGoal(togglePayload));

      if ((!goalDetails?.notificationDays && !goalDetails?.notificationTimes) || (goalDetails?.notificationDays?.length === 0 && goalDetails?.notificationTimes?.length === 0) && res.payload.id) {
        await updateGoalStates(goalDetails, res);
        return;
      }

      if (goalDetails?.notificationDays && goalDetails?.notificationTimes && (goalDetails?.notificationDays?.length === 0 || goalDetails?.notificationTimes?.length === 0)) {
        await updateGoalStates(goalDetails, res);
        return;
      }

      if (res?.payload && res?.payload?.active) {
        setAsTargetGoal({ ...goalDetails, active: !goalDetails.active, id: res.payload.id });
        return;
      }
    } catch (error) {
      console.error("Error occured while toggling",error);
    } finally {
      setToggleRequestFlag(false);
    }
    },
    [dispatch, setAsTargetGoal, updateGoalStates]
  );

  const toggleGoalCard = useCallback(
    async (goalDetails: PatientGoal) => {

      const { journeyId, journeyGoalId } = goalDetails || {};

      if (goalDetails.active) {
        setAsTargetGoal({ ...goalDetails });
        return;
      }

      let res;
      let togglePayload;

      if (goalDetails?.uuid) {
        togglePayload = { journeyId, journeyGoalId, uuid: goalDetails.uuid }
      } else {
        togglePayload = { journeyId, journeyGoalId}
      }

      res = await dispatch(togglePatientGoal(togglePayload));

      if ((!goalDetails?.notificationDays && !goalDetails?.notificationTimes) || (goalDetails?.notificationDays?.length === 0 && goalDetails?.notificationTimes?.length === 0) && res.payload.id) {
        await updateGoalStates(goalDetails, res);
        return;
      }

      if (goalDetails?.notificationDays && goalDetails?.notificationTimes && (goalDetails?.notificationDays?.length === 0 || goalDetails?.notificationTimes?.length === 0)) {
        await updateGoalStates(goalDetails, res);
        return;
      }

      if (res?.payload) {
        setAsTargetGoal({ ...goalDetails, active: !goalDetails.active, id: res.payload.id });
        return;
      }
    },
    [dispatch, setAsTargetGoal, updateGoalStates]
  );

  return { toggleSwitch, toggleGoalCard, selectedGoal, targetGoal, setSelectedGoal, setTargetGoal,toggleRequestFlag };
};

export default useTogglePatientGoal;

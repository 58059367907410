export interface SurveyResponse {
  answer: SurveyAnswer;
  name: string;
  dbId: number; //todo: derive from survey type
}

type SurveyAnswer =
  | boolean
  | string
  | number
  | boolean[]
  | string[]
  | number[]
  | Object[];

export const isSurveyResponse = (obj: unknown): obj is SurveyResponse => {
  const requiredKeys: (keyof SurveyResponse)[] = ["answer", "dbId", "name"];

  return (
    typeof obj === "object" &&
    obj != null &&
    !Array.isArray(obj) &&
    requiredKeys.every((reqKey) => reqKey in obj)
  );
};

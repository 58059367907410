import { Link } from "react-router-dom";
import { Badge } from "rizzui";
import useAuthOLogout from "../../core/_hooks/useAuthOLogout";
import { MyAccoutSvg } from "../../Images/icons/Icons";
import { setShowModal } from "../../redux/layout/LayoutSlice";
import { NotificationType } from "../../redux/notification/NotificationSlice";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";

const MenuHeaderControls = () => {
  const patientRegistered = useAppSelector(
    (state: RootState) => state.user.authUser?.redox_patient_id
  );
  const currentJourneySlug: string = useAppSelector(
    (state: RootState) => state.journey.currentJourneySlug
  );
  const notifications: any = useAppSelector(
    (state: RootState) => state.notification
  );

  const showModal = useAppSelector(
    (state: RootState) => state.layout.showModal
  );

  const notificationsList: NotificationType[] = notifications.data;
  const dispatch = useAppDispatch();

  const { auth0Logout } = useAuthOLogout();
  return (
    <div className="fixed p-5 z-10 text-white right-0 justify-end pb-3.5 first:pt-2 last:h-[100vh] inline-block w-fit pointer-events-auto menu-header">
      <ul className="flex">
        <li
          className={`px-3 before:content-none cursor-pointer menu-header-link ${!patientRegistered && "hidden"
            }`}
        >
          <Link
            to={`${currentJourneySlug}/scheduling`}
            onClick={() => {
              if (!showModal) {
                dispatch(setShowModal(true));
              }
            }}
          >
            Scheduling
          </Link>
        </li>
        <li
          className={`px-3 before:content-none cursor-pointer menu-header-link ${!patientRegistered && "hidden"
            }`}
        >
          <Link
            to={`${currentJourneySlug}/dashboard`}
            onClick={() => {
              localStorage.setItem("dashboard_activeTab", "overview");
              if (!showModal) {
                dispatch(setShowModal(true));
              }
            }}
          >
            Dashboard
          </Link>
        </li>
        <li
          className={`px-3 before:content-none cursor-pointer menu-header-link relative ${!patientRegistered && "hidden"
            }`}
        >
          <Link
            to={`${currentJourneySlug}/notifications`}
            onClick={() => {
              if (!showModal) {
                dispatch(setShowModal(true));
              }
            }}
          >
            Notifications
          </Link>
          {notificationsList &&
            notifications.data.filter(
              (notification: NotificationType) => !notification.readTimestamp
            ).length > 0 && (
              <Badge
                size="sm"
                color="danger"
                enableOutlineRing
                className="absolute right-1 top-0 -translate-y-1/3 translate-x-1/2 ring-0"
              >
                {
                  notifications?.data?.filter(
                    (notification: NotificationType) =>
                      !notification.readTimestamp
                  ).length
                }
              </Badge>
            )}
        </li>
        <li
          className={`px-3 before:content-none mr-5 cursor-pointer menu-header-link ${!patientRegistered && "hidden"
            }`}
        >
          <Link
            to={`${currentJourneySlug}/video-gallery`}
            onClick={() => {
              if (!showModal) {
                dispatch(setShowModal(true));
              }
            }}
          >
            Videos
          </Link>
        </li>
        <li
          className={`px-3 before:content-none cursor-pointer menu-header-link flex text-white ${!patientRegistered && "hidden"
            }`}
        >
          <Link to={`${currentJourneySlug}/profile`} className="mr-2">
            <MyAccoutSvg />
          </Link>
        </li>
        <li
          className="before:content-none cursor-pointer menu-header-link"
          onClick={auth0Logout}>
            Sign out
        </li>
      </ul>
    </div>
  );
};
export default MenuHeaderControls;

import {
  InformationCircleIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import ConditionallyRender from "react-conditionally-render";
import { Button, Textarea } from "rizzui";
import {
  AppointmentActivity,
  AppointmentActivityTypes,
  PhaseActivityStatus,
} from "../../../../core/phase-activity/AppointmentActivityConfig";
import {
  AppointmentProgress,
  AppointmentStatus,
  fetchPatientJourneyProgresses,
} from "../../../../redux/appointment/AppointmentSlice";
import {
  Note,
  getDashboardNotes,
  updateDashboardNote,
} from "../../../../redux/dashboard/DashboardSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import Tabs from "../../../tabs/Tabs";

function Notes() {
  const dispatch = useAppDispatch();

  const [editNote, setEditNote] = useState<Note | null>(null);
  const [noteUpdating, setNoteUpdating] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>();

  const PatientNotes = useAppSelector(
    (state: RootState) => state.dashboard.notes
  );
  const appointmentProgresses: AppointmentProgress[] = useAppSelector(
    (state: RootState) => state.appointment.data.appontments
  );
  const patient = useAppSelector((state: RootState) => state.user.authUser);

  const completedAppointments = useMemo(
    () =>
      appointmentProgresses?.filter(
        (item: AppointmentProgress) =>
          item?.status === AppointmentStatus.COMPLETED ||
          item?.status === AppointmentStatus.CURRENT
      ),
    [appointmentProgresses]
  );

  const allAppointments = useMemo(
    () =>
      completedAppointments?.filter((appointment: AppointmentProgress) => {
        return appointment?.journey_phase.patient_phase_activity?.some(
          (activity: AppointmentActivity) =>
            activity?.phase_activity?.type ===
              AppointmentActivityTypes.SUMMARY &&
            activity?.status === PhaseActivityStatus?.COMPLETED
        );
      }),
    [completedAppointments]
  );

  const allSummaryNotesData = useMemo(
    () =>
      allAppointments?.filter((appointment) =>
        appointment?.journey_phase?.patient_phase_activity?.find(
          (iactivity) => iactivity?.external_data
        )
      ),
    [allAppointments]
  );

  const tabsData = useMemo(() => {
    return (
      allSummaryNotesData?.map((activity) => {
        return {
          key: activity?.journey_phase?.slug || "",
          value: activity?.journey_phase?.title || "",
        };
      }) ?? []
    );
  }, [allSummaryNotesData]);

  useEffect(() => {
    dispatch(getDashboardNotes());
  }, [dispatch]);

  const onChangeHandler = useCallback((e: any) => {
    const newValue = e.target.value;
    setEditNote((prevNote) =>
      prevNote ? { ...prevNote, patient_note_content: newValue } : null
    );
  }, []);

  const updateNote = useCallback(() => {
    setNoteUpdating(true);
    try {
      dispatch(
        updateDashboardNote({
          id: editNote?.patient_activity_id,
          data: editNote?.patient_note_content,
        })
      ).then(async (res: any) => {
        setEditNote(null);
        setNoteUpdating(false);
        await dispatch(getDashboardNotes());
        dispatch(
          fetchPatientJourneyProgresses({
             journeyId: patient.current_journey_id,
          })
        );
      });
    } catch (err) {
      console.log(err);
      setNoteUpdating(false);
    }
  }, [dispatch, editNote, patient.id, patient.current_journey_id]);

  const renderTabData = useCallback(
    (tabKey: string) => {
      let currentSummary = allSummaryNotesData.find(
        (activity) => activity?.journey_phase?.slug === tabKey
      );
      let currentSummaryNote =
        currentSummary?.journey_phase?.patient_phase_activity?.find(
          (phaseactivity) =>
            phaseactivity.phase_activity?.type ===
            AppointmentActivityTypes.SUMMARY
        );
      let filteredNote = PatientNotes.data.filter(
        (activity) => activity.patient_activity_id.toString() === currentSummaryNote?.id
      );
      return (
        <>
          { filteredNote && filteredNote.length > 0 ?
            filteredNote?.map((note: Note, i: number) => (
              <div className="bg-white p-5 rounded-xl " key={i}>
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <div className="flex flex-col mb-3">
                      <p className="text-xs text-black/50 font-medium mb-1">
                        {" "}
                        Last updated: {note?.last_update}
                      </p>
                    </div>

                    <div className="inline-block">
                      <PencilSquareIcon
                        onClick={() => setEditNote(note)}
                        className="h-5 w-5 flex-none self-start cursor-pointer text-green-700"
                      />
                    </div>
                  </div>

                  {editNote &&
                  editNote.patient_activity_id === note?.patient_activity_id ? (
                    <div className="flex flex-col">
                      <Textarea
                        placeholder="Write you message..."
                        textareaClassName="text_area_scrollbar"
                        className="[&>label>span>textarea]:border-black mb-4"
                        defaultValue={
                          editNote?.patient_note_content
                            ? editNote?.patient_note_content
                            : ""
                        }
                        onChange={onChangeHandler}
                      />

                      <div className="flex gap-4 justify-end">
                        <Button
                          isLoading={noteUpdating}
                          className="uppercase bg-green-700"
                          onClick={updateNote}
                        >
                          Save
                        </Button>
                        <button
                          type="button"
                          className="tw-btn bg-red-600 text-white block uppercase"
                          onClick={() => setEditNote(null)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {note?.patient_note_content ? (
                        <p className="text-base text-[#1D1D1D]">
                          {note?.patient_note_content}
                        </p>
                      ) : (
                        <div className="flex justify-center items-center space-x-2">
                          <InformationCircleIcon
                            className="h-8 w-8 text-green-700"
                            aria-hidden="true"
                          />
                          <h4 className="text-base text-[#1D1D1D]">
                            Feel free to add your notes here.
                          </h4>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )) :
            <div className="text-sm font-medium text-center p-5 text-[#1D1D1D]">
              <p className="pb-1">It looks like no notes have been added yet.<br/></p>
              <p>To get started, go to your Summary sections and add notes.</p>
            </div>
          }
        </>
      );
    },
    [
      PatientNotes.data,
      allSummaryNotesData,
      editNote,
      noteUpdating,
      onChangeHandler,
      updateNote,
    ]
  );

  const handleActiveTab = (tab: string) => {
    setCurrentTab(tab);
  };
  
  return (
    <>
      <ConditionallyRender
        condition={!PatientNotes?.data || PatientNotes?.data?.length === 0}
        show={
          <div className="bg-white p-8 rounded-xl">
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <svg
                className="h-6 w-6 text-green-600"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                ></path>
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Your Personal Notes
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Within various appointments, you have ability to provide the
                  notes information.
                </p>
              </div>
            </div>
          </div>
        }
        elseShow={
          <div className="grid grid-cols-1 gap-6 px-2">
            {tabsData?.length && (
              <Tabs
                renderTabData={renderTabData}
                tabListData={tabsData}
                activeTab={currentTab ?? tabsData[0]?.key}
                currentTab={handleActiveTab}
              />
            )}
          </div>
        }
      />
    </>
  );
}

export default Notes;

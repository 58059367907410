import { Squares2X2Icon } from "@heroicons/react/24/outline";
import classnames from "classnames";
import { FC, useEffect, useState } from "react";
import { Tab } from "rizzui";
import { TabTypes } from "../patient/dashboard/Dashboard";

type TabProps = {
  renderTabData: (tabKey: string) => React.ReactNode;
  tabListData: TabTypes[];
  activeTab: string;
  currentTab?: (tab: string) => void;
};
const Tabs: FC<TabProps> = ({
  renderTabData,
  tabListData,
  currentTab,
  activeTab,
}) => {
  const [tabList, setTabList] = useState(tabListData);
  const [currentActiveTab, setCurrentActiveTab] = useState<string>(activeTab);

  useEffect(() => {
    if (Object.keys(tabList).length > 0) {
      setTabList(tabList);
      setCurrentActiveTab(activeTab);
    }
    currentTab?.(activeTab);
  }, []);

  const changeTabHandler = (tabKey: string) => {
    currentTab?.(tabKey);
    setCurrentActiveTab(tabKey);
  };

  return (
    <Tab
      hideHoverAnimation
      selectedIndex={tabList.findIndex((tab) => tab.key === currentActiveTab)}
      onChange={(index: number) => changeTabHandler(tabList[index].key)}
    >
      <Tab.List className="border-none gap-5 justify-center mb-3 pb-0">
        {tabList &&
          tabList.map((tab, idx) => (
            <Tab.ListItem
              key={tab.key}
              className={classnames(
                "text-xs font-inter px-0 hover:text-black journal-tab-container",
                currentActiveTab === tab.key
                 ? "text-black tab-active-border before:h-[2.5px]"
                  : "text-[#808A98] before:h-[2.5px] before:absolute before:transition-all before:duration-200  before:w-full before:start-0 before:-bottom-[1px] before:opacity-100 before:bg-[#CFD4DA]"
              )}
              onClick={() => changeTabHandler(tab.key)}
            >
              <span>
                <Squares2X2Icon className="h-4 w-4" />
              </span>
              {tab.value}
            </Tab.ListItem>
          ))}
      </Tab.List>

      <Tab.Panels className="px-4 py-0 mt-0 max-h-[calc(72vh-165px)] min-h-[calc(72vh-165px)] overflow-y-auto scrollbar">
        {tabList?.map((tab, idx) => (
          <Tab.Panel key={idx}>{renderTabData(tab.key)}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab>
  );
};

export default Tabs;

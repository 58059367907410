import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useAuthOLogout from './_hooks/useAuthOLogout';

const defaultTimeout = process.env.REACT_APP_IDLE_TIMEOUT ? parseInt(process.env.REACT_APP_IDLE_TIMEOUT) : 15;
const timeout = defaultTimeout * 60 * 1000;
const promptBeforeIdle = 2 * 60 * 1000;

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
};

const InactivityHandler = () => {

  const [remaining, setRemaining] = useState<number>(timeout);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const { auth0Logout } = useAuthOLogout();

  const handleLogout = async () => auth0Logout();
  
  const onIdle = () => {
    setShowWarning(false);
    handleLogout();
  }

  const onActive = () => setShowWarning(false);

  const onPrompt = () => setShowWarning(true);

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 300
  });

  const handleStayLoggedIn = () => activate();

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  if (showWarning) {
    return (
      <div
        className="fixed shadow-md inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-[10]"
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="inactivity-warning"
        aria-describedby="inactivity-description"
      >
        <div className="bg-slate-50 rounded-lg shadow-lg p-3 max-w-xl w-full">
          <div className="p-6" id="inactivity-description">
            <p className="mb-4 leading-6 text-lg text-gray-700">
              You will be logged out in <strong>{formatTime(remaining)}</strong> minutes due to inactivity.
              Click 'Stay Logged In' to continue your session.
            </p>
            <div className="flex justify-center space-x-4 mt-6">
              <div className="flex justify-center gap-4">
                <button
                  className="text-gray-500 border border-solid border-gray-400 hover:border-gray-800 hover:text-gray-700 px-4 py-2 rounded-md"
                  onClick={handleStayLoggedIn}
                  aria-label="Stay Logged In"
                >
                  Stay Logged In
                </button>
                <button
                  className="bg-green-dark-100 hover:bg-green-dark-200 text-white px-4 py-2 rounded-md"
                  onClick={handleLogout}
                  aria-label="Logout Now"
                >
                  Logout Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <></>;
}

export default InactivityHandler
import Hls from "hls.js";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import VideoPlayer, {
  hlsSupportedExtensions,
} from "../components/video-player/CustomVideoPlayer";
import { Video } from "../redux/video-gallery/VideoGallerySlice";
import { alertNotification } from "./alert/ToastAlert";
import { getVideoDetailsById } from "./requests/_requests";

const PlayVideo = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mediaId = searchParams.get("mediaId");
  const [videoDetails, setVideoDetails] = useState<Video>({
    id: 0,
    subtitles: [],
  });

  const enableHlsSupport = (url: string) => {
    const fileExtension = url?.split(".").pop()?.toLowerCase();
    if (!hlsSupportedExtensions.includes(`.${fileExtension}`)) return;

    const video = document.getElementsByTagName("video")[0];
    if (!Hls.isSupported()) return;

    const hls = new Hls();
    hls.loadSource(url);
    hls.attachMedia(video);
  };

  useEffect(() => {
    const fetchVideo = async () => {
      if (!mediaId) return;
      let decodedMediaId = "";
      try {
        decodedMediaId = atob(mediaId);
      } catch (e) {
        alertNotification("error", "Invalid Media Id");
        return;
      }
      const videoData = await getVideoDetailsById(decodedMediaId);
      if (!videoData || !videoData.media_url) return;
      setVideoDetails(videoData);
      enableHlsSupport(videoData.media_url);
    };

    fetchVideo();
  }, [mediaId]);

  return (
    <div className="bg-lobby bg-cover h-screen">
      <div className="header py-3 w-2/3 m-auto">
        <a target="_blank" href="https://www.beringhealth.com/" rel="noreferrer">
          <img
            src="images/bering_logo.png"
            alt="bering_logo"
            className="inline-block"
            width={150}
          />
        </a>
      </div>

      <div className="w-350-offset mx-auto rounded-lg p-1 bg-gradient-to-b from-slate-900 bg-amber-lighter h-[calc(100vh-62px)]">
        <div className="p-1 bg-black rounded-md h-full relative">
          <div className="rounded-sm overflow-hidden h-full share-video-player-wrap">
            <VideoPlayer
              key={videoDetails.id}
              src={videoDetails?.media_url || ""}
              subtitles={videoDetails.subtitles}
              loop={false}
              autoPlay={true}
            />
          </div>

          <div className="video-control flex justify-center items-center absolute w-full bottom-[7px]">
            <div className="text-center text-white text-sm">
              You are watching "{videoDetails.title}"
            </div>
            {/* <div>
              <a href={videoDetails.download_url}>
                <button
                  type="button"
                  className="px-8 py-3 rounded-full text-white bg-gradient-to-r from-sky-600 to-cyan-300 flex items-center"
                >
                  Download
                  <ArrowDownTrayIcon className="h-5 w-5 ml-2" />
                </button>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayVideo;

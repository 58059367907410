import { BackwardIcon, ForwardIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { FC } from "react";
import { matchRoutes, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/store";

type NavProps = {
  nextFn?: () => void;
  backFn?: () => undefined | boolean | Promise<void>;
  disabled?: { nextBtn?: boolean; backBtn?: boolean };
  backButtonRef?: React.RefObject<HTMLButtonElement>;
  nextButtonRef?: React.RefObject<HTMLButtonElement>;
};

const NavControls: FC<NavProps> = ({ nextFn, backFn, disabled, backButtonRef, nextButtonRef }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activityId = searchParams.get("activityId"); // To get the activityId from queryParam

  const navigate = useNavigate();

  const { appointmentSlug, journeySlug } = useParams<{
    appointmentSlug: string;
    journeySlug: string;
  }>(); // Getting the appointmentSlug and journeySlug from URL

  const currentJourneySlug = useAppSelector(
    (state) => state.journey.currentJourneySlug
  );

  const appointmentProgresses = useAppSelector(
    (state) => state.appointment.data.appontments
  ); // List of Appointments

  const currentJourneyPhaseIndex = appointmentProgresses?.findIndex(
    (phase) => phase?.journey_phase.slug === appointmentSlug
  ); // Get the index of current phase from appointments array

  // Disabling BACK button for the first Phase
  if (currentJourneyPhaseIndex === 0) {
    disabled = { ...disabled, backBtn: true };
  }

  // TO-DO : Discuss  to decide the behaviour
  // Disabling NEXT button for the Last Phase or Last Activity
  // if (currentJourneyPhaseIndex === appointmentProgresses.length - 1) {
  //   const lastActivitiesList =
  //     appointmentProgresses[currentJourneyPhaseIndex]?.journey_phase
  //       .patient_phase_activity;
  //   if (!lastActivitiesList) {
  //     disabled = { nextBtn: true }; // Disabling NEXT button if no activitiy exists in last phase
  //   } else {
  //     const activityIndex = lastActivitiesList.findIndex(
  //       (activity) => activity.id + "" === activityId
  //     );
  //     if (activityIndex === lastActivitiesList.length - 1) {
  //       disabled = { nextBtn: true }; // Disabling NEXT button if last activitiy is reached in last phase
  //     }
  //   }
  // }

  const backNavigationLogic = () => {
    if (currentJourneyPhaseIndex && activityId) {
      const activityIndex = appointmentProgresses[
        currentJourneyPhaseIndex
      ]?.journey_phase?.patient_phase_activity?.findIndex(
        (activity) => activity.id + "" === activityId
      ); // Getting the current activity index
      if (activityIndex > 0) {
        // If the current activity Index is more than 0 then navigate to previous activity
        const previousActivity =
          appointmentProgresses[currentJourneyPhaseIndex]?.journey_phase
            .patient_phase_activity[activityIndex - 1];
        navigate(
          `/${journeySlug}/journey-phase/${appointmentSlug}?activityId=${previousActivity.id}`
        );
      } else if (activityIndex === 0) {
        // If the current activity Index is 0 then navigate to Phase

        navigate(`/${journeySlug}/journey-phase/${appointmentSlug}`);
      }
    } else if (matchRoutes([{ path: "/:journeySlug/profile" }], location.pathname)) {
      // Handled Profile specific navigation
      navigate(`/${currentJourneySlug}/journey-phase/patient-details`);
    } else if (!activityId) {
      // This block will execute on Phase back click
      const previousJourneyPhase =
        appointmentProgresses[currentJourneyPhaseIndex - 1]?.journey_phase;
      if (previousJourneyPhase.patient_phase_activity) {
        // Navigate to Previous phase last activity
        const previousActivity =
          previousJourneyPhase.patient_phase_activity[
          previousJourneyPhase.patient_phase_activity.length - 1
          ];

        navigate(
          `/${journeySlug}/journey-phase/${previousJourneyPhase.slug}?activityId=${previousActivity.id}`
        );
      } else {
        // Navigate to Previous Phase
        navigate(`/${journeySlug}/journey-phase/${previousJourneyPhase.slug}`);
      }
    }
  };

  // Back button click navigation
  const backClick = () => {
    const skipNavigation = backFn?.(); // Calling the function if defined
    if (skipNavigation) return;

    backNavigationLogic();
  };

  return (
    <div className="max-w-[48%] w-full flex justify-between fixed bottom-20 right-0 left-0 mx-auto z-50">
      <button
        type="button"
        disabled={disabled?.backBtn}
        onClick={backClick}
        className={classNames(
          " text-white p-3 rounded-full flex flex-col items-center transition-all duration-500",
          disabled?.backBtn
            ? "bg-black/50 hover:bg-black/75 cursor-not-allowed"
            : "bg-blue-500 hover:bg-blue-700"
        )}
        ref={backButtonRef}
      >
        <BackwardIcon className="h-5 w-5 text-white" />
        BACK
      </button>
      <button
        type="button"
        disabled={disabled?.nextBtn}
        onClick={nextFn}
        className={classNames(
          " text-white p-3 rounded-full flex flex-col items-center transition-all duration-500",
          disabled?.nextBtn
            ? "bg-black/50 hover:bg-black/75 cursor-not-allowed"
            : "bg-green-700 hover:bg-green-800"
        )}
        ref={nextButtonRef}
      >
        <ForwardIcon className="h-5 w-5 text-white" />
        NEXT
      </button>
    </div>
  );
};

export default NavControls;

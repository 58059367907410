import JoditEditor, { IJoditEditorProps, Jodit } from "jodit-react";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

interface EditorProps {
  content: string;
  setJournalText: Dispatch<SetStateAction<string>>;
}

const Editor: FC<EditorProps> = ({ content, setJournalText }) => {
  const editorRef = useRef<Jodit | null>(null);
  const [editorDefaultValue, setEditorDefaultValue] = useState("");

  useEffect(() => {
    if (editorDefaultValue === "") {
      setEditorDefaultValue(content)
    }
  }, [content, editorDefaultValue])

  const config: IJoditEditorProps["config"] = useMemo(
    () => ({
      readonly: false,
      handleDragAndDrop: true,
      toolbar: true,
      textIcons: false,
      placeholder: "",
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "eraser",
        "ul",
        "ol",
        "font",
        "fontsize",
        "paragraph",
        "lineHeight",
        "spellcheck",
        "cut",
        "copy",
        "paste",
        "table",
        "link",
        "indent",
        "outdent",
        "align",
        "brush",
        "undo",
        "redo",
        "find",
        "print",
      ],
      buttonsMD: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "eraser",
        "ul",
        "ol",
        "font",
        "fontsize",
        "paragraph",
        "lineHeight",
        "spellcheck",
        "cut",
        "copy",
        "paste",
        "table",
        "link",
        "indent",
        "outdent",
        "align",
        "brush",
        "undo",
        "redo",
        "find",
        "print",
      ],
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      mediaInFakeBlock: false
    }),
    []
  );

  return (
    <JoditEditor
      className="jodit-editor-class bg-white"
      ref={editorRef}
      value={editorDefaultValue}
      config={config}
      onChange={(newContent) => {
        const editorInstance = editorRef.current;
        if (editorInstance) {
          editorInstance.value = newContent;
          setJournalText(newContent);
        }
      }}
    />
  );
};

export default Editor;

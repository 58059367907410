import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  ComponentProps,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { alertNotification } from "../../core/alert/ToastAlert";
import { days, months, time } from "../../core/constants/date_time";
import { ScheduleVisitPatientProfileDescription } from "../../core/constants/menopause_constants";
import { AppointmentActivity } from "../../core/phase-activity/AppointmentActivityConfig";
import {
  getInPersonVisit,
  getSelectedDoctor,
  makeInPersonVisit,
} from "../../core/requests/_requests";
import NextFooter from "../../providers/LayoutProvider/NextFooter";
import PageWrapper from "../../providers/LayoutProvider/PageWrapper";
import { setBackGroundLoading } from "../../redux/layout/LayoutSlice";
import { AppDispatch, useAppDispatch } from "../../redux/store";
import PatientProfile from "../patient/patient-profile/PatientProfile";
import { DoctorType } from "./Scheduling";

type ScheduleProps = {
  activityDetails: AppointmentActivity;
  handleActivityCompletion?: (
    activityDetails: AppointmentActivity
  ) => void | Promise<void>;
  setHideDefaultNextButton: Dispatch<SetStateAction<boolean>>;
};

const ScheduleAppointment: FC<ScheduleProps> = ({
  activityDetails,
  handleActivityCompletion,
  setHideDefaultNextButton,
}) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const callback = searchParams.get("callback");
  const { journeySlug } = useParams<{
    journeySlug: string;
  }>();

  const [confirmed, setConfirm] = useState<boolean>(false);
  const [verifyPatient, setVerifyPatient] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<number>(-1);
  const [doctor, setDoctor] = useState<DoctorType | null>(null);

  const handleDateChange: ComponentProps<typeof Calendar>["onChange"] = (
    date,
    _event
  ) => {
    if (date == null || Array.isArray(date)) return;
    setSelectedDate(new Date(date));
  };

  const handleSubmit = async () => {
    if (selectedTime === -1) {
      setConfirm(false);
      return;
    }
    await makeInPersonVisit({
      date: selectedDate,
      time: selectedTime,
    });
    if (callback) {
      return navigate(`/${journeySlug}/${callback}`);
    }
    setConfirm(true);
  };

  const handleNext = async () => {
    dispatch(setBackGroundLoading({ status: true }));
    try {
      handleActivityCompletion &&
        (await handleActivityCompletion(activityDetails));
    } catch (error) {
      alertNotification("error", "Something went wrong!");
    } finally {
      dispatch(setBackGroundLoading({ status: false }));
    }
  };
  const handleBack = () => {
    setConfirm(false);
    return true;
  };
  const backVerify = () => {
    setVerifyPatient(true);
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      const inpersonVisit = await getInPersonVisit();
      if (inpersonVisit) {
        setSelectedDate(new Date(inpersonVisit.date));
        setSelectedTime(inpersonVisit.time);
      }
      const doctor = await getSelectedDoctor();
      if (doctor) setDoctor(doctor);
    };
    fetchData();
    if (callback) {
      setVerifyPatient(false);
    }
  }, []);

  useEffect(() => {
    setHideDefaultNextButton(true);
  }, []);

  return (
    <>
      {verifyPatient ? (
        <PatientProfile
          handleVerified={() => setVerifyPatient(false)}
          scheduling={true}
          description={ScheduleVisitPatientProfileDescription}
        />
      ) : (
        <>
          {confirmed ? (
            <PageWrapper nextCallback={handleNext} backCallback={handleBack}>
              <div className="flex flex-col h-full">
                <div className="flex-none">
                  <div className="grid grid-cols-12 gap-4 py-4">
                    <div className="col-start-2 col-end-12 flex justify-center">
                      <h2 className="text-center text-3xl font-semibold">
                        Doctor Appointment Confirmation
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="flex  overflow-y-auto scrollbar mx-2">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-start-2 col-end-12">
                      <p className="mt-12 mb-4 leading-6">
                        Your doctor appointment is set for{" "}
                        <b>
                          {" "}
                          {`${
                            months[selectedDate.getMonth()]
                          } ${selectedDate.getDate()}, ${selectedDate.getFullYear()}`}
                        </b>{" "}
                        at <b>{time[selectedTime]} ET</b>, with{" "}
                        <b>
                          Dr. {doctor?.firstName} {doctor?.lastName}
                        </b>{" "}
                        at the Cleveland Clinic Main Campus.
                      </p>
                      <ul className="space-y-2">
                        <li>
                          <div className="flex align-top">
                            <InformationCircleIcon className=" text-[#00843d] h-8 me-2 " />
                            <p className="mt-1">
                              You can change or cancel appointments by selecting
                              Scheduling from the menu above at any time, up to
                              24 hours before your scheduled visit.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="flex align-top">
                            <InformationCircleIcon className="text-[#00843d] h-9 me-2 " />
                            <p className="mt-2">
                              You will receive an appointment reminder 24 hours
                              before your scheduled doctor visit. We will also
                              followup with next-steps once you have completed
                              your visit.
                            </p>
                          </div>
                        </li>
                      </ul>
                      <br />
                    </div>
                  </div>
                </div>

                <div className="flex-1 overflow-y-auto scrollbar mx-8 px-2"></div>
                <NextFooter />
              </div>
            </PageWrapper>
          ) : (
            <PageWrapper
              nextCallback={handleSubmit}
              backCallback={backVerify}
              disabled={{ nextBtn: selectedTime === -1 }}
            >
              <div className="flex flex-col h-full">
                <div className="flex-none">
                  <div className="grid grid-cols-12 gap-4 py-4">
                    <div className="col-start-2 col-end-12">
                      <div className="flex justify-center flex-col ">
                        <h2 className="text-center text-3xl font-semibold mb-6">
                          Schedule an In-Person Visit
                        </h2>
                        <p className="pl-16 text-base font-medium mb-2 leading-6">
                          Select a date on the calender to view the available
                          times for that day. Then click <br /> on the{" "}
                          <span className="tw-btn-green px-1">time</span> that
                          works best for you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-1 overflow-y-auto scrollbar ">
                  <div className="flex flex-col">
                    <div className="grid grid-cols-12 gap-4 mt-2">
                      <div className="col-start-2 col-end-7 mt-12 ml-10">
                        <Calendar
                          className="w-fit h-fit scale-125"
                          onChange={handleDateChange}
                          value={new Date(selectedDate)}
                          minDate={new Date()}
                        />
                      </div>

                      <div className="col-start-8 col-end-12">
                        <div>
                          <div className="font-semibold text-base text-black py-3 ">
                            {`${days[selectedDate.getDay()]}, ${
                              months[selectedDate.getMonth()]
                            } ${selectedDate.getDate()}, ${selectedDate.getFullYear()}`}
                          </div>

                          <div className="grid grid-cols-1 gap-x-1 gap-y-3">
                            {time.map((time, index) => (
                              <div
                                key={index}
                                className={`flex items-center p-2 rounded-md ${
                                  selectedTime === index
                                    ? "bg-gray-200"
                                    : "bg-transparent"
                                }`}
                              >
                                <button
                                  key={index}
                                  className={`tw-btn py-1.5 px-2 w-26 rounded-xl ${
                                    selectedTime === index
                                      ? "bg-sky-600 hover:bg-sky-500 text-white"
                                      : "tw-btn-green"
                                  }`}
                                  onClick={() => setSelectedTime(index)}
                                >
                                  {time}
                                </button>
                                <span className="ml-5 font-semibold text-xs">
                                  ADDRESS: 9500 EUCLID AVE,
                                  <br />
                                  CLEVELAND, OH 44195 <br />
                                  PHONE: (216) 444-2200 <br />
                                  CLINICIAN: DR.{" "}
                                  {doctor?.firstName?.toUpperCase()}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-14 gap-4 justify-center">
                      <div className="flex items-center gap-2">
                        <div className="w-3 h-3 bg-sky-600" />
                        <span className="text-sm">Selected</span>
                      </div>

                      <div className="flex items-center gap-2">
                        <div className="w-3 h-3 bg-green-700" />
                        <span className="text-sm">Available</span>
                      </div>
                    </div>
                  </div>
                </div>

                <NextFooter />
              </div>
            </PageWrapper>
          )}
        </>
      )}
    </>
  );
};

export default ScheduleAppointment;

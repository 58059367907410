import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from "react";
import ConditionallyRender from "react-conditionally-render";
import useTogglePatientGoal from "../../../../core/_hooks/useTogglePatientGoal";
import { alertNotification } from "../../../../core/alert/ToastAlert";
import {
  getDashboardGoals,
  PatientGoal,
  togglePatientGoal,
  updatePatientGoal,
} from "../../../../redux/dashboard/DashboardSlice";
import { setShowNudge } from "../../../../redux/journals/JournalSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import GoalCard from "../../../goals/GoalCard";
import GoalReminders from "../../../goals/GoalReminders";

interface GoalsProps {
  completeClick: boolean;
  setCompleteClick: Dispatch<SetStateAction<boolean>>;
  setNavActionDisabled: Dispatch<
    SetStateAction<{
      back: boolean;
      forward: boolean;
    }>
  >;
  backClick: boolean;
}

const Goals: FC<GoalsProps> = ({
  completeClick,
  setNavActionDisabled,
  setCompleteClick,
  backClick,
}) => {
  const dispatch = useAppDispatch();
  const goals = useAppSelector(
    (state: RootState) => state.dashboard.goals.data
  );
  const symptomsList = useAppSelector(
    (state: RootState) => state.symptom.symptomsList
  );

  const Patient = useAppSelector((state: RootState) => state.user.authUser);
  const [patientGoals, setPatientGoals] = useState<PatientGoal[]>([]);

  const { toggleSwitch, selectedGoal, targetGoal, setSelectedGoal, setTargetGoal, toggleGoalCard,toggleRequestFlag } = useTogglePatientGoal();

  useEffect(() => {
    if (!goals) return;
    if (!symptomsList) {
      setPatientGoals(goals);
      return;
    }
    const selectedSymptoms = symptomsList
      .filter((itm) => itm?.is_positive)
      .map((itm) => itm?.title);

    if (!selectedSymptoms.length) {
      setPatientGoals(goals);
      return;
    }
    const filteredGoal = goals.filter((item) =>
      item.reportedSymptoms?.some((symptom) =>
        selectedSymptoms.includes(symptom)
      )
    );
    setPatientGoals(filteredGoal.length > 0 ? filteredGoal : goals);
  }, [goals, symptomsList]);

  const NextButtonCallBack = async () => {
    if (targetGoal !== null) {
      if (targetGoal?.notificationDays?.length === 0 || targetGoal?.notificationTimes?.length === 0) {
        const updatedTargetGoal = {
          ...targetGoal,
          uuid: targetGoal.uuid,
          notificationDays: [],
          notificationTimes: [],
          active: false
        }
        await dispatch(updatePatientGoal(updatedTargetGoal));

        alertNotification("warning", targetGoal?.notificationDays?.length === 0 ? "As no day is selected, Goal will now be deactivated" : "As no time is selected, Goal will now be deactivated", "top-right");
      } else {
        await dispatch(updatePatientGoal(targetGoal));
      }
      dispatch(setShowNudge(true));
      await fetchGoals();
      setTargetGoal(null);
      setSelectedGoal(null);
      return;
    }
  };

  const disableNextButton = (): boolean => {
    if (
      targetGoal &&
      targetGoal.notificationDays &&
      targetGoal.notificationTimes
    ) {
      return (
        targetGoal.notificationDays.length === 0 &&
        targetGoal.notificationTimes.length === 0
      );
    }
    return false;
  };

  const BackButtonCallBack = async () => {
    if (!selectedGoal?.notificationDays || !selectedGoal?.notificationTimes || selectedGoal?.notificationDays?.length === 0 || selectedGoal?.notificationTimes?.length === 0) {
      if (selectedGoal && targetGoal?.active) {        
        await dispatch(togglePatientGoal({ journeyId: selectedGoal?.journeyId, journeyGoalId: selectedGoal?.journeyGoalId, id: selectedGoal?.id , uuid: selectedGoal?.uuid}));
      }
    }
    dispatch(setShowNudge(true));
    await fetchGoals();
    setTargetGoal(null);
    setSelectedGoal(null);
  };

  useEffect(() => {
    if (backClick) {
      if (selectedGoal) {
        BackButtonCallBack();
      } else {
        fetchGoals();
        setTargetGoal(null);
        setSelectedGoal(null);
      }
    }
  }, [backClick]);

  useEffect(() => {
    if (completeClick && Patient) {
      NextButtonCallBack().then(async () => {
        setCompleteClick(false);
      });
    }
  }, [completeClick, Patient]);

  useEffect(() => {
    if (targetGoal) {
      setNavActionDisabled({ forward: disableNextButton(), back: false });
    } else {
      setNavActionDisabled({ forward: true, back: true });
    }
  }, [targetGoal]);

  const fetchGoals = async () => {
    if (Patient.current_journey_id) {
      await dispatch(getDashboardGoals(Patient.current_journey_id));
    }
  };

  useEffect(() => {
    if (Patient) {
      fetchGoals();
    }
  }, [Patient]);

  return (
    <div className="flex flex-col w-full h-full overflow-y-auto max-h-[calc(88vh-250px)]">
      <div className="flex-none bg-[#f5f5f5] px-2">
        {!targetGoal && (
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-end-12">
              <div className="flex flex-col px-2.5">
                <h2 className="text-center text-xl font-semibold capitalize">
                  set your daily goals
                </h2>
                <p className="px-4 text-center text-sm font-medium mb-2">
                  Use the toggles to add activities and exercises to your Journey,
                  and get <br /> reminders to stay on track.
                </p>
                <p className="flex justify-center text-center text-sm my-3 capitalize">
                  adjust your goal settings
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex-1 overflow-y-auto scrollbar px-2">
        {targetGoal ? (
          <GoalReminders goal={targetGoal} setTargetGoal={setTargetGoal} />
        ) : (
          <div className="flex flex-col h-full relative bg-[#f5f5f5]">
            <div className="flex-1 overflow-y-auto scrollbar mx-2">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-start-2 col-end-12">
                  <ConditionallyRender
                    condition={patientGoals && patientGoals.length !== 0}
                    show={
                      <div className="flex flex-col">
                        {patientGoals.map((goal, index) => (
                          <GoalCard
                            {...{ goal }}
                            key={index}
                            setAsTargetGoal={() => toggleGoalCard(goal)}
                            toggleSwitchHandler={() =>
                              toggleSwitch(goal)
                            }
                            toggleRequestFlag={toggleRequestFlag}
                          />
                        ))}
                      </div>
                    }
                    elseShow={
                      <div className="bg-white p-8 rounded-xl">
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                          <svg
                            className="h-6 w-6 text-green-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                            ></path>
                          </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <h3
                            className="text-base font-semibold leading-6 text-gray-900"
                            id="modal-title"
                          >
                            Your Personal Goals
                          </h3>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Please select symptoms to set your personal goals.
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Goals;

import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { SketchPicker } from "react-color";
import { alertNotification } from "../../../../core/alert/ToastAlert";
import { createPatientJournal } from "../../../../redux/journals/JournalSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { Patient } from "../../../../redux/user/UserSlice";
import { PatientJournal } from "../../../../types/journal";

const defaultJournalData = { color: "#358738", description: "", name: "" };

type CreateJournalFormProps = {
  completeClick: boolean;
  setCompleteClick: Dispatch<SetStateAction<boolean>>;
  setNavActionDisabled: Dispatch<
    SetStateAction<{
      back: boolean;
      forward: boolean;
    }>
  >;
  hideCreateJournalForm: () => void;
  setCurrentJournalIndex: Dispatch<SetStateAction<number>>;
};

const CreateJournalForm: FC<CreateJournalFormProps> = ({
  completeClick,
  setCompleteClick,
  setNavActionDisabled,
  hideCreateJournalForm,
  setCurrentJournalIndex,
}) => {
  const dispatch = useAppDispatch();

  const patient: Patient = useAppSelector(
    (state: RootState) => state.user.authUser
  );
  const [newJournal, setNewJournal] =
    useState<Omit<PatientJournal, "uuid" | "patientId">>(defaultJournalData);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef<HTMLDivElement | null>(null);
  const systemConfigurations = useAppSelector(
    (state) => state.systemConfigurations.data
  );

  const handleSubmit = async () => {
    if (patient.id) {
      const newPatientJournal = {
        name: newJournal.name,
        color: newJournal.color,
        description: newJournal.description,
      };

      const { payload } = await dispatch(
        createPatientJournal(newPatientJournal)
      );

      if (payload && Array.isArray(payload)) {
        setNewJournal(defaultJournalData);
        hideCreateJournalForm();
        setCompleteClick(false);
        setNavActionDisabled({ forward: true, back: true });

        const journalLimit = systemConfigurations?.MAXIMUM_ALLOWED_JOURNALS + 1;

        setCurrentJournalIndex(payload.length - 1);

        if (payload.length >= journalLimit) {
          alertNotification(
            "warning",
            `You are approaching your journal limit. You can create up to ${journalLimit - 1
            } journals.`
          );
        }

      }
    }

  };

  useEffect(() => {
    if (completeClick && newJournal.name.trim().length > 0) {
      handleSubmit();
    }
  }, [completeClick]);

  useEffect(() => {
    setNavActionDisabled({
      forward: newJournal.name.trim() === "" ? true : false,
      back: false,
    });
  }, [newJournal]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      pickerRef.current &&
      !pickerRef.current.contains(event.target as Node)
    ) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  return (
    <>
      <div className="bg-white p-3 rounded-lg border border-[#C9C8C8] w-full max-h-[calc(72vh-176px)] min-h-[calc(72vh-176px)] journal-tab-container">
        <div className="bg-[#D9D9D9] p-2 rounded-lg w-full max-h-[calc(72vh-202px)] min-h-[calc(72vh-202px)]">
          <h2 className="text-lg font-bold ml-2">Add a New Journal</h2>

          <form className="mt-10 max-h-[calc(72vh-326px)] min-h-[calc(72vh-326px)] overflow-y-auto scrollbar">
            <div className="grid grid-cols-4 gap-4">
              <div className="col-start-1 col-span-1">
                <div className="flex flex-col text-sm text-right gap-10 mt-2">
                  <p>Journal Name</p>
                  <p>Description</p>
                  <p>Color</p>
                </div>
              </div>
              <div className="col-start-2 col-end-4">
                <div className="flex flex-col w-full gap-5">
                  <input
                    type="text"
                    id="journal-name"
                    className="w-full border rounded-md"
                    value={newJournal?.name}
                    onChange={(e) =>
                      setNewJournal({ ...newJournal, name: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    id="journal-description"
                    className="w-full border rounded-md"
                    value={newJournal?.description}
                    onChange={(e) =>
                      setNewJournal({
                        ...newJournal,
                        description: e.target.value,
                      })
                    }
                  />
                  <button
                    type="button"
                    className="h-7 w-9 rounded-md"
                    onClick={() => setShowPicker(!showPicker)}
                    style={{ backgroundColor: newJournal.color }}
                  />
                  {showPicker && (
                    <div
                      ref={pickerRef}
                      style={{ position: "absolute", zIndex: 2 }}
                    >
                      <SketchPicker
                        color={newJournal?.color}
                        onChangeComplete={(color: any) => {
                          setNewJournal((prev) => ({
                            ...prev,
                            color: color.hex,
                          }));
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-start-4 col-span-1"></div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center mt-1">
        <p className="text-base font-semibold">
          Select <span className="text-green-700">Next</span> to save or{" "}
          <span className="text-[#0078BF]">Back </span>
          to cancel.
        </p>
      </div>
    </>
  );
};
export default CreateJournalForm;
import classNames from "classnames";
import { ComponentProps, FC, memo, useRef } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AppointmentActivity } from "../../../core/phase-activity/AppointmentActivityConfig";
import VideoPlayer, {
  CustomEventHandlers,
} from "../../video-player/CustomVideoPlayer";

type VideoActivityProps = {
  activityDetails: AppointmentActivity;
  handleActivityCompletion: (
    activityDetails: AppointmentActivity
  ) => void | Promise<void>;
};

const VideoActivity: FC<VideoActivityProps> = ({
  activityDetails,
  handleActivityCompletion,
}) => {
  const endedRef = useRef<boolean>(false);
  const resetEndedRef = () => {
    endedRef.current = false;
  };

  const HandleNextButtonClick: NonNullable<
    ComponentProps<typeof VideoPlayer>["customVideoEndButton"]
  >["endingEvent"] = async (videoRef, userClick) => {
    if (!endedRef.current) {
      endedRef.current = true;
      if (userClick) {
        videoRef.pause();
      }
      await handleActivityCompletion(activityDetails);
      resetEndedRef();
    }
  };

  const handleVideoActivityEnd = async (videoElem: HTMLVideoElement) => {
    if (!endedRef.current) {
      endedRef.current = true;
      videoElem.pause();
      await handleActivityCompletion(activityDetails);
      resetEndedRef();
    }
  };

  const generateCustomEventHandlers = (): CustomEventHandlers => {
    return {
      ended: (event: Event) => {
        const videoElem = event.target as HTMLVideoElement;
        handleVideoActivityEnd(videoElem);
      },
    };
  };

  // todo: replace with flag on media
  const showTitle =
    activityDetails.phase_activity.media.media_url.match(/rose/i) == null;

  return (
    <div className="px-4 max-w-screen-2xl mx-auto">
      <div className="max-w-screen-md 2xl:max-w-screen-lg overflow-y-auto scrollbar w-full md:pr-5 xl:pr-20 pb-6 pt-4 xl:pt-10">
        <div className="flex flex-col space-y-7 activity-video">
          <div
            className={classNames(
              "fullscreen-video fixed top-0 left-0 w-full h-screen"
            )}
          >
            <VideoPlayer
              key={activityDetails.id}
              src={activityDetails.phase_activity?.media?.media_url || ""}
              customEventHandlers={generateCustomEventHandlers()}
              customVideoEndButton={{
                show: activityDetails?.phase_activity?.is_skippable ?? false,
                //$ buttonText: "NEXT",
                endingEvent: HandleNextButtonClick,
              }}
              subtitles={activityDetails?.phase_activity?.media?.subtitles}
              loop={false}
              autoPlay={true}
              title={
                showTitle
                  ? activityDetails?.phase_activity?.media?.title
                  : undefined
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(VideoActivity);

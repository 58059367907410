import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { ComponentProps, useEffect, useState } from "react";
import ConditionallyRender from "react-conditionally-render";
import { useParams } from "react-router-dom";
import { Button, Textarea } from "rizzui";
import { USDateformat } from "../../../../core/DateFormat";
import {
  Journal,
  createDashboardJournalSurvey,
  getAllDashboardJournalSurvey,
  updateDashboardJournal,
} from "../../../../redux/dashboard/DashboardSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import GenerateSurveyModal from "../../../survey/GenerateSurveyModal";
import ActivitySkeleton from "../ActivitySkeleton";

function MenopauseJournal() {
  const dispatch = useAppDispatch();
  const systemConfigurations: any = useAppSelector(
    (state: RootState) => state.systemConfigurations.data
  );
  const PatientJournal = useAppSelector(
    (state: RootState) => state.dashboard.journal
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const { journeySlug } = useParams<{ journeySlug: string }>();
  const [editJournal, setEditJournal] = useState<Journal | null>(null);
  const [journalUpdating, setJournalUpdating] = useState<boolean>(false);

  const setJournals = async (): Promise<void> => {
    await dispatch(getAllDashboardJournalSurvey(journeySlug));
  };

  useEffect(() => {
    setJournals();
  }, []);

  const handleJournalSurveyEnd: ComponentProps<
    typeof GenerateSurveyModal
  >["onComplete"] = async (data) => {
    await dispatch(
      createDashboardJournalSurvey({
        ...data,
        journey_slug: journeySlug,
      })
    );
    await setJournals();
    setShowModal(false);
  };

  const onChangeHandler = (e: any) => {
    const newValue = e.target.value;

    setEditJournal((prevJournal) => {
      if (prevJournal) {
        return {
          ...prevJournal,
          journal_external_data: {
            title: newValue,
          },
        };
      }
      return null;
    });
  };

  const updateJournal = () => {
    setJournalUpdating(true);

    try {
      dispatch(
        updateDashboardJournal({
          id: editJournal?.id,
          data: {
            journey_slug: journeySlug,
            title: editJournal?.journal_external_data?.title,
          },
        })
      ).then(async (res: any) => {
        setEditJournal(null);
        setJournalUpdating(false);
      });
    } catch (err) {
      console.log(err);
      setJournalUpdating(false);
    }
  };

  const noDataFound = PatientJournal?.data?.length === 0;

  if (PatientJournal?.loading) {
    return <ActivitySkeleton />;
  }

  return (
    <>
      <div className="flex gap-4 justify-end mb-3 mr-2">
        <button
          className="tw-btn tw-btn-green block h-[26px] text-xs py-1 px-2"
          onClick={() => setShowModal(true)}
        >
          New Entry
        </button>
      </div>
      <ConditionallyRender
        condition={noDataFound}
        show={
          <div className="bg-white/80 p-8 rounded-xl mt-4">
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <svg
                className="h-6 w-6 text-green-600"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                ></path>
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Start Tracking Your Daily Health Habits Now!
              </h3>
              <div className="my-2">
                <p className="text-sm text-gray-800">
                  Take control of your health by journaling your daily
                  experiences and feelings as you navigate through this
                  transformative phase of life.
                </p>
                <br />
                <p className="text-sm text-gray-800">
                  Documenting your journey will not only help you track progress
                  but also provide valuable insights for yourself and your
                  healthcare provider.
                </p>
              </div>
            </div>
          </div>
        }
        elseShow={
          <>
            <div className="grid grid-cols-1 gap-6 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar px-2">
              {PatientJournal.data &&
                PatientJournal.data?.map((journal: Journal, i: number) => (
                  <div className="bg-white p-5 rounded-xl ">
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <div className="flex flex-col mb-3">
                          <h2 className="text-xl font-medium mb-1">Journal</h2>
                          <p className="text-xs text-black/50 font-medium mb-1">
                            {journal?.journal_date &&
                              USDateformat(journal.journal_date)}
                          </p>
                        </div>
                        <div className="inline-block">
                          <PencilSquareIcon
                            onClick={() => setEditJournal(journal)}
                            className="h-5 w-5 flex-none self-start cursor-pointer text-green-700"
                          />
                        </div>
                      </div>
                      {editJournal && editJournal.id === journal?.id ? (
                        <div className="flex flex-col">
                          <Textarea
                            placeholder="Write you message..."
                            textareaClassName="text_area_scrollbar"
                            className="[&>label>span>textarea]:border-black mb-4"
                            defaultValue={
                              editJournal?.journal_external_data?.title
                                ? editJournal?.journal_external_data?.title
                                : ""
                            }
                            onChange={onChangeHandler}
                          />

                          <div className="flex gap-4 justify-end">
                            <Button
                              isLoading={journalUpdating}
                              className="uppercase bg-green-700"
                              onClick={updateJournal}
                            >
                              Save
                            </Button>
                            <button
                              type="button"
                              className="tw-btn bg-red-600 text-white block uppercase"
                              onClick={() => setEditJournal(null)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          {journal?.journal_external_data?.title && (
                            <p className="text-base text-[#1D1D1D]">
                              {journal?.journal_external_data?.title}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </>
        }
      />
      {showModal &&
        systemConfigurations?.ADD_MENOPAUSE_JOURNAL_SURVEY_FORM
          ?.extra_information && (
          <GenerateSurveyModal
            surveyData={
              systemConfigurations?.ADD_MENOPAUSE_JOURNAL_SURVEY_FORM
                ?.extra_information
            }
            showSurveyModal={showModal}
            // external_data={activityDetails.external_data}
            onClose={() => setShowModal(false)}
            onComplete={handleJournalSurveyEnd}
          />
        )}
    </>
  );
}

export default MenopauseJournal;

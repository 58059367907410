import classNames from "classnames";
import Hls from "hls.js";
import { FC, useEffect, useState } from "react";
import placeholder_img from "../../Images/placeholder_img.jpg";
import { Video } from "../../redux/video-gallery/VideoGallerySlice";
import { Subtitle } from "../../types/video";
import { hlsSupportedExtensions } from "../video-player/CustomVideoPlayer";

const IntroGalleryVideo: FC<{
  video: Video;
  renderAbsoluteIcon: (video: Video) => void;
  onClick: () => void;
}> = ({ video, renderAbsoluteIcon, onClick }) => {

  const [videoLoaded, setVideoLoaded] = useState(false);

  const addSubtitlesToVideo = (
    videoElement: HTMLVideoElement,
    subtitles: Subtitle[]
  ) => {
    const existingTracks = videoElement.querySelectorAll("track");
    existingTracks.forEach(track => track.remove());

    subtitles.forEach((caption, i) => {
      const track = document.createElement("track");
      track.kind = "subtitles";
      track.label = caption.language;
      track.srclang = caption.lang;
      track.src = caption.url;
      track.default = i === 0;
      videoElement.appendChild(track);
    });
  };

  useEffect(() => {
    const videoElement = document.getElementById(
      `video-${video.id}`
    ) as HTMLVideoElement;

    const wrapElement = document.getElementById(
      `wrap-${video.id}`
    ) as HTMLDivElement;

    if (video?.media_url) {
      const fileExtension = video?.media_url.split(".").pop()?.toLowerCase();

      if (videoElement) {
        if (fileExtension && hlsSupportedExtensions.includes(`.${fileExtension}`)) {
          const hls = new Hls();
          hls.loadSource(video?.media_url);
          hls.attachMedia(videoElement);
          hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            setVideoLoaded(true);
          });
        } else {
          videoElement.src = video?.media_url
          videoElement.addEventListener("loadeddata", () => {
            setVideoLoaded(true);
          });
        }
      }
    }

    addSubtitlesToVideo(videoElement, video?.subtitles);

    const handleMouseOver = async () => {
      const isPlaying = videoElement.currentTime > 0 && !videoElement.paused && !videoElement.ended
        && videoElement.readyState > videoElement.HAVE_CURRENT_DATA;

      if (videoLoaded && !isPlaying) {
        await videoElement.play().catch(_ => {
          // Do nothing
        });
      }
    };

    wrapElement?.addEventListener("mouseover", async () => {
      await handleMouseOver()
    });

    wrapElement?.addEventListener("mouseout", (e) => {
      const relatedTarget = e.relatedTarget as HTMLElement;
      if (!wrapElement.contains(relatedTarget)) {
        videoElement.pause();
        videoElement.currentTime = 0;
        setVideoLoaded(false);
      }
    });

    return () => {
      wrapElement?.removeEventListener("mouseover", async () => {
        await handleMouseOver();
      });

      wrapElement?.removeEventListener("mouseout", (e) => {
        const relatedTarget = e.relatedTarget as HTMLElement;
        if (!wrapElement.contains(relatedTarget)) {
          videoElement.pause();
          videoElement.currentTime = 0;
          setVideoLoaded(false);
        }
      });
    };
  }, [video, videoLoaded]);

  return (
    <div id={`wrap-${video.id}`}>
      <div className="relative">
        <img
          src={video?.poster_url ? video?.poster_url : placeholder_img}
          alt={"doctor.first_name"}
          className={classNames(
            "card-image top-0 left-0 w-full transition-all duration-500 h-[157px] object-cover"
          )}
        />
        <video
          poster={video?.poster_url ? video?.poster_url : placeholder_img}
          id={`video-${video.id}`}
          src={video?.media_url}
          className={classNames(
            "hover-video absolute w-full top-0 left-0 opacity-0 group-hover:opacity-100 transition-all object-cover duration-700 h-[157px]"
          )}
          loop
          controls={false}
          muted
          crossOrigin="anonymous"
        />
        <div
          className="absolute top-0 bottom-0 left-0 right-0 bg-transparent z-[1]"
          onClick={() => onClick()}
        />

        <div className="absolute top-0 right-0 z-[11]">
          <>{renderAbsoluteIcon(video)}</>
        </div>
      </div>
    </div>
  );
};
export default IntroGalleryVideo;

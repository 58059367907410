import { PlusIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

const JournalCreateButton: FC<{ handleJournalCreate: () => void }> = ({ handleJournalCreate }) => {
  return (
    <div className="flex justify-end absolute right-[38px] top-[73px] journal-tab-container">
      <button
        className="tw-btn flex items-center text-[#808A98] text-[16px] font-normal p-0 capitalize"
        onClick={handleJournalCreate}
      >
        <PlusIcon className="h-4 w-4 mb-[3px]" />
        New Journal
      </button>
    </div>
  )
}

export default JournalCreateButton

import classnames from "classnames";
import {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Tab } from "rizzui";
import { getDashboardTabsData } from "../../../core/requests/_requests";
import PageWrapper from "../../../providers/LayoutProvider/PageWrapper";
import {
  allPatientJournalEntries,
  allPatientJournals,
  createPatientJournal,
} from "../../../redux/journals/JournalSlice";
import { setShowModal } from "../../../redux/layout/LayoutSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { Patient } from "../../../redux/user/UserSlice";
import { JournalEntry } from "../../../types/journal";
import ActivitySkeleton from "./ActivitySkeleton";
import Goals from "./goals/Goals";
import GoalTabTitle from "./GoalTabTitle";
import Journal, { initialJournal } from "./journal/Journal";
import JourneyStatus from "./journey-status/JourneyStatus";
import MenopauseJournal from "./menopause-journal/MenopauseJournal";
import Notes from "./notes/Notes";
import Overview from "./overview/Overview";
import Homework from "./survey-history/Homework";
import Summaries from "./survey-history/Summaries";
import SymptomTracker from "./symptom-tracker/SymptomTracker";

export interface TabTypes {
  key: string;
  value: string;
  journey_id?: number;
}

export interface ISummaryTab {
  page: number;
  activeTab: string;
}

const ParentTabs = {
  Summary: "summaries",
  Journal: "journal",
  Goals: "goals",
  Symptoms: "symptoms",
};

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { journeySlug } = useParams<{ journeySlug: string }>();
  const navigate = useNavigate();
  const [searchParams]: any = useSearchParams();

  const [activeTab, setActiveTab] = useState<string>("");
  const [journalEntryToEdit, setJournalEntryToEdit] = useState<
    JournalEntry | undefined
  >(undefined);
  const [summaryActivePage, setSummaryActivePage] = useState<ISummaryTab[]>([]);
  const [nestedActiveTab, setNestedActiveTab] = useState<string>("");
  const patient: Patient = useAppSelector(
    (state: RootState) => state.user.authUser
  );

  const [navActionDisabled, setNavActionDisabled] = useState<{
    back: boolean;
    forward: boolean;
  }>({ back: true, forward: true });

  const [completeClick, setCompleteClick] = useState<boolean>(false);
  const [tabList, setTabList] = useState<TabTypes[]>([]);

  const changeTabHandler = (tabKey: string) => {
    setNavActionDisabled({ back: true, forward: true });
    setActiveTab(tabKey);
    localStorage.setItem("dashboard_activeTab", tabKey);
  };

  const removeParamFromUrl = (paramKey: string) => {
    const url = new URL(window.location.href);
    url.searchParams.delete(paramKey);
    navigate(url.pathname + url.search, { replace: true });
  };

  const getTabDataHandler = useCallback(
    async (journeySlug?: string) => {
      const res = await getDashboardTabsData(journeySlug);
      setTabList(res);

      if (
        !searchParams.get("activeTab") &&
        !localStorage.getItem("dashboard_activeTab")
      ) {
        setActiveTab(res[0]?.key);
      }
    },
    [searchParams]
  );
  useEffect(() => {
    dispatch(setShowModal(true));
  }, []);
  useEffect(() => {
    if (searchParams.get("activeTab")) {
      setActiveTab(searchParams.get("activeTab"));

      localStorage.setItem(
        "dashboard_activeTab",
        searchParams.get("activeTab")
      );
      removeParamFromUrl("activeTab");
    } else if (localStorage.getItem("dashboard_activeTab")) {
      const activeTab: any = localStorage.getItem("dashboard_activeTab");
      setActiveTab(activeTab);
    }
  }, [searchParams]);

  useEffect(() => {
    if (journeySlug) {
      getTabDataHandler(journeySlug);
    }
  }, [journeySlug]);

  const gotoJournalTab = (editEntry?: JournalEntry) => {
    if (editEntry) {
      setJournalEntryToEdit(editEntry);
    }
    setActiveTab("journal");
  };
  const gotoSymptomsTab = () => {
    setActiveTab("symptoms");
  };

  const onChangeTab = (activeNestedTab: string) => {
    setNestedActiveTab(activeNestedTab);
    setSummaryActivePage((prev) => {
      const updatedPages = prev.map((a) =>
        a.activeTab === activeNestedTab ? { ...a, page: 0 } : { ...a }
      );
      if (!updatedPages.some((a) => a.activeTab === activeNestedTab)) {
        updatedPages.push({ activeTab: activeNestedTab, page: 0 });
      }
      return updatedPages;
    });
  };

  const handleCompletion = (activeNestedTab: string) => {
    setSummaryActivePage((prev) => {
      const updatedPages = prev.map((a) =>
        a.activeTab === activeNestedTab ? { ...a, page: 0 } : { ...a }
      );
      if (!updatedPages.some((a) => a.activeTab === activeNestedTab)) {
        updatedPages.push({ activeTab: activeNestedTab, page: 0 });
      }
      return updatedPages;
    });
  };

  const handleBackNav = () => {
    switch (activeTab) {
      case ParentTabs.Summary:
        setSummaryActivePage((prev) => {
          return prev.map((a) => {
            if (a.activeTab === nestedActiveTab) {
              const newPage = a.page - 1;
              return { ...a, page: Math.max(newPage, 0) };
            } else {
              return { ...a };
            }
          });
        });
        break;
      case ParentTabs.Journal:
        setCompleteClick(false);
        setNavActionDisabled({ back: true, forward: true });
        break;
      case ParentTabs.Goals:
        setNavActionDisabled({ back: true, forward: true });
        setCompleteClick(false);
        break;
      case ParentTabs.Symptoms:
        setNavActionDisabled({ back: true, forward: true });
        setCompleteClick(false);
        break;
      default:
        return true;
    }
    return true;
  };

  const handleNextNav = () => {
    switch (activeTab) {
      case ParentTabs.Summary:
        setSummaryActivePage((prev) => {
          return prev.map((a) =>
            a.activeTab === nestedActiveTab
              ? { ...a, page: a.page + 1 }
              : { ...a }
          );
        });
        break;
      case ParentTabs.Journal:
        setCompleteClick(true);
        break;
      case ParentTabs.Goals:
        setCompleteClick(true);
        break;
      case ParentTabs.Symptoms:
        setCompleteClick(true);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (patient.id) {
      dispatch(allPatientJournals()).then(async (response) => {
        // If no journals exist for the patient (empty response payload), create a new default journal.
        if ((response.payload as []).length === 0) {
          await dispatch(createPatientJournal({ ...initialJournal }));
        }
      });
      dispatch(allPatientJournalEntries());
    }
  }, [patient.id, dispatch]);

  const getActivePageObj = useMemo(() => {
    return summaryActivePage.find((p) => p.activeTab === nestedActiveTab);
  }, [nestedActiveTab, summaryActivePage]);

  const renderTabData = (tabKey: string): ReactNode => {
    switch (tabKey) {
      case "overview":
        return <Overview gotoJournalTab={gotoJournalTab} gotoSymptomsTab={gotoSymptomsTab}/>;

      case "symptoms":
        return <SymptomTracker {...{ completeClick, setCompleteClick, setNavActionDisabled }} />

      case "journal":
        return (
          <Journal
            {...{
              completeClick,
              setCompleteClick,
              setNavActionDisabled,
              journalEntryToEdit,
              setJournalEntryToEdit,
            }}
            backClick={navActionDisabled.back}
          />
        );

      case "notes":
        return (
          <Suspense fallback={<ActivitySkeleton />}>
            <Notes />
          </Suspense>
        );

      case "goals":
        return (
          <Goals
            {...{ completeClick, setCompleteClick, setNavActionDisabled }}
            backClick={navActionDisabled.back}
          />
        );

      case "summaries":
        return (
          <Suspense fallback={<ActivitySkeleton />}>
            <Summaries
              onChangeTab={onChangeTab}
              onComplete={handleCompletion}
              activePage={getActivePageObj}
            />
          </Suspense>
        );

      case "homework":
        return (
          <Suspense fallback={<ActivitySkeleton />}>
            <Homework />
          </Suspense>
        );

      case "journey-status":
        return <JourneyStatus />;

      case "menopause-journal":
        return <MenopauseJournal />;

      default:
        return <>No Tab Found !</>;
    }
  };

  return (
    <PageWrapper
      nextCallback={handleNextNav}
      backCallback={handleBackNav}
      disabled={{
        backBtn:
          navActionDisabled.back &&
          (getActivePageObj === undefined ||
            getActivePageObj?.page === 0 ||
            ![ParentTabs.Summary].includes(activeTab)),
        nextBtn:
          navActionDisabled.forward &&
          (getActivePageObj?.page === undefined ||
            ![ParentTabs.Summary].includes(activeTab)),
      }}
      closeable
    >
      {tabList && tabList?.length > 0 && (
        <Tab
          hideHoverAnimation
          selectedIndex={tabList.findIndex((tab) => tab.key === activeTab)}
          onChange={(index: number) => changeTabHandler(tabList[index].key)}
          className="flex flex-col h-full bg-[#f5f5f5]"
        >
          <Tab.List className="border-none border-0 gap-0 justify-center flex-none dashboard-tab-container">
            {tabList.map((tab, idx) => (
              <Tab.ListItem
                key={tab.key}
                className={classnames(
                  "text-base font-inter px-4 before:h-[2px] before:w-[100%] before:start-[0px] before:top-11 hover:text-black",
                  activeTab === tab.key
                    ? "text-[#252E3B] before:bg-[#0178BF]"
                    : "dashboard-all-tabs text-[#808A98] flex items-center justify-center"
                )}
                onClick={() => changeTabHandler(tab.key)}
              >
                {tab.value === "Goals" ? <GoalTabTitle /> : tab.value}
              </Tab.ListItem>
            ))}
          </Tab.List>

          <Tab.Panels className="px-4 mt-2 flex-1 overflow-y-auto scrollbar mx-1">
            {tabList?.map((tab, idx) => (
              <Tab.Panel key={idx} className={"px-4"}>
                {renderTabData(tab.key)}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab>
      )}
    </PageWrapper>
  );
};

export default Dashboard;

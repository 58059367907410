import { FC, useEffect, useState } from "react";
import ConditionallyRender from "react-conditionally-render";
import { useLocation, useParams } from "react-router-dom";
import { Model } from "survey-core";
import { AppointmentActivity } from "../../../core/phase-activity/AppointmentActivityConfig";
import { AppointmentProgress, JourneyStatus } from "../../../redux/appointment/AppointmentSlice";
import {
  setCurrentActivityId,
  setCurrentPhaseId,
  setTransitionEffect,
} from "../../../redux/current-phase/CurrentPhaseSlice";
import { setBackGroundImage } from "../../../redux/layout/LayoutSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import PausedJourneyAlert from "./PausedJourneyAlert";
import VerifiedJourneyAppointment from "./VerifiedJourneyAppointment";

const JourneyAppointment: FC = () => {
  const { appointmentSlug } = useParams<{ appointmentSlug: string }>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activityId = searchParams.get("activityId");
  const patientJourneyStatus = useAppSelector((state: RootState) => state.appointment.data.journey_status);
  const [pausedJourneyModelData, setPausedJourneyModelData] = useState<Model | null>(null);
  const systemConfigurations: any = useAppSelector((state: RootState) => state.systemConfigurations.data);

  const appointmentProgresses: AppointmentProgress[] = useAppSelector(
    (state: RootState) => state.appointment.data.appontments
  );
  const currentAppointmentId: string = useAppSelector(
    (state: RootState) => state.currentPhase.id
  );

  const CurrentPhaseData: AppointmentProgress | undefined =
    appointmentProgresses?.find(
      (appointment: AppointmentProgress) =>
        appointment.id === currentAppointmentId
    );
  const CurrentPhaseActivities: AppointmentActivity[] | [] =
    CurrentPhaseData?.journey_phase.patient_phase_activity || [];

  const setAppointmentData = async (slug?: string) => {
    const CurrentPhase = appointmentProgresses?.find(
      (appointment: AppointmentProgress) =>
        appointment.journey_phase.slug === slug
    );
    if (CurrentPhase) {
      dispatch(setCurrentPhaseId(CurrentPhase.id));
      CurrentPhase.journey_phase?.background_image &&
        dispatch(
          setBackGroundImage({
            imageSource: CurrentPhase.journey_phase?.background_image,
          })
        );
    }
  };

  const setFirstActivityAsCurrent = () => {
    const firstActivity =
      CurrentPhaseActivities?.length > 0 ? CurrentPhaseActivities[0] : null;
    if (firstActivity) {
      dispatch(setCurrentActivityId(firstActivity.id.toString()));
    } else {
      dispatch(setCurrentActivityId('0'));
    }
  };

  useEffect(() => {
    if (!activityId) {
      dispatch(
        setTransitionEffect({ showing: true, phaseSlug: appointmentSlug })
      );
    }
    if (appointmentProgresses?.length > 0) {
      setAppointmentData(appointmentSlug);
    }
  }, [appointmentSlug, appointmentProgresses?.length, activityId]);

  useEffect(() => {
    if (activityId) {
      dispatch(setTransitionEffect({ showing: false }));
      dispatch(setCurrentActivityId(activityId));
    } else {
      setFirstActivityAsCurrent();
    }
  }, [activityId]);

  useEffect(() => {
    if (patientJourneyStatus?.status == JourneyStatus.PAUSED) {
      setPausedJourneyModelData(systemConfigurations?.PAUSE_JOURNEY_SURVEY_FORM?.extra_information);
    }
  }, [patientJourneyStatus?.status, systemConfigurations]);

  const showPausedAlert = (): boolean => {
    if (patientJourneyStatus?.status !== JourneyStatus.PAUSED) return false;

    const pausedJourneySlug = patientJourneyStatus.pausedJourneyDTO?.slug;
    const pausedAppointmentIndex = appointmentProgresses.findIndex(appointmentProgress => appointmentProgress.journey_phase.slug === pausedJourneySlug);
    const currentAppointmentIndex = appointmentProgresses.findIndex(appointmentProgress => appointmentProgress.journey_phase.slug === appointmentSlug);

    return currentAppointmentIndex >= pausedAppointmentIndex;
  };

  return (
    <ConditionallyRender
      condition={showPausedAlert()}
      show={<PausedJourneyAlert pausedJourneyModelData={pausedJourneyModelData} setPausedJourneyModelData={setPausedJourneyModelData} />}
      elseShow={<>{appointmentProgresses.length > 0 && < VerifiedJourneyAppointment />}</>}
    />
  )

};

export default JourneyAppointment;

import { FC, useCallback, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { alertNotification } from "../../../core/alert/ToastAlert";
import {
  AppointmentActivity,
  AppointmentActivityTypes,
  PhaseActivityStatus,
} from "../../../core/phase-activity/AppointmentActivityConfig";
import { getPatientJourneyPhaseProgressComplete } from "../../../core/requests/_requests";
import { callWithDelay } from "../../../core/utilities";
import {
  AppointmentProgress,
  fetchPatientJourneyProgresses,
  markActivityAsCompleted,
  setAppointments,
} from "../../../redux/appointment/AppointmentSlice";
import {
  setTransitionEffect,
  transitionEffectDataType,
} from "../../../redux/current-phase/CurrentPhaseSlice";
import { JOURNEY_SLUGS } from "../../../redux/journey/JourneySlice";
import {
  setBackGroundLoading,
  setShowChatBot,
} from "../../../redux/layout/LayoutSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import KegelTool from "../../kegel-tool/KegelTool";
import PatientDetails from "../../patient/dialouge-boxes/patient-details/PatientDetails";
import DoctorListing from "../../patient/doctor-listing/DoctorListing";
import MedicationReview from "../../prescription/MedicationReview";
import ScheduleAppointment from "../../schedule/ScheduleAppointment";
import SymptomChecker from "../../symptom-checker/SymptomChecker";
import SurveyActivity from "../appointment-activities/SurveyActivity";
import GoalActivity from "../goal-activity/GoalActivity";
import PhaseTransition from "../phase-transition/PhaseTransition";
import VideoActivity from "../video-activity/VideoActivity";

//$ interface CompletionData {
//$   flag?: string;
//$   last_menstrual_period: string;
//$   have_ovaries: boolean;
//$   [key: string]: unknown; // for other properties
//$ }
const VerifiedJourneyAppointment: FC = () => {
  const [hideDefaultNextButton, setHideDefaultNextButton] =
    useState<boolean>(false);
  const { appointmentSlug, journeySlug } = useParams<{
    appointmentSlug: string;
    journeySlug: string;
  }>();
  const dispatch: AppDispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  //$ const treatments: Treatment[] = useAppSelector(
  //$   (state: RootState) => state.treatments.data
  //$ );
  const appointmentProgresses: AppointmentProgress[] = useAppSelector(
    (state: RootState) => state.appointment.data.appontments
  );
  const currentAppointmentId: string = useAppSelector(
    (state: RootState) => state.currentPhase.id
  );
  const currentActivityId: string = useAppSelector(
    (state: RootState) => state.currentPhase.currentActivityId
  );
  const transitionEffect: transitionEffectDataType = useAppSelector(
    (state: RootState) => state.currentPhase.transitionEffect
  );
  const currentJourneySlug: string = useAppSelector(
    (state: RootState) => state.journey.currentJourneySlug
  );
  const [emailFlag, setEmailFlag] = useState(false);

  const CurrentPhaseData: AppointmentProgress | undefined =
    appointmentProgresses.find(
      (appointment: AppointmentProgress) =>
        appointment.id == currentAppointmentId
    );
  const CurrentPhaseActivities: AppointmentActivity[] | [] =
    CurrentPhaseData?.journey_phase.patient_phase_activity || [];
  const CurrentActivity: AppointmentActivity | undefined =
    CurrentPhaseActivities?.find(
      (activity: AppointmentActivity) => activity.id === currentActivityId
    );

  const {
    DOCTOR_SELECTION,
    VIDEO,
    PAGE,
    SURVEY,
    SUMMARY,
    TASK,
    DIRECTION,
    JOURNAL,
    QUESTIONNAIRE,
    TAKEAWAYS,
    GUIDANCE,
    HOMEWORK,
    DOCTOR_VIDEO,
    SYMPTOM_CHECKER,
    PATIENT_INFORMATION,
    MEDICATION_REVIEW,
    SCHEDUAL_APPOINTMENT,
    PATIENT_GOAL,
    KEGEL_TIMER,
  } = AppointmentActivityTypes;
  const validTypes = [
    SYMPTOM_CHECKER,
    DOCTOR_SELECTION,
    DOCTOR_VIDEO,
    VIDEO,
    PAGE,
  ];
  const showNextButton =
    CurrentPhaseData &&
    !transitionEffect.showing &&
    !hideDefaultNextButton &&
    !validTypes.includes(CurrentActivity?.phase_activity.type) &&
    (([DOCTOR_VIDEO, VIDEO].includes(CurrentActivity?.phase_activity.type) &&
      CurrentActivity?.status === 1) ||
      CurrentActivity?.phase_activity.type === PAGE);
  const patient = useAppSelector((state: RootState) => state.user.authUser);

  const redirectToAppointment = (
    slug: string,
    appointments: AppointmentProgress[]
  ) => {
    const slugExists = appointments.some(
      (appointment) =>
        appointment.journey_phase && appointment.journey_phase.slug === slug
    );

    const navPath = slugExists
      ? `/${journeySlug}/journey-phase/${slug}`
      : `/${journeySlug}/dashboard`;

    navigate(navPath);
  };

  const HandleActivityEnd = async (
    activity: AppointmentActivity,
    completionData?: unknown
  ) => {
    const shareFlag = Array.isArray(completionData) ? completionData.find((item: { name: string; }) => item.name === "SHARE_FLAG" || item.name === "NEXT_STEPS_SHARE_FLAG") : null;
    const reFetchData = async () => {
      await dispatch(
        fetchPatientJourneyProgresses({
          journeyId: patient.current_journey_id,
        })
      );
    };

    const playNextUnSkippedActivity = async (
      phasesActivities: AppointmentActivity[],
      ignoreStatusCheck: boolean = false
    ) => {
      const currentIndex = phasesActivities.findIndex(
        (a) => a.id === activity.id
      );
      const nextActivity = phasesActivities.find((a, index) => {
        if (ignoreStatusCheck) {
          return index > currentIndex;
        } else {
          return (
            index > currentIndex && a.status !== PhaseActivityStatus.SKIPPED
          );
        }
      });
      navigate(
        `/${currentJourneySlug}/journey-phase/${appointmentSlug}?activityId=${nextActivity ? nextActivity.id : CurrentPhaseActivities[0].id
        }`
      );
    };

    // todo: RESTORE MENOPAUSE FLAG LOGIC (PLF-46)
    //$ // This method is for Getting the flag value based on the answers given to the questionnair by the patient in Menopause journey
    //$ const getMenopauseConditionalFlag = (completionData: CompletionData) => {
    //$   let flag;
    //$   if (completionData?.have_ovaries === false) {
    //$     // If patient doesn't have Overies
    //$     flag = MENOPAUSE_FLAG.SURGERY_MENO;
    //$   } else if (completionData?.last_menstrual_period) {
    //$     // Getting the Patient's Age
    //$     const patientAge = patient.birthdate ? getAge(patient.birthdate) : 0;
    //$     // Getting the number of days since the Patient's last menstrual period
    //$     const daysDiff = calculateDaysInBetween(
    //$       new Date(completionData?.last_menstrual_period),
    //$       new Date()
    //$     );
    //$     if (daysDiff > MENO_LIMIT_DAYS) {
    //$       // If last menstrual days are greater than 352
    //$       if (patientAge < AGE_FOR_MENOPAUSE) {
    //$         flag = MENOPAUSE_FLAG.EARLY_MENO;
    //$       } else {
    //$         flag = MENOPAUSE_FLAG.MENO;
    //$       }
    //$     }
    //$   }
    //$   completionData.flag = flag;
    //$   return JSON.stringify(completionData);
    //$ };

    //$ // type check to ensure data matches `CompletionData` interface
    //$ const isCompletionData = (data: unknown): data is CompletionData => {
    //$   return (
    //$     typeof data === "object" &&
    //$     data !== null &&
    //$     "last_menstrual_period" in data &&
    //$     typeof data.last_menstrual_period === "string"
    //$   );
    //$ };
    try {
      dispatch(
        setBackGroundLoading({
          status: true,
          loadingMessage: "Submitting Activity",
        })
      );

      // todo: RESTORE MENOPAUSE FLAG LOGIC (PLF-46)
      //$ /** Menopause Conditional Logic Requirement Block Starts*/
      //$ if (journeySlug === "menopause" && typeof completionData === "string") {
      //$   const parsedData = JSON.parse(completionData) as unknown;
      //$   if (isCompletionData(parsedData)) {
      //$     completionData = getMenopauseConditionalFlag(parsedData);
      //$   }
      //$ }
      //$ /** Menopause Conditional Logic Requirement Block Ends*/
      let completeData: any = completionData; //todo: remove `any` declarations
      if (
        journeySlug === "menopause" &&
        activity?.phase_activity?.frontier_data?.pages[0].name ===
        "patient-journey-recommendation"
      ) {
        if (completeData.length < 1 || !Array.isArray(completeData)) return;
        completeData[0].answer = (completionData as any)?.[0]?.answer?.filter(
          (a: number | string) => typeof a !== "string"
        );
      }
      await dispatch(
        markActivityAsCompleted({
          activityId: activity.id,
          phaseSlug: appointmentSlug || "",
          data: completeData,
          isExternalData:
            completeData && Object.keys(completeData).includes("totalRepCounts")
              ? false
              : true,
        })
      ).then(async (response: any) => {
        setEmailFlag(false);
        if (response.payload.pause_journey) {

          await reFetchData();
          navigate(`/${journeySlug}/dashboard?activeTab=overview`);
        } else if (response.payload.forward_to_next_appointment) {
          dispatch(
            setBackGroundLoading({
              status: true,
              loadingMessage: "Fetching Appointments",
            })
          );
          await dispatch(
            fetchPatientJourneyProgresses({
              journeyId: patient.current_journey_id,
            })
          )
            .then((res: any) => {
              if (response.payload.next_phase_slug) {
                redirectToAppointment(
                  response.payload.next_phase_slug,
                  res.payload.appontments
                );
              }
            })
            .catch(() => {
              dispatch(setBackGroundLoading({ status: false }));
            });
        } else if (response.payload.navigate_to_route) {
          await reFetchData();
          if (response.payload.redirect_route) {
            navigate(response.payload.redirect_route);
          }
        } else {
          const newPhaseActivites = response.payload.appontments.find(
            (phase: AppointmentProgress) => phase.id === currentAppointmentId
          )?.journey_phase.patient_phase_activity;
          await dispatch(setAppointments(response.payload.appontments));
          // todo: improve error handling
          await playNextUnSkippedActivity(
            newPhaseActivites,
            !!response.payload.continue_to_current_appointment
          );
        }
      });
    } catch (error) {
      if (shareFlag?.answer) {
        alertNotification("warning", "Unable to send the email", "top-right");
        setEmailFlag(true);
      }
    } finally {
      callWithDelay(() => dispatch(setBackGroundLoading({ status: false })), 0);
      if (shareFlag?.answer && !emailFlag) {
        alertNotification("success", "Email sent successfully", "top-right");
      }
    }
  };

  const handlePhaseTransitionEnd = async () => {
    if (
      CurrentPhaseData &&
      appointmentSlug === CurrentPhaseData.journey_phase.slug
    ) {
      dispatch(setTransitionEffect({ showing: false }));
      if (CurrentPhaseActivities.length === 0) {
        dispatch(
          setBackGroundLoading({
            status: true,
            loadingMessage: "Submitting Activity",
          })
        );
        const phaseData = await getPatientJourneyPhaseProgressComplete(
          CurrentPhaseData.id.toString()
        );
        if (phaseData?.appontments) {
          dispatch(setAppointments(phaseData.appontments));
        }

        /** Commenting Below logic as this statement calls Get progress API everytime. */
        // (await dispatch(
        //   fetchPatientAppointmentProgresses(currentJourneySlug)
        // )) &&
        if (phaseData.next_phase_slug === "/") {
          await dispatch(
            fetchPatientJourneyProgresses({
              journeyId: patient.current_journey_id,
            })
          )
        }
        redirectToAppointment(phaseData.next_phase_slug, appointmentProgresses);
        dispatch(setBackGroundLoading({ status: false }));
      } else {
        navigate(`?activityId=${CurrentPhaseActivities[0].id}`);
      }
    }
  };

  const openChatBot = () => {
    dispatch(setShowChatBot(true));
  };

  const reloadPhaseHistory = async () => {
    if (localStorage.getItem("skip_transition") == "true") {
      await handlePhaseTransitionEnd();
      localStorage.removeItem("skip_transition");
    }
  };

  useEffect(() => {
    if (!transitionEffect.showing) {
      if (CurrentActivity?.phase_activity.showChatBot) {
        openChatBot();
      } else {
        dispatch(setShowChatBot(false));
      }
    }
  }, [CurrentActivity?.phase_activity, transitionEffect.showing]);

  const renderActivity = useCallback(() => {
    if (
      CurrentPhaseData &&
      transitionEffect.showing &&
      transitionEffect.phaseSlug == appointmentSlug
    ) {
      return (
        <PhaseTransition
          openChatBot={openChatBot}
          currentPhaseProgress={CurrentPhaseData}
          handlePhaseTransitionEnd={handlePhaseTransitionEnd}
        />
      );
    }
    if (
      CurrentActivity?.phase_activity.type === SYMPTOM_CHECKER &&
      currentJourneySlug === JOURNEY_SLUGS[0]
    ) {
      return (
        <SymptomChecker
          activityDetails={CurrentActivity}
          handleActivityCompletion={HandleActivityEnd}
        />
      );
    }
    if (CurrentActivity?.phase_activity.type === PATIENT_GOAL) {
      return (
        <GoalActivity
          activityDetails={CurrentActivity}
          handleActivityCompletion={HandleActivityEnd}
        />
      );
    }
    if (CurrentActivity?.phase_activity.type === DOCTOR_SELECTION) {
      return (
        <DoctorListing
          activityDetails={CurrentActivity}
          handleActivityCompletion={HandleActivityEnd}
        />
      );
    }
    if (
      CurrentActivity?.phase_activity.type &&
      [VIDEO, DOCTOR_VIDEO].includes(CurrentActivity?.phase_activity.type)
    ) {
      return (
        <VideoActivity
          activityDetails={CurrentActivity}
          handleActivityCompletion={HandleActivityEnd}
        />
      );
    }
    if (
      CurrentActivity?.phase_activity.type &&
      [
        SURVEY,
        TASK,
        DIRECTION,
        SUMMARY,
        JOURNAL,
        QUESTIONNAIRE,
        TAKEAWAYS,
        GUIDANCE,
        HOMEWORK,
      ].includes(CurrentActivity?.phase_activity.type)
    ) {
      return (
        <SurveyActivity
          activityDetails={CurrentActivity}
          handleActivityCompletion={HandleActivityEnd}
        />
      );
    }
    if (CurrentActivity?.phase_activity.type === PATIENT_INFORMATION) {
      return (
        <PatientDetails
          activityDetails={CurrentActivity}
        />
      );
    }
    if (CurrentActivity?.phase_activity.type === MEDICATION_REVIEW) {
      return (
        <MedicationReview
          activityDetails={CurrentActivity}
          handleActivityCompletion={HandleActivityEnd}
          setHideDefaultNextButton={setHideDefaultNextButton}
        />
      );
    }
    if (CurrentActivity?.phase_activity.type === SCHEDUAL_APPOINTMENT) {
      return (
        <ScheduleAppointment
          activityDetails={CurrentActivity}
          handleActivityCompletion={HandleActivityEnd}
          setHideDefaultNextButton={setHideDefaultNextButton}
        />
      );
    }
    if (CurrentActivity?.phase_activity.type === KEGEL_TIMER) {
      return (
        <KegelTool
          activityDetails={CurrentActivity}
          handleActivityCompletion={HandleActivityEnd}
        />
      );
    }

    return <></>;
  }, [CurrentActivity, transitionEffect]);

  useEffect(() => {
    reloadPhaseHistory();
  }, [appointmentSlug]);

  return (
    <>
      {renderActivity()}
      {/* {showNextButton && (
        <div key={CurrentPhaseData.id} className="absolute right-28 top-5">
          <button
            onClick={() => {
              CurrentActivity && HandleActivityEnd(CurrentActivity);
            }}
            className={classNames(
              " text-white p-3 rounded-full flex flex-col items-center transition-all duration-500",
              CurrentPhaseData.status === AppointmentStatus.COMPLETED
                ? "bg-green-800 hover:bg-green-700"
                : "bg-black/50 hover:bg-black/75 cursor-not-allowed"
            )}
          >
            <ForwardIcon className="h-5 w-5 text-white" />
            NEXT
          </button>
        </div>
      )} */}
    </>
  );
};
export default VerifiedJourneyAppointment;

import { useEffect } from "react";
import eventEmitter from "../Event";
import { JOURNEY_SLUGS } from "../../redux/journey/JourneySlice";
import { useAppSelector } from "../../redux/store";

const CHATBOT_APP_ID = process.env.REACT_APP_KOMMUNICATE_CHATBOT_APP_ID;
const CHAT_STATE_KEY = "kommunicateChatState";

const getStorage = () => {
  try {
    if (localStorage) return localStorage;
  } catch (e) {
    try {
      if (sessionStorage) return sessionStorage;
    } catch (e) {
      return null;
    }
  }
  return null;
};
const storage = getStorage();

const useKommunicateChat = () => {
  const user = useAppSelector((state) => state.user.authUser);
  const currentSlug = useAppSelector(
    (state) => state.journey.currentJourneySlug
  );
  const botId =
    currentSlug === JOURNEY_SLUGS[0]
      ? "q-a-snwyf"
      : currentSlug === JOURNEY_SLUGS[1]
      ? "bering-menopause-q-a-lc0is"
      : null;
  const stateMatchesPath =
    JOURNEY_SLUGS.includes(currentSlug) &&
    window.location.pathname.includes(currentSlug);

  useEffect(() => {
    const updateChatState = () => {
      const kommunicateState = JSON.parse(
        localStorage.getItem(`kommunicate-${CHATBOT_APP_ID}`)
      );
      if (kommunicateState && Object.keys(kommunicateState).length > 0) {
        storage?.setItem(CHAT_STATE_KEY, "open");
      } else {
        storage?.setItem(CHAT_STATE_KEY, "closed");
      }
    };

    updateChatState();
    const intervalId = setInterval(updateChatState, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!window.kommunicate && user && stateMatchesPath) {
      ((d) => {
        let onInitRan = false;

        const kommunicateSettings = {
          appId: process.env.REACT_APP_KOMMUNICATE_CHATBOT_APP_ID,
          botIds: [botId],
          userId: user.email + currentSlug,
          popupWidget: false,
          automaticChatOpenOnNavigation: true,
          onInit: async function () {
            if (!onInitRan) {
              window.Kommunicate.displayKommunicateWidget(false);
              onInitRan = true;
            }

            const defaultSettings = {
              defaultBotIds: [botId],
              defaultAssignee: botId,
              skipRouting: true,
            };

            const css = `
              #mck-sidebox-launcher {
                display: none !important;
              }
              .km-custom-widget-background-color-secondary {
                background: #0078BF !important;
              }
              .km-custom-widget-background-color {
                background: #00843D !important;
              }
              .mck-msg-left .mck-msg-box {
                color: white !important;
              }
              .km-custom-widget-fill {
                fill: #00843D !important;
              }
              .mck-msg-left .mck-msg-box {
                border-radius: 0px 10px 10px 10px !important;
              }
              .mck-msg-right .mck-msg-box {
                border-radius: 10px 0px 10px 10px !important;
              }
              .km-typing-wrapper {
                border-radius: 0px 10px 10px 10px !important;
                background: #0078BF !important;
                color: white;
              }
              #km-chat-widget-close-button svg {
                display: none !important;
              }
              #km-chat-widget-close-button {
                font-size: 24px;
                text-align: center;
              }
              #km-chat-widget-close-button:before {
                content: "−";
              }
            `;

            const userdetail = {
              email: user.email,
              displayName: `${user?.given_name} ${user?.family_name}`,
              metadata: {
                Email: user.email,
                Name: `${user?.first_name} ${user?.last_name}`,
              },
            };

            await window.Kommunicate.updateUser(userdetail);
            await window.Kommunicate.updateSettings(defaultSettings);
            window.Kommunicate.customizeWidgetCss(css);
          },
        };

        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.kommunicate.io/v2/kommunicate.app";

        const h = document.getElementsByTagName("head")[0];
        h.appendChild(s);

        window.kommunicate = d;
        d._globals = kommunicateSettings;

        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.addedNodes.length) {
              mutation.addedNodes.forEach((node) => {
                if (
                  node.tagName === "IFRAME" &&
                  node.id === "kommunicate-widget-iframe"
                ) {
                  const checkUlInterval = setInterval(() => {
                    const iframeDocument =
                      node.contentDocument || node.contentWindow.document;
                    const ulElement =
                      iframeDocument.getElementById("mck-contact-list");
                    if (ulElement) {
                      const firstLi = ulElement.querySelector("li");
                      if (firstLi) {
                        const openChatId = firstLi.id.split("-")[2];
                        localStorage.setItem("openChatId", openChatId);
                        clearInterval(checkUlInterval);
                      }
                    }
                  }, 500);

                  const iframeDocument =
                    node.contentDocument || node.contentWindow.document;

                  const setupClickListener = () => {
                    const ulElement =
                      iframeDocument.getElementById("mck-contact-list");
                    if (ulElement) {
                      ulElement.addEventListener("click", (event) => {
                        const target = event.target;
                        const liElement = target.closest("li");
                        if (liElement) {
                          const aTag = liElement.querySelector("a");
                          if (aTag) {
                            const openChatId = aTag.getAttribute("data-mck-id");
                            localStorage.setItem("openChatId", openChatId);
                          }
                        }
                      });
                    }
                  };

                  setupClickListener();
                  const iframeObserver = new MutationObserver(() => {
                    setupClickListener();
                  });
                  iframeObserver.observe(iframeDocument.body, {
                    childList: true,
                    subtree: true,
                  });
                  observer.disconnect();
                }
              });
            }
          });
        });
        observer.observe(document.body, { childList: true, subtree: true });
      })(window.kommunicate || {});
    }

    const unsubscribeOpenKommunicateChatBot = eventEmitter.subscribe(
      "openKommunicateChatBot",
      async () => {
        if (
          window.kommunicate &&
          typeof window.kommunicate.displayKommunicateWidget === "function"
        ) {
          window.kommunicate.displayKommunicateWidget(true);
          const openChatId = JSON.parse(localStorage.getItem("openChatId"));
          if (!openChatId) window.kommunicate.openWidgetPreview();
          if (openChatId) window.kommunicate.openConversation(openChatId);
        } else {
          console.log("Kommunicate not initialized.");
        }
      }
    );

    const unsubscribeCloseKommunicateChatBot = eventEmitter.subscribe(
      "closeKommunicateChatBot",
      async () => {
        if (
          window.kommunicate &&
          typeof window.kommunicate.displayKommunicateWidget === "function"
        ) {
          window.kommunicate.displayKommunicateWidget(false);
        } else {
          console.log("Kommunicate not initialized.");
        }
      }
    );

    return () => {
      unsubscribeOpenKommunicateChatBot();
      unsubscribeCloseKommunicateChatBot();
    };
  }, [user, botId, stateMatchesPath]);
};

export default useKommunicateChat;

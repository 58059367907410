import { useAuth0 } from "@auth0/auth0-react";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { FC, PropsWithChildren, useEffect } from "react";
import { RootState, useAppSelector } from "../redux/store";
import { Patient } from "../redux/user/UserSlice";

const LogProvider: FC<PropsWithChildren> = ({ children }) => {

    const { isLoading, isAuthenticated } = useAuth0();
    const patient: Patient = useAppSelector((state: RootState) => state.user.authUser);
    const apiKey = process.env.REACT_APP_LOGROCKET_API_KEY;
    const enableLogRocket = process.env.REACT_APP_ENABLE_LOG_ROCKET === 'true';

    useEffect(() => {
        if (apiKey && enableLogRocket) {
            setupLogRocketReact(LogRocket);
            LogRocket.init(apiKey);

            if (!isLoading && isAuthenticated && patient?.id) {
                const userId = patient.id.toString();
                const email = patient.email || "";
                const name = patient.first_name ? `${patient.first_name} ${patient.last_name}` : email;
                LogRocket.identify(userId, {
                    name, email
                });
            }
        }
    }, [apiKey, isLoading, isAuthenticated, patient?.id, patient?.first_name, patient?.last_name, patient?.email]);

    return (
        <>
            {children}
        </>
    )
}

export default LogProvider
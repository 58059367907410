/** Constants for Menopause */
export const MENO_LIMIT_DAYS = 352;
export const AGE_FOR_MENOPAUSE = 45;

export enum MENOPAUSE_FLAG {
  SURGERY_MENO = "Surgery Meno",
  EARLY_MENO = "Early Meno",
  MENO = "Meno",
}

export const ScheduleVisitPatientProfileDescription = "Bering Health is integrated with Cleveland Clinic and can assist in scheduling appointments for medication or procedure consultations. Please review your contact details for accuracy. Bering will send a contact request to Cleveland Clinic on your behalf, and their scheduling team will reach out to finalize your appointment."

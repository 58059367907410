import { useEffect, useState } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { HappySvg, NeutralSvg, SadSvg } from "../../../../Images/icons/Icons";
import { getMoodReport } from "../../../../core/requests/_requests";
import { moodOptions } from "../journal/JournalEntries";
import { SymptomEntry } from "../symptom-tracker/SymptomTracker";

export function WeeklyMoodChart({
  journalEntries,
  gotoSymptomsTab,
  weeklyMoodTrendData,
}: {
  journalEntries: SymptomEntry[];
  gotoSymptomsTab: () => void;
  weeklyMoodTrendData: (data: string) => void;
}) {
  const [moodChartData, setMoodChartData] = useState([]);
  const [dataExists, setDataExists] = useState(false);

  const CustomizedDot = (props: any) => {
    const { cx, cy, value } = props;
    if (cy === null || props.payload.value === 0) {
      return <></>;
    } else if (props.payload.value === moodOptions[2].value) {
      return (
        <svg
          x={cx - 16}
          y={cy - 10}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16"
            cy="16"
            r="12"
            fill="#FFC800"
            stroke="#FFC800"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="11.0166"
            y="11.8334"
            width="0.0166667"
            height="0.0166667"
            transform="rotate(90 11.0166 11.8334)"
            stroke="#CD7900"
            stroke-width="3"
            stroke-linejoin="round"
          />
          <rect
            x="21.0166"
            y="11.8334"
            width="0.0166667"
            height="0.0166667"
            transform="rotate(90 21.0166 11.8334)"
            stroke="#CD7900"
            stroke-width="3"
            stroke-linejoin="round"
          />
          <path
            d="M21.7752 19.3334C20.6225 21.326 18.468 22.6667 16.0004 22.6667C13.5328 22.6667 11.3783 21.326 10.2256 19.3334"
            stroke="#CD7900"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    } else if (props.payload.value === moodOptions[0].value) {
      return (
        <svg
          x={cx - 20}
          y={cy - 20}
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="15"
            cy="15"
            r="12"
            transform="matrix(-1 0 0 1 35 5)"
            fill="#FFC800"
            stroke="#FFC800"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            width="0.0170833"
            height="0.0175"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 24.9834 15.8333)"
            stroke="#CD7900"
            stroke-width="3"
            stroke-linejoin="round"
          />
          <rect
            width="0.0170833"
            height="0.0175"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 14.9834 15.8333)"
            stroke="#CD7900"
            stroke-width="3"
            stroke-linejoin="round"
          />
          <path
            d="M25.7752 26.6667C24.6225 24.674 22.468 23.3333 20.0004 23.3333C17.5328 23.3333 15.3783 24.674 14.2256 26.6667"
            stroke="#CD7900"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    } else {
      return (
        <svg
          x={cx - 18}
          y={cy - 20}
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16"
            cy="16"
            r="12"
            fill="#FFC800"
            stroke="#FFC800"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="11.0166"
            y="11.8335"
            width="0.0166667"
            height="0.0166667"
            transform="rotate(90 11.0166 11.8335)"
            stroke="#CD7900"
            stroke-width="3"
            stroke-linejoin="round"
          />
          <rect
            x="21.0166"
            y="11.8335"
            width="0.0166667"
            height="0.0166667"
            transform="rotate(90 21.0166 11.8335)"
            stroke="#CD7900"
            stroke-width="3"
            stroke-linejoin="round"
          />
          <path
            d="M11 21H21"
            stroke="#CD7900"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      );
    }
  };

  const handleNavigation = () => {
    if (dataExists === false) {
      gotoSymptomsTab();
    }
    return;
  };

  const dayMap: { [key: string]: string } = {
    Mo: "Monday",
    Tu: "Tuesday",
    We: "Wednesday",
    Th: "Thursday",
    Fr: "Friday",
    Sa: "Saturday",
    Su: "Sunday",
  };

  const CustomTooltip = ({ payload, label, active, coordinate }: any) => {
    if (active && payload && payload.length) {
      const { x, y } = coordinate;

      return (
        <>
          {payload[0].payload && payload[0].payload.value > 0 ? (
            <div
              className="bg-white border border-[#ccc] rounded-sm p-1"
              style={{
                position: "absolute",
                top: y + 20,
                left: x - 20,
                //height: "80px",
                width: "110px",
                zIndex: "1",
              }}
            >
              <div className="flex flex-col mb-0 text-xs">
                <div className="flex justify-between">
                  <p className="flex justify-between text-xs font-semibold">{`Day: `}</p>
                  <p className="flex justify-between text-xs font-semibold">{` ${
                    dayMap[label] || label
                  }`}</p>
                </div>
                <div className="flex gap-0 items-center my-1 justify-between">
                  <span className="text-xs">Mood:</span>
                  <div>
                    {payload[0].value &&
                    payload[0].value === moodOptions[2].value ? (
                      <HappySvg />
                    ) : null}
                    {payload[0].value &&
                    payload[0].value === moodOptions[1].value ? (
                      <NeutralSvg />
                    ) : null}
                    {payload[0].value &&
                    payload[0].value === moodOptions[0].value ? (
                      <SadSvg />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    const fetchMoodGraphData = async () => {
      try {
        const moodData = await getMoodReport();
        const anyValueGreaterThanZero = moodData.moodScoreDto.some(
          (item: { weekDay: string; moodScore: number; date: Date }) =>
            item.moodScore > 0
        );
        setDataExists(anyValueGreaterThanZero);
        if (
          moodData &&
          Array.isArray(moodData.moodScoreDto) &&
          moodData.moodScoreDto.length > 0
        ) {
          const moodTrend = moodData.trend?.toLowerCase();
          weeklyMoodTrendData(moodTrend);
          const updatedMoodChartData = moodData.moodScoreDto.map(
            (moodObj: {
              weekDay: string;
              moodScore: number | null;
              date: Date;
            }) => {
              return {
                label:
                  moodObj.weekDay.slice(0, 2).charAt(0).toUpperCase() +
                  moodObj.weekDay.slice(1, 2).toLowerCase(),
                value:moodOptions
                .filter(option => option.value === moodObj.moodScore) // Filter to find matching moodScore
                .map(option => option.apiVal)[0] ?? 0,
                date: moodObj.date,
              };
            }
          );
          setMoodChartData(updatedMoodChartData);
        }
      } catch (error) {
        console.log("Error fetching the chart data", error);
      }
    };

    fetchMoodGraphData();
  }, [journalEntries]);

  return (
    <>
      <div className="w-full h-52">
        <ResponsiveContainer>
          <AreaChart
            className={`${dataExists ? "!cursor-pointer" : "!cursor-default"}`}
            onClick={handleNavigation}
            data={moodChartData}
            syncId="weeklyMood"
            margin={{
              top: 18,
              right: 16,
              left: 16,
              bottom: -8,
            }}
          >
            <XAxis
              dataKey="label"
              stroke="black"
              tickLine={false}
              axisLine={false}
              fontWeight="Inter"
              fontSize={12}
            />
            {dataExists && <Tooltip content={<CustomTooltip />} />}
            <Area
              type="monotone"
              dataKey="value"
              fillOpacity="0.05"
              fill="#000000"
              stroke={`${dataExists ? "none" : "#3182bd"}`}
              dot={<CustomizedDot />}
            />
            {dataExists === false && (
              <>
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fill="black"
                  fontSize={16}
                  cursor="pointer"
                  onClick={() => gotoSymptomsTab()}
                >
                  Click Here for Symptom Tracker
                </text>
              </>
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default WeeklyMoodChart;

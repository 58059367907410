import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { alertNotification } from "../../../core/alert/ToastAlert";
import { AppointmentActivity } from "../../../core/phase-activity/AppointmentActivityConfig";
import NextFooter from "../../../providers/LayoutProvider/NextFooter";
import PageWrapper from "../../../providers/LayoutProvider/PageWrapper";
import { Doctor, fetchDoctorsList } from "../../../redux/doctor/DoctorSlice";
import { setBackGroundLoading } from "../../../redux/layout/LayoutSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { Language, patientUpdate } from "../../../redux/user/UserSlice";
import LoadingContainer from "../../loader/LoadingContainer";
import IntroVideoPlayer from "./IntroVideoPlayer";

type DoctorListingProps = {
  activityDetails: AppointmentActivity;
  handleActivityCompletion?: (
    activityDetails: AppointmentActivity
  ) => void | Promise<void>;
};

const DoctorListing: FC<DoctorListingProps> = ({
  activityDetails,
  handleActivityCompletion,
}) => {
  const patient = useAppSelector((state) => state.user.authUser);
  const [selectedLanguageId, setSelectedLanguageId] = useState<number | null>(
    patient.doctor?.selected_language?.id || 1
  );
  const doctorList = useAppSelector((state) => state.doctor.doctorList);
  const doctors = doctorList.data;
  const dispatch = useAppDispatch();
  const currentJourneySlug: string = useAppSelector(
    (state) => state.journey.currentJourneySlug
  );

  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(
    patient?.doctor || null
  );
  const [submittingFlag, setSubmittingFlag] = useState<boolean>(false);
  const setDoctorList = () => {
    //dispatch(fetchDoctorsList(journeyIds[currentJourneySlug]));        //$ removing hardcoding journeyIds
    if (patient?.current_journey_id === undefined) return;
    dispatch(fetchDoctorsList(patient?.current_journey_id));
    
  };

  const HandleDoctorSelection = (doctor: Doctor) => {
    setSelectedDoctor(doctor);
  };

  const HandleDoctorSubmit = async () => {
    try {
      setSubmittingFlag(true);
      if (!selectedLanguageId) {
        alertNotification(
          "error",
          "Please select communication language !",
          "top-right"
        );
        return;
      }
      dispatch(
        setBackGroundLoading({
          status: true,
          loadingMessage: "Submitting details",
        })
      );
      await dispatch(
        patientUpdate({
          ...patient,
          doctor_id: selectedDoctor?.id,
          doctor_language: selectedLanguageId,
        })
      );
      handleActivityCompletion &&
        (await handleActivityCompletion(activityDetails));
      dispatch(setBackGroundLoading({ status: false }));
    } catch (error) {
      console.error("Error", error);
      setSubmittingFlag(false);
    }
  };

  const handleLanguageSelection = (languageId: number) => {
    selectedLanguageId == languageId
      ? setSelectedLanguageId(null)
      : setSelectedLanguageId(languageId);
  };

  useEffect(() => {
    setDoctorList();
  }, []);

  if (doctorList.loading) {
    return <LoadingContainer customLoadingMessage="Fetching Doctors" />;
  }

  const DoctorListMapping = () => {
    return (
      <>
        {doctors.map((doctor) => {
          return (
            <div
              key={doctor.id}
              className={`relative w-full bg-black/75 rounded-3xl border-[5px] ${
                selectedDoctor?.id === doctor.id
                  ? "border-sky-600"
                  : "border-white"
              } `}
            >
              <div className="w-full overflow-hidden rounded-t-2xl relative group">
                <div className="h-full cursor-pointer">
                  <IntroVideoPlayer doctor={doctor} />
                </div>
              </div>

              <div className="flex flex-col px-5 pt-2 pb-11">
                <h2 className="text-white font-semibold text-[16px] mb-0">{`${doctor.salutation} ${doctor.first_name} ${doctor.last_name}, ${doctor.qualification}`}</h2>

                {doctor?.languages?.length !== 0 ? (
                  <div className="flex flex-wrap gap-2 mb-2">
                    {doctor?.languages?.map((language: Language) => (
                      <button
                        key={language.id}
                        className={classNames(
                          selectedLanguageId == language.id && "bg-green-800",
                          "px-2.5 py-1.5 text-xs font-semibold text-white bg-black/90 hover:bg-green-800 transition-all rounded-full flex items-center"
                        )}
                        onClick={() => handleLanguageSelection(language.id)}
                      >
                        {language.language}
                      </button>
                    ))}
                  </div>
                ) : (
                  ""
                )}

                <p
                  className="transition-all duration-500 text-white/75 group-hover:text-white text-[13px] font-normal mb-0"
                  dangerouslySetInnerHTML={{ __html: doctor.description ?? "" }}
                />
              </div>

              <div className="absolute bottom-2 left-0 right-0 flex justify-center">
                <button
                  disabled={selectedDoctor?.id === doctor.id}
                  className={classNames(
                    "tw-btn",
                    selectedDoctor?.id === doctor.id
                      ? "text-gray-50 font-bold bg-sky-600 hover:bg-sky-500"
                      : "tw-btn-green",
                    "w-full group-hover:bg-green-500 mx-8 flex items-center justify-center h-8 px-2 text-xs"
                  )}
                  onClick={() => {
                    doctor.id && HandleDoctorSelection(doctor);
                  }}
                >
                  {selectedDoctor?.id === doctor.id
                    ? "selected"
                    : `select ${doctor.salutation} ${doctor.last_name}`}
                </button>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      {!submittingFlag ? (
        <PageWrapper
          nextCallback={HandleDoctorSubmit}
          disabled={{
            nextBtn: !Boolean(selectedDoctor),
          }}
        >
          <div className="flex flex-col h-full">
            <div className="flex-none">
              <div className="grid grid-cols-12 gap-4 py-4">
                <div className="col-start-2 col-end-12">
                  <div className="flex justify-center flex-col ">
                    <h2 className="text-center text-2xl font-semibold">
                      Select Your Doctor
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto scrollbar mx-2">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-start-2 col-end-12">
                  <div className="inline-block w-full rounded-xl">
                    <div className="inline-block w-full">
                      <div className={`grid grid-cols-1 md:grid-cols-2 gap-3`}>
                        <DoctorListMapping />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <NextFooter />
          </div>
        </PageWrapper>
      ) : null}
    </>
  );
};
export default DoctorListing;

import { FC, useEffect, useState } from "react";
import HourFormat from "./HourFormat";
import HourWheel from "./HourWheel";
import MinuteWheel from "./MinuteWheel";

interface TimePickerSelectionProps {
  pickerDefaultValue: string;
  initialValue: string;
  onChange: (value: string) => void;
  setInputValue: (value: string) => void;
  seperator?: boolean;
  use12Hours: boolean;
  onAmPmChange: (format: string) => void;
}

interface HourFormatState {
  mount: boolean;
  hourFormat: string;
}

const height = 28;

const TimePickerSelection: FC<TimePickerSelectionProps> = ({
  pickerDefaultValue,
  initialValue,
  onChange,
  setInputValue,
  seperator,
  use12Hours,
  onAmPmChange,
}) => {

  const initialTimeValue = use12Hours ? initialValue.slice(0, 5) : initialValue;
  const [value, setValue] = useState<string>(
    initialValue === null ? pickerDefaultValue : initialTimeValue
  );
  const [hourFormat, setHourFormat] = useState<HourFormatState>({
    mount: false,
    hourFormat: initialValue.slice(6, 8),
  });

  useEffect(() => {
    setValue(pickerDefaultValue.slice(0, 5));
    if (use12Hours) {
      setHourFormat((prev) => ({
        ...prev,
        hourFormat: pickerDefaultValue.slice(6, 8),
      }));
    }
  }, [pickerDefaultValue]);

  useEffect(() => {
    handleSave();
  }, [value, hourFormat]);

  useEffect(() => {
    if (hourFormat.mount) {
      onAmPmChange(hourFormat.hourFormat);
    }
  }, [hourFormat]);

  const params = {
    height,
    value,
    setValue,
    use12Hours,
    onAmPmChange,
    setHourFormat,
    hourFormat,
  };

  const handleSave = () => {
    const finalSelectedValue = use12Hours
      ? `${value} ${hourFormat.hourFormat}`
      : value;
    setInputValue(finalSelectedValue);
    onChange(finalSelectedValue);
  };

  return (
    <div className="react-ios-time-picker  react-ios-time-picker-transition">
      <div
        className="react-ios-time-picker-container"
        style={{ height: `${height * 5 + 40}px` }}
      >
        <div
          className="react-ios-time-picker-selected-overlay"
          style={{
            top: `${height * 2 + 20}px`,
            height: `${height}px`,
          }}
        />
        <HourWheel {...params} />
        {seperator && <div className="react-ios-time-picker-colon">:</div>}
        <MinuteWheel {...params} />
        {use12Hours && <HourFormat {...params} />}
      </div>
    </div>
  );
}

export default TimePickerSelection;

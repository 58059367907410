import { Switch } from "@headlessui/react";
import {
  ArrowDownRightIcon,
  ArrowRightIcon,
  ArrowUpRightIcon,
} from "@heroicons/react/20/solid";
import { FC } from "react";
import { Tooltip } from "rizzui";
import { ResetSvg } from "../../../../Images/icons/Icons";
export interface VoidingDataType {
  urge: boolean;
  leakage: number | "";
  voiding: number | "";
  amount: number;
}

const voidingOptions = [
  {
    label: "Low",
    value: 1,
    icon: <ArrowDownRightIcon className="w-5 h-5" />,
    className: "rounded-l-md focus:z-1",
  },
  {
    label: "Normal",
    value: 2,
    icon: <ArrowRightIcon className="w-5 h-5" />,
    className: "focus:z-10",
  },
  {
    label: "High",
    value: 3,
    icon: <ArrowUpRightIcon className="w-5 h-5" />,
    className: "rounded-r-md focus:z-1",
  },
];

type VoidingOption = {
  voidingData: VoidingDataType;
  setVoidingData: React.Dispatch<React.SetStateAction<VoidingDataType>>;
  resetValuesHandler: () => void;
};

const Voiding: FC<VoidingOption> = ({ voidingData, setVoidingData, resetValuesHandler }) => {

  const handleChange = (name: string, value: string) => {
    let numericValue = parseInt(value, 10);

    if (isNaN(numericValue)) numericValue = 0;
    else if (numericValue < 0) numericValue = 0;
    else if (numericValue > 10) numericValue = 10;

    setVoidingData((prevData) => ({
      ...prevData,
      [name]: numericValue,
    }));
  };

  const handleSwitchChange = (checked: boolean) => {
    setVoidingData((prevData) => ({
      ...prevData,
      urge: checked,
    }));
  };

  const handleVoidingOptionChange = (value: number) => {
    setVoidingData((prevData) => ({
      ...prevData,
      amount: value,
    }));
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center flex-col mb-4">
        <div className="relative flex justify-center items-center w-full">
          <h5 className="text-2xl mb-1">Voiding & Leaks</h5>
          <div className="absolute right-2 cursor-pointer" onClick={() => resetValuesHandler()}>
            <Tooltip
              content="Reset"
              placement="top"
            >
              <span><ResetSvg /></span>
            </Tooltip>
          </div>
        </div>
        <p className="text-sm text-center mt-2">
          Enter the number of leaks and urination <br />
          Press “<span className="text-[#358738] text-sm">Next</span>” to save your entry
        </p>
      </div>

      <div className="grid grid-cols-4 gap-4 items-center mb-8">
        <div className="col-span-3">
          <p>Leakage? How many Times?</p>
        </div>
        <div className="flex justify-center items-center">
          <input
            type="number"
            className="w-9 h-9 p-1 text-center rounded-md"
            max={10}
            min={1}
            name="leakage"
            value={voidingData.leakage}
            onChange={(e) => handleChange("leakage", e.target.value)}
            onKeyDown={(e) => {
              if (['-', '+', '.', 'e'].includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 items-center mb-8">
        <div className="col-span-3">
          <p>Strong Urge?</p>
        </div>
        <div className="flex justify-center items-center">
          <Switch
            checked={voidingData.urge}
            onChange={handleSwitchChange}
            className={`group inline-flex h-7 min-w-[48px] items-center rounded-full shadow-inner ${voidingData.urge ? "bg-[#19B394]" : "bg-gray-300"} transition`}
          >
            <span
              className={`block h-[80%] w-1/2 rounded-full transition shadow-inner duration-300 ease-in-out bg-white transform ${voidingData.urge
                ? "translate-x-[90%]"
                : "translate-x-[10%]"
                }`}
            ></span>
          </Switch>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 items-center mb-6">
        <div className="col-span-3">
          <p>Voiding - How many Times?</p>
        </div>
        <div className="flex justify-center items-center">
          <input
            type="number"
            name="voiding"
            className="w-9 h-9 p-1 text-center rounded-md"
            max={10}
            min={1}
            value={voidingData.voiding}
            onChange={(e) => handleChange("voiding", e.target.value)}
            onKeyDown={(e) => {
              if (['-', '+', '.', 'e'].includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>

      <div className="flex flex-col mb-6">
        <p className="mb-1">How Much?</p>
        <div className="flex justify-center items-center flex-col">
          <div
            className="flex justify-between w-full rounded-md shadow-sm"
            role="group"
          >
            {voidingOptions.map((option) => (
              <button
                key={option.value}
                type="button"
                onClick={() => handleVoidingOptionChange(option.value)}
                className={`${option.className
                  } px-2 py-2 ring-1 ring-inset ring-gray-500 cursor-pointer relative inline-flex w-full justify-center gap-2 items-center ${voidingData.amount === option.value
                    ? "bg-gray-300"
                    : "bg-white"
                  }`}
              >
                <p className="text-sm mr-2 font-semibold text-gray-900">{option.label}</p>
                {option.icon}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voiding;

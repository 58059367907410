import classNames from "classnames";

const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL;
const imageUrl = `${CLOUDFRONT_URL}/assets/home-bg-picture.png`;

const UnderMaintenancePage = () => {
  return (
    <div className={classNames("fixed top-0 w-full h-full")}>
      <img src={imageUrl} alt="Dynamic" className={"w-full h-full object-cover transition-all duration-800"} />
      <div className="fixed overflow-y-auto h-full top-0 w-full ">
        <div className="fixed overflow-y-auto h-full top-0 w-full z-[10]">
          <div className="min-h-[100vh] flex justify-center bg-transparent">
            <div className="inline-block mt-14 relative max-w-[48%] w-full min-h-[73vh] max-h-[73vh] rounded-md shadow-lg container_border">
              <div className="absolute bg-white top-3 bottom-3 left-3 right-3 rounded-md overflow-y-auto scrollbar">
                <div className="h-full relative inline-block w-full">
                  <div className="flex flex-col h-full relative bg-[#f5f5f5]">
                    <div className="flex-none">
                      <div className="grid grid-cols-12 gap-4 py-2">
                        <div className="col-start-2 col-end-12">
                          <div className="flex goals-center flex-col px-2.5">
                            <h2 className="text-center text-3xl font-bold my-4 text-gray-800">
                              Bering Health Platform Maintenance
                            </h2>
                            <p className="text-center font-normal text-md my-4 text-gray-700">
                              We’re currently performing scheduled maintenance on the Bering Health platform to enhance your experience. During this time, the platform will be temporarily unavailable.
                            </p>
                            <p className="text-center font-normal text-md my-4 text-gray-700">
                              We apologize for any inconvenience and appreciate your patience. Our team is working hard to bring the platform back online as soon as possible.
                            </p>
                            <p className="text-center font-normal text-md my-4 text-gray-700">
                              If you have any urgent concerns, please don’t hesitate to contact our support team at <a href="mailto:info@beringhealth.com" className="text-blue-500 hover:text-blue-700 underline">
                                info@beringhealth.com
                              </a>
                            </p>
                            <p className="text-center font-normal text-md my-4 text-gray-700">
                              Thank you for your understanding!
                            </p>
                            <h2 className="text-end text-xl font-semibold my-6 text-gray-800">
                              — The Bering Health Team
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnderMaintenancePage;

const LoadingIcon = () => {
    return (
        <div className="flex items-center rounded-lg">
            <svg
                className="margin: auto; background: rgb(255, 255, 255); display: block; --darkreader-inline-bgcolor: #181a1b; --darkreader-inline-bgimage: none; shape-rendering: auto;"
                width="28px"
                height="28px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                data-darkreader-inline-bgcolor=""
                data-darkreader-inline-bgimage=""
            >
                <path
                    d="M10 50A40 40 0 0 0 90 50A40 44 0 0 1 10 50"
                    fill="#ef4444"
                    stroke="none"
                    className="--darkreader-inline-fill: #f04d4d; --darkreader-inline-stroke: none;"
                    data-darkreader-inline-fill=""
                    data-darkreader-inline-stroke=""
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        dur="1s"
                        repeatCount="indefinite"
                        keyTimes="0;1"
                        values="0 50 52;360 50 52"
                    ></animateTransform>
                </path>
            </svg>
        </div>
    )
}

export default LoadingIcon
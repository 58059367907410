interface EventCallback {
    (data: any): void;
}

interface EventEmitter {
    events: { [event: string]: EventCallback[] };
    emit(event: string, data: any): void;
    dispatch(event: string, data: any): void;
    subscribe(event: string, callback: EventCallback): () => void;
}

const eventEmitter: EventEmitter = {
    events: {},
    emit(event: string, data: any) {
        if (!this.events[event]) return;
        this.events[event].forEach(callback => callback(data));
    },
    dispatch(event: string, data: any) {
        if (!this.events[event]) return;
        this.events[event].forEach(callback => callback(data));
    },
    subscribe(event: string, callback: EventCallback) {
        if (!this.events[event]) this.events[event] = [];

        const callbackWrapper = (data: any) => callback(data);

        this.events[event].push(callbackWrapper);

        return () => {
            this.events[event] = this.events[event].filter(cb => cb !== callbackWrapper);
        };
    }
};

export default eventEmitter;

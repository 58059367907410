import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import AppointmentSlice from "./appointment/AppointmentSlice";
import ConfigurationSlice from "./configuration/ConfigurationSlice";
import CurrentPhaseSlice from "./current-phase/CurrentPhaseSlice";
import DashboardSlice from "./dashboard/DashboardSlice";
import DoctorSlice from "./doctor/DoctorSlice";
import JournalSlice from "./journals/JournalSlice";
import JourneySlice from "./journey/JourneySlice";
import LayoutSlice from "./layout/LayoutSlice";
import NotificationSlice from "./notification/NotificationSlice";
import SymptomSlice from "./symptom/SymptomSlice";
import SystemConfigurationsSlice from "./system-configurations/SystemConfigurationsSlice";
import TreatmentSlice from "./treatments/TreatmentSlice";
import UserSlice from "./user/UserSlice";
import VideoGallerySlice from "./video-gallery/VideoGallerySlice";

const store = configureStore({
  reducer: {
    user: UserSlice,
    doctor: DoctorSlice,
    layout: LayoutSlice,
    journey: JourneySlice,
    appointment: AppointmentSlice,
    notification: NotificationSlice,
    videoGallery: VideoGallerySlice,
    currentPhase: CurrentPhaseSlice,
    symptom: SymptomSlice,
    dashboard: DashboardSlice,
    configuration: ConfigurationSlice,
    systemConfigurations: SystemConfigurationsSlice,
    treatments: TreatmentSlice,
    journals: JournalSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;

import { RootState, useAppSelector } from '../../../../redux/store';
import { AppointmentActivity, PhaseActivityStatus, AppointmentActivityTypes } from '../../../../core/phase-activity/AppointmentActivityConfig';
import ActivitySkeleton from '../ActivitySkeleton';
import { SurveyHistoryManager } from './Summaries';
import ConditionallyRender from 'react-conditionally-render';
import { AppointmentProgress, AppointmentStatus } from '../../../../redux/appointment/AppointmentSlice';

const Homework = () => {

    const appointmentProgresses: AppointmentProgress[] = useAppSelector((state: RootState) => state.appointment.data.appontments);
    const ActivitySubmitLoader = useAppSelector((state: RootState) => state.dashboard.activitySubmitLoader);
    const completedAppointments: AppointmentProgress[] = appointmentProgresses?.filter((item: AppointmentProgress) => item?.status === AppointmentStatus.COMPLETED || item?.status === AppointmentStatus.CURRENT);

    if (ActivitySubmitLoader) {
        return <ActivitySkeleton />
    }

    const hasHomeworks = completedAppointments?.some((appointment: AppointmentProgress) => {
        return appointment?.journey_phase?.patient_phase_activity?.some((activity: AppointmentActivity) => {
            return activity?.phase_activity?.type === AppointmentActivityTypes?.HOMEWORK && activity?.status === PhaseActivityStatus?.COMPLETED;
        });
    });

    return (
        <ConditionallyRender
            condition={hasHomeworks}
            show={
                <div className='grid grid-cols-1 gap-6 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar px-2'>
                    {
                        completedAppointments?.map((appointment: AppointmentProgress) => {
                            const appointmentHomeWorkActivities = appointment?.journey_phase?.patient_phase_activity?.filter((activity: AppointmentActivity) => (activity?.phase_activity?.type === AppointmentActivityTypes?.HOMEWORK && activity?.status === PhaseActivityStatus?.COMPLETED))
                            return appointmentHomeWorkActivities?.map((activity: any, i: number) => {
                                return activity?.phase_activity?.frontier_data && (
                                    <div className="inline-block w-full [&>div]:rounded-[0.5rem]" key={i}>
                                        <SurveyHistoryManager activity={activity} key={i} />
                                    </div>
                                )
                            })
                        })
                    }
                </div>
            }
            elseShow={
                <div className='bg-white p-8 rounded-xl'>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path>
                        </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Keep up with homework!</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">Within various appointments, you will have the information about the homework information. Please feel free to provide as much information as you can for better health tracking information.</p>
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default Homework
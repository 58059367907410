import { useAuth0 } from "@auth0/auth0-react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import ConditionallyRender from "react-conditionally-render";
import { NavigateFunction, useNavigate } from "react-router";
import { Tooltip } from "rizzui";
import { alertNotification } from "../../core/alert/ToastAlert";
import { AppointmentActivity } from "../../core/phase-activity/AppointmentActivityConfig";
import { verifyPatientSymtoms } from "../../core/requests/_requests";
import NextFooter from "../../providers/LayoutProvider/NextFooter";
import PageWrapper from "../../providers/LayoutProvider/PageWrapper";
import { fetchPatientJourneyProgresses } from "../../redux/appointment/AppointmentSlice";
import { resetCurrentPhaseData } from "../../redux/current-phase/CurrentPhaseSlice";
import { JOURNEY_SLUGS } from "../../redux/journey/JourneySlice";
import { setBackGroundLoading } from "../../redux/layout/LayoutSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../redux/store";
import { Symptom, fetchSymptomsList } from "../../redux/symptom/SymptomSlice";
import { setAuthenticatedPatientDetails } from "../../redux/user/UserSlice";
import SymptomIntructions from "./SymptomIntructions";

type symptomsInstruction = {
  type: "INITIAL" | "DISQUALIFIED" | null;
};
type SymptomCheckerProps = {
  activityDetails: AppointmentActivity;
  handleActivityCompletion?: (
    activityDetails: AppointmentActivity
  ) => void | Promise<void>;
};

const DISQAULIFICATION_ALERT =
  "This symptom can be associated with conditions other than OAB. If you have this symptom, please consult your doctor.";
const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL;

const SymptomChecker: FC<SymptomCheckerProps> = ({
  activityDetails,
  handleActivityCompletion,
}) => {
  const symptoms = useAppSelector((state: RootState) => state.symptom);
  const dispatch: AppDispatch = useAppDispatch();
  const { user } = useAuth0();
  const [selectedSymtoms, setSelectedSymtoms] = useState<number[]>([]);
  const [showDisqualifiedIntructions, setShowDisqualifiedIntructions] =
    useState<symptomsInstruction>({ type: null });
  const navigate: NavigateFunction = useNavigate();
  const currentJourneySlug: string = useAppSelector(
    (state: RootState) => state.journey.currentJourneySlug
  );
  const patient = useAppSelector((state: RootState) => state.user.authUser);
  const HandleSymtomSelection = (symptom: Symptom) => {
    setSelectedSymtoms((symptoms) =>
      selectedSymtoms.includes(symptom.id)
        ? symptoms.filter((id) => id !== symptom.id)
        : [...symptoms, symptom.id]
    );
  };
  const submitSymptoms = async () => {
    if (selectedSymtoms.length === 0) {
      alertNotification("warning", "Please select any symtoms", "top-right");
      return;
    }
    dispatch(
      setBackGroundLoading({
        status: true,
        loadingMessage: "Submitting Symtoms",
      })
    );
    const verifySymptoms = await verifyPatientSymtoms({
      journeyId: patient.current_journey_id,
      patientActivityUuid: activityDetails.id.toString(),
      symptom_id: selectedSymtoms,
    });
    if (verifySymptoms?.eligible_for_journey) {
      handleActivityCompletion &&
        (await handleActivityCompletion(activityDetails));
      getAllSymptoms();
    } else {
      await dispatch(
        fetchPatientJourneyProgresses({
          journeyId: patient.current_journey_id,
        })
      );
      await dispatch(
        setAuthenticatedPatientDetails({
          currentJourneySlug,
          email: user?.email,
        })
      );
      setShowDisqualifiedIntructions({ type: "DISQUALIFIED" });
      dispatch(setBackGroundLoading({ status: false }));
    }
  };
  const disqualifiedIntructionsEnd = () => {
    setShowDisqualifiedIntructions({ type: null });
    dispatch(resetCurrentPhaseData());
    navigate(`/${currentJourneySlug}/dashboard?activeTab=journey-status`);
  };
  const getAllSymptoms = () => {
    if (currentJourneySlug !== JOURNEY_SLUGS[0]) return;
    dispatch(
      fetchSymptomsList({
         journeyId: patient.current_journey_id,
      })
    );
  };
  useEffect(() => {
    getAllSymptoms();
  }, []);
  useEffect(() => {
    if (symptoms.data.length > 0) {
      const checkSelectedSymptoms: any = symptoms?.data
        ?.filter((itm) => itm?.is_positive === true)
        .map((itm) => itm?.id);
      setSelectedSymtoms(checkSelectedSymptoms);
    }
  }, [symptoms]);

  const renderSymptoms = (symptom: Symptom, isSelected: boolean) => {
    return (
      <div className="flex flex-col px-3 py-2 space-y-2">
        <div className="flex">
          <div className="w-20 flex-none self-start">
            <img
              className="object-cover h-12"
              src={symptom.media_url}
              alt="Symptom"
            />
          </div>
          <div className="flex">
            <label
              className="absolute right-4 top-2 flex items-center p-3 rounded-full"
              htmlFor="customStyle"
            >
              <input
                type="checkbox"
                className={`before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-full bg-gray-900/10 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity ${symptom.is_dissable_qualification
                  ? "checked:bg-red-600/90 checked:hover:bg-red-600/90"
                  : "checked:bg-green-500"
                  } checked:before:bg-gray-900 hover:scale-105 hover:before:opacity-0`}
                id="customStyle"
                checked={isSelected}
              />
              <span className="absolute transition-opacity pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-4 w-4 ${isSelected ? "text-white" : "text-gray-400"
                    }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="0"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex-1">
            <h2 className="text-sm font-semibold text-black">
              {symptom.title}
            </h2>
          </div>
          <div className="flex flex-col">
            <p className="text-xs  text-[rgba(0,0,0,0.75)]">
              {symptom.description}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ConditionallyRender
        condition={showDisqualifiedIntructions.type === null}
        show={
          <PageWrapper
            nextCallback={submitSymptoms}
            disabled={{ nextBtn: selectedSymtoms.length == 0 }}
          >
            <div className="flex flex-col h-full">
              <div className="flex-none">
                <div className="grid grid-cols-12 gap-4 py-8">
                  <div className="col-start-2 col-end-12">
                    <div className="flex justify-center">
                      <h1 className="text-2xl md:text-3xl font-semibold">
                        Do you have any of these symptoms?
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-1 overflow-y-auto scrollbar mx-2">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-start-2 col-end-12">
                    <div className={`grid grid-cols-1 gap-3 lg:grid-cols-3`}>
                      {symptoms?.data &&
                        symptoms?.data.map((symptom: Symptom, i) => {
                          const isSelected = selectedSymtoms.includes(
                            symptom.id
                          );
                          return (
                            <div
                              key={symptom.id}
                              className={classNames("flex-none h-auto w-full")}
                            >
                              <div
                                onClick={() => HandleSymtomSelection(symptom)}
                                className={classNames(
                                  "relative overflow-hidden h-full bg-white/90 rounded-xl cursor-pointer border-4",
                                  isSelected
                                    ? `${symptom.is_dissable_qualification
                                      ? "border-red-600/90 shadow-[0_0_10px_0_#dc2626e6]"
                                      : "border-green-500 shadow-[0_0_10px_0_#00DA65]"
                                    } `
                                    : "border-gray-200"
                                )}
                              >
                                {symptom.is_dissable_qualification ? (
                                  <Tooltip
                                    className="bg-red-700/90 [&>svg]:fill-red-700/90"
                                    content={
                                      <div className="w-48 px-2 inline-block">
                                        <div className="flex flex-col gap-2">
                                          <div className="flex items-center gap-2">
                                            <InformationCircleIcon className="w-5 h-5" />
                                            <p className="font-semibold">
                                              Alert
                                            </p>
                                          </div>
                                          <p className="text-sm text-left">
                                            {DISQAULIFICATION_ALERT}
                                          </p>
                                        </div>
                                      </div>
                                    }
                                    placement="right"
                                  >
                                    {renderSymptoms(symptom, isSelected)}
                                  </Tooltip>
                                ) : (
                                  <>{renderSymptoms(symptom, isSelected)}</>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <NextFooter />
            </div>
          </PageWrapper>
        }
        elseShow={
          <SymptomIntructions
            src={`${CLOUDFRONT_URL}/video/other/545/04_Rose_OAB_GUI3_V13.mp4`}
            handleVideoEnd={disqualifiedIntructionsEnd}
            videoSubtitles={[
              {
                lang: "En",
                language: "English",
                url: `${CLOUDFRONT_URL}/video/other/545/04_Rose_OAB_GUI3_V13.vtt`,
              },
            ]}
          />
        }
      />
    </>
  );
};
export default SymptomChecker;

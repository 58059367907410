import { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  Journey,
  fetchPatientJourneys,
} from "../../../redux/journey/JourneySlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { Patient } from "../../../redux/user/UserSlice";

const JourneySelection = () => {
  const patient: Patient = useAppSelector(
    (state: RootState) => state.user.authUser
  );
  const journeys: Journey[] = useAppSelector(
    (state: RootState) => state.journey.data
  );
  const dispatch: AppDispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const setPatientJourneys = () => dispatch(fetchPatientJourneys());

  useEffect(() => {
    setPatientJourneys();
  }, [patient]);

  return (
    <div className="px-4 max-w-screen-2xl mx-auto">
      <div className="pt-4 xl:pt-10 pb-4 xl:pb-7">
        <h1 className="text-3xl md:text-5xl text-white font-semibold">
          Journey Selection
        </h1>
      </div>
      <div className="flex flex-wrap -mx-4">
        {journeys.map((journey, i) => (
          <div
            key={`${i}-rvbYkn`}
            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-4"
          >
            <div
              className="overflow-hidden bg-black/50 rounded-xl cursor-pointer"
              onClick={() => navigate(`/${journey.slug}`)}
            >
              <img
                className="object-cover w-full"
                src={journey.media?.media_url}
                alt={journey.title}
              />
              <div className="px-5 py-5 space-y-6">
                <h2 className="text-2xl font-semibold text-white">
                  {journey.title}
                </h2>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JourneySelection;

import classNames from "classnames";
import { FC, useEffect, useRef } from "react";
import { matchRoutes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  AppointmentActivity,
  AppointmentActivityTypes,
  PhaseActivityStatus,
} from "../../core/phase-activity/AppointmentActivityConfig";
import {
  AppointmentProgress,
  AppointmentStatus,
} from "../../redux/appointment/AppointmentSlice";
import {
  setShowSurveyModal,
  setTransitionEffect,
  transitionEffectDataType,
} from "../../redux/current-phase/CurrentPhaseSlice";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";

const Stepper: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const overflowingElementRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const callback = searchParams.get("callback");
  const inPatientInformationRoute = matchRoutes([{ path: "/:journeySlug/profile" }], location.pathname) && callback != null;

  // Reference to the circle element within the DOM.
  const circleElementRef = useRef<HTMLDivElement>(null);
  const innerCircleElementRef = useRef<HTMLDivElement>(null);

  const appointmentProgresses: AppointmentProgress[] = useAppSelector(
    (state: RootState) => state.appointment.data.appontments
  );
  const currentAppointmentId: string = useAppSelector(
    (state: RootState) => state.currentPhase.id
  );
  const currentActivityId: string = useAppSelector(
    (state: RootState) => state.currentPhase.currentActivityId
  );
  const transitionEffect: transitionEffectDataType = useAppSelector(
    (state: RootState) => state.currentPhase.transitionEffect
  );
  const currentJourneySlug: string = useAppSelector(
    (state: RootState) => state.journey.currentJourneySlug
  );

  const CurrentPhaseData: AppointmentProgress | undefined =
    appointmentProgresses?.find(
      (appointment: AppointmentProgress) =>
        appointment.id === currentAppointmentId
    );
  const CurrentPhaseActivities: AppointmentActivity[] | [] =
    CurrentPhaseData?.journey_phase.patient_phase_activity || [];
  const CurrentActivity: AppointmentActivity | undefined =
    CurrentPhaseActivities?.find(
      (activity: AppointmentActivity) => activity.id === currentActivityId
    );

  const showSurveyModal: boolean = useAppSelector(
    (state: RootState) => state.currentPhase.showSurveyModal
  );

  const {
    SURVEY,
    SUMMARY,
    TASK,
    DIRECTION,
    JOURNAL,
    QUESTIONNAIRE,
    TAKEAWAYS,
    GUIDANCE,
    HOMEWORK,
  } = AppointmentActivityTypes;

  const handleActivitySelection = (
    activity: AppointmentActivity,
    step: AppointmentProgress
  ) => {
    if (
      [
        SURVEY,
        TASK,
        DIRECTION,
        SUMMARY,
        JOURNAL,
        QUESTIONNAIRE,
        TAKEAWAYS,
        GUIDANCE,
        HOMEWORK,
      ].includes(activity?.phase_activity?.type) &&
      !showSurveyModal
    ) {
      dispatch(setShowSurveyModal(true));
    }
    navigate(
      `/${currentJourneySlug}/journey-phase/${step?.journey_phase?.slug}?activityId=${activity.id}`
    );
  };

  const handleApppppointmentSelection = (step: AppointmentProgress) => {
    dispatch(
      setTransitionEffect({
        showing: true,
        phaseSlug: step.journey_phase?.slug,
      })
    );
    navigate(
      `/${currentJourneySlug}/journey-phase/${step?.journey_phase?.slug}`
    );
  };

  // useEffect to set up a MutationObserver when the component mounts
  useEffect(() => {
    // Creating a new MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver((mutations) => {
      // Looping through each mutation that the observer detects
      mutations.forEach((mutation) => {
        // Checking if the mutation is an attribute change and the attribute is 'class'
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const targetElement = mutation.target as HTMLElement;
          // If the mutated element has the 'stepper-mutationobserver' class, calling the scrollPositionFunc
          if (targetElement.classList.contains("stepper-mutationobserver")) {
            scrollPositionFunc();
          }
        }
      });
    });

    // observing the entire document for attribute changes in all descendants
    observer.observe(document.documentElement, {
      subtree: true,
      attributes: true,
    });

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  // Function to adjust the scroll position so that the active element is centered in the parent container
  const scrollPositionFunc = () => {
    // Finding the element with the 'stepper-mutationobserver' class
    const activeElement = document.querySelector(".stepper-mutationobserver");
    if (activeElement && overflowingElementRef.current) {
      const parentElement = overflowingElementRef.current;
      const parentRect = parentElement.getBoundingClientRect();
      const activeRect = activeElement.getBoundingClientRect();

      // Checking if the active element is out of view of its parent container
      if (
        activeRect.top < parentRect.top ||
        activeRect.bottom > parentRect.bottom
      ) {
        // Adjusting the scroll position of the parent container to center the active element
        parentElement.scrollTop +=
          activeRect.top -
          parentRect.top -
          parentElement.clientHeight / 2 +
          activeRect.height / 2;
      }
    }
  };

  // useEffect to call scrollPositionFunc whenever the dependencies change
  useEffect(() => {
    scrollPositionFunc();
  }, [appointmentProgresses, currentActivityId, currentAppointmentId]); // Dependency array ensures scrollPositionFunc runs when any of these values change

  return (
    <>
      {appointmentProgresses.length > 0 && (
        <>
          <div className="pointer-events-none absolute bottom-0 left-0 w-1/3 h-[65px] left-nav-background" />
          <div
            className={` opacity-50 absolute w-[1px] z-10 bottom-0 h-[65px] left-[47px] bg-white`}
          />
          <div className="inline-block ps-10 w-1/3 fixed h-[calc(100vh-65px)] pointer-events-none left-nav-background">
            <div
              className={` opacity-50 absolute w-[1px] -z-[1] h-full top-0 left-[47px] bg-white`}
            />
            <div
              ref={overflowingElementRef}
              className="flex flex-col flex-shrink-0 h-[calc(100vh-65px)] overflow-auto scrollbar stepper_scroll hover:stepper_scroll relative"
            >
              {appointmentProgresses &&
                appointmentProgresses
                  ?.map((appointment: AppointmentProgress, index: number) => {
                    const isTransitionShowing = transitionEffect.showing;
                    const activePatientDetailAppointment =
                      inPatientInformationRoute &&
                      (appointment.journey_phase.slug === "patient-details" ||
                        appointment.journey_phase.slug ===
                        "patient-registration");
                    const isCurrentStep =
                      (CurrentPhaseData
                        ? appointment.id === CurrentPhaseData.id
                        : false) || activePatientDetailAppointment;
                    const isStepCompleted =
                      appointment?.status === AppointmentStatus.COMPLETED;
                    const showInStepper =
                      appointment.status !== AppointmentStatus.LOCKED;
                    const phaseActivities =
                      appointment.journey_phase?.patient_phase_activity;
                    if (showInStepper) {
                      return (
                        <div
                          className="pb-3.5 first:pt-2 last:h-[100vh] inline-block relative w-fit pointer-events-auto"
                          key={index}
                        >
                          <div
                            className={`flex flex-col items-start cursor-pointer`}
                          >
                            <>
                              <div
                                className={`pl-8 flex items-start flex-col `}
                              >
                                <div className={`flex flex-col`}>
                                  <div className="flex items-start gap-4">
                                    <div className="relative">
                                      <div
                                        onClick={() => {
                                          handleApppppointmentSelection(
                                            appointment
                                          );
                                        }}
                                        className={classNames(
                                          "transition-all duration-500 relative origin-left max-w-[300px] text-nowrap",
                                          isCurrentStep
                                            ? "font-bold text-lg text-white"
                                            : "text-sm hover:scale-125 font-normal text-gray-300"
                                        )}
                                      >
                                        {appointment?.journey_phase?.title}
                                      </div>
                                      <div>
                                        <div
                                          ref={circleElementRef}
                                          className={classNames(
                                            "step-circle rounded-full h-4 w-4 absolute -left-8 top-0 bottom-0 my-auto min-w-[16px]",
                                            isCurrentStep &&
                                            (isTransitionShowing
                                              ? "bg-green-600 stepper-mutationobserver"
                                              : "bg-white stepper-inactive"),
                                            !isCurrentStep &&
                                            isStepCompleted &&
                                            "bg-transparent"
                                          )}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {phaseActivities && (
                                  <>
                                    {phaseActivities
                                      ?.map(
                                        (
                                          activity: AppointmentActivity,
                                          idx: number
                                        ) => {
                                          const isCurrentActivity =
                                            activity.id === CurrentActivity?.id;
                                          const activePatientDetailActivity =
                                            inPatientInformationRoute &&
                                            activity.phase_activity.type ===
                                            AppointmentActivityTypes.PATIENT_INFORMATION;
                                          if (
                                            activity.status ===
                                            PhaseActivityStatus.COMPLETED ||
                                            ((isCurrentActivity || activePatientDetailActivity) &&
                                              !transitionEffect.showing)
                                          ) {
                                            return (
                                              <div
                                                className="flex flex-col mt-1"
                                                key={idx}
                                              >
                                                <div
                                                  className="flex items-center cursor-pointer gap-2"
                                                  onClick={() => { }}
                                                >
                                                  <div
                                                    ref={innerCircleElementRef}
                                                    className={`step-circle step-circle ${(isCurrentActivity &&
                                                      !isTransitionShowing) ||
                                                      activePatientDetailActivity
                                                      ? "bg-[#75FF35] stepper-mutationobserver"
                                                      : "bg-gray-400 stepper-inactive"
                                                      } rounded-full h-1.5 w-1.5`}
                                                  />
                                                  <div
                                                    className={`${isCurrentStep &&
                                                      ((isCurrentActivity &&
                                                        !isTransitionShowing) ||
                                                        activePatientDetailActivity)
                                                      ? "text-base font-semibold text-white"
                                                      : "text-sm text-gray-300"
                                                      } hover:scale-[1.15] transition-all duration-400  origin-left max-w-[300px]`}
                                                    onClick={() => {
                                                      handleActivitySelection(
                                                        activity,
                                                        appointment
                                                      );
                                                    }}
                                                  >
                                                    {
                                                      activity?.phase_activity
                                                        ?.title
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )
                                      .filter(Boolean)}
                                  </>
                                )}
                              </div>
                            </>
                          </div>
                        </div>
                      );
                    }
                  })
                  .filter(Boolean)}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Stepper;
